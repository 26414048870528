@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../fonts/fontawesome-webfont.woff?v=4.7.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
:root {
    --color-primary: var(--primaryCol);
    --color-primary-hover: var(--primaryCol-hover);
  }

.userbox .profile-icon:hover img {
    filter:  invert(0) sepia(0) saturate(14) hue-rotate(225deg);
  }
  .userbox .profile-icon img {
    filter: var(--color-primary);
  }

  .icon-svg-list div p span .icon-svg {
    filter: invert(0.5) sepia(1) saturate(0) hue-rotate(235deg);
  }
  
  .icon-svg-list div p:hover span .icon-svg {
    filter: invert(0.7) sepia(1) saturate(9) hue-rotate(235deg);
  }

  .vertical-menu > li > a > img {
    filter: invert(0.6) sepia(1) saturate(1) hue-rotate(185deg);
  }

  .vertical-menu > li:hover > a > img {
    filter: var(--color-primary);
  }
  
  .vertical-menu > li.active > a > img {
    filter: var(--color-primary);
  }

  .vertical-menu-rtl > li > a > img {
    filter: invert(0.6) sepia(1) saturate(1) hue-rotate(185deg);
  }

  .vertical-menu-rtl > li:hover > a > img {
    filter: invert(0.62) sepia(1) saturate(4.5) hue-rotate(199deg);
  }
  
  .vertical-menu-rtl > li.active > a > img {
    filter: invert(0.62) sepia(1) saturate(4.5) hue-rotate(199deg);
  }

  .topbar-mobile .mobile-togglebar a.topbar-toggle-hamburger img {
    filter: invert(0.62) sepia(1) saturate(4.5) hue-rotate(199deg);
  }

  .topbar-mobile .mobile-togglebar span.topbar-toggle-hamburger img {
    filter: invert(0.62) sepia(1) saturate(4.5) hue-rotate(199deg);
  }

  .topbar-mobile .mobile-togglebar .menu-hamburger img {
    filter: var(--color-primary);
  }

  .topbar .searchbar .btn img {
    filter: var(--color-primary);
  }

  .topbar .togglebar .menu-hamburger img {
    filter: var(--color-primary);
  }

  .topbar .infobar .infobar-icon img {
    filter: var(--color-primary);
  }

  .topbar .infobar .infobar-icon:hover img {
    filter: invert(0) sepia(0) saturate(14) hue-rotate(225deg);
  }

  .infobar-notifications-sidebar .infobar-notifications-sidebar-head .infobar-notifications-close:hover img {
    filter: invert(0) sepia(0) saturate(14) hue-rotate(225deg);
  }
  .infobar-notifications-sidebar .infobar-notifications-sidebar-head .infobar-notifications-close img {
    filter: var(--color-primary);
  }

  .infobar-settings-sidebar .infobar-settings-sidebar-head .infobar-settings-close:hover img {
    filter: invert(0) sepia(0) saturate(14) hue-rotate(225deg);
  }
  .infobar-settings-sidebar .infobar-settings-sidebar-head .infobar-settings-close img {
    filter: var(--color-primary);
  }

/* CUSTOM SCROLLBAR */

.cScrollBar {
  overflow-y: hidden;
  overflow-x: hidden;
  direction: ltr;
  scrollbar-color: var(--mainPrimaryCol);
  scrollbar-width: thin;
  position: relative;
}

.cScrollBar:hover {
  overflow-y: initial;
  overflow-x: hidden;
  direction: ltr;
  scrollbar-color: var(--mainPrimaryCol);
  scrollbar-width: thin;
}

.cScrollBar h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.cScrollBar p + p {
  margin-top: 1rem;
}

.cScrollBar::-webkit-scrollbar {
  width: 0.3rem;
  padding-right: 8px;
}
.cScrollBar::-webkit-scrollbar:hover {
  width: 0.3rem;
}

.cScrollBar::-webkit-scrollbar-track {
  background: transparent;
}

.cScrollBar::-webkit-scrollbar-thumb {
  background-color: var(--mainPrimaryCol);
}

.mnav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  overflow-x: auto;
}

.mnav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 13px;
  color: #444444;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.mnav__link:hover {
  background-color: #eeeeee;
}

.mnav__link--active {
  color: #009578;
}

.mnav__icon {
  font-size: 18px;
}

.profilebarBorder{
  border-bottom-color: #F2F2F2 !important;
    border-bottom: solid;
    border-bottom-width: 0.02rem;
}

.sideMenu{
  position: fixed;
    width: 80px;
    margin-left: 0;
    transition: all 0.3s ease;
}

.roleMenu{
  background: rgba(0, 0, 0, 0.4);
    position: fixed;
}

.cursor_Pointer{
  cursor: pointer;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

/* CUSTOM INPUT PREFIX */

.custom-input-prefix {
  position: relative;
  right: -3px;
  color: grey;
}

input.has-prefix {
  padding-left: 50px;
  margin-left: -50px
}

/* CUSTOM ATTENDANCE CALENDAR */

/*  Calendar wrapper */
.calendar-container {
	position: relative;
	margin: 0 auto;
	height: 100%;
	width: 100%;
	background: #fff;
	font: 13px Helvetica, Arial, san-serif;
	display: inline-block;
	padding: 20px;
	float: right; }
	@media (max-width: 991.98px) {
	  .calendar-container {
		padding: 0; } }
	@media (max-width: 767.98px) {
	  .calendar-container {
		padding: 0;
		width: 100%; } }
  
  .calendar-container:after {
	clear: both; }
  
  .calendar {
	width: 100%;
	padding: 0; }
  
  /* Calendar Header */
  .year-header {
	background: #fff;
	height: 40px;
	text-align: center;
	position: relative;
	color: #fff;
	border-top-left-radius: 3px; }
  
  .year-header span {
	display: inline-block;
	font-size: 20px;
	line-height: 40px;
	color: #000; }
  
  .left-button, .right-button {
	cursor: pointer;
	width: 28px;
	text-align: center;
	position: absolute;
	color: #cccccc !important;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	font-size: 14px !important; }
	@media (prefers-reduced-motion: reduce) {
	  .left-button, .right-button {
		-webkit-transition: none;
		-o-transition: none;
		transition: none; } }
	.left-button:hover, .right-button:hover {
	  color: var(--primary) !important; }
  
  .left-button {
	left: 0; }
  
  .right-button {
	right: 0;
	top: 0; }
  
  /* Buttons */
  .button {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	font-size: 1rem;
	border-radius: 25px;
	padding: 0.65rem 1.9rem;
	-webkit-transition: .2s ease all;
	-o-transition: .2s ease all;
	transition: .2s ease all;
	color: white;
	border: none;
	background: var(--primary); }
	.button.button-white {
	  background: #fff;
	  color: #000; }
	.button:focus {
	  -webkit-box-shadow: none;
	  box-shadow: none;
	  outline: none; }
  
  #cancel-button {
	background: #000; }
  
  #add-button {
	display: block;
	position: absolute;
	right: 20px;
	bottom: 20px; }
  
  #add-button:hover, #ok-button:hover, #cancel-button:hover {
	-webkit-transform: scale(1.03);
	-ms-transform: scale(1.03);
	transform: scale(1.03); }
  
  #add-button:active, #ok-button:active, #cancel-button:active {
	-webkit-transform: translateY(3px) scale(0.97);
	-ms-transform: translateY(3px) scale(0.97);
	transform: translateY(3px) scale(0.97); }
  
  /* Days/months tables */
  .days-table, .dates-table, .months-table {
	border-collapse: separate;
	text-align: center; }
  
  .day {
	height: 26px;
	width: 26px;
	padding: 0 10px;
	line-height: 26px;
	border: 2px solid transparent;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 400;
	color: #000; }
  
  .month {
	cursor: default;
	height: 26px;
	width: 26px;
	padding: 0 2px;
	padding-top: 10px;
	line-height: 26px;
	text-transform: uppercase;
	font-size: 11px;
	color: #cccccc;
	-webkit-transition: all 250ms;
	-o-transition: all 250ms;
	transition: all 250ms; }
	@media (max-width: 991.98px) {
	  .month {
		font-size: 8px; } }
	@media (max-width: 767.98px) {
	  .month {
		font-size: 10.5px; } }
  
  .active-month {
	font-weight: 700;
	color: var(--primary); }
  
  .month:hover {
	color: var(--primary); }
  
  /*  Dates table */
  .table-date {
	cursor: default;
	color: #2b2b2b;
	height: 26px;
	width: 26px;
	font-size: 15px;
	padding: 10px;
	line-height: 26px;
	text-align: center;
	border-radius: 50%;
	border: 1px solid transparent;
	-webkit-transition: all 250ms;
	-o-transition: all 250ms;
	transition: all 250ms;
	position: relative;
	z-index: 0; }
	.table-date:before {
	  position: absolute;
	  top: 50%;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  -webkit-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  transform: translateY(-50%);
	  width: 40px;
	  height: 40px;
	  content: '';
	  margin: 0 auto;
	  border-radius: 50%;
	  z-index: -1; }
  
  .event-date {
	border-color: var(--primary);
	background: var(--primary);
	color: #fff; }
	.event-date:after {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 10px;
	  height: 10px;
	  border-radius: 50%;
	  content: '';
	  background: var(--primary);
	  border: 1px solid white; }

	  .active-date-width{
		width: 8%;
	  }
  
  .active-date {
	color: #fff; }
	.active-date:before {
	  background: var(--primary); }
  
  .event-date.active-date {
	background: transparent;
	border: none; }

  /* QR CODE READER */

  #camcontainer {
    position: relative;
  }
  #camnavi {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  .ocrloader {
    width:80%;
    height:80%;
    outline-offset:10px;
    position:relative;
    position: absolute;
    left: 10%;
    top: 10%;
  }
  
  .ocrloader span::before {
    content:"";
    position:absolute;
    top:5%;
    bottom:0;
    left:4%;
    width:0;
    height: 90%;
    background:#ffffff;
    box-shadow:0 0 50px 10px #ffffff;
    clip-path:inset(0);
    animation:
      x 1s ease-in-out infinite alternate,
      y 1s   ease-in-out infinite;
  }
  
  .ocrloader p::before {
    content: '';
    display:inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
    position: relative;
    right: 4px;
  }
  
  .ocrloader p {
    color: #ffffff;
    position: absolute;
    bottom: -30px;
    left: 38%;
    font-size: 16px;
    font-weight: 600;
    animation: blinker 1s linear infinite;
    font-family: sans-serif;
    text-transform: uppercase;
  }
  
  .ocrloader:before,
  .ocrloader:after,
  .ocrloader em:after,
  .ocrloader em:before {
    border-color: #ffffff;
    content: "";
    position: absolute;
    width: 45px;
    height: 46px;
    border-style: solid;
    border-width: 0px;
  }
  
  .ocrloader:before {
    left: 0;
    top: 0;
    border-left-width: 5px;
    border-top-width: 5px;
    border-radius: 5px 0 0 0;
  }
  
  .ocrloader:after {
    right: 0;
    top: 0;
    border-right-width: 5px;
    border-top-width: 5px;
    border-radius: 0 5px 0 0;
  }
  .ocrloader em:before {
    left: 0;
    bottom: 0;
    border-left-width: 5px;
    border-bottom-width: 5px;
    border-radius: 0 0 0 5px;
  }
  .ocrloader em:after {
    right: 0;
    bottom: 0;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-radius: 0 0 5px 0;
  }
  
  @keyframes blinker {  
    50% { opacity: 0; }
  }
  

  /* QR CODE READER */

  /* PAGE BUILDER */

  /* PAGE BUILDER */

  .builderbox {
    position: relative;
height:auto;
justify-content: center;
align-items: center;
}
.builderhidden {
height:30px;
-webkit-transition:height, 0.5s linear;
-moz-transition: height, 0.5s linear;
-ms-transition: height, 0.5s linear;
-o-transition: height, 0.5s linear;
transition: height, 0.5s linear;
border: dashed 2px #c3c3c3;
}
.builderbox:hover .builderhidden {
height:150px;
background-color: #FFFFFF;
-webkit-transition:height, 0.5s linear;
-moz-transition: height, 0.5s linear;
-ms-transition: height, 0.5s linear;
-o-transition: height, 0.5s linear;
transition: height, 0.5s linear;
}

.builderbottomRow {
    display: block;
    width: 100%;
    height: 100%;
}
.buildericon {
    width: 50px;
    height: 50px;
    position: absolute;
    margin: -25px 0 0 -25px;
}
.builderbottomRow:hover {
    display: block;
    width: 100%;
    height: 100%;
}

.buildercustomBtnColorTitle {
    background-color: #ffffff !important;
    color: black;
    opacity: 1;
}

.buildercustomBtnColorTitle:hover {
    background-color: #ffffff !important;
    color: black;
    opacity: 1;
}

.buildercustomBtnColor {
    background-color: #ffffff !important;
    color: black;
}

.buildercustomBtnColor:hover {
    background-color: #168dff !important;
    color: #ffffff;
}

.buildercustomBtnColorDanger {
    background-color: #ffffff !important;
    color: black;
}

.buildercustomBtnColorDanger:hover {
    background-color: #f34d89 !important;
    color: #ffffff;
}

.builderdndcard {
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    overflow: hidden;
}

.buildertopright {
    position: absolute;
    display: none;
    top: 8px;
    right: 16px;
    font-size: 18px;
    border: none !important;
    z-index: 1000;
}

.buildertopleft {
    position: absolute;
    display: none;
    top: 8px;
    left: 16px;
    font-size: 18px;
    border: none !important;
    z-index: 1000;
}

.builderbottomright {
    position: absolute;
    display: none;
    top: 50px;
    right: 16px;
    font-size: 18px;
    border: none !important;
    z-index: 1000;
}

.builderbottomAdd{
    position: absolute;
    display: none;
    bottom: -2px;
    right: 50%;
    font-size: 18px;
    border: none !important;
}

.builderdndcard:hover .builderbottomAdd{
    position: absolute;
    display: block;
    bottom: -2px;
    right: 50%;
    font-size: 18px;
    border: none !important;
}

.builderdndcard:hover {
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    overflow: hidden;
    background-color: #ffffff;
}

.builderdndcard:hover .builderbottomright {
    display: block;
    animation: fade_in_show 0.5s;
}

.builderdndcard:not(:hover) .builderbottomright {
    display: none;
    animation: fade_out_show 0.5s;
}

.builderdndcard:hover .buildertopright {
    display: block;
    animation: fade_in_show 0.5s;
}

.builderdndcard:not(:hover) .buildertopright {
    display: none;
    animation: fade_out_show 0.5s;
}

.builderdndcard:hover .buildertopleft {
    display: block;
    animation: fade_in_show 0.5s;
}

.builderdndcard:not(:hover) .buildertopleft {
    display: none;
    animation: fade_out_show 0.5s;
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade_out_show {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.builderdndcard:before,
.builderdndcard:after {
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
}
.builderdndcard:before {
    top: 0;
}
.builderdndcard:after {
    bottom: 0;
}
.builderdndcard:hover:before,
.builderdndcard:hover:after {
    transform: translate3d(0, 0, 0);
}
.builderdndcard:hover > span:before,
.builderdndcard:hover > span:after {
    transform: translate3d(0, 0, 0);
}
.builderdndcard > span:before,
.builderdndcard > span:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
}
.builderdndcard > span:before {
    left: 0;
}
.builderdndcard > span:after {
    right: 0;
}
.builderdndcard:before,
.builderdndcard:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 9;
    height: 2px;
    width: 100%;
    background-color: #2150bf;
}
.builderdndcard:before {
    top: 0;
    transform: translate3d(-105%, 0, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.builderdndcard:after {
    bottom: 0;
    transform: translate3d(105%, 0, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.builderdndcard > span:before,
.builderdndcard > span:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 2px;
    background-color: #2150bf;
}
.builderdndcard > span:before {
    left: 0;
    transform: translate3d(0, 105%, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.builderdndcard > span:after {
    right: 0;
    transform: translate3d(0, -105%, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.buildertopRow {
    height: 10px;
}
.buildertopRow.active {
    height: 100px;
}