.footer-content {
    height: 70vh; }
  
  .footer-footer-16371 {
    padding: 4rem 0;
    font-family: "Poppins", sans-serif; }
    .footer-footer-16371 .footer-footer-site-logo {
      text-align: center;
      font-size: 2rem;
      font-weight: 900; }
      .footer-footer-16371 .footer-footer-site-logo a {
        color: #4200ff; }
    .footer-footer-16371 .footer-nav-links {
      padding: 0;
      margin: 0; }
      .footer-footer-16371 .footer-nav-links li {
        display: inline-block; }
        .footer-footer-16371 .footer-nav-links li a {
          padding: 10px;
          color: #000; }
          .footer-footer-16371 .footer-nav-links li a:hover {
            color: #4200ff; }
    .footer-footer-16371 .footer-social h3 {
      font-weight: bold;
      font-size: 18px;
      color: #000; }
    .footer-footer-16371 .footer-social ul li {
      display: inline-block; }
      .footer-footer-16371 .footer-social ul li a {
        display: inline-block;
        padding: 10px; }
      .footer-footer-16371 .footer-social ul li.in a {
        color: #df588a; }
      .footer-footer-16371 .footer-social ul li.tw a {
        color: #15b8ff; }
      .footer-footer-16371 .footer-social ul li.fb a {
        color: #3742d0; }
      .footer-footer-16371 .footer-social ul li.dr a {
        color: #fa38c1; }
      .footer-footer-16371 .footer-social ul li.pin a {
        color: #dd4d5a; }
    .footer-footer-16371 .footer-copyright {
      color: #999999; }