.contact-container{
    width: 100%;
    background: #fff;
    
    padding: 20px 60px 30px 40px;
    
  }
  .contact-container .contact-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contact-container .contact-content .contact-left-side{
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .contact-content .contact-left-side::before{
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
  }
  .contact-content .contact-left-side .contact-details{
    margin: 14px;
    text-align: center;
  }
  .contact-content .contact-left-side .contact-details i{
    font-size: 30px;
    color: var(--mainPrimaryCol);
    margin-bottom: 10px;
  }
  .contact-content .contact-left-side .contact-details .contact-topic{
    font-size: 18px;
    font-weight: 500;
  }
  .contact-content .contact-left-side .contact-details .contact-text-one,
  .contact-content .contact-left-side .contact-details .contact-text-two{
    font-size: 14px;
    color: #afafb6;
  }
  .container .contact-content .contact-right-side{
    width: 65%;
    margin-left: 75px;
  }
  .contact-content .contact-right-side .contact-topic-text{
    font-size: 23px;
    font-weight: 600;
    color: var(--mainPrimaryCol);
  }
  .contact-right-side .contact-input-box{
    height: 50px;
    width: 100%;
    margin: 12px 0;
  }
  .contact-right-side .contact-input-box input,
  .contact-right-side .contact-input-box textarea{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .contact-right-side .contact-message-box{
    min-height: 110px;
  }
  .contact-right-side .contact-input-box textarea{
    padding-top: 6px;
  }
  .contact-right-side .contact-button{
    display: inline-block;
    margin-top: 12px;
  }
  .contact-right-side .contact-button input[type="submit"]{
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: var(--mainPrimaryCol);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .contact-button input[type="submit"]:hover{
    background: #5029bc;
  }

  @media (min-width: 951px) {
    .contact-container{
      width: 90%;
      padding: 30px 40px 40px 35px ;
    }
    .contact-container .contact-content .contact-right-side{
     width: 75%;
     margin-left: 55px;
     position: relative;
  }
  }
  
  @media (max-width: 950px) {
    .contact-container{
      width: 90%;
      padding: 30px 40px 40px 35px ;
    }
    .contact-container .contact-content .contact-right-side{
     width: 75%;
     margin-left: 55px;
     position: relative;
  }
  }
  @media (max-width: 820px) {
    .contact-container{
      margin: 40px 0;
      height: 100%;
    }
    .contact-container .contact-content{
      flex-direction: column-reverse;
    }
   .contact-container .contact-content .contact-left-side{
     width: 100%;
     flex-direction: row;
     margin-top: 40px;
     justify-content: center;
     flex-wrap: wrap;
   }
   .contact-container .contact-content .contact-left-side::before{
     display: none;
   }
   .contact-container .contact-content .contact-right-side{
     width: 100%;
     margin-left: 0;
     position: relative;
   }
  }