.q1-blockquote {
    border-left: 3px solid var(--mainPrimaryCol);
    color: #1a1a1a;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-size: 1.25em;
    font-style: italic;
    line-height: 1.8em;
    margin: 1.1em;
    padding: 1em 2em;
    position: relative;
    transition: 0.2s border ease-in-out;
    z-index: 0;
  }
  .q1-blockquote:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -4px;
    height: 2em;
    background-color: #fff;
    width: 5px;
    margin-top: -1em;
  }
  .q1-blockquote:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -0.5em;
    color: var(--mainPrimaryCol);
    font-family: "Ionicons";
    font-style: normal;
    line-height: 1em;
    text-align: center;
    text-indent: -2px;
    width: 1em;
    margin-top: -0.5em;
    transition: 0.2s all ease-in-out, 0.4s transform ease-in-out;
  }
  .q1-blockquote.format:before, blockquote.format:after {
    display: none;
  }
  .q1-blockquote.book:after {
    content: "";
  }
  .q1-blockquote.podcast:after {
    content: "";
  }
  .q1-blockquote.lyrics:after {
    content: "";
  }
  .q1-blockquote.favorite:after {
    content: "";
  }
  .q1-blockquote:active:after {
    transform: rotateY(360deg);
  }
  .q1-blockquote cite {
    display: block;
    font-size: 0.75em;
    line-height: 1.8em;
    margin-top: 1em;
  }
  
  @media (max-width: 980px) {
  
    .q1-blockquote {
      font-size: 1.1em;
      margin: 1.1em;
    }
  }
  @media (max-width: 767px) {
    
    .q1-blockquote {
      border-top: 2px solid var(--mainPrimaryCol);
      border-bottom: 2px solid var(--mainPrimaryCol);
      border-left: none;
      margin: 1.5em 0;
      padding: 1.5em 1em;
    }
    .q1-blockquote:before {
      left: 50%;
      top: 0;
      height: 4px;
      margin-top: -3px;
      margin-left: -1em;
      width: 2em;
    }
    .q1-blockquote:after {
      font-size: 0.75em;
      left: 50%;
      top: 0;
      margin-top: -0.5em;
      margin-left: -0.5em;
    }
    .q1-blockquote cite {
      text-align: right;
    }
  }