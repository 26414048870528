.hero-caption {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: white;
    z-index: 2;
    font-size: 0.6rem;
  }
  
  .hero-parallax-wrapper {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    perspective: 10px;
  }
  
  .hero-parallax-content {
    position: relative;
    width: 100vw;
    height: 90vh;
  }
  
  .hero-hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
  }
  .hero-hero img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 1;
    transform: translateZ(1px);
  }
  .hero-hero .hero-hero__title {
    width: 40%;
    z-index: 2;
    text-align: left;
    transform: translateZ(-2px) scale(1.2);
    margin: 10%;
  }
  .hero-hero .hero-hero__title p {
    margin-top: 0.5rem;
    font-size: calc(0.6rem + 0.75vmin);
  }
  
  .hero-hero::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 0 100%;
    transform: translateZ(8px);
    pointer-events: none;
    z-index: 3;
  }
  
  