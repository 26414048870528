@media print {
    body {
      visibility: hidden;
    }
    #section-to-print {
        width: 100%;
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
    }
    #print-button{
        visibility: hidden;
    }
  }