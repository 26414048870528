@charset "UTF-8";
/* @import url("../fonts/NunitoSans.ttf"); */
@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/nunitosans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
:root {
    --main-color-primary: var(--mainPrimaryCol);
    --main-color-primary-hover: var(--mainPrimaryColHover);
}
html {
    position: relative;
    min-height: 100%;
}
body {
    font-family: "Nunito Sans", sans-serif;
    margin: 0;
    background: #f5f9fc;
    font-size: 16px;
    font-weight: 400;
    color: #8a98ac;
    line-height: 1.5;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: #282828;
}
h1 {
    font-size: 40px;
}
h2 {
    font-size: 32px;
}
h3 {
    font-size: 28px;
}
h4 {
    font-size: 24px;
}
h5 {
    font-size: 20px;
}
h6 {
    font-size: 16px;
}
b {
    font-weight: 700;
}
strong {
    font-weight: 700;
}
p {
    line-height: 1.3;
    margin-bottom: 10px;
}
label {
    vertical-align: middle;
}
* {
    outline: none !important;
}
a {
    color: var(--main-color-primary);
}
a:hover {
    color: var(--main-color-primary-hover);
    outline: 0;
    text-decoration: none;
}
a:active {
    color: var(--main-color-primary-hover);
    outline: 0;
    text-decoration: none;
}
a:focus {
    color: var(--main-color-primary-hover);
    outline: 0;
    text-decoration: none;
}
code {
    color: var(--main-color-primary);
}
.logobarHo {
    
    text-align: center;
}
.logobarHo .logo.logo-small {
    display: none;
}
.logobarHo .logo img {
    width: 180px;
}
.leftbar {
    width: 250px;
    height: 100%;
    position: fixed;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    z-index: 9;
    transition: all 0.3s ease;
}
.profilebar {
    padding: 15px 30px 25px 30px;
}
.profilebar img {
    width: 50px;
}
.profilename {
    padding: 10px 0;
}
.profilename h5 {
    margin-bottom: 5px;
    font-size: 18px;
}
.profilename p {
    color: #282828;
    opacity: 0.6;
    margin-bottom: 5px;
    font-size: 15px;
}
.userbox .profile-icon {
    background-color: rgba(110, 129, 220, 0.1);
    padding: 7px 10px 11px;
    color: #282828;
    border-radius: 50%;
}
.userbox .profile-icon:hover {
    background-color: var(--main-color-primary);
}
.userbox .profile-icon:hover img {
    width: 20px;
}
.userbox .profile-icon img {
    width: 20px;
}
.rightbar {
    margin-left: 250px;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    min-height: 500px;
    transition: all 0.3s ease;
}
.contentbar {
    padding: 30px;
    margin-bottom: 30px;
}
.footerbar {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 250px;
    padding: 20px 30px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.toggle-menu .footerbar {
    left: 0;
}
.slimScrollBar {
    z-index: 0 !important;
}
.navigationbar {
    height: calc(100vh - 100px);
    overflow: auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
/* ::-webkit-scrollbar {
    width: 0.3rem;
}
::-webkit-scrollbar-track {
}
::-webkit-scrollbar-thumb {
    background: var(--main-color-primary);
}
::-webkit-scrollbar-thumb:hover {
    background: var(--main-color-primary);
} */
.p-t-5 {
    padding-top: 5px;
}
.p-t-10 {
    padding-top: 10px;
}
.p-t-15 {
    padding-top: 15px;
}
.p-t-20 {
    padding-top: 20px;
}
.p-t-25 {
    padding-top: 25px;
}
.p-t-30 {
    padding-top: 30px;
}
.p-t-35 {
    padding-top: 35px;
}
.p-t-40 {
    padding-top: 40px;
}
.p-t-45 {
    padding-top: 45px;
}
.p-t-50 {
    padding-top: 50px;
}
.p-r-5 {
    padding-right: 5px;
}
.p-r-10 {
    padding-right: 10px;
}
.p-r-15 {
    padding-right: 15px;
}
.p-r-20 {
    padding-right: 20px;
}
.p-r-25 {
    padding-right: 25px;
}
.p-r-30 {
    padding-right: 30px;
}
.p-r-35 {
    padding-right: 35px;
}
.p-r-40 {
    padding-right: 40px;
}
.p-r-45 {
    padding-right: 45px;
}
.p-r-50 {
    padding-right: 50px;
}
.p-b-5 {
    padding-bottom: 5px;
}
.p-b-10 {
    padding-bottom: 10px;
}
.p-b-15 {
    padding-bottom: 15px;
}
.p-b-20 {
    padding-bottom: 20px;
}
.p-b-25 {
    padding-bottom: 25px;
}
.p-b-30 {
    padding-bottom: 30px;
}
.p-b-35 {
    padding-bottom: 35px;
}
.p-b-40 {
    padding-bottom: 40px;
}
.p-b-45 {
    padding-bottom: 45px;
}
.p-b-50 {
    padding-bottom: 50px;
}
.p-l-5 {
    padding-left: 5px;
}
.p-l-10 {
    padding-left: 10px;
}
.p-l-15 {
    padding-left: 15px;
}
.p-l-20 {
    padding-left: 20px;
}
.p-l-25 {
    padding-left: 25px;
}
.p-l-30 {
    padding-left: 30px;
}
.p-l-35 {
    padding-left: 35px;
}
.p-l-40 {
    padding-left: 40px;
}
.p-l-45 {
    padding-left: 45px;
}
.p-l-50 {
    padding-left: 50px;
}
.m-t-5 {
    margin-top: 5px;
}
.m-t-10 {
    margin-top: 10px;
}
.m-t-15 {
    margin-top: 15px;
}
.m-t-20 {
    margin-top: 20px;
}
.m-t-25 {
    margin-top: 25px;
}
.m-t-30 {
    margin-top: 30px;
}
.m-t-35 {
    margin-top: 35px;
}
.m-t-40 {
    margin-top: 40px;
}
.m-t-45 {
    margin-top: 45px;
}
.m-t-50 {
    margin-top: 50px;
}
.m-r-5 {
    margin-right: 5px;
}
.m-r-10 {
    margin-right: 10px;
}
.m-r-15 {
    margin-right: 15px;
}
.m-r-20 {
    margin-right: 20px;
}
.m-r-25 {
    margin-right: 25px;
}
.m-r-30 {
    margin-right: 30px;
}
.m-r-35 {
    margin-right: 35px;
}
.m-r-40 {
    margin-right: 40px;
}
.m-r-45 {
    margin-right: 45px;
}
.m-r-50 {
    margin-right: 50px;
}
.m-b-5 {
    margin-bottom: 5px;
}
.m-b-10 {
    margin-bottom: 10px;
}
.m-b-15 {
    margin-bottom: 15px;
}
.m-b-20 {
    margin-bottom: 20px;
}
.m-b-25 {
    margin-bottom: 25px;
}
.m-b-30 {
    margin-bottom: 30px;
}
.m-b-35 {
    margin-bottom: 35px;
}
.m-b-40 {
    margin-bottom: 40px;
}
.m-b-45 {
    margin-bottom: 45px;
}
.m-b-50 {
    margin-bottom: 50px;
}
.m-l-5 {
    margin-left: 5px;
}
.m-l-10 {
    margin-left: 10px;
}
.m-l-15 {
    margin-left: 15px;
}
.m-l-20 {
    margin-left: 20px;
}
.m-l-25 {
    margin-left: 25px;
}
.m-l-30 {
    margin-left: 30px;
}
.m-l-35 {
    margin-left: 35px;
}
.m-l-40 {
    margin-left: 40px;
}
.m-l-45 {
    margin-left: 45px;
}
.m-l-50 {
    margin-left: 50px;
}
.l-h-20 {
    line-height: 20px;
}
.l-h-22 {
    line-height: 22px;
}
.l-h-24 {
    line-height: 24px;
}
.l-h-26 {
    line-height: 26px;
}
.l-h-28 {
    line-height: 28px;
}
.l-h-30 {
    line-height: 30px;
}
.l-h-32 {
    line-height: 32px;
}
.l-h-34 {
    line-height: 34px;
}
.l-h-36 {
    line-height: 36px;
}
.l-h-38 {
    line-height: 38px;
}
.l-h-40 {
    line-height: 40px;
}
.font-10 {
    font-size: 10px !important;
}
.font-11 {
    font-size: 11px !important;
}
.font-12 {
    font-size: 12px !important;
}
.font-13 {
    font-size: 13px !important;
}
.font-14 {
    font-size: 14px !important;
}
.font-15 {
    font-size: 15px !important;
}
.font-16 {
    font-size: 16px !important;
}
.font-17 {
    font-size: 17px !important;
}
.font-18 {
    font-size: 18px !important;
}
.font-19 {
    font-size: 19px !important;
}
.font-20 {
    font-size: 20px !important;
}
.font-21 {
    font-size: 21px !important;
}
.font-22 {
    font-size: 22px !important;
}
.font-23 {
    font-size: 23px !important;
}
.font-24 {
    font-size: 24px !important;
}
.font-25 {
    font-size: 25px !important;
}
.font-26 {
    font-size: 26px !important;
}
.font-27 {
    font-size: 27px !important;
}
.font-28 {
    font-size: 28px !important;
}
.font-29 {
    font-size: 29px !important;
}
.font-30 {
    font-size: 30px !important;
}
.font-31 {
    font-size: 31px !important;
}
.font-32 {
    font-size: 32px !important;
}
.font-33 {
    font-size: 33px !important;
}
.font-34 {
    font-size: 34px !important;
}
.font-35 {
    font-size: 35px !important;
}
.font-36 {
    font-size: 36px !important;
}
.font-37 {
    font-size: 37px !important;
}
.font-38 {
    font-size: 38px !important;
}
.font-39 {
    font-size: 39px !important;
}
.font-40 {
    font-size: 40px !important;
}
.f-w-3 {
    font-weight: 300;
}
.f-w-4 {
    font-weight: 400;
}
.f-w-5 {
    font-weight: 500;
}
.f-w-6 {
    font-weight: 600;
}
.f-w-7 {
    font-weight: 700;
}
.b-r-1 {
    border-radius: 1px;
}
.b-r-2 {
    border-radius: 2px;
}
.b-r-3 {
    border-radius: 3px;
}
.b-r-4 {
    border-radius: 4px;
}
.b-r-5 {
    border-radius: 5px;
}
.b-r-6 {
    border-radius: 6px;
}
.b-r-7 {
    border-radius: 7px;
}
.b-r-8 {
    border-radius: 8px;
}
.b-r-9 {
    border-radius: 9px;
}
.b-r-10 {
    border-radius: 10px;
}
.b-r-15 {
    border-radius: 15px;
}
.b-r-20 {
    border-radius: 20px;
}
.b-r-25 {
    border-radius: 25px;
}
.b-r-30 {
    border-radius: 30px;
}
.b-r-35 {
    border-radius: 35px;
}
.b-r-40 {
    border-radius: 40px;
}
.b-r-45 {
    border-radius: 45px;
}
.b-r-50 {
    border-radius: 50px;
}
.b-r-100 {
    border-radius: 100px;
}
.chart-height {
    height: 320px;
}
.v-a-m {
    vertical-align: middle;
}
.psn-abs {
    position: absolute;
}
.bx-shadow {
    -moz-box-shadow: 0 0 30px 0 rgba(200, 200, 200, 0.2);
    -webkit-box-shadow: 0 0 30px 0 rgba(200, 200, 200, 0.2);
    box-shadow: 0 0 30px 0 rgba(200, 200, 200, 0.2);
}
.bg-primary-gradient {
    background-image: linear-gradient(58deg, var(--main-color-primary) 0, #969efa 100%);
}
.bg-secondary-gradient {
    background-image: linear-gradient(58deg, #718093 0, #e6e8f0 100%);
}
.bg-success-gradient {
    background-image: linear-gradient(58deg, #5fc27e 0, #45e396 100%);
}
.bg-danger-gradient {
    background-image: linear-gradient(58deg, #f44455 0, #ff8181 100%);
}
.bg-warning-gradient {
    background-image: linear-gradient(58deg, #fcc100 0, #fef07f 100%);
}
.bg-info-gradient {
    background-image: linear-gradient(58deg, #72d0fb 0, #a7daf5 100%);
}
.bg-light-gradient {
    background-image: linear-gradient(58deg, #dcdde1 0, #f8fbff 100%);
}
.bg-dark-gradient {
    background-image: linear-gradient(58deg, #2d3646 0, #4c4c4c 100%);
}
.text-primary-gradient {
    background: -webkit-linear-gradient(#4cc6f3, var(--main-color-primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-secondary-gradient {
    background: -webkit-linear-gradient(#cfd2d6, #718093);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-success-gradient {
    background: -webkit-linear-gradient(#9acd2b, #5fc27e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-danger-gradient {
    background: -webkit-linear-gradient(#ff7e4b, #f44455);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-warning-gradient {
    background: -webkit-linear-gradient(#faec51, #fcc100);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-info-gradient {
    background: -webkit-linear-gradient(#2ce9ad, #72d0fb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-light-gradient {
    background: -webkit-linear-gradient(#f3f3f3, #dcdde1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-dark-gradient {
    background: -webkit-linear-gradient(#666666, #2d3646);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.btn-shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.primary-rgba {
    background-color: rgba(110, 129, 220, 0.1);
}
.secondary-rgba {
    background-color: rgba(113, 128, 147, 0.1);
}
.success-rgba {
    background-color: rgba(95, 194, 126, 0.1);
}
.danger-rgba {
    background-color: rgba(244, 68, 85, 0.1);
}
.warning-rgba {
    background-color: rgba(252, 193, 0, 0.1);
}
.info-rgba {
    background-color: rgba(114, 208, 251, 0.1);
}
.light-rgba {
    background-color: rgba(220, 221, 225, 0.1);
}
.dark-rgba {
    background-color: rgba(45, 54, 70, 0.1);
}
.icon-box-list div {
    color: #8a98ac;
    border-radius: 50px;
    line-height: 38px;
    white-space: nowrap;
}
.icon-box-list div p {
    margin: 10px;
    line-height: inherit;
}
.icon-box-list div p i {
    display: inline-block;
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;
    margin-right: 12px;
    margin-bottom: 1px;
    margin-top: 1px;
    transition: transform 0.2s;
}
.icon-box-list div p i.flag {
    background-size: cover;
    background-position: center;
}
.icon-box-list div p span.text-alias {
    color: #d4d4d4;
}
.icon-box-list div:hover p {
    color: var(--main-color-primary);
}
.icon-box-list div:hover p i {
    color: var(--main-color-primary);
    background-color: rgba(110, 129, 220, 0.1) !important;
    transform: scale(1.2);
}
.icon-box-list div:hover p span.text-alias {
    color: var(--main-color-primary);
}
.icon-svg-list div p span {
    padding: 9px 12px 13px;
    border-radius: 50%;
    margin-right: 12px;
    width: 40px;
    height: 40px;
}
.icon-svg-list div p span .icon-svg {
    width: 20px;
    transition: transform 0.2s;
}
.icon-svg-list div p:hover span {
    background-color: rgba(110, 129, 220, 0.1) !important;
}
.icon-svg-list div p:hover span .icon-svg {
    transform: scale(1.2);
}
.example-row .grid-example {
    position: relative;
    padding: 1rem;
    margin: 1rem -15px 0;
    border: solid #fefeff;
    border-width: 0.2rem 0 0;
}
.example-row .row > .col,
.example-row .row > [class^="col-"] {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: rgba(110, 129, 220, 0.1);
    border: 1px solid #c0c8f0;
    color: var(--main-color-primary);
}
.example-row .row + .row {
    margin-top: 1rem;
}
.example-row.example-row-flex-cols .row {
    min-height: 10rem;
    background-color: #e9ecfa;
}
@media (min-width: 576px) {
    .grid-example {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 0.2rem;
    }
}
.docs-data > .input-group > span {
    min-width: 2rem !important;
}
.custom-checkbox-button input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    outline: 0 !important;
    z-index: 1;
}
.custom-checkbox-button input[type="checkbox"]:checked + label:after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}
.custom-checkbox-button label {
    position: relative;
    display: inline-block;
}
.custom-checkbox-button label:before {
    content: "";
    position: absolute;
    top: 0;
    left: -18px;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 3px;
    border: 2px solid transparent;
    outline: 0 !important;
}
.custom-checkbox-button label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: -12px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 3px;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
}
.custom-checkbox-button .checkbox-primary input[type="checkbox"] + label:before {
    border-color: var(--main-color-primary);
}
.custom-checkbox-button .checkbox-primary input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-primary input[type="checkbox"]:checked + label:before {
    background-color: var(--main-color-primary);
}
.custom-checkbox-button .checkbox-primary input[type="checkbox"]:checked + label:after {
    background-color: var(--main-color-primary);
}
.custom-checkbox-button .checkbox-secondary input[type="checkbox"] + label:before {
    border-color: #718093;
}
.custom-checkbox-button .checkbox-secondary input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-secondary input[type="checkbox"]:checked + label:before {
    background-color: #718093;
}
.custom-checkbox-button .checkbox-secondary input[type="checkbox"]:checked + label:after {
    background-color: #718093;
}
.custom-checkbox-button .checkbox-success input[type="checkbox"] + label:before {
    border-color: #5fc27e;
}
.custom-checkbox-button .checkbox-success input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-success input[type="checkbox"]:checked + label:before {
    background-color: #5fc27e;
}
.custom-checkbox-button .checkbox-success input[type="checkbox"]:checked + label:after {
    background-color: #5fc27e;
}
.custom-checkbox-button .checkbox-danger input[type="checkbox"] + label:before {
    border-color: #f44455;
}
.custom-checkbox-button .checkbox-danger input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-danger input[type="checkbox"]:checked + label:before {
    background-color: #f44455;
}
.custom-checkbox-button .checkbox-danger input[type="checkbox"]:checked + label:after {
    background-color: #f44455;
}
.custom-checkbox-button .checkbox-warning input[type="checkbox"] + label:before {
    border-color: #fcc100;
}
.custom-checkbox-button .checkbox-warning input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-warning input[type="checkbox"]:checked + label:before {
    background-color: #fcc100;
}
.custom-checkbox-button .checkbox-warning input[type="checkbox"]:checked + label:after {
    background-color: #fcc100;
}
.custom-checkbox-button .checkbox-info input[type="checkbox"] + label:before {
    border-color: #72d0fb;
}
.custom-checkbox-button .checkbox-info input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-info input[type="checkbox"]:checked + label:before {
    background-color: #72d0fb;
}
.custom-checkbox-button .checkbox-info input[type="checkbox"]:checked + label:after {
    background-color: #72d0fb;
}
.custom-checkbox-button .checkbox-light input[type="checkbox"] + label:before {
    border-color: #dcdde1;
}
.custom-checkbox-button .checkbox-light input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-light input[type="checkbox"]:checked + label:before {
    background-color: #dcdde1;
}
.custom-checkbox-button .checkbox-light input[type="checkbox"]:checked + label:after {
    background-color: #dcdde1;
}
.custom-checkbox-button .checkbox-dark input[type="checkbox"] + label:before {
    border-color: #2d3646;
}
.custom-checkbox-button .checkbox-dark input[type="checkbox"] + label:after {
    border-color: #ffffff;
}
.custom-checkbox-button .checkbox-dark input[type="checkbox"]:checked + label:before {
    background-color: #2d3646;
}
.custom-checkbox-button .checkbox-dark input[type="checkbox"]:checked + label:after {
    background-color: #2d3646;
}
.custom-radio-button input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    outline: 0 !important;
    z-index: 1;
}
.custom-radio-button input[type="radio"]:checked + label:after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}
.custom-radio-button label {
    position: relative;
    display: inline-block;
}
.custom-radio-button label:before {
    content: "";
    position: absolute;
    top: 0;
    left: -18px;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid transparent;
    outline: 0 !important;
}
.custom-radio-button label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: -12px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
}
.custom-radio-button .radio-primary input[type="radio"] + label:before {
    border-color: var(--main-color-primary);
}
.custom-radio-button .radio-primary input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-primary input[type="radio"]:checked + label:before {
    background-color: var(--main-color-primary);
}
.custom-radio-button .radio-primary input[type="radio"]:checked + label:after {
    background-color: var(--main-color-primary);
}
.custom-radio-button .radio-secondary input[type="radio"] + label:before {
    border-color: #718093;
}
.custom-radio-button .radio-secondary input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-secondary input[type="radio"]:checked + label:before {
    background-color: #718093;
}
.custom-radio-button .radio-secondary input[type="radio"]:checked + label:after {
    background-color: #718093;
}
.custom-radio-button .radio-success input[type="radio"] + label:before {
    border-color: #5fc27e;
}
.custom-radio-button .radio-success input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-success input[type="radio"]:checked + label:before {
    background-color: #5fc27e;
}
.custom-radio-button .radio-success input[type="radio"]:checked + label:after {
    background-color: #5fc27e;
}
.custom-radio-button .radio-danger input[type="radio"] + label:before {
    border-color: #f44455;
}
.custom-radio-button .radio-danger input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-danger input[type="radio"]:checked + label:before {
    background-color: #f44455;
}
.custom-radio-button .radio-danger input[type="radio"]:checked + label:after {
    background-color: #f44455;
}
.custom-radio-button .radio-warning input[type="radio"] + label:before {
    border-color: #fcc100;
}
.custom-radio-button .radio-warning input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-warning input[type="radio"]:checked + label:before {
    background-color: #fcc100;
}
.custom-radio-button .radio-warning input[type="radio"]:checked + label:after {
    background-color: #fcc100;
}
.custom-radio-button .radio-info input[type="radio"] + label:before {
    border-color: #72d0fb;
}
.custom-radio-button .radio-info input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-info input[type="radio"]:checked + label:before {
    background-color: #72d0fb;
}
.custom-radio-button .radio-info input[type="radio"]:checked + label:after {
    background-color: #72d0fb;
}
.custom-radio-button .radio-light input[type="radio"] + label:before {
    border-color: #dcdde1;
}
.custom-radio-button .radio-light input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-light input[type="radio"]:checked + label:before {
    background-color: #dcdde1;
}
.custom-radio-button .radio-light input[type="radio"]:checked + label:after {
    background-color: #dcdde1;
}
.custom-radio-button .radio-dark input[type="radio"] + label:before {
    border-color: #2d3646;
}
.custom-radio-button .radio-dark input[type="radio"] + label:after {
    border-color: #ffffff;
}
.custom-radio-button .radio-dark input[type="radio"]:checked + label:before {
    background-color: #2d3646;
}
.custom-radio-button .radio-dark input[type="radio"]:checked + label:after {
    background-color: #2d3646;
}
.border {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.border-primary {
    border-color: var(--main-color-primary) !important;
}
.border-secondary {
    border-color: #718093 !important;
}
.border-success {
    border-color: #5fc27e !important;
}
.border-danger {
    border-color: #f44455 !important;
}
.border-warning {
    border-color: #fcc100 !important;
}
.border-info {
    border-color: #72d0fb !important;
}
.border-light {
    border-color: #dcdde1 !important;
}
.border-dark {
    border-color: #2d3646 !important;
}
.breadcrumb {
    display: inline-flex;
    background-color: transparent;
    margin: 0;
    padding: 10px 0 0;
}
.toast {
    background-color: #ffffff;
    width: 320px;
    border: none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;
}
.toast .toast-header {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast .toast-header .toast-title {
    font-size: 16px;
    font-weight: 600;
    color: #282828;
}
.toast .toast-header .close {
    color: #282828;
    background-color: transparent;
}
.dropdown-menu {
    padding: 5px 0;
    font-size: 16px;
    border: none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}
.dropdown-menu .dropdown-item {
    padding: 0.5rem 1.5rem;
    color: #8a98ac;
}
.dropdown-menu .dropdown-item.active {
    background-color: rgba(0, 0, 0, 0.05);
    color: #282828;
    text-decoration: none;
}
.dropdown-menu .dropdown-item:active {
    background-color: rgba(0, 0, 0, 0.05);
    color: #282828;
    text-decoration: none;
}
.dropdown-menu .dropdown-item:hover {
    color: #282828;
    background-color: rgba(0, 0, 0, 0.05);
}
.dropdown-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.bg-primary {
    background-color: var(--main-color-primary) !important;
}
.bg-secondary {
    background-color: #718093 !important;
}
.bg-success {
    background-color: #5fc27e !important;
}
.bg-danger {
    background-color: #f44455 !important;
}
.bg-warning {
    background-color: #fcc100 !important;
}
.bg-info {
    background-color: #72d0fb !important;
}
.bg-light {
    background-color: #dcdde1 !important;
}
.bg-dark {
    background-color: #2d3646 !important;
}
.bg-muted {
    background-color: #8a98ac !important;
}
.bg-white {
    background-color: #ffffff !important;
}
.bg-primary-rgba {
    background-color: rgba(110, 129, 220, 0.1) !important;
}
.bg-secondary-rgba {
    background-color: rgba(113, 128, 147, 0.1) !important;
}
.bg-success-rgba {
    background-color: rgba(95, 194, 126, 0.1) !important;
}
.bg-danger-rgba {
    background-color: rgba(244, 68, 85, 0.1) !important;
}
.bg-warning-rgba {
    background-color: rgba(252, 193, 0, 0.1) !important;
}
.bg-info-rgba {
    background-color: rgba(114, 208, 251, 0.1) !important;
}
.bg-light-rgba {
    background-color: rgba(220, 221, 225, 0.1) !important;
}
.bg-dark-rgba {
    background-color: rgba(45, 54, 70, 0.1) !important;
}
.text-white {
    color: #ffffff !important;
}
.text-black {
    color: #282828 !important;
}
.text-muted {
    color: #8a98ac !important;
}
.text-primary {
    color: var(--main-color-primary) !important;
}
.text-secondary {
    color: #718093 !important;
}
.text-success {
    color: #5fc27e !important;
}
.text-danger {
    color: #f44455 !important;
}
.text-warning {
    color: #fcc100 !important;
}
.text-info {
    color: #72d0fb !important;
}
.text-light {
    color: #dcdde1 !important;
}
.text-dark {
    color: #2d3646 !important;
}
a.text-primary:focus {
    color: var(--main-color-primary-hover) !important;
}
a.text-primary:hover {
    color: var(--main-color-primary-hover) !important;
}
a.text-secondary:focus {
    color: #657385 !important;
}
a.text-secondary:hover {
    color: #657385 !important;
}
a.text-success:focus {
    color: #4dbb6f !important;
}
a.text-success:hover {
    color: #4dbb6f !important;
}
a.text-danger:focus {
    color: #f32c3f !important;
}
a.text-danger:hover {
    color: #f32c3f !important;
}
a.text-warning:focus {
    color: #e3ad00 !important;
}
a.text-warning:hover {
    color: #e3ad00 !important;
}
a.text-info:focus {
    color: #59c8fa !important;
}
a.text-info:hover {
    color: #59c8fa !important;
}
a.text-light:focus {
    color: #ced0d5 !important;
}
a.text-light:hover {
    color: #ced0d5 !important;
}
a.text-dark:focus {
    color: #232a36 !important;
}
a.text-dark:hover {
    color: #232a36 !important;
}
.blockquote {
    font-size: 1.05rem;
}
.bootstrap-touchspin .btn {
    box-shadow: none;
}
.media .media-body {
    word-break: break-word;
}
.list-group-item {
    background-color: transparent;
}
.mark {
    background-color: rgba(252, 193, 0, 0.1);
    color: #282828;
}
mark {
    background-color: rgba(252, 193, 0, 0.1);
    color: #282828;
}
@font-face {
    font-family: "FontAwesome";
    src: url("../fonts/fontawesome-webfont.eot?v=4.7.0");
    src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
        url("../fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
        url("../fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
        url("../fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
        url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
    font-weight: normal;
    font-style: normal;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-lg {
    font-size: 1.3333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.fa-2x {
    font-size: 2em;
}
.fa-3x {
    font-size: 3em;
}
.fa-4x {
    font-size: 4em;
}
.fa-5x {
    font-size: 5em;
}
.fa-fw {
    width: 1.2857142857em;
    text-align: center;
}
.fa-ul {
    padding-left: 0;
    margin-left: 2.1428571429em;
    list-style-type: none;
}
.fa-ul > li {
    position: relative;
}
.fa-li {
    position: absolute;
    left: -2.1428571429em;
    width: 2.1428571429em;
    top: 0.1428571429em;
    text-align: center;
}
.fa-li.fa-lg {
    left: -1.8571428571em;
}
.fa-border {
    padding: 0.2em 0.25em 0.15em;
    border: solid 0.08em #eee;
    border-radius: 0.1em;
}
.fa-pull-left {
    float: left;
}
.fa-pull-right {
    float: right;
}
.fa.fa-pull-left {
    margin-right: 0.3em;
}
.fa.fa-pull-right {
    margin-left: 0.3em;
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.fa.pull-left {
    margin-right: 0.3em;
}
.fa.pull-right {
    margin-left: 0.3em;
}
.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}
.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
    filter: none;
}
.fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}
.fa-stack-1x {
    line-height: inherit;
}
.fa-stack-2x {
    font-size: 2em;
}
.fa-inverse {
    color: #fff;
}
.fa-glass:before {
    content: "";
}
.fa-music:before {
    content: "";
}
.fa-search:before {
    content: "";
}
.fa-envelope-o:before {
    content: "";
}
.fa-heart:before {
    content: "";
}
.fa-star:before {
    content: "";
}
.fa-star-o:before {
    content: "";
}
.fa-user:before {
    content: "";
}
.fa-film:before {
    content: "";
}
.fa-th-large:before {
    content: "";
}
.fa-th:before {
    content: "";
}
.fa-th-list:before {
    content: "";
}
.fa-check:before {
    content: "";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
    content: "";
}
.fa-search-plus:before {
    content: "";
}
.fa-search-minus:before {
    content: "";
}
.fa-power-off:before {
    content: "";
}
.fa-signal:before {
    content: "";
}
.fa-gear:before,
.fa-cog:before {
    content: "";
}
.fa-trash-o:before {
    content: "";
}
.fa-home:before {
    content: "";
}
.fa-file-o:before {
    content: "";
}
.fa-clock-o:before {
    content: "";
}
.fa-road:before {
    content: "";
}
.fa-download:before {
    content: "";
}
.fa-arrow-circle-o-down:before {
    content: "";
}
.fa-arrow-circle-o-up:before {
    content: "";
}
.fa-inbox:before {
    content: "";
}
.fa-play-circle-o:before {
    content: "";
}
.fa-rotate-right:before,
.fa-repeat:before {
    content: "";
}
.fa-refresh:before {
    content: "";
}
.fa-list-alt:before {
    content: "";
}
.fa-lock:before {
    content: "";
}
.fa-flag:before {
    content: "";
}
.fa-headphones:before {
    content: "";
}
.fa-volume-off:before {
    content: "";
}
.fa-volume-down:before {
    content: "";
}
.fa-volume-up:before {
    content: "";
}
.fa-qrcode:before {
    content: "";
}
.fa-barcode:before {
    content: "";
}
.fa-tag:before {
    content: "";
}
.fa-tags:before {
    content: "";
}
.fa-book:before {
    content: "";
}
.fa-bookmark:before {
    content: "";
}
.fa-print:before {
    content: "";
}
.fa-camera:before {
    content: "";
}
.fa-font:before {
    content: "";
}
.fa-bold:before {
    content: "";
}
.fa-italic:before {
    content: "";
}
.fa-text-height:before {
    content: "";
}
.fa-text-width:before {
    content: "";
}
.fa-align-left:before {
    content: "";
}
.fa-align-center:before {
    content: "";
}
.fa-align-right:before {
    content: "";
}
.fa-align-justify:before {
    content: "";
}
.fa-list:before {
    content: "";
}
.fa-dedent:before,
.fa-outdent:before {
    content: "";
}
.fa-indent:before {
    content: "";
}
.fa-video-camera:before {
    content: "";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
    content: "";
}
.fa-pencil:before {
    content: "";
}
.fa-map-marker:before {
    content: "";
}
.fa-adjust:before {
    content: "";
}
.fa-tint:before {
    content: "";
}
.fa-edit:before,
.fa-pencil-square-o:before {
    content: "";
}
.fa-share-square-o:before {
    content: "";
}
.fa-check-square-o:before {
    content: "";
}
.fa-arrows:before {
    content: "";
}
.fa-step-backward:before {
    content: "";
}
.fa-fast-backward:before {
    content: "";
}
.fa-backward:before {
    content: "";
}
.fa-play:before {
    content: "";
}
.fa-pause:before {
    content: "";
}
.fa-stop:before {
    content: "";
}
.fa-forward:before {
    content: "";
}
.fa-fast-forward:before {
    content: "";
}
.fa-step-forward:before {
    content: "";
}
.fa-eject:before {
    content: "";
}
.fa-chevron-left:before {
    content: "";
}
.fa-chevron-right:before {
    content: "";
}
.fa-plus-circle:before {
    content: "";
}
.fa-minus-circle:before {
    content: "";
}
.fa-times-circle:before {
    content: "";
}
.fa-check-circle:before {
    content: "";
}
.fa-question-circle:before {
    content: "";
}
.fa-info-circle:before {
    content: "";
}
.fa-crosshairs:before {
    content: "";
}
.fa-times-circle-o:before {
    content: "";
}
.fa-check-circle-o:before {
    content: "";
}
.fa-ban:before {
    content: "";
}
.fa-arrow-left:before {
    content: "";
}
.fa-arrow-right:before {
    content: "";
}
.fa-arrow-up:before {
    content: "";
}
.fa-arrow-down:before {
    content: "";
}
.fa-mail-forward:before,
.fa-share:before {
    content: "";
}
.fa-expand:before {
    content: "";
}
.fa-compress:before {
    content: "";
}
.fa-plus:before {
    content: "";
}
.fa-minus:before {
    content: "";
}
.fa-asterisk:before {
    content: "";
}
.fa-exclamation-circle:before {
    content: "";
}
.fa-gift:before {
    content: "";
}
.fa-leaf:before {
    content: "";
}
.fa-fire:before {
    content: "";
}
.fa-eye:before {
    content: "";
}
.fa-eye-slash:before {
    content: "";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
    content: "";
}
.fa-plane:before {
    content: "";
}
.fa-calendar:before {
    content: "";
}
.fa-random:before {
    content: "";
}
.fa-comment:before {
    content: "";
}
.fa-magnet:before {
    content: "";
}
.fa-chevron-up:before {
    content: "";
}
.fa-chevron-down:before {
    content: "";
}
.fa-retweet:before {
    content: "";
}
.fa-shopping-cart:before {
    content: "";
}
.fa-folder:before {
    content: "";
}
.fa-folder-open:before {
    content: "";
}
.fa-arrows-v:before {
    content: "";
}
.fa-arrows-h:before {
    content: "";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
    content: "";
}
.fa-twitter-square:before {
    content: "";
}
.fa-facebook-square:before {
    content: "";
}
.fa-camera-retro:before {
    content: "";
}
.fa-key:before {
    content: "";
}
.fa-gears:before,
.fa-cogs:before {
    content: "";
}
.fa-comments:before {
    content: "";
}
.fa-thumbs-o-up:before {
    content: "";
}
.fa-thumbs-o-down:before {
    content: "";
}
.fa-star-half:before {
    content: "";
}
.fa-heart-o:before {
    content: "";
}
.fa-sign-out:before {
    content: "";
}
.fa-linkedin-square:before {
    content: "";
}
.fa-thumb-tack:before {
    content: "";
}
.fa-external-link:before {
    content: "";
}
.fa-sign-in:before {
    content: "";
}
.fa-trophy:before {
    content: "";
}
.fa-github-square:before {
    content: "";
}
.fa-upload:before {
    content: "";
}
.fa-lemon-o:before {
    content: "";
}
.fa-phone:before {
    content: "";
}
.fa-square-o:before {
    content: "";
}
.fa-bookmark-o:before {
    content: "";
}
.fa-phone-square:before {
    content: "";
}
.fa-twitter:before {
    content: "";
}
.fa-facebook-f:before,
.fa-facebook:before {
    content: "";
}
.fa-github:before {
    content: "";
}
.fa-unlock:before {
    content: "";
}
.fa-credit-card:before {
    content: "";
}
.fa-feed:before,
.fa-rss:before {
    content: "";
}
.fa-hdd-o:before {
    content: "";
}
.fa-bullhorn:before {
    content: "";
}
.fa-bell:before {
    content: "";
}
.fa-certificate:before {
    content: "";
}
.fa-hand-o-right:before {
    content: "";
}
.fa-hand-o-left:before {
    content: "";
}
.fa-hand-o-up:before {
    content: "";
}
.fa-hand-o-down:before {
    content: "";
}
.fa-arrow-circle-left:before {
    content: "";
}
.fa-arrow-circle-right:before {
    content: "";
}
.fa-arrow-circle-up:before {
    content: "";
}
.fa-arrow-circle-down:before {
    content: "";
}
.fa-globe:before {
    content: "";
}
.fa-wrench:before {
    content: "";
}
.fa-tasks:before {
    content: "";
}
.fa-filter:before {
    content: "";
}
.fa-briefcase:before {
    content: "";
}
.fa-arrows-alt:before {
    content: "";
}
.fa-group:before,
.fa-users:before {
    content: "";
}
.fa-chain:before,
.fa-link:before {
    content: "";
}
.fa-cloud:before {
    content: "";
}
.fa-flask:before {
    content: "";
}
.fa-cut:before,
.fa-scissors:before {
    content: "";
}
.fa-copy:before,
.fa-files-o:before {
    content: "";
}
.fa-paperclip:before {
    content: "";
}
.fa-save:before,
.fa-floppy-o:before {
    content: "";
}
.fa-square:before {
    content: "";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
    content: "";
}
.fa-list-ul:before {
    content: "";
}
.fa-list-ol:before {
    content: "";
}
.fa-strikethrough:before {
    content: "";
}
.fa-underline:before {
    content: "";
}
.fa-table:before {
    content: "";
}
.fa-magic:before {
    content: "";
}
.fa-truck:before {
    content: "";
}
.fa-pinterest:before {
    content: "";
}
.fa-pinterest-square:before {
    content: "";
}
.fa-google-plus-square:before {
    content: "";
}
.fa-google-plus:before {
    content: "";
}
.fa-money:before {
    content: "";
}
.fa-caret-down:before {
    content: "";
}
.fa-caret-up:before {
    content: "";
}
.fa-caret-left:before {
    content: "";
}
.fa-caret-right:before {
    content: "";
}
.fa-columns:before {
    content: "";
}
.fa-unsorted:before,
.fa-sort:before {
    content: "";
}
.fa-sort-down:before,
.fa-sort-desc:before {
    content: "";
}
.fa-sort-up:before,
.fa-sort-asc:before {
    content: "";
}
.fa-envelope:before {
    content: "";
}
.fa-linkedin:before {
    content: "";
}
.fa-rotate-left:before,
.fa-undo:before {
    content: "";
}
.fa-legal:before,
.fa-gavel:before {
    content: "";
}
.fa-dashboard:before,
.fa-tachometer:before {
    content: "";
}
.fa-comment-o:before {
    content: "";
}
.fa-comments-o:before {
    content: "";
}
.fa-flash:before,
.fa-bolt:before {
    content: "";
}
.fa-sitemap:before {
    content: "";
}
.fa-umbrella:before {
    content: "";
}
.fa-paste:before,
.fa-clipboard:before {
    content: "";
}
.fa-lightbulb-o:before {
    content: "";
}
.fa-exchange:before {
    content: "";
}
.fa-cloud-download:before {
    content: "";
}
.fa-cloud-upload:before {
    content: "";
}
.fa-user-md:before {
    content: "";
}
.fa-stethoscope:before {
    content: "";
}
.fa-suitcase:before {
    content: "";
}
.fa-bell-o:before {
    content: "";
}
.fa-coffee:before {
    content: "";
}
.fa-cutlery:before {
    content: "";
}
.fa-file-text-o:before {
    content: "";
}
.fa-building-o:before {
    content: "";
}
.fa-hospital-o:before {
    content: "";
}
.fa-ambulance:before {
    content: "";
}
.fa-medkit:before {
    content: "";
}
.fa-fighter-jet:before {
    content: "";
}
.fa-beer:before {
    content: "";
}
.fa-h-square:before {
    content: "";
}
.fa-plus-square:before {
    content: "";
}
.fa-angle-double-left:before {
    content: "";
}
.fa-angle-double-right:before {
    content: "";
}
.fa-angle-double-up:before {
    content: "";
}
.fa-angle-double-down:before {
    content: "";
}
.fa-angle-left:before {
    content: "";
}
.fa-angle-right:before {
    content: "";
}
.fa-angle-up:before {
    content: "";
}
.fa-angle-down:before {
    content: "";
}
.fa-desktop:before {
    content: "";
}
.fa-laptop:before {
    content: "";
}
.fa-tablet:before {
    content: "";
}
.fa-mobile-phone:before,
.fa-mobile:before {
    content: "";
}
.fa-circle-o:before {
    content: "";
}
.fa-quote-left:before {
    content: "";
}
.fa-quote-right:before {
    content: "";
}
.fa-spinner:before {
    content: "";
}
.fa-circle:before {
    content: "";
}
.fa-mail-reply:before,
.fa-reply:before {
    content: "";
}
.fa-github-alt:before {
    content: "";
}
.fa-folder-o:before {
    content: "";
}
.fa-folder-open-o:before {
    content: "";
}
.fa-smile-o:before {
    content: "";
}
.fa-frown-o:before {
    content: "";
}
.fa-meh-o:before {
    content: "";
}
.fa-gamepad:before {
    content: "";
}
.fa-keyboard-o:before {
    content: "";
}
.fa-flag-o:before {
    content: "";
}
.fa-flag-checkered:before {
    content: "";
}
.fa-terminal:before {
    content: "";
}
.fa-code:before {
    content: "";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
    content: "";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
    content: "";
}
.fa-location-arrow:before {
    content: "";
}
.fa-crop:before {
    content: "";
}
.fa-code-fork:before {
    content: "";
}
.fa-unlink:before,
.fa-chain-broken:before {
    content: "";
}
.fa-question:before {
    content: "";
}
.fa-info:before {
    content: "";
}
.fa-exclamation:before {
    content: "";
}
.fa-superscript:before {
    content: "";
}
.fa-subscript:before {
    content: "";
}
.fa-eraser:before {
    content: "";
}
.fa-puzzle-piece:before {
    content: "";
}
.fa-microphone:before {
    content: "";
}
.fa-microphone-slash:before {
    content: "";
}
.fa-shield:before {
    content: "";
}
.fa-calendar-o:before {
    content: "";
}
.fa-fire-extinguisher:before {
    content: "";
}
.fa-rocket:before {
    content: "";
}
.fa-maxcdn:before {
    content: "";
}
.fa-chevron-circle-left:before {
    content: "";
}
.fa-chevron-circle-right:before {
    content: "";
}
.fa-chevron-circle-up:before {
    content: "";
}
.fa-chevron-circle-down:before {
    content: "";
}
.fa-html5:before {
    content: "";
}
.fa-css3:before {
    content: "";
}
.fa-anchor:before {
    content: "";
}
.fa-unlock-alt:before {
    content: "";
}
.fa-bullseye:before {
    content: "";
}
.fa-ellipsis-h:before {
    content: "";
}
.fa-ellipsis-v:before {
    content: "";
}
.fa-rss-square:before {
    content: "";
}
.fa-play-circle:before {
    content: "";
}
.fa-ticket:before {
    content: "";
}
.fa-minus-square:before {
    content: "";
}
.fa-minus-square-o:before {
    content: "";
}
.fa-level-up:before {
    content: "";
}
.fa-level-down:before {
    content: "";
}
.fa-check-square:before {
    content: "";
}
.fa-pencil-square:before {
    content: "";
}
.fa-external-link-square:before {
    content: "";
}
.fa-share-square:before {
    content: "";
}
.fa-compass:before {
    content: "";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
    content: "";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
    content: "";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
    content: "";
}
.fa-euro:before,
.fa-eur:before {
    content: "";
}
.fa-gbp:before {
    content: "";
}
.fa-dollar:before,
.fa-usd:before {
    content: "";
}
.fa-rupee:before,
.fa-inr:before {
    content: "";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
    content: "";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
    content: "";
}
.fa-won:before,
.fa-krw:before {
    content: "";
}
.fa-bitcoin:before,
.fa-btc:before {
    content: "";
}
.fa-file:before {
    content: "";
}
.fa-file-text:before {
    content: "";
}
.fa-sort-alpha-asc:before {
    content: "";
}
.fa-sort-alpha-desc:before {
    content: "";
}
.fa-sort-amount-asc:before {
    content: "";
}
.fa-sort-amount-desc:before {
    content: "";
}
.fa-sort-numeric-asc:before {
    content: "";
}
.fa-sort-numeric-desc:before {
    content: "";
}
.fa-thumbs-up:before {
    content: "";
}
.fa-thumbs-down:before {
    content: "";
}
.fa-youtube-square:before {
    content: "";
}
.fa-youtube:before {
    content: "";
}
.fa-xing:before {
    content: "";
}
.fa-xing-square:before {
    content: "";
}
.fa-youtube-play:before {
    content: "";
}
.fa-dropbox:before {
    content: "";
}
.fa-stack-overflow:before {
    content: "";
}
.fa-instagram:before {
    content: "";
}
.fa-flickr:before {
    content: "";
}
.fa-adn:before {
    content: "";
}
.fa-bitbucket:before {
    content: "";
}
.fa-bitbucket-square:before {
    content: "";
}
.fa-tumblr:before {
    content: "";
}
.fa-tumblr-square:before {
    content: "";
}
.fa-long-arrow-down:before {
    content: "";
}
.fa-long-arrow-up:before {
    content: "";
}
.fa-long-arrow-left:before {
    content: "";
}
.fa-long-arrow-right:before {
    content: "";
}
.fa-apple:before {
    content: "";
}
.fa-windows:before {
    content: "";
}
.fa-android:before {
    content: "";
}
.fa-linux:before {
    content: "";
}
.fa-dribbble:before {
    content: "";
}
.fa-skype:before {
    content: "";
}
.fa-foursquare:before {
    content: "";
}
.fa-trello:before {
    content: "";
}
.fa-female:before {
    content: "";
}
.fa-male:before {
    content: "";
}
.fa-gittip:before,
.fa-gratipay:before {
    content: "";
}
.fa-sun-o:before {
    content: "";
}
.fa-moon-o:before {
    content: "";
}
.fa-archive:before {
    content: "";
}
.fa-bug:before {
    content: "";
}
.fa-vk:before {
    content: "";
}
.fa-weibo:before {
    content: "";
}
.fa-renren:before {
    content: "";
}
.fa-pagelines:before {
    content: "";
}
.fa-stack-exchange:before {
    content: "";
}
.fa-arrow-circle-o-right:before {
    content: "";
}
.fa-arrow-circle-o-left:before {
    content: "";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
    content: "";
}
.fa-dot-circle-o:before {
    content: "";
}
.fa-wheelchair:before {
    content: "";
}
.fa-vimeo-square:before {
    content: "";
}
.fa-turkish-lira:before,
.fa-try:before {
    content: "";
}
.fa-plus-square-o:before {
    content: "";
}
.fa-space-shuttle:before {
    content: "";
}
.fa-slack:before {
    content: "";
}
.fa-envelope-square:before {
    content: "";
}
.fa-wordpress:before {
    content: "";
}
.fa-openid:before {
    content: "";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
    content: "";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
    content: "";
}
.fa-yahoo:before {
    content: "";
}
.fa-google:before {
    content: "";
}
.fa-reddit:before {
    content: "";
}
.fa-reddit-square:before {
    content: "";
}
.fa-stumbleupon-circle:before {
    content: "";
}
.fa-stumbleupon:before {
    content: "";
}
.fa-delicious:before {
    content: "";
}
.fa-digg:before {
    content: "";
}
.fa-pied-piper-pp:before {
    content: "";
}
.fa-pied-piper-alt:before {
    content: "";
}
.fa-drupal:before {
    content: "";
}
.fa-joomla:before {
    content: "";
}
.fa-language:before {
    content: "";
}
.fa-fax:before {
    content: "";
}
.fa-building:before {
    content: "";
}
.fa-child:before {
    content: "";
}
.fa-paw:before {
    content: "";
}
.fa-spoon:before {
    content: "";
}
.fa-cube:before {
    content: "";
}
.fa-cubes:before {
    content: "";
}
.fa-behance:before {
    content: "";
}
.fa-behance-square:before {
    content: "";
}
.fa-steam:before {
    content: "";
}
.fa-steam-square:before {
    content: "";
}
.fa-recycle:before {
    content: "";
}
.fa-automobile:before,
.fa-car:before {
    content: "";
}
.fa-cab:before,
.fa-taxi:before {
    content: "";
}
.fa-tree:before {
    content: "";
}
.fa-spotify:before {
    content: "";
}
.fa-deviantart:before {
    content: "";
}
.fa-soundcloud:before {
    content: "";
}
.fa-database:before {
    content: "";
}
.fa-file-pdf-o:before {
    content: "";
}
.fa-file-word-o:before {
    content: "";
}
.fa-file-excel-o:before {
    content: "";
}
.fa-file-powerpoint-o:before {
    content: "";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
    content: "";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
    content: "";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
    content: "";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
    content: "";
}
.fa-file-code-o:before {
    content: "";
}
.fa-vine:before {
    content: "";
}
.fa-codepen:before {
    content: "";
}
.fa-jsfiddle:before {
    content: "";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
    content: "";
}
.fa-circle-o-notch:before {
    content: "";
}
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
    content: "";
}
.fa-ge:before,
.fa-empire:before {
    content: "";
}
.fa-git-square:before {
    content: "";
}
.fa-git:before {
    content: "";
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
    content: "";
}
.fa-tencent-weibo:before {
    content: "";
}
.fa-qq:before {
    content: "";
}
.fa-wechat:before,
.fa-weixin:before {
    content: "";
}
.fa-send:before,
.fa-paper-plane:before {
    content: "";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
    content: "";
}
.fa-history:before {
    content: "";
}
.fa-circle-thin:before {
    content: "";
}
.fa-header:before {
    content: "";
}
.fa-paragraph:before {
    content: "";
}
.fa-sliders:before {
    content: "";
}
.fa-share-alt:before {
    content: "";
}
.fa-share-alt-square:before {
    content: "";
}
.fa-bomb:before {
    content: "";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
    content: "";
}
.fa-tty:before {
    content: "";
}
.fa-binoculars:before {
    content: "";
}
.fa-plug:before {
    content: "";
}
.fa-slideshare:before {
    content: "";
}
.fa-twitch:before {
    content: "";
}
.fa-yelp:before {
    content: "";
}
.fa-newspaper-o:before {
    content: "";
}
.fa-wifi:before {
    content: "";
}
.fa-calculator:before {
    content: "";
}
.fa-paypal:before {
    content: "";
}
.fa-google-wallet:before {
    content: "";
}
.fa-cc-visa:before {
    content: "";
}
.fa-cc-mastercard:before {
    content: "";
}
.fa-cc-discover:before {
    content: "";
}
.fa-cc-amex:before {
    content: "";
}
.fa-cc-paypal:before {
    content: "";
}
.fa-cc-stripe:before {
    content: "";
}
.fa-bell-slash:before {
    content: "";
}
.fa-bell-slash-o:before {
    content: "";
}
.fa-trash:before {
    content: "";
}
.fa-copyright:before {
    content: "";
}
.fa-at:before {
    content: "";
}
.fa-eyedropper:before {
    content: "";
}
.fa-paint-brush:before {
    content: "";
}
.fa-birthday-cake:before {
    content: "";
}
.fa-area-chart:before {
    content: "";
}
.fa-pie-chart:before {
    content: "";
}
.fa-line-chart:before {
    content: "";
}
.fa-lastfm:before {
    content: "";
}
.fa-lastfm-square:before {
    content: "";
}
.fa-toggle-off:before {
    content: "";
}
.fa-toggle-on:before {
    content: "";
}
.fa-bicycle:before {
    content: "";
}
.fa-bus:before {
    content: "";
}
.fa-ioxhost:before {
    content: "";
}
.fa-angellist:before {
    content: "";
}
.fa-cc:before {
    content: "";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
    content: "";
}
.fa-meanpath:before {
    content: "";
}
.fa-buysellads:before {
    content: "";
}
.fa-connectdevelop:before {
    content: "";
}
.fa-dashcube:before {
    content: "";
}
.fa-forumbee:before {
    content: "";
}
.fa-leanpub:before {
    content: "";
}
.fa-sellsy:before {
    content: "";
}
.fa-shirtsinbulk:before {
    content: "";
}
.fa-simplybuilt:before {
    content: "";
}
.fa-skyatlas:before {
    content: "";
}
.fa-cart-plus:before {
    content: "";
}
.fa-cart-arrow-down:before {
    content: "";
}
.fa-diamond:before {
    content: "";
}
.fa-ship:before {
    content: "";
}
.fa-user-secret:before {
    content: "";
}
.fa-motorcycle:before {
    content: "";
}
.fa-street-view:before {
    content: "";
}
.fa-heartbeat:before {
    content: "";
}
.fa-venus:before {
    content: "";
}
.fa-mars:before {
    content: "";
}
.fa-mercury:before {
    content: "";
}
.fa-intersex:before,
.fa-transgender:before {
    content: "";
}
.fa-transgender-alt:before {
    content: "";
}
.fa-venus-double:before {
    content: "";
}
.fa-mars-double:before {
    content: "";
}
.fa-venus-mars:before {
    content: "";
}
.fa-mars-stroke:before {
    content: "";
}
.fa-mars-stroke-v:before {
    content: "";
}
.fa-mars-stroke-h:before {
    content: "";
}
.fa-neuter:before {
    content: "";
}
.fa-genderless:before {
    content: "";
}
.fa-facebook-official:before {
    content: "";
}
.fa-pinterest-p:before {
    content: "";
}
.fa-whatsapp:before {
    content: "";
}
.fa-server:before {
    content: "";
}
.fa-user-plus:before {
    content: "";
}
.fa-user-times:before {
    content: "";
}
.fa-hotel:before,
.fa-bed:before {
    content: "";
}
.fa-viacoin:before {
    content: "";
}
.fa-train:before {
    content: "";
}
.fa-subway:before {
    content: "";
}
.fa-medium:before {
    content: "";
}
.fa-yc:before,
.fa-y-combinator:before {
    content: "";
}
.fa-optin-monster:before {
    content: "";
}
.fa-opencart:before {
    content: "";
}
.fa-expeditedssl:before {
    content: "";
}
.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
    content: "";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
    content: "";
}
.fa-battery-2:before,
.fa-battery-half:before {
    content: "";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
    content: "";
}
.fa-battery-0:before,
.fa-battery-empty:before {
    content: "";
}
.fa-mouse-pointer:before {
    content: "";
}
.fa-i-cursor:before {
    content: "";
}
.fa-object-group:before {
    content: "";
}
.fa-object-ungroup:before {
    content: "";
}
.fa-sticky-note:before {
    content: "";
}
.fa-sticky-note-o:before {
    content: "";
}
.fa-cc-jcb:before {
    content: "";
}
.fa-cc-diners-club:before {
    content: "";
}
.fa-clone:before {
    content: "";
}
.fa-balance-scale:before {
    content: "";
}
.fa-hourglass-o:before {
    content: "";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
    content: "";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
    content: "";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
    content: "";
}
.fa-hourglass:before {
    content: "";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
    content: "";
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
    content: "";
}
.fa-hand-scissors-o:before {
    content: "";
}
.fa-hand-lizard-o:before {
    content: "";
}
.fa-hand-spock-o:before {
    content: "";
}
.fa-hand-pointer-o:before {
    content: "";
}
.fa-hand-peace-o:before {
    content: "";
}
.fa-trademark:before {
    content: "";
}
.fa-registered:before {
    content: "";
}
.fa-creative-commons:before {
    content: "";
}
.fa-gg:before {
    content: "";
}
.fa-gg-circle:before {
    content: "";
}
.fa-tripadvisor:before {
    content: "";
}
.fa-odnoklassniki:before {
    content: "";
}
.fa-odnoklassniki-square:before {
    content: "";
}
.fa-get-pocket:before {
    content: "";
}
.fa-wikipedia-w:before {
    content: "";
}
.fa-safari:before {
    content: "";
}
.fa-chrome:before {
    content: "";
}
.fa-firefox:before {
    content: "";
}
.fa-opera:before {
    content: "";
}
.fa-internet-explorer:before {
    content: "";
}
.fa-tv:before,
.fa-television:before {
    content: "";
}
.fa-contao:before {
    content: "";
}
.fa-500px:before {
    content: "";
}
.fa-amazon:before {
    content: "";
}
.fa-calendar-plus-o:before {
    content: "";
}
.fa-calendar-minus-o:before {
    content: "";
}
.fa-calendar-times-o:before {
    content: "";
}
.fa-calendar-check-o:before {
    content: "";
}
.fa-industry:before {
    content: "";
}
.fa-map-pin:before {
    content: "";
}
.fa-map-signs:before {
    content: "";
}
.fa-map-o:before {
    content: "";
}
.fa-map:before {
    content: "";
}
.fa-commenting:before {
    content: "";
}
.fa-commenting-o:before {
    content: "";
}
.fa-houzz:before {
    content: "";
}
.fa-vimeo:before {
    content: "";
}
.fa-black-tie:before {
    content: "";
}
.fa-fonticons:before {
    content: "";
}
.fa-reddit-alien:before {
    content: "";
}
.fa-edge:before {
    content: "";
}
.fa-credit-card-alt:before {
    content: "";
}
.fa-codiepie:before {
    content: "";
}
.fa-modx:before {
    content: "";
}
.fa-fort-awesome:before {
    content: "";
}
.fa-usb:before {
    content: "";
}
.fa-product-hunt:before {
    content: "";
}
.fa-mixcloud:before {
    content: "";
}
.fa-scribd:before {
    content: "";
}
.fa-pause-circle:before {
    content: "";
}
.fa-pause-circle-o:before {
    content: "";
}
.fa-stop-circle:before {
    content: "";
}
.fa-stop-circle-o:before {
    content: "";
}
.fa-shopping-bag:before {
    content: "";
}
.fa-shopping-basket:before {
    content: "";
}
.fa-hashtag:before {
    content: "";
}
.fa-bluetooth:before {
    content: "";
}
.fa-bluetooth-b:before {
    content: "";
}
.fa-percent:before {
    content: "";
}
.fa-gitlab:before {
    content: "";
}
.fa-wpbeginner:before {
    content: "";
}
.fa-wpforms:before {
    content: "";
}
.fa-envira:before {
    content: "";
}
.fa-universal-access:before {
    content: "";
}
.fa-wheelchair-alt:before {
    content: "";
}
.fa-question-circle-o:before {
    content: "";
}
.fa-blind:before {
    content: "";
}
.fa-audio-description:before {
    content: "";
}
.fa-volume-control-phone:before {
    content: "";
}
.fa-braille:before {
    content: "";
}
.fa-assistive-listening-systems:before {
    content: "";
}
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
    content: "";
}
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
    content: "";
}
.fa-glide:before {
    content: "";
}
.fa-glide-g:before {
    content: "";
}
.fa-signing:before,
.fa-sign-language:before {
    content: "";
}
.fa-low-vision:before {
    content: "";
}
.fa-viadeo:before {
    content: "";
}
.fa-viadeo-square:before {
    content: "";
}
.fa-snapchat:before {
    content: "";
}
.fa-snapchat-ghost:before {
    content: "";
}
.fa-snapchat-square:before {
    content: "";
}
.fa-pied-piper:before {
    content: "";
}
.fa-first-order:before {
    content: "";
}
.fa-yoast:before {
    content: "";
}
.fa-themeisle:before {
    content: "";
}
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
    content: "";
}
.fa-fa:before,
.fa-font-awesome:before {
    content: "";
}
.fa-handshake-o:before {
    content: "";
}
.fa-envelope-open:before {
    content: "";
}
.fa-envelope-open-o:before {
    content: "";
}
.fa-linode:before {
    content: "";
}
.fa-address-book:before {
    content: "";
}
.fa-address-book-o:before {
    content: "";
}
.fa-vcard:before,
.fa-address-card:before {
    content: "";
}
.fa-vcard-o:before,
.fa-address-card-o:before {
    content: "";
}
.fa-user-circle:before {
    content: "";
}
.fa-user-circle-o:before {
    content: "";
}
.fa-user-o:before {
    content: "";
}
.fa-id-badge:before {
    content: "";
}
.fa-drivers-license:before,
.fa-id-card:before {
    content: "";
}
.fa-drivers-license-o:before,
.fa-id-card-o:before {
    content: "";
}
.fa-quora:before {
    content: "";
}
.fa-free-code-camp:before {
    content: "";
}
.fa-telegram:before {
    content: "";
}
.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
    content: "";
}
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
    content: "";
}
.fa-thermometer-2:before,
.fa-thermometer-half:before {
    content: "";
}
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
    content: "";
}
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
    content: "";
}
.fa-shower:before {
    content: "";
}
.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
    content: "";
}
.fa-podcast:before {
    content: "";
}
.fa-window-maximize:before {
    content: "";
}
.fa-window-minimize:before {
    content: "";
}
.fa-window-restore:before {
    content: "";
}
.fa-times-rectangle:before,
.fa-window-close:before {
    content: "";
}
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
    content: "";
}
.fa-bandcamp:before {
    content: "";
}
.fa-grav:before {
    content: "";
}
.fa-etsy:before {
    content: "";
}
.fa-imdb:before {
    content: "";
}
.fa-ravelry:before {
    content: "";
}
.fa-eercast:before {
    content: "";
}
.fa-microchip:before {
    content: "";
}
.fa-snowflake-o:before {
    content: "";
}
.fa-superpowers:before {
    content: "";
}
.fa-wpexplorer:before {
    content: "";
}
.fa-meetup:before {
    content: "";
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}
.mdi:before,
.mdi-set {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.mdi-access-point:before {
    content: "\F002";
}
.mdi-access-point-network:before {
    content: "\F003";
}
.mdi-account:before {
    content: "\F004";
}
.mdi-account-alert:before {
    content: "\F005";
}
.mdi-account-box:before {
    content: "\F006";
}
.mdi-account-box-multiple:before {
    content: "\F933";
}
.mdi-account-box-outline:before {
    content: "\F007";
}
.mdi-account-card-details:before {
    content: "\F5D2";
}
.mdi-account-check:before {
    content: "\F008";
}
.mdi-account-child:before {
    content: "\FA88";
}
.mdi-account-child-circle:before {
    content: "\FA89";
}
.mdi-account-circle:before {
    content: "\F009";
}
.mdi-account-convert:before {
    content: "\F00A";
}
.mdi-account-details:before {
    content: "\F631";
}
.mdi-account-edit:before {
    content: "\F6BB";
}
.mdi-account-group:before {
    content: "\F848";
}
.mdi-account-heart:before {
    content: "\F898";
}
.mdi-account-key:before {
    content: "\F00B";
}
.mdi-account-location:before {
    content: "\F00C";
}
.mdi-account-minus:before {
    content: "\F00D";
}
.mdi-account-minus-outline:before {
    content: "\FAEB";
}
.mdi-account-multiple:before {
    content: "\F00E";
}
.mdi-account-multiple-check:before {
    content: "\F8C4";
}
.mdi-account-multiple-minus:before {
    content: "\F5D3";
}
.mdi-account-multiple-outline:before {
    content: "\F00F";
}
.mdi-account-multiple-plus:before {
    content: "\F010";
}
.mdi-account-multiple-plus-outline:before {
    content: "\F7FF";
}
.mdi-account-network:before {
    content: "\F011";
}
.mdi-account-off:before {
    content: "\F012";
}
.mdi-account-outline:before {
    content: "\F013";
}
.mdi-account-plus:before {
    content: "\F014";
}
.mdi-account-plus-outline:before {
    content: "\F800";
}
.mdi-account-remove:before {
    content: "\F015";
}
.mdi-account-remove-outline:before {
    content: "\FAEC";
}
.mdi-account-search:before {
    content: "\F016";
}
.mdi-account-search-outline:before {
    content: "\F934";
}
.mdi-account-settings:before {
    content: "\F630";
}
.mdi-account-star:before {
    content: "\F017";
}
.mdi-account-supervisor:before {
    content: "\FA8A";
}
.mdi-account-supervisor-circle:before {
    content: "\FA8B";
}
.mdi-account-switch:before {
    content: "\F019";
}
.mdi-accusoft:before {
    content: "\F849";
}
.mdi-adjust:before {
    content: "\F01A";
}
.mdi-adobe:before {
    content: "\F935";
}
.mdi-air-conditioner:before {
    content: "\F01B";
}
.mdi-airballoon:before {
    content: "\F01C";
}
.mdi-airplane:before {
    content: "\F01D";
}
.mdi-airplane-landing:before {
    content: "\F5D4";
}
.mdi-airplane-off:before {
    content: "\F01E";
}
.mdi-airplane-takeoff:before {
    content: "\F5D5";
}
.mdi-airplay:before {
    content: "\F01F";
}
.mdi-airport:before {
    content: "\F84A";
}
.mdi-alarm:before {
    content: "\F020";
}
.mdi-alarm-bell:before {
    content: "\F78D";
}
.mdi-alarm-check:before {
    content: "\F021";
}
.mdi-alarm-light:before {
    content: "\F78E";
}
.mdi-alarm-multiple:before {
    content: "\F022";
}
.mdi-alarm-off:before {
    content: "\F023";
}
.mdi-alarm-plus:before {
    content: "\F024";
}
.mdi-alarm-snooze:before {
    content: "\F68D";
}
.mdi-album:before {
    content: "\F025";
}
.mdi-alert:before {
    content: "\F026";
}
.mdi-alert-box:before {
    content: "\F027";
}
.mdi-alert-circle:before {
    content: "\F028";
}
.mdi-alert-circle-outline:before {
    content: "\F5D6";
}
.mdi-alert-decagram:before {
    content: "\F6BC";
}
.mdi-alert-octagon:before {
    content: "\F029";
}
.mdi-alert-octagram:before {
    content: "\F766";
}
.mdi-alert-outline:before {
    content: "\F02A";
}
.mdi-alien:before {
    content: "\F899";
}
.mdi-all-inclusive:before {
    content: "\F6BD";
}
.mdi-alpha:before {
    content: "\F02B";
}
.mdi-alpha-a:before {
    content: "\41";
}
.mdi-alpha-a-box:before {
    content: "\FAED";
}
.mdi-alpha-b:before {
    content: "\42";
}
.mdi-alpha-b-box:before {
    content: "\FAEE";
}
.mdi-alpha-c:before {
    content: "\43";
}
.mdi-alpha-c-box:before {
    content: "\FAEF";
}
.mdi-alpha-d:before {
    content: "\44";
}
.mdi-alpha-d-box:before {
    content: "\FAF0";
}
.mdi-alpha-e:before {
    content: "\45";
}
.mdi-alpha-e-box:before {
    content: "\FAF1";
}
.mdi-alpha-f:before {
    content: "\46";
}
.mdi-alpha-f-box:before {
    content: "\FAF2";
}
.mdi-alpha-g:before {
    content: "\47";
}
.mdi-alpha-g-box:before {
    content: "\FAF3";
}
.mdi-alpha-h:before {
    content: "\48";
}
.mdi-alpha-h-box:before {
    content: "\FAF4";
}
.mdi-alpha-i:before {
    content: "\49";
}
.mdi-alpha-i-box:before {
    content: "\FAF5";
}
.mdi-alpha-j:before {
    content: "\4A";
}
.mdi-alpha-j-box:before {
    content: "\FAF6";
}
.mdi-alpha-k:before {
    content: "\4B";
}
.mdi-alpha-k-box:before {
    content: "\FAF7";
}
.mdi-alpha-l:before {
    content: "\4C";
}
.mdi-alpha-l-box:before {
    content: "\FAF8";
}
.mdi-alpha-m:before {
    content: "\4D";
}
.mdi-alpha-m-box:before {
    content: "\FAF9";
}
.mdi-alpha-n:before {
    content: "\4E";
}
.mdi-alpha-n-box:before {
    content: "\FAFA";
}
.mdi-alpha-o:before {
    content: "\4F";
}
.mdi-alpha-o-box:before {
    content: "\FAFB";
}
.mdi-alpha-p:before {
    content: "\50";
}
.mdi-alpha-p-box:before {
    content: "\FAFC";
}
.mdi-alpha-q:before {
    content: "\51";
}
.mdi-alpha-q-box:before {
    content: "\FAFD";
}
.mdi-alpha-r:before {
    content: "\52";
}
.mdi-alpha-r-box:before {
    content: "\FAFE";
}
.mdi-alpha-s:before {
    content: "\53";
}
.mdi-alpha-s-box:before {
    content: "\FAFF";
}
.mdi-alpha-t:before {
    content: "\54";
}
.mdi-alpha-t-box:before {
    content: "\FB00";
}
.mdi-alpha-u:before {
    content: "\55";
}
.mdi-alpha-u-box:before {
    content: "\FB01";
}
.mdi-alpha-v:before {
    content: "\56";
}
.mdi-alpha-v-box:before {
    content: "\FB02";
}
.mdi-alpha-w:before {
    content: "\57";
}
.mdi-alpha-w-box:before {
    content: "\FB03";
}
.mdi-alpha-x:before {
    content: "\58";
}
.mdi-alpha-x-box:before {
    content: "\FB04";
}
.mdi-alpha-y:before {
    content: "\59";
}
.mdi-alpha-y-box:before {
    content: "\FB05";
}
.mdi-alpha-z:before {
    content: "\5A";
}
.mdi-alpha-z-box:before {
    content: "\FB06";
}
.mdi-alphabetical:before {
    content: "\F02C";
}
.mdi-altimeter:before {
    content: "\F5D7";
}
.mdi-amazon:before {
    content: "\F02D";
}
.mdi-amazon-alexa:before {
    content: "\F8C5";
}
.mdi-amazon-drive:before {
    content: "\F02E";
}
.mdi-ambulance:before {
    content: "\F02F";
}
.mdi-ampersand:before {
    content: "\FA8C";
}
.mdi-amplifier:before {
    content: "\F030";
}
.mdi-anchor:before {
    content: "\F031";
}
.mdi-android:before {
    content: "\F032";
}
.mdi-android-auto:before {
    content: "\FA8D";
}
.mdi-android-debug-bridge:before {
    content: "\F033";
}
.mdi-android-head:before {
    content: "\F78F";
}
.mdi-android-studio:before {
    content: "\F034";
}
.mdi-angle-acute:before {
    content: "\F936";
}
.mdi-angle-obtuse:before {
    content: "\F937";
}
.mdi-angle-right:before {
    content: "\F938";
}
.mdi-angular:before {
    content: "\F6B1";
}
.mdi-angularjs:before {
    content: "\F6BE";
}
.mdi-animation:before {
    content: "\F5D8";
}
.mdi-animation-outline:before {
    content: "\FA8E";
}
.mdi-animation-play:before {
    content: "\F939";
}
.mdi-animation-play-outline:before {
    content: "\FA8F";
}
.mdi-anvil:before {
    content: "\F89A";
}
.mdi-apple:before {
    content: "\F035";
}
.mdi-apple-finder:before {
    content: "\F036";
}
.mdi-apple-icloud:before {
    content: "\F038";
}
.mdi-apple-ios:before {
    content: "\F037";
}
.mdi-apple-keyboard-caps:before {
    content: "\F632";
}
.mdi-apple-keyboard-command:before {
    content: "\F633";
}
.mdi-apple-keyboard-control:before {
    content: "\F634";
}
.mdi-apple-keyboard-option:before {
    content: "\F635";
}
.mdi-apple-keyboard-shift:before {
    content: "\F636";
}
.mdi-apple-safari:before {
    content: "\F039";
}
.mdi-application:before {
    content: "\F614";
}
.mdi-apps:before {
    content: "\F03B";
}
.mdi-arch:before {
    content: "\F8C6";
}
.mdi-archive:before {
    content: "\F03C";
}
.mdi-arrange-bring-forward:before {
    content: "\F03D";
}
.mdi-arrange-bring-to-front:before {
    content: "\F03E";
}
.mdi-arrange-send-backward:before {
    content: "\F03F";
}
.mdi-arrange-send-to-back:before {
    content: "\F040";
}
.mdi-arrow-all:before {
    content: "\F041";
}
.mdi-arrow-bottom-left:before {
    content: "\F042";
}
.mdi-arrow-bottom-left-bold-outline:before {
    content: "\F9B6";
}
.mdi-arrow-bottom-left-thick:before {
    content: "\F9B7";
}
.mdi-arrow-bottom-right:before {
    content: "\F043";
}
.mdi-arrow-bottom-right-bold-outline:before {
    content: "\F9B8";
}
.mdi-arrow-bottom-right-thick:before {
    content: "\F9B9";
}
.mdi-arrow-collapse:before {
    content: "\F615";
}
.mdi-arrow-collapse-all:before {
    content: "\F044";
}
.mdi-arrow-collapse-down:before {
    content: "\F791";
}
.mdi-arrow-collapse-horizontal:before {
    content: "\F84B";
}
.mdi-arrow-collapse-left:before {
    content: "\F792";
}
.mdi-arrow-collapse-right:before {
    content: "\F793";
}
.mdi-arrow-collapse-up:before {
    content: "\F794";
}
.mdi-arrow-collapse-vertical:before {
    content: "\F84C";
}
.mdi-arrow-decision:before {
    content: "\F9BA";
}
.mdi-arrow-decision-auto:before {
    content: "\F9BB";
}
.mdi-arrow-decision-auto-outline:before {
    content: "\F9BC";
}
.mdi-arrow-decision-outline:before {
    content: "\F9BD";
}
.mdi-arrow-down:before {
    content: "\F045";
}
.mdi-arrow-down-bold:before {
    content: "\F72D";
}
.mdi-arrow-down-bold-box:before {
    content: "\F72E";
}
.mdi-arrow-down-bold-box-outline:before {
    content: "\F72F";
}
.mdi-arrow-down-bold-circle:before {
    content: "\F047";
}
.mdi-arrow-down-bold-circle-outline:before {
    content: "\F048";
}
.mdi-arrow-down-bold-hexagon-outline:before {
    content: "\F049";
}
.mdi-arrow-down-bold-outline:before {
    content: "\F9BE";
}
.mdi-arrow-down-box:before {
    content: "\F6BF";
}
.mdi-arrow-down-drop-circle:before {
    content: "\F04A";
}
.mdi-arrow-down-drop-circle-outline:before {
    content: "\F04B";
}
.mdi-arrow-down-thick:before {
    content: "\F046";
}
.mdi-arrow-expand:before {
    content: "\F616";
}
.mdi-arrow-expand-all:before {
    content: "\F04C";
}
.mdi-arrow-expand-down:before {
    content: "\F795";
}
.mdi-arrow-expand-horizontal:before {
    content: "\F84D";
}
.mdi-arrow-expand-left:before {
    content: "\F796";
}
.mdi-arrow-expand-right:before {
    content: "\F797";
}
.mdi-arrow-expand-up:before {
    content: "\F798";
}
.mdi-arrow-expand-vertical:before {
    content: "\F84E";
}
.mdi-arrow-left:before {
    content: "\F04D";
}
.mdi-arrow-left-bold:before {
    content: "\F730";
}
.mdi-arrow-left-bold-box:before {
    content: "\F731";
}
.mdi-arrow-left-bold-box-outline:before {
    content: "\F732";
}
.mdi-arrow-left-bold-circle:before {
    content: "\F04F";
}
.mdi-arrow-left-bold-circle-outline:before {
    content: "\F050";
}
.mdi-arrow-left-bold-hexagon-outline:before {
    content: "\F051";
}
.mdi-arrow-left-bold-outline:before {
    content: "\F9BF";
}
.mdi-arrow-left-box:before {
    content: "\F6C0";
}
.mdi-arrow-left-drop-circle:before {
    content: "\F052";
}
.mdi-arrow-left-drop-circle-outline:before {
    content: "\F053";
}
.mdi-arrow-left-right-bold-outline:before {
    content: "\F9C0";
}
.mdi-arrow-left-thick:before {
    content: "\F04E";
}
.mdi-arrow-right:before {
    content: "\F054";
}
.mdi-arrow-right-bold:before {
    content: "\F733";
}
.mdi-arrow-right-bold-box:before {
    content: "\F734";
}
.mdi-arrow-right-bold-box-outline:before {
    content: "\F735";
}
.mdi-arrow-right-bold-circle:before {
    content: "\F056";
}
.mdi-arrow-right-bold-circle-outline:before {
    content: "\F057";
}
.mdi-arrow-right-bold-hexagon-outline:before {
    content: "\F058";
}
.mdi-arrow-right-bold-outline:before {
    content: "\F9C1";
}
.mdi-arrow-right-box:before {
    content: "\F6C1";
}
.mdi-arrow-right-drop-circle:before {
    content: "\F059";
}
.mdi-arrow-right-drop-circle-outline:before {
    content: "\F05A";
}
.mdi-arrow-right-thick:before {
    content: "\F055";
}
.mdi-arrow-split-horizontal:before {
    content: "\F93A";
}
.mdi-arrow-split-vertical:before {
    content: "\F93B";
}
.mdi-arrow-top-left:before {
    content: "\F05B";
}
.mdi-arrow-top-left-bold-outline:before {
    content: "\F9C2";
}
.mdi-arrow-top-left-thick:before {
    content: "\F9C3";
}
.mdi-arrow-top-right:before {
    content: "\F05C";
}
.mdi-arrow-top-right-bold-outline:before {
    content: "\F9C4";
}
.mdi-arrow-top-right-thick:before {
    content: "\F9C5";
}
.mdi-arrow-up:before {
    content: "\F05D";
}
.mdi-arrow-up-bold:before {
    content: "\F736";
}
.mdi-arrow-up-bold-box:before {
    content: "\F737";
}
.mdi-arrow-up-bold-box-outline:before {
    content: "\F738";
}
.mdi-arrow-up-bold-circle:before {
    content: "\F05F";
}
.mdi-arrow-up-bold-circle-outline:before {
    content: "\F060";
}
.mdi-arrow-up-bold-hexagon-outline:before {
    content: "\F061";
}
.mdi-arrow-up-bold-outline:before {
    content: "\F9C6";
}
.mdi-arrow-up-box:before {
    content: "\F6C2";
}
.mdi-arrow-up-down-bold-outline:before {
    content: "\F9C7";
}
.mdi-arrow-up-drop-circle:before {
    content: "\F062";
}
.mdi-arrow-up-drop-circle-outline:before {
    content: "\F063";
}
.mdi-arrow-up-thick:before {
    content: "\F05E";
}
.mdi-artist:before {
    content: "\F802";
}
.mdi-aspect-ratio:before {
    content: "\FA23";
}
.mdi-assistant:before {
    content: "\F064";
}
.mdi-asterisk:before {
    content: "\F6C3";
}
.mdi-at:before {
    content: "\F065";
}
.mdi-atlassian:before {
    content: "\F803";
}
.mdi-atom:before {
    content: "\F767";
}
.mdi-attachment:before {
    content: "\F066";
}
.mdi-audio-video:before {
    content: "\F93C";
}
.mdi-audiobook:before {
    content: "\F067";
}
.mdi-augmented-reality:before {
    content: "\F84F";
}
.mdi-auto-fix:before {
    content: "\F068";
}
.mdi-auto-upload:before {
    content: "\F069";
}
.mdi-autorenew:before {
    content: "\F06A";
}
.mdi-av-timer:before {
    content: "\F06B";
}
.mdi-axe:before {
    content: "\F8C7";
}
.mdi-azure:before {
    content: "\F804";
}
.mdi-babel:before {
    content: "\FA24";
}
.mdi-baby:before {
    content: "\F06C";
}
.mdi-baby-buggy:before {
    content: "\F68E";
}
.mdi-backburger:before {
    content: "\F06D";
}
.mdi-backspace:before {
    content: "\F06E";
}
.mdi-backup-restore:before {
    content: "\F06F";
}
.mdi-badminton:before {
    content: "\F850";
}
.mdi-balloon:before {
    content: "\FA25";
}
.mdi-ballot:before {
    content: "\F9C8";
}
.mdi-ballot-outline:before {
    content: "\F9C9";
}
.mdi-bandcamp:before {
    content: "\F674";
}
.mdi-bank:before {
    content: "\F070";
}
.mdi-bank-transfer:before {
    content: "\FA26";
}
.mdi-bank-transfer-in:before {
    content: "\FA27";
}
.mdi-bank-transfer-out:before {
    content: "\FA28";
}
.mdi-barcode:before {
    content: "\F071";
}
.mdi-barcode-scan:before {
    content: "\F072";
}
.mdi-barley:before {
    content: "\F073";
}
.mdi-barrel:before {
    content: "\F074";
}
.mdi-baseball:before {
    content: "\F851";
}
.mdi-baseball-bat:before {
    content: "\F852";
}
.mdi-basecamp:before {
    content: "\F075";
}
.mdi-basket:before {
    content: "\F076";
}
.mdi-basket-fill:before {
    content: "\F077";
}
.mdi-basket-unfill:before {
    content: "\F078";
}
.mdi-basketball:before {
    content: "\F805";
}
.mdi-battery:before {
    content: "\F079";
}
.mdi-battery-10:before {
    content: "\F07A";
}
.mdi-battery-10-bluetooth:before {
    content: "\F93D";
}
.mdi-battery-20:before {
    content: "\F07B";
}
.mdi-battery-20-bluetooth:before {
    content: "\F93E";
}
.mdi-battery-30:before {
    content: "\F07C";
}
.mdi-battery-30-bluetooth:before {
    content: "\F93F";
}
.mdi-battery-40:before {
    content: "\F07D";
}
.mdi-battery-40-bluetooth:before {
    content: "\F940";
}
.mdi-battery-50:before {
    content: "\F07E";
}
.mdi-battery-50-bluetooth:before {
    content: "\F941";
}
.mdi-battery-60:before {
    content: "\F07F";
}
.mdi-battery-60-bluetooth:before {
    content: "\F942";
}
.mdi-battery-70:before {
    content: "\F080";
}
.mdi-battery-70-bluetooth:before {
    content: "\F943";
}
.mdi-battery-80:before {
    content: "\F081";
}
.mdi-battery-80-bluetooth:before {
    content: "\F944";
}
.mdi-battery-90:before {
    content: "\F082";
}
.mdi-battery-90-bluetooth:before {
    content: "\F945";
}
.mdi-battery-alert:before {
    content: "\F083";
}
.mdi-battery-alert-bluetooth:before {
    content: "\F946";
}
.mdi-battery-bluetooth:before {
    content: "\F947";
}
.mdi-battery-bluetooth-variant:before {
    content: "\F948";
}
.mdi-battery-charging:before {
    content: "\F084";
}
.mdi-battery-charging-10:before {
    content: "\F89B";
}
.mdi-battery-charging-100:before {
    content: "\F085";
}
.mdi-battery-charging-20:before {
    content: "\F086";
}
.mdi-battery-charging-30:before {
    content: "\F087";
}
.mdi-battery-charging-40:before {
    content: "\F088";
}
.mdi-battery-charging-50:before {
    content: "\F89C";
}
.mdi-battery-charging-60:before {
    content: "\F089";
}
.mdi-battery-charging-70:before {
    content: "\F89D";
}
.mdi-battery-charging-80:before {
    content: "\F08A";
}
.mdi-battery-charging-90:before {
    content: "\F08B";
}
.mdi-battery-charging-outline:before {
    content: "\F89E";
}
.mdi-battery-charging-wireless:before {
    content: "\F806";
}
.mdi-battery-charging-wireless-10:before {
    content: "\F807";
}
.mdi-battery-charging-wireless-20:before {
    content: "\F808";
}
.mdi-battery-charging-wireless-30:before {
    content: "\F809";
}
.mdi-battery-charging-wireless-40:before {
    content: "\F80A";
}
.mdi-battery-charging-wireless-50:before {
    content: "\F80B";
}
.mdi-battery-charging-wireless-60:before {
    content: "\F80C";
}
.mdi-battery-charging-wireless-70:before {
    content: "\F80D";
}
.mdi-battery-charging-wireless-80:before {
    content: "\F80E";
}
.mdi-battery-charging-wireless-90:before {
    content: "\F80F";
}
.mdi-battery-charging-wireless-alert:before {
    content: "\F810";
}
.mdi-battery-charging-wireless-outline:before {
    content: "\F811";
}
.mdi-battery-minus:before {
    content: "\F08C";
}
.mdi-battery-negative:before {
    content: "\F08D";
}
.mdi-battery-outline:before {
    content: "\F08E";
}
.mdi-battery-plus:before {
    content: "\F08F";
}
.mdi-battery-positive:before {
    content: "\F090";
}
.mdi-battery-unknown:before {
    content: "\F091";
}
.mdi-battery-unknown-bluetooth:before {
    content: "\F949";
}
.mdi-beach:before {
    content: "\F092";
}
.mdi-beaker:before {
    content: "\F68F";
}
.mdi-beats:before {
    content: "\F097";
}
.mdi-bed-empty:before {
    content: "\F89F";
}
.mdi-beer:before {
    content: "\F098";
}
.mdi-behance:before {
    content: "\F099";
}
.mdi-bell:before {
    content: "\F09A";
}
.mdi-bell-off:before {
    content: "\F09B";
}
.mdi-bell-off-outline:before {
    content: "\FA90";
}
.mdi-bell-outline:before {
    content: "\F09C";
}
.mdi-bell-plus:before {
    content: "\F09D";
}
.mdi-bell-plus-outline:before {
    content: "\FA91";
}
.mdi-bell-ring:before {
    content: "\F09E";
}
.mdi-bell-ring-outline:before {
    content: "\F09F";
}
.mdi-bell-sleep:before {
    content: "\F0A0";
}
.mdi-bell-sleep-outline:before {
    content: "\FA92";
}
.mdi-beta:before {
    content: "\F0A1";
}
.mdi-betamax:before {
    content: "\F9CA";
}
.mdi-bible:before {
    content: "\F0A2";
}
.mdi-bike:before {
    content: "\F0A3";
}
.mdi-bing:before {
    content: "\F0A4";
}
.mdi-binoculars:before {
    content: "\F0A5";
}
.mdi-bio:before {
    content: "\F0A6";
}
.mdi-biohazard:before {
    content: "\F0A7";
}
.mdi-bitbucket:before {
    content: "\F0A8";
}
.mdi-bitcoin:before {
    content: "\F812";
}
.mdi-black-mesa:before {
    content: "\F0A9";
}
.mdi-blackberry:before {
    content: "\F0AA";
}
.mdi-blender:before {
    content: "\F0AB";
}
.mdi-blinds:before {
    content: "\F0AC";
}
.mdi-block-helper:before {
    content: "\F0AD";
}
.mdi-blogger:before {
    content: "\F0AE";
}
.mdi-bluetooth:before {
    content: "\F0AF";
}
.mdi-bluetooth-audio:before {
    content: "\F0B0";
}
.mdi-bluetooth-connect:before {
    content: "\F0B1";
}
.mdi-bluetooth-off:before {
    content: "\F0B2";
}
.mdi-bluetooth-settings:before {
    content: "\F0B3";
}
.mdi-bluetooth-transfer:before {
    content: "\F0B4";
}
.mdi-blur:before {
    content: "\F0B5";
}
.mdi-blur-linear:before {
    content: "\F0B6";
}
.mdi-blur-off:before {
    content: "\F0B7";
}
.mdi-blur-radial:before {
    content: "\F0B8";
}
.mdi-bomb:before {
    content: "\F690";
}
.mdi-bomb-off:before {
    content: "\F6C4";
}
.mdi-bone:before {
    content: "\F0B9";
}
.mdi-book:before {
    content: "\F0BA";
}
.mdi-book-lock:before {
    content: "\F799";
}
.mdi-book-lock-open:before {
    content: "\F79A";
}
.mdi-book-minus:before {
    content: "\F5D9";
}
.mdi-book-multiple:before {
    content: "\F0BB";
}
.mdi-book-multiple-minus:before {
    content: "\FA93";
}
.mdi-book-multiple-plus:before {
    content: "\FA94";
}
.mdi-book-multiple-remove:before {
    content: "\FA95";
}
.mdi-book-multiple-variant:before {
    content: "\F0BC";
}
.mdi-book-open:before {
    content: "\F0BD";
}
.mdi-book-open-page-variant:before {
    content: "\F5DA";
}
.mdi-book-open-variant:before {
    content: "\F0BE";
}
.mdi-book-plus:before {
    content: "\F5DB";
}
.mdi-book-remove:before {
    content: "\FA96";
}
.mdi-book-variant:before {
    content: "\F0BF";
}
.mdi-bookmark:before {
    content: "\F0C0";
}
.mdi-bookmark-check:before {
    content: "\F0C1";
}
.mdi-bookmark-minus:before {
    content: "\F9CB";
}
.mdi-bookmark-minus-outline:before {
    content: "\F9CC";
}
.mdi-bookmark-music:before {
    content: "\F0C2";
}
.mdi-bookmark-off:before {
    content: "\F9CD";
}
.mdi-bookmark-off-outline:before {
    content: "\F9CE";
}
.mdi-bookmark-outline:before {
    content: "\F0C3";
}
.mdi-bookmark-plus:before {
    content: "\F0C5";
}
.mdi-bookmark-plus-outline:before {
    content: "\F0C4";
}
.mdi-bookmark-remove:before {
    content: "\F0C6";
}
.mdi-boombox:before {
    content: "\F5DC";
}
.mdi-bootstrap:before {
    content: "\F6C5";
}
.mdi-border-all:before {
    content: "\F0C7";
}
.mdi-border-all-variant:before {
    content: "\F8A0";
}
.mdi-border-bottom:before {
    content: "\F0C8";
}
.mdi-border-bottom-variant:before {
    content: "\F8A1";
}
.mdi-border-color:before {
    content: "\F0C9";
}
.mdi-border-horizontal:before {
    content: "\F0CA";
}
.mdi-border-inside:before {
    content: "\F0CB";
}
.mdi-border-left:before {
    content: "\F0CC";
}
.mdi-border-left-variant:before {
    content: "\F8A2";
}
.mdi-border-none:before {
    content: "\F0CD";
}
.mdi-border-none-variant:before {
    content: "\F8A3";
}
.mdi-border-outside:before {
    content: "\F0CE";
}
.mdi-border-right:before {
    content: "\F0CF";
}
.mdi-border-right-variant:before {
    content: "\F8A4";
}
.mdi-border-style:before {
    content: "\F0D0";
}
.mdi-border-top:before {
    content: "\F0D1";
}
.mdi-border-top-variant:before {
    content: "\F8A5";
}
.mdi-border-vertical:before {
    content: "\F0D2";
}
.mdi-bottle-wine:before {
    content: "\F853";
}
.mdi-bow-tie:before {
    content: "\F677";
}
.mdi-bowl:before {
    content: "\F617";
}
.mdi-bowling:before {
    content: "\F0D3";
}
.mdi-box:before {
    content: "\F0D4";
}
.mdi-box-cutter:before {
    content: "\F0D5";
}
.mdi-box-shadow:before {
    content: "\F637";
}
.mdi-braille:before {
    content: "\F9CF";
}
.mdi-brain:before {
    content: "\F9D0";
}
.mdi-bridge:before {
    content: "\F618";
}
.mdi-briefcase:before {
    content: "\F0D6";
}
.mdi-briefcase-check:before {
    content: "\F0D7";
}
.mdi-briefcase-download:before {
    content: "\F0D8";
}
.mdi-briefcase-edit:before {
    content: "\FA97";
}
.mdi-briefcase-minus:before {
    content: "\FA29";
}
.mdi-briefcase-outline:before {
    content: "\F813";
}
.mdi-briefcase-plus:before {
    content: "\FA2A";
}
.mdi-briefcase-remove:before {
    content: "\FA2B";
}
.mdi-briefcase-search:before {
    content: "\FA2C";
}
.mdi-briefcase-upload:before {
    content: "\F0D9";
}
.mdi-brightness-1:before {
    content: "\F0DA";
}
.mdi-brightness-2:before {
    content: "\F0DB";
}
.mdi-brightness-3:before {
    content: "\F0DC";
}
.mdi-brightness-4:before {
    content: "\F0DD";
}
.mdi-brightness-5:before {
    content: "\F0DE";
}
.mdi-brightness-6:before {
    content: "\F0DF";
}
.mdi-brightness-7:before {
    content: "\F0E0";
}
.mdi-brightness-auto:before {
    content: "\F0E1";
}
.mdi-broom:before {
    content: "\F0E2";
}
.mdi-brush:before {
    content: "\F0E3";
}
.mdi-buddhism:before {
    content: "\F94A";
}
.mdi-buffer:before {
    content: "\F619";
}
.mdi-bug:before {
    content: "\F0E4";
}
.mdi-bug-check:before {
    content: "\FA2D";
}
.mdi-bug-check-outline:before {
    content: "\FA2E";
}
.mdi-bug-outline:before {
    content: "\FA2F";
}
.mdi-bulldozer:before {
    content: "\FB07";
}
.mdi-bulletin-board:before {
    content: "\F0E5";
}
.mdi-bullhorn:before {
    content: "\F0E6";
}
.mdi-bullhorn-outline:before {
    content: "\FB08";
}
.mdi-bullseye:before {
    content: "\F5DD";
}
.mdi-bullseye-arrow:before {
    content: "\F8C8";
}
.mdi-bus:before {
    content: "\F0E7";
}
.mdi-bus-alert:before {
    content: "\FA98";
}
.mdi-bus-articulated-end:before {
    content: "\F79B";
}
.mdi-bus-articulated-front:before {
    content: "\F79C";
}
.mdi-bus-clock:before {
    content: "\F8C9";
}
.mdi-bus-double-decker:before {
    content: "\F79D";
}
.mdi-bus-school:before {
    content: "\F79E";
}
.mdi-bus-side:before {
    content: "\F79F";
}
.mdi-cached:before {
    content: "\F0E8";
}
.mdi-cake:before {
    content: "\F0E9";
}
.mdi-cake-layered:before {
    content: "\F0EA";
}
.mdi-cake-variant:before {
    content: "\F0EB";
}
.mdi-calculator:before {
    content: "\F0EC";
}
.mdi-calculator-variant:before {
    content: "\FA99";
}
.mdi-calendar:before {
    content: "\F0ED";
}
.mdi-calendar-alert:before {
    content: "\FA30";
}
.mdi-calendar-blank:before {
    content: "\F0EE";
}
.mdi-calendar-check:before {
    content: "\F0EF";
}
.mdi-calendar-clock:before {
    content: "\F0F0";
}
.mdi-calendar-edit:before {
    content: "\F8A6";
}
.mdi-calendar-export:before {
    content: "\FB09";
}
.mdi-calendar-heart:before {
    content: "\F9D1";
}
.mdi-calendar-import:before {
    content: "\FB0A";
}
.mdi-calendar-multiple:before {
    content: "\F0F1";
}
.mdi-calendar-multiple-check:before {
    content: "\F0F2";
}
.mdi-calendar-multiselect:before {
    content: "\FA31";
}
.mdi-calendar-plus:before {
    content: "\F0F3";
}
.mdi-calendar-question:before {
    content: "\F691";
}
.mdi-calendar-range:before {
    content: "\F678";
}
.mdi-calendar-remove:before {
    content: "\F0F4";
}
.mdi-calendar-search:before {
    content: "\F94B";
}
.mdi-calendar-star:before {
    content: "\F9D2";
}
.mdi-calendar-text:before {
    content: "\F0F5";
}
.mdi-calendar-today:before {
    content: "\F0F6";
}
.mdi-calendar-week:before {
    content: "\FA32";
}
.mdi-calendar-week-begin:before {
    content: "\FA33";
}
.mdi-call-made:before {
    content: "\F0F7";
}
.mdi-call-merge:before {
    content: "\F0F8";
}
.mdi-call-missed:before {
    content: "\F0F9";
}
.mdi-call-received:before {
    content: "\F0FA";
}
.mdi-call-split:before {
    content: "\F0FB";
}
.mdi-camcorder:before {
    content: "\F0FC";
}
.mdi-camcorder-box:before {
    content: "\F0FD";
}
.mdi-camcorder-box-off:before {
    content: "\F0FE";
}
.mdi-camcorder-off:before {
    content: "\F0FF";
}
.mdi-camera:before {
    content: "\F100";
}
.mdi-camera-account:before {
    content: "\F8CA";
}
.mdi-camera-burst:before {
    content: "\F692";
}
.mdi-camera-enhance:before {
    content: "\F101";
}
.mdi-camera-front:before {
    content: "\F102";
}
.mdi-camera-front-variant:before {
    content: "\F103";
}
.mdi-camera-gopro:before {
    content: "\F7A0";
}
.mdi-camera-image:before {
    content: "\F8CB";
}
.mdi-camera-iris:before {
    content: "\F104";
}
.mdi-camera-metering-center:before {
    content: "\F7A1";
}
.mdi-camera-metering-matrix:before {
    content: "\F7A2";
}
.mdi-camera-metering-partial:before {
    content: "\F7A3";
}
.mdi-camera-metering-spot:before {
    content: "\F7A4";
}
.mdi-camera-off:before {
    content: "\F5DF";
}
.mdi-camera-party-mode:before {
    content: "\F105";
}
.mdi-camera-rear:before {
    content: "\F106";
}
.mdi-camera-rear-variant:before {
    content: "\F107";
}
.mdi-camera-switch:before {
    content: "\F108";
}
.mdi-camera-timer:before {
    content: "\F109";
}
.mdi-cancel:before {
    content: "\F739";
}
.mdi-candle:before {
    content: "\F5E2";
}
.mdi-candycane:before {
    content: "\F10A";
}
.mdi-cannabis:before {
    content: "\F7A5";
}
.mdi-caps-lock:before {
    content: "\FA9A";
}
.mdi-car:before {
    content: "\F10B";
}
.mdi-car-battery:before {
    content: "\F10C";
}
.mdi-car-connected:before {
    content: "\F10D";
}
.mdi-car-convertible:before {
    content: "\F7A6";
}
.mdi-car-estate:before {
    content: "\F7A7";
}
.mdi-car-hatchback:before {
    content: "\F7A8";
}
.mdi-car-limousine:before {
    content: "\F8CC";
}
.mdi-car-pickup:before {
    content: "\F7A9";
}
.mdi-car-side:before {
    content: "\F7AA";
}
.mdi-car-sports:before {
    content: "\F7AB";
}
.mdi-car-wash:before {
    content: "\F10E";
}
.mdi-caravan:before {
    content: "\F7AC";
}
.mdi-cards:before {
    content: "\F638";
}
.mdi-cards-club:before {
    content: "\F8CD";
}
.mdi-cards-diamond:before {
    content: "\F8CE";
}
.mdi-cards-heart:before {
    content: "\F8CF";
}
.mdi-cards-outline:before {
    content: "\F639";
}
.mdi-cards-playing-outline:before {
    content: "\F63A";
}
.mdi-cards-spade:before {
    content: "\F8D0";
}
.mdi-cards-variant:before {
    content: "\F6C6";
}
.mdi-carrot:before {
    content: "\F10F";
}
.mdi-cart:before {
    content: "\F110";
}
.mdi-cart-off:before {
    content: "\F66B";
}
.mdi-cart-outline:before {
    content: "\F111";
}
.mdi-cart-plus:before {
    content: "\F112";
}
.mdi-case-sensitive-alt:before {
    content: "\F113";
}
.mdi-cash:before {
    content: "\F114";
}
.mdi-cash-100:before {
    content: "\F115";
}
.mdi-cash-multiple:before {
    content: "\F116";
}
.mdi-cash-refund:before {
    content: "\FA9B";
}
.mdi-cash-usd:before {
    content: "\F117";
}
.mdi-cassette:before {
    content: "\F9D3";
}
.mdi-cast:before {
    content: "\F118";
}
.mdi-cast-connected:before {
    content: "\F119";
}
.mdi-cast-off:before {
    content: "\F789";
}
.mdi-castle:before {
    content: "\F11A";
}
.mdi-cat:before {
    content: "\F11B";
}
.mdi-cctv:before {
    content: "\F7AD";
}
.mdi-ceiling-light:before {
    content: "\F768";
}
.mdi-cellphone:before {
    content: "\F11C";
}
.mdi-cellphone-android:before {
    content: "\F11D";
}
.mdi-cellphone-arrow-down:before {
    content: "\F9D4";
}
.mdi-cellphone-basic:before {
    content: "\F11E";
}
.mdi-cellphone-dock:before {
    content: "\F11F";
}
.mdi-cellphone-erase:before {
    content: "\F94C";
}
.mdi-cellphone-iphone:before {
    content: "\F120";
}
.mdi-cellphone-key:before {
    content: "\F94D";
}
.mdi-cellphone-link:before {
    content: "\F121";
}
.mdi-cellphone-link-off:before {
    content: "\F122";
}
.mdi-cellphone-lock:before {
    content: "\F94E";
}
.mdi-cellphone-message:before {
    content: "\F8D2";
}
.mdi-cellphone-off:before {
    content: "\F94F";
}
.mdi-cellphone-screenshot:before {
    content: "\FA34";
}
.mdi-cellphone-settings:before {
    content: "\F123";
}
.mdi-cellphone-settings-variant:before {
    content: "\F950";
}
.mdi-cellphone-sound:before {
    content: "\F951";
}
.mdi-cellphone-text:before {
    content: "\F8D1";
}
.mdi-cellphone-wireless:before {
    content: "\F814";
}
.mdi-certificate:before {
    content: "\F124";
}
.mdi-chair-school:before {
    content: "\F125";
}
.mdi-chart-arc:before {
    content: "\F126";
}
.mdi-chart-areaspline:before {
    content: "\F127";
}
.mdi-chart-bar:before {
    content: "\F128";
}
.mdi-chart-bar-stacked:before {
    content: "\F769";
}
.mdi-chart-bubble:before {
    content: "\F5E3";
}
.mdi-chart-donut:before {
    content: "\F7AE";
}
.mdi-chart-donut-variant:before {
    content: "\F7AF";
}
.mdi-chart-gantt:before {
    content: "\F66C";
}
.mdi-chart-histogram:before {
    content: "\F129";
}
.mdi-chart-line:before {
    content: "\F12A";
}
.mdi-chart-line-stacked:before {
    content: "\F76A";
}
.mdi-chart-line-variant:before {
    content: "\F7B0";
}
.mdi-chart-multiline:before {
    content: "\F8D3";
}
.mdi-chart-pie:before {
    content: "\F12B";
}
.mdi-chart-scatterplot-hexbin:before {
    content: "\F66D";
}
.mdi-chart-timeline:before {
    content: "\F66E";
}
.mdi-check:before {
    content: "\F12C";
}
.mdi-check-all:before {
    content: "\F12D";
}
.mdi-check-circle:before {
    content: "\F5E0";
}
.mdi-check-circle-outline:before {
    content: "\F5E1";
}
.mdi-check-decagram:before {
    content: "\F790";
}
.mdi-check-outline:before {
    content: "\F854";
}
.mdi-checkbook:before {
    content: "\FA9C";
}
.mdi-checkbox-blank:before {
    content: "\F12E";
}
.mdi-checkbox-blank-circle:before {
    content: "\F12F";
}
.mdi-checkbox-blank-circle-outline:before {
    content: "\F130";
}
.mdi-checkbox-blank-outline:before {
    content: "\F131";
}
.mdi-checkbox-intermediate:before {
    content: "\F855";
}
.mdi-checkbox-marked:before {
    content: "\F132";
}
.mdi-checkbox-marked-circle:before {
    content: "\F133";
}
.mdi-checkbox-marked-circle-outline:before {
    content: "\F134";
}
.mdi-checkbox-marked-outline:before {
    content: "\F135";
}
.mdi-checkbox-multiple-blank:before {
    content: "\F136";
}
.mdi-checkbox-multiple-blank-circle:before {
    content: "\F63B";
}
.mdi-checkbox-multiple-blank-circle-outline:before {
    content: "\F63C";
}
.mdi-checkbox-multiple-blank-outline:before {
    content: "\F137";
}
.mdi-checkbox-multiple-marked:before {
    content: "\F138";
}
.mdi-checkbox-multiple-marked-circle:before {
    content: "\F63D";
}
.mdi-checkbox-multiple-marked-circle-outline:before {
    content: "\F63E";
}
.mdi-checkbox-multiple-marked-outline:before {
    content: "\F139";
}
.mdi-checkerboard:before {
    content: "\F13A";
}
.mdi-chemical-weapon:before {
    content: "\F13B";
}
.mdi-chess-bishop:before {
    content: "\F85B";
}
.mdi-chess-king:before {
    content: "\F856";
}
.mdi-chess-knight:before {
    content: "\F857";
}
.mdi-chess-pawn:before {
    content: "\F858";
}
.mdi-chess-queen:before {
    content: "\F859";
}
.mdi-chess-rook:before {
    content: "\F85A";
}
.mdi-chevron-double-down:before {
    content: "\F13C";
}
.mdi-chevron-double-left:before {
    content: "\F13D";
}
.mdi-chevron-double-right:before {
    content: "\F13E";
}
.mdi-chevron-double-up:before {
    content: "\F13F";
}
.mdi-chevron-down:before {
    content: "\F140";
}
.mdi-chevron-down-box:before {
    content: "\F9D5";
}
.mdi-chevron-down-box-outline:before {
    content: "\F9D6";
}
.mdi-chevron-down-circle:before {
    content: "\FB0B";
}
.mdi-chevron-down-circle-outline:before {
    content: "\FB0C";
}
.mdi-chevron-left:before {
    content: "\F141";
}
.mdi-chevron-left-box:before {
    content: "\F9D7";
}
.mdi-chevron-left-box-outline:before {
    content: "\F9D8";
}
.mdi-chevron-left-circle:before {
    content: "\FB0D";
}
.mdi-chevron-left-circle-outline:before {
    content: "\FB0E";
}
.mdi-chevron-right:before {
    content: "\F142";
}
.mdi-chevron-right-box:before {
    content: "\F9D9";
}
.mdi-chevron-right-box-outline:before {
    content: "\F9DA";
}
.mdi-chevron-right-circle:before {
    content: "\FB0F";
}
.mdi-chevron-right-circle-outline:before {
    content: "\FB10";
}
.mdi-chevron-up:before {
    content: "\F143";
}
.mdi-chevron-up-box:before {
    content: "\F9DB";
}
.mdi-chevron-up-box-outline:before {
    content: "\F9DC";
}
.mdi-chevron-up-circle:before {
    content: "\FB11";
}
.mdi-chevron-up-circle-outline:before {
    content: "\FB12";
}
.mdi-chili-hot:before {
    content: "\F7B1";
}
.mdi-chili-medium:before {
    content: "\F7B2";
}
.mdi-chili-mild:before {
    content: "\F7B3";
}
.mdi-chip:before {
    content: "\F61A";
}
.mdi-christianity:before {
    content: "\F952";
}
.mdi-church:before {
    content: "\F144";
}
.mdi-circle:before {
    content: "\F764";
}
.mdi-circle-edit-outline:before {
    content: "\F8D4";
}
.mdi-circle-medium:before {
    content: "\F9DD";
}
.mdi-circle-outline:before {
    content: "\F765";
}
.mdi-circle-slice-1:before {
    content: "\FA9D";
}
.mdi-circle-slice-2:before {
    content: "\FA9E";
}
.mdi-circle-slice-3:before {
    content: "\FA9F";
}
.mdi-circle-slice-4:before {
    content: "\FAA0";
}
.mdi-circle-slice-5:before {
    content: "\FAA1";
}
.mdi-circle-slice-6:before {
    content: "\FAA2";
}
.mdi-circle-slice-7:before {
    content: "\FAA3";
}
.mdi-circle-slice-8:before {
    content: "\FAA4";
}
.mdi-circle-small:before {
    content: "\F9DE";
}
.mdi-cisco-webex:before {
    content: "\F145";
}
.mdi-city:before {
    content: "\F146";
}
.mdi-city-variant:before {
    content: "\FA35";
}
.mdi-city-variant-outline:before {
    content: "\FA36";
}
.mdi-clipboard:before {
    content: "\F147";
}
.mdi-clipboard-account:before {
    content: "\F148";
}
.mdi-clipboard-alert:before {
    content: "\F149";
}
.mdi-clipboard-arrow-down:before {
    content: "\F14A";
}
.mdi-clipboard-arrow-left:before {
    content: "\F14B";
}
.mdi-clipboard-check:before {
    content: "\F14C";
}
.mdi-clipboard-check-outline:before {
    content: "\F8A7";
}
.mdi-clipboard-flow:before {
    content: "\F6C7";
}
.mdi-clipboard-outline:before {
    content: "\F14D";
}
.mdi-clipboard-plus:before {
    content: "\F750";
}
.mdi-clipboard-pulse:before {
    content: "\F85C";
}
.mdi-clipboard-pulse-outline:before {
    content: "\F85D";
}
.mdi-clipboard-text:before {
    content: "\F14E";
}
.mdi-clipboard-text-outline:before {
    content: "\FA37";
}
.mdi-clippy:before {
    content: "\F14F";
}
.mdi-clock:before {
    content: "\F953";
}
.mdi-clock-alert:before {
    content: "\F954";
}
.mdi-clock-alert-outline:before {
    content: "\F5CE";
}
.mdi-clock-end:before {
    content: "\F151";
}
.mdi-clock-fast:before {
    content: "\F152";
}
.mdi-clock-in:before {
    content: "\F153";
}
.mdi-clock-out:before {
    content: "\F154";
}
.mdi-clock-outline:before {
    content: "\F150";
}
.mdi-clock-start:before {
    content: "\F155";
}
.mdi-close:before {
    content: "\F156";
}
.mdi-close-box:before {
    content: "\F157";
}
.mdi-close-box-outline:before {
    content: "\F158";
}
.mdi-close-circle:before {
    content: "\F159";
}
.mdi-close-circle-outline:before {
    content: "\F15A";
}
.mdi-close-network:before {
    content: "\F15B";
}
.mdi-close-octagon:before {
    content: "\F15C";
}
.mdi-close-octagon-outline:before {
    content: "\F15D";
}
.mdi-close-outline:before {
    content: "\F6C8";
}
.mdi-closed-caption:before {
    content: "\F15E";
}
.mdi-cloud:before {
    content: "\F15F";
}
.mdi-cloud-alert:before {
    content: "\F9DF";
}
.mdi-cloud-braces:before {
    content: "\F7B4";
}
.mdi-cloud-check:before {
    content: "\F160";
}
.mdi-cloud-circle:before {
    content: "\F161";
}
.mdi-cloud-download:before {
    content: "\F162";
}
.mdi-cloud-off-outline:before {
    content: "\F164";
}
.mdi-cloud-outline:before {
    content: "\F163";
}
.mdi-cloud-print:before {
    content: "\F165";
}
.mdi-cloud-print-outline:before {
    content: "\F166";
}
.mdi-cloud-question:before {
    content: "\FA38";
}
.mdi-cloud-search:before {
    content: "\F955";
}
.mdi-cloud-search-outline:before {
    content: "\F956";
}
.mdi-cloud-sync:before {
    content: "\F63F";
}
.mdi-cloud-tags:before {
    content: "\F7B5";
}
.mdi-cloud-upload:before {
    content: "\F167";
}
.mdi-clover:before {
    content: "\F815";
}
.mdi-code-array:before {
    content: "\F168";
}
.mdi-code-braces:before {
    content: "\F169";
}
.mdi-code-brackets:before {
    content: "\F16A";
}
.mdi-code-equal:before {
    content: "\F16B";
}
.mdi-code-greater-than:before {
    content: "\F16C";
}
.mdi-code-greater-than-or-equal:before {
    content: "\F16D";
}
.mdi-code-less-than:before {
    content: "\F16E";
}
.mdi-code-less-than-or-equal:before {
    content: "\F16F";
}
.mdi-code-not-equal:before {
    content: "\F170";
}
.mdi-code-not-equal-variant:before {
    content: "\F171";
}
.mdi-code-parentheses:before {
    content: "\F172";
}
.mdi-code-string:before {
    content: "\F173";
}
.mdi-code-tags:before {
    content: "\F174";
}
.mdi-code-tags-check:before {
    content: "\F693";
}
.mdi-codepen:before {
    content: "\F175";
}
.mdi-coffee:before {
    content: "\F176";
}
.mdi-coffee-outline:before {
    content: "\F6C9";
}
.mdi-coffee-to-go:before {
    content: "\F177";
}
.mdi-cogs:before {
    content: "\F8D5";
}
.mdi-coin:before {
    content: "\F178";
}
.mdi-coins:before {
    content: "\F694";
}
.mdi-collage:before {
    content: "\F640";
}
.mdi-collapse-all:before {
    content: "\FAA5";
}
.mdi-collapse-all-outline:before {
    content: "\FAA6";
}
.mdi-color-helper:before {
    content: "\F179";
}
.mdi-comment:before {
    content: "\F17A";
}
.mdi-comment-account:before {
    content: "\F17B";
}
.mdi-comment-account-outline:before {
    content: "\F17C";
}
.mdi-comment-alert:before {
    content: "\F17D";
}
.mdi-comment-alert-outline:before {
    content: "\F17E";
}
.mdi-comment-arrow-left:before {
    content: "\F9E0";
}
.mdi-comment-arrow-left-outline:before {
    content: "\F9E1";
}
.mdi-comment-arrow-right:before {
    content: "\F9E2";
}
.mdi-comment-arrow-right-outline:before {
    content: "\F9E3";
}
.mdi-comment-check:before {
    content: "\F17F";
}
.mdi-comment-check-outline:before {
    content: "\F180";
}
.mdi-comment-eye:before {
    content: "\FA39";
}
.mdi-comment-eye-outline:before {
    content: "\FA3A";
}
.mdi-comment-multiple:before {
    content: "\F85E";
}
.mdi-comment-multiple-outline:before {
    content: "\F181";
}
.mdi-comment-outline:before {
    content: "\F182";
}
.mdi-comment-plus:before {
    content: "\F9E4";
}
.mdi-comment-plus-outline:before {
    content: "\F183";
}
.mdi-comment-processing:before {
    content: "\F184";
}
.mdi-comment-processing-outline:before {
    content: "\F185";
}
.mdi-comment-question:before {
    content: "\F816";
}
.mdi-comment-question-outline:before {
    content: "\F186";
}
.mdi-comment-remove:before {
    content: "\F5DE";
}
.mdi-comment-remove-outline:before {
    content: "\F187";
}
.mdi-comment-search:before {
    content: "\FA3B";
}
.mdi-comment-search-outline:before {
    content: "\FA3C";
}
.mdi-comment-text:before {
    content: "\F188";
}
.mdi-comment-text-multiple:before {
    content: "\F85F";
}
.mdi-comment-text-multiple-outline:before {
    content: "\F860";
}
.mdi-comment-text-outline:before {
    content: "\F189";
}
.mdi-compare:before {
    content: "\F18A";
}
.mdi-compass:before {
    content: "\F18B";
}
.mdi-compass-outline:before {
    content: "\F18C";
}
.mdi-console:before {
    content: "\F18D";
}
.mdi-console-line:before {
    content: "\F7B6";
}
.mdi-console-network:before {
    content: "\F8A8";
}
.mdi-contact-mail:before {
    content: "\F18E";
}
.mdi-contacts:before {
    content: "\F6CA";
}
.mdi-contain:before {
    content: "\FA3D";
}
.mdi-contain-end:before {
    content: "\FA3E";
}
.mdi-contain-start:before {
    content: "\FA3F";
}
.mdi-content-copy:before {
    content: "\F18F";
}
.mdi-content-cut:before {
    content: "\F190";
}
.mdi-content-duplicate:before {
    content: "\F191";
}
.mdi-content-paste:before {
    content: "\F192";
}
.mdi-content-save:before {
    content: "\F193";
}
.mdi-content-save-all:before {
    content: "\F194";
}
.mdi-content-save-outline:before {
    content: "\F817";
}
.mdi-content-save-settings:before {
    content: "\F61B";
}
.mdi-content-save-settings-outline:before {
    content: "\FB13";
}
.mdi-contrast:before {
    content: "\F195";
}
.mdi-contrast-box:before {
    content: "\F196";
}
.mdi-contrast-circle:before {
    content: "\F197";
}
.mdi-cookie:before {
    content: "\F198";
}
.mdi-copyright:before {
    content: "\F5E6";
}
.mdi-cordova:before {
    content: "\F957";
}
.mdi-corn:before {
    content: "\F7B7";
}
.mdi-counter:before {
    content: "\F199";
}
.mdi-cow:before {
    content: "\F19A";
}
.mdi-crane:before {
    content: "\F861";
}
.mdi-creation:before {
    content: "\F1C9";
}
.mdi-credit-card:before {
    content: "\F19B";
}
.mdi-credit-card-multiple:before {
    content: "\F19C";
}
.mdi-credit-card-off:before {
    content: "\F5E4";
}
.mdi-credit-card-plus:before {
    content: "\F675";
}
.mdi-credit-card-refund:before {
    content: "\FAA7";
}
.mdi-credit-card-scan:before {
    content: "\F19D";
}
.mdi-credit-card-settings:before {
    content: "\F8D6";
}
.mdi-crop:before {
    content: "\F19E";
}
.mdi-crop-free:before {
    content: "\F19F";
}
.mdi-crop-landscape:before {
    content: "\F1A0";
}
.mdi-crop-portrait:before {
    content: "\F1A1";
}
.mdi-crop-rotate:before {
    content: "\F695";
}
.mdi-crop-square:before {
    content: "\F1A2";
}
.mdi-crosshairs:before {
    content: "\F1A3";
}
.mdi-crosshairs-gps:before {
    content: "\F1A4";
}
.mdi-crown:before {
    content: "\F1A5";
}
.mdi-cryengine:before {
    content: "\F958";
}
.mdi-crystal-ball:before {
    content: "\FB14";
}
.mdi-cube:before {
    content: "\F1A6";
}
.mdi-cube-outline:before {
    content: "\F1A7";
}
.mdi-cube-send:before {
    content: "\F1A8";
}
.mdi-cube-unfolded:before {
    content: "\F1A9";
}
.mdi-cup:before {
    content: "\F1AA";
}
.mdi-cup-off:before {
    content: "\F5E5";
}
.mdi-cup-water:before {
    content: "\F1AB";
}
.mdi-cupcake:before {
    content: "\F959";
}
.mdi-curling:before {
    content: "\F862";
}
.mdi-currency-bdt:before {
    content: "\F863";
}
.mdi-currency-btc:before {
    content: "\F1AC";
}
.mdi-currency-chf:before {
    content: "\F7B8";
}
.mdi-currency-cny:before {
    content: "\F7B9";
}
.mdi-currency-eth:before {
    content: "\F7BA";
}
.mdi-currency-eur:before {
    content: "\F1AD";
}
.mdi-currency-gbp:before {
    content: "\F1AE";
}
.mdi-currency-inr:before {
    content: "\F1AF";
}
.mdi-currency-jpy:before {
    content: "\F7BB";
}
.mdi-currency-krw:before {
    content: "\F7BC";
}
.mdi-currency-kzt:before {
    content: "\F864";
}
.mdi-currency-ngn:before {
    content: "\F1B0";
}
.mdi-currency-php:before {
    content: "\F9E5";
}
.mdi-currency-rub:before {
    content: "\F1B1";
}
.mdi-currency-sign:before {
    content: "\F7BD";
}
.mdi-currency-try:before {
    content: "\F1B2";
}
.mdi-currency-twd:before {
    content: "\F7BE";
}
.mdi-currency-usd:before {
    content: "\F1B3";
}
.mdi-currency-usd-off:before {
    content: "\F679";
}
.mdi-current-ac:before {
    content: "\F95A";
}
.mdi-current-dc:before {
    content: "\F95B";
}
.mdi-cursor-default:before {
    content: "\F1B4";
}
.mdi-cursor-default-outline:before {
    content: "\F1B5";
}
.mdi-cursor-move:before {
    content: "\F1B6";
}
.mdi-cursor-pointer:before {
    content: "\F1B7";
}
.mdi-cursor-text:before {
    content: "\F5E7";
}
.mdi-database:before {
    content: "\F1B8";
}
.mdi-database-check:before {
    content: "\FAA8";
}
.mdi-database-export:before {
    content: "\F95D";
}
.mdi-database-import:before {
    content: "\F95C";
}
.mdi-database-lock:before {
    content: "\FAA9";
}
.mdi-database-minus:before {
    content: "\F1B9";
}
.mdi-database-plus:before {
    content: "\F1BA";
}
.mdi-database-search:before {
    content: "\F865";
}
.mdi-death-star:before {
    content: "\F8D7";
}
.mdi-death-star-variant:before {
    content: "\F8D8";
}
.mdi-debian:before {
    content: "\F8D9";
}
.mdi-debug-step-into:before {
    content: "\F1BB";
}
.mdi-debug-step-out:before {
    content: "\F1BC";
}
.mdi-debug-step-over:before {
    content: "\F1BD";
}
.mdi-decagram:before {
    content: "\F76B";
}
.mdi-decagram-outline:before {
    content: "\F76C";
}
.mdi-decimal-decrease:before {
    content: "\F1BE";
}
.mdi-decimal-increase:before {
    content: "\F1BF";
}
.mdi-delete:before {
    content: "\F1C0";
}
.mdi-delete-circle:before {
    content: "\F682";
}
.mdi-delete-empty:before {
    content: "\F6CB";
}
.mdi-delete-forever:before {
    content: "\F5E8";
}
.mdi-delete-outline:before {
    content: "\F9E6";
}
.mdi-delete-restore:before {
    content: "\F818";
}
.mdi-delete-sweep:before {
    content: "\F5E9";
}
.mdi-delete-variant:before {
    content: "\F1C1";
}
.mdi-delta:before {
    content: "\F1C2";
}
.mdi-desk-lamp:before {
    content: "\F95E";
}
.mdi-deskphone:before {
    content: "\F1C3";
}
.mdi-desktop-classic:before {
    content: "\F7BF";
}
.mdi-desktop-mac:before {
    content: "\F1C4";
}
.mdi-desktop-mac-dashboard:before {
    content: "\F9E7";
}
.mdi-desktop-tower:before {
    content: "\F1C5";
}
.mdi-desktop-tower-monitor:before {
    content: "\FAAA";
}
.mdi-details:before {
    content: "\F1C6";
}
.mdi-developer-board:before {
    content: "\F696";
}
.mdi-deviantart:before {
    content: "\F1C7";
}
.mdi-dialpad:before {
    content: "\F61C";
}
.mdi-diamond:before {
    content: "\F1C8";
}
.mdi-dice-1:before {
    content: "\F1CA";
}
.mdi-dice-2:before {
    content: "\F1CB";
}
.mdi-dice-3:before {
    content: "\F1CC";
}
.mdi-dice-4:before {
    content: "\F1CD";
}
.mdi-dice-5:before {
    content: "\F1CE";
}
.mdi-dice-6:before {
    content: "\F1CF";
}
.mdi-dice-d10:before {
    content: "\F76E";
}
.mdi-dice-d12:before {
    content: "\F866";
}
.mdi-dice-d20:before {
    content: "\F5EA";
}
.mdi-dice-d4:before {
    content: "\F5EB";
}
.mdi-dice-d6:before {
    content: "\F5EC";
}
.mdi-dice-d8:before {
    content: "\F5ED";
}
.mdi-dice-multiple:before {
    content: "\F76D";
}
.mdi-dictionary:before {
    content: "\F61D";
}
.mdi-dip-switch:before {
    content: "\F7C0";
}
.mdi-directions:before {
    content: "\F1D0";
}
.mdi-directions-fork:before {
    content: "\F641";
}
.mdi-disc:before {
    content: "\F5EE";
}
.mdi-disc-alert:before {
    content: "\F1D1";
}
.mdi-disc-player:before {
    content: "\F95F";
}
.mdi-discord:before {
    content: "\F66F";
}
.mdi-dishwasher:before {
    content: "\FAAB";
}
.mdi-disqus:before {
    content: "\F1D2";
}
.mdi-disqus-outline:before {
    content: "\F1D3";
}
.mdi-division:before {
    content: "\F1D4";
}
.mdi-division-box:before {
    content: "\F1D5";
}
.mdi-dlna:before {
    content: "\FA40";
}
.mdi-dna:before {
    content: "\F683";
}
.mdi-dns:before {
    content: "\F1D6";
}
.mdi-do-not-disturb:before {
    content: "\F697";
}
.mdi-do-not-disturb-off:before {
    content: "\F698";
}
.mdi-docker:before {
    content: "\F867";
}
.mdi-doctor:before {
    content: "\FA41";
}
.mdi-dog:before {
    content: "\FA42";
}
.mdi-dog-service:before {
    content: "\FAAC";
}
.mdi-dog-side:before {
    content: "\FA43";
}
.mdi-dolby:before {
    content: "\F6B2";
}
.mdi-domain:before {
    content: "\F1D7";
}
.mdi-donkey:before {
    content: "\F7C1";
}
.mdi-door:before {
    content: "\F819";
}
.mdi-door-closed:before {
    content: "\F81A";
}
.mdi-door-open:before {
    content: "\F81B";
}
.mdi-doorbell-video:before {
    content: "\F868";
}
.mdi-dot-net:before {
    content: "\FAAD";
}
.mdi-dots-horizontal:before {
    content: "\F1D8";
}
.mdi-dots-horizontal-circle:before {
    content: "\F7C2";
}
.mdi-dots-vertical:before {
    content: "\F1D9";
}
.mdi-dots-vertical-circle:before {
    content: "\F7C3";
}
.mdi-douban:before {
    content: "\F699";
}
.mdi-download:before {
    content: "\F1DA";
}
.mdi-download-multiple:before {
    content: "\F9E8";
}
.mdi-download-network:before {
    content: "\F6F3";
}
.mdi-drag:before {
    content: "\F1DB";
}
.mdi-drag-horizontal:before {
    content: "\F1DC";
}
.mdi-drag-vertical:before {
    content: "\F1DD";
}
.mdi-drawing:before {
    content: "\F1DE";
}
.mdi-drawing-box:before {
    content: "\F1DF";
}
.mdi-dribbble:before {
    content: "\F1E0";
}
.mdi-dribbble-box:before {
    content: "\F1E1";
}
.mdi-drone:before {
    content: "\F1E2";
}
.mdi-dropbox:before {
    content: "\F1E3";
}
.mdi-drupal:before {
    content: "\F1E4";
}
.mdi-duck:before {
    content: "\F1E5";
}
.mdi-dumbbell:before {
    content: "\F1E6";
}
.mdi-ear-hearing:before {
    content: "\F7C4";
}
.mdi-ear-hearing-off:before {
    content: "\FA44";
}
.mdi-earth:before {
    content: "\F1E7";
}
.mdi-earth-box:before {
    content: "\F6CC";
}
.mdi-earth-box-off:before {
    content: "\F6CD";
}
.mdi-earth-off:before {
    content: "\F1E8";
}
.mdi-edge:before {
    content: "\F1E9";
}
.mdi-egg:before {
    content: "\FAAE";
}
.mdi-egg-easter:before {
    content: "\FAAF";
}
.mdi-eight-track:before {
    content: "\F9E9";
}
.mdi-eject:before {
    content: "\F1EA";
}
.mdi-elephant:before {
    content: "\F7C5";
}
.mdi-elevation-decline:before {
    content: "\F1EB";
}
.mdi-elevation-rise:before {
    content: "\F1EC";
}
.mdi-elevator:before {
    content: "\F1ED";
}
.mdi-email:before {
    content: "\F1EE";
}
.mdi-email-alert:before {
    content: "\F6CE";
}
.mdi-email-check:before {
    content: "\FAB0";
}
.mdi-email-check-outline:before {
    content: "\FAB1";
}
.mdi-email-lock:before {
    content: "\F1F1";
}
.mdi-email-open:before {
    content: "\F1EF";
}
.mdi-email-open-outline:before {
    content: "\F5EF";
}
.mdi-email-outline:before {
    content: "\F1F0";
}
.mdi-email-plus:before {
    content: "\F9EA";
}
.mdi-email-plus-outline:before {
    content: "\F9EB";
}
.mdi-email-search:before {
    content: "\F960";
}
.mdi-email-search-outline:before {
    content: "\F961";
}
.mdi-email-variant:before {
    content: "\F5F0";
}
.mdi-ember:before {
    content: "\FB15";
}
.mdi-emby:before {
    content: "\F6B3";
}
.mdi-emoticon:before {
    content: "\F1F2";
}
.mdi-emoticon-cool:before {
    content: "\F1F3";
}
.mdi-emoticon-dead:before {
    content: "\F69A";
}
.mdi-emoticon-devil:before {
    content: "\F1F4";
}
.mdi-emoticon-excited:before {
    content: "\F69B";
}
.mdi-emoticon-happy:before {
    content: "\F1F5";
}
.mdi-emoticon-neutral:before {
    content: "\F1F6";
}
.mdi-emoticon-poop:before {
    content: "\F1F7";
}
.mdi-emoticon-sad:before {
    content: "\F1F8";
}
.mdi-emoticon-tongue:before {
    content: "\F1F9";
}
.mdi-engine:before {
    content: "\F1FA";
}
.mdi-engine-off:before {
    content: "\FA45";
}
.mdi-engine-off-outline:before {
    content: "\FA46";
}
.mdi-engine-outline:before {
    content: "\F1FB";
}
.mdi-equal:before {
    content: "\F1FC";
}
.mdi-equal-box:before {
    content: "\F1FD";
}
.mdi-eraser:before {
    content: "\F1FE";
}
.mdi-eraser-variant:before {
    content: "\F642";
}
.mdi-escalator:before {
    content: "\F1FF";
}
.mdi-et:before {
    content: "\FAB2";
}
.mdi-ethereum:before {
    content: "\F869";
}
.mdi-ethernet:before {
    content: "\F200";
}
.mdi-ethernet-cable:before {
    content: "\F201";
}
.mdi-ethernet-cable-off:before {
    content: "\F202";
}
.mdi-etsy:before {
    content: "\F203";
}
.mdi-ev-station:before {
    content: "\F5F1";
}
.mdi-eventbrite:before {
    content: "\F7C6";
}
.mdi-evernote:before {
    content: "\F204";
}
.mdi-exclamation:before {
    content: "\F205";
}
.mdi-exit-run:before {
    content: "\FA47";
}
.mdi-exit-to-app:before {
    content: "\F206";
}
.mdi-expand-all:before {
    content: "\FAB3";
}
.mdi-expand-all-outline:before {
    content: "\FAB4";
}
.mdi-exponent:before {
    content: "\F962";
}
.mdi-exponent-box:before {
    content: "\F963";
}
.mdi-export:before {
    content: "\F207";
}
.mdi-eye:before {
    content: "\F208";
}
.mdi-eye-off:before {
    content: "\F209";
}
.mdi-eye-off-outline:before {
    content: "\F6D0";
}
.mdi-eye-outline:before {
    content: "\F6CF";
}
.mdi-eye-plus:before {
    content: "\F86A";
}
.mdi-eye-plus-outline:before {
    content: "\F86B";
}
.mdi-eye-settings:before {
    content: "\F86C";
}
.mdi-eye-settings-outline:before {
    content: "\F86D";
}
.mdi-eyedropper:before {
    content: "\F20A";
}
.mdi-eyedropper-variant:before {
    content: "\F20B";
}
.mdi-face:before {
    content: "\F643";
}
.mdi-face-profile:before {
    content: "\F644";
}
.mdi-facebook:before {
    content: "\F20C";
}
.mdi-facebook-box:before {
    content: "\F20D";
}
.mdi-facebook-messenger:before {
    content: "\F20E";
}
.mdi-facebook-workplace:before {
    content: "\FB16";
}
.mdi-factory:before {
    content: "\F20F";
}
.mdi-fan:before {
    content: "\F210";
}
.mdi-fan-off:before {
    content: "\F81C";
}
.mdi-fast-forward:before {
    content: "\F211";
}
.mdi-fast-forward-outline:before {
    content: "\F6D1";
}
.mdi-fax:before {
    content: "\F212";
}
.mdi-feather:before {
    content: "\F6D2";
}
.mdi-feature-search:before {
    content: "\FA48";
}
.mdi-feature-search-outline:before {
    content: "\FA49";
}
.mdi-fedora:before {
    content: "\F8DA";
}
.mdi-ferry:before {
    content: "\F213";
}
.mdi-file:before {
    content: "\F214";
}
.mdi-file-account:before {
    content: "\F73A";
}
.mdi-file-alert:before {
    content: "\FA4A";
}
.mdi-file-alert-outline:before {
    content: "\FA4B";
}
.mdi-file-cabinet:before {
    content: "\FAB5";
}
.mdi-file-chart:before {
    content: "\F215";
}
.mdi-file-check:before {
    content: "\F216";
}
.mdi-file-cloud:before {
    content: "\F217";
}
.mdi-file-compare:before {
    content: "\F8A9";
}
.mdi-file-delimited:before {
    content: "\F218";
}
.mdi-file-document:before {
    content: "\F219";
}
.mdi-file-document-box:before {
    content: "\F21A";
}
.mdi-file-document-box-multiple:before {
    content: "\FAB6";
}
.mdi-file-document-box-multiple-outline:before {
    content: "\FAB7";
}
.mdi-file-document-box-outline:before {
    content: "\F9EC";
}
.mdi-file-document-outline:before {
    content: "\F9ED";
}
.mdi-file-download:before {
    content: "\F964";
}
.mdi-file-download-outline:before {
    content: "\F965";
}
.mdi-file-excel:before {
    content: "\F21B";
}
.mdi-file-excel-box:before {
    content: "\F21C";
}
.mdi-file-export:before {
    content: "\F21D";
}
.mdi-file-find:before {
    content: "\F21E";
}
.mdi-file-hidden:before {
    content: "\F613";
}
.mdi-file-image:before {
    content: "\F21F";
}
.mdi-file-import:before {
    content: "\F220";
}
.mdi-file-lock:before {
    content: "\F221";
}
.mdi-file-move:before {
    content: "\FAB8";
}
.mdi-file-multiple:before {
    content: "\F222";
}
.mdi-file-music:before {
    content: "\F223";
}
.mdi-file-outline:before {
    content: "\F224";
}
.mdi-file-pdf:before {
    content: "\F225";
}
.mdi-file-pdf-box:before {
    content: "\F226";
}
.mdi-file-percent:before {
    content: "\F81D";
}
.mdi-file-plus:before {
    content: "\F751";
}
.mdi-file-powerpoint:before {
    content: "\F227";
}
.mdi-file-powerpoint-box:before {
    content: "\F228";
}
.mdi-file-presentation-box:before {
    content: "\F229";
}
.mdi-file-question:before {
    content: "\F86E";
}
.mdi-file-replace:before {
    content: "\FB17";
}
.mdi-file-replace-outline:before {
    content: "\FB18";
}
.mdi-file-restore:before {
    content: "\F670";
}
.mdi-file-send:before {
    content: "\F22A";
}
.mdi-file-tree:before {
    content: "\F645";
}
.mdi-file-undo:before {
    content: "\F8DB";
}
.mdi-file-upload:before {
    content: "\FA4C";
}
.mdi-file-upload-outline:before {
    content: "\FA4D";
}
.mdi-file-video:before {
    content: "\F22B";
}
.mdi-file-word:before {
    content: "\F22C";
}
.mdi-file-word-box:before {
    content: "\F22D";
}
.mdi-file-xml:before {
    content: "\F22E";
}
.mdi-film:before {
    content: "\F22F";
}
.mdi-filmstrip:before {
    content: "\F230";
}
.mdi-filmstrip-off:before {
    content: "\F231";
}
.mdi-filter:before {
    content: "\F232";
}
.mdi-filter-outline:before {
    content: "\F233";
}
.mdi-filter-remove:before {
    content: "\F234";
}
.mdi-filter-remove-outline:before {
    content: "\F235";
}
.mdi-filter-variant:before {
    content: "\F236";
}
.mdi-finance:before {
    content: "\F81E";
}
.mdi-find-replace:before {
    content: "\F6D3";
}
.mdi-fingerprint:before {
    content: "\F237";
}
.mdi-fire:before {
    content: "\F238";
}
.mdi-fire-truck:before {
    content: "\F8AA";
}
.mdi-firebase:before {
    content: "\F966";
}
.mdi-firefox:before {
    content: "\F239";
}
.mdi-fish:before {
    content: "\F23A";
}
.mdi-flag:before {
    content: "\F23B";
}
.mdi-flag-checkered:before {
    content: "\F23C";
}
.mdi-flag-outline:before {
    content: "\F23D";
}
.mdi-flag-triangle:before {
    content: "\F23F";
}
.mdi-flag-variant:before {
    content: "\F240";
}
.mdi-flag-variant-outline:before {
    content: "\F23E";
}
.mdi-flash:before {
    content: "\F241";
}
.mdi-flash-auto:before {
    content: "\F242";
}
.mdi-flash-circle:before {
    content: "\F81F";
}
.mdi-flash-off:before {
    content: "\F243";
}
.mdi-flash-outline:before {
    content: "\F6D4";
}
.mdi-flash-red-eye:before {
    content: "\F67A";
}
.mdi-flashlight:before {
    content: "\F244";
}
.mdi-flashlight-off:before {
    content: "\F245";
}
.mdi-flask:before {
    content: "\F093";
}
.mdi-flask-empty:before {
    content: "\F094";
}
.mdi-flask-empty-outline:before {
    content: "\F095";
}
.mdi-flask-outline:before {
    content: "\F096";
}
.mdi-flattr:before {
    content: "\F246";
}
.mdi-flip-to-back:before {
    content: "\F247";
}
.mdi-flip-to-front:before {
    content: "\F248";
}
.mdi-floor-lamp:before {
    content: "\F8DC";
}
.mdi-floor-plan:before {
    content: "\F820";
}
.mdi-floppy:before {
    content: "\F249";
}
.mdi-floppy-variant:before {
    content: "\F9EE";
}
.mdi-flower:before {
    content: "\F24A";
}
.mdi-flower-outline:before {
    content: "\F9EF";
}
.mdi-flower-tulip:before {
    content: "\F9F0";
}
.mdi-flower-tulip-outline:before {
    content: "\F9F1";
}
.mdi-folder:before {
    content: "\F24B";
}
.mdi-folder-account:before {
    content: "\F24C";
}
.mdi-folder-clock:before {
    content: "\FAB9";
}
.mdi-folder-clock-outline:before {
    content: "\FABA";
}
.mdi-folder-download:before {
    content: "\F24D";
}
.mdi-folder-edit:before {
    content: "\F8DD";
}
.mdi-folder-google-drive:before {
    content: "\F24E";
}
.mdi-folder-image:before {
    content: "\F24F";
}
.mdi-folder-key:before {
    content: "\F8AB";
}
.mdi-folder-key-network:before {
    content: "\F8AC";
}
.mdi-folder-lock:before {
    content: "\F250";
}
.mdi-folder-lock-open:before {
    content: "\F251";
}
.mdi-folder-move:before {
    content: "\F252";
}
.mdi-folder-multiple:before {
    content: "\F253";
}
.mdi-folder-multiple-image:before {
    content: "\F254";
}
.mdi-folder-multiple-outline:before {
    content: "\F255";
}
.mdi-folder-network:before {
    content: "\F86F";
}
.mdi-folder-open:before {
    content: "\F76F";
}
.mdi-folder-outline:before {
    content: "\F256";
}
.mdi-folder-plus:before {
    content: "\F257";
}
.mdi-folder-remove:before {
    content: "\F258";
}
.mdi-folder-search:before {
    content: "\F967";
}
.mdi-folder-search-outline:before {
    content: "\F968";
}
.mdi-folder-star:before {
    content: "\F69C";
}
.mdi-folder-upload:before {
    content: "\F259";
}
.mdi-font-awesome:before {
    content: "\F03A";
}
.mdi-food:before {
    content: "\F25A";
}
.mdi-food-apple:before {
    content: "\F25B";
}
.mdi-food-croissant:before {
    content: "\F7C7";
}
.mdi-food-fork-drink:before {
    content: "\F5F2";
}
.mdi-food-off:before {
    content: "\F5F3";
}
.mdi-food-variant:before {
    content: "\F25C";
}
.mdi-football:before {
    content: "\F25D";
}
.mdi-football-australian:before {
    content: "\F25E";
}
.mdi-football-helmet:before {
    content: "\F25F";
}
.mdi-forklift:before {
    content: "\F7C8";
}
.mdi-format-align-bottom:before {
    content: "\F752";
}
.mdi-format-align-center:before {
    content: "\F260";
}
.mdi-format-align-justify:before {
    content: "\F261";
}
.mdi-format-align-left:before {
    content: "\F262";
}
.mdi-format-align-middle:before {
    content: "\F753";
}
.mdi-format-align-right:before {
    content: "\F263";
}
.mdi-format-align-top:before {
    content: "\F754";
}
.mdi-format-annotation-minus:before {
    content: "\FABB";
}
.mdi-format-annotation-plus:before {
    content: "\F646";
}
.mdi-format-bold:before {
    content: "\F264";
}
.mdi-format-clear:before {
    content: "\F265";
}
.mdi-format-color-fill:before {
    content: "\F266";
}
.mdi-format-color-text:before {
    content: "\F69D";
}
.mdi-format-columns:before {
    content: "\F8DE";
}
.mdi-format-float-center:before {
    content: "\F267";
}
.mdi-format-float-left:before {
    content: "\F268";
}
.mdi-format-float-none:before {
    content: "\F269";
}
.mdi-format-float-right:before {
    content: "\F26A";
}
.mdi-format-font:before {
    content: "\F6D5";
}
.mdi-format-font-size-decrease:before {
    content: "\F9F2";
}
.mdi-format-font-size-increase:before {
    content: "\F9F3";
}
.mdi-format-header-1:before {
    content: "\F26B";
}
.mdi-format-header-2:before {
    content: "\F26C";
}
.mdi-format-header-3:before {
    content: "\F26D";
}
.mdi-format-header-4:before {
    content: "\F26E";
}
.mdi-format-header-5:before {
    content: "\F26F";
}
.mdi-format-header-6:before {
    content: "\F270";
}
.mdi-format-header-decrease:before {
    content: "\F271";
}
.mdi-format-header-equal:before {
    content: "\F272";
}
.mdi-format-header-increase:before {
    content: "\F273";
}
.mdi-format-header-pound:before {
    content: "\F274";
}
.mdi-format-horizontal-align-center:before {
    content: "\F61E";
}
.mdi-format-horizontal-align-left:before {
    content: "\F61F";
}
.mdi-format-horizontal-align-right:before {
    content: "\F620";
}
.mdi-format-indent-decrease:before {
    content: "\F275";
}
.mdi-format-indent-increase:before {
    content: "\F276";
}
.mdi-format-italic:before {
    content: "\F277";
}
.mdi-format-letter-case:before {
    content: "\FB19";
}
.mdi-format-letter-case-lower:before {
    content: "\FB1A";
}
.mdi-format-letter-case-upper:before {
    content: "\FB1B";
}
.mdi-format-line-spacing:before {
    content: "\F278";
}
.mdi-format-line-style:before {
    content: "\F5C8";
}
.mdi-format-line-weight:before {
    content: "\F5C9";
}
.mdi-format-list-bulleted:before {
    content: "\F279";
}
.mdi-format-list-bulleted-type:before {
    content: "\F27A";
}
.mdi-format-list-checkbox:before {
    content: "\F969";
}
.mdi-format-list-checks:before {
    content: "\F755";
}
.mdi-format-list-numbers:before {
    content: "\F27B";
}
.mdi-format-page-break:before {
    content: "\F6D6";
}
.mdi-format-paint:before {
    content: "\F27C";
}
.mdi-format-paragraph:before {
    content: "\F27D";
}
.mdi-format-pilcrow:before {
    content: "\F6D7";
}
.mdi-format-quote-close:before {
    content: "\F27E";
}
.mdi-format-quote-open:before {
    content: "\F756";
}
.mdi-format-rotate-90:before {
    content: "\F6A9";
}
.mdi-format-section:before {
    content: "\F69E";
}
.mdi-format-size:before {
    content: "\F27F";
}
.mdi-format-strikethrough:before {
    content: "\F280";
}
.mdi-format-strikethrough-variant:before {
    content: "\F281";
}
.mdi-format-subscript:before {
    content: "\F282";
}
.mdi-format-superscript:before {
    content: "\F283";
}
.mdi-format-text:before {
    content: "\F284";
}
.mdi-format-textdirection-l-to-r:before {
    content: "\F285";
}
.mdi-format-textdirection-r-to-l:before {
    content: "\F286";
}
.mdi-format-title:before {
    content: "\F5F4";
}
.mdi-format-underline:before {
    content: "\F287";
}
.mdi-format-vertical-align-bottom:before {
    content: "\F621";
}
.mdi-format-vertical-align-center:before {
    content: "\F622";
}
.mdi-format-vertical-align-top:before {
    content: "\F623";
}
.mdi-format-wrap-inline:before {
    content: "\F288";
}
.mdi-format-wrap-square:before {
    content: "\F289";
}
.mdi-format-wrap-tight:before {
    content: "\F28A";
}
.mdi-format-wrap-top-bottom:before {
    content: "\F28B";
}
.mdi-forum:before {
    content: "\F28C";
}
.mdi-forum-outline:before {
    content: "\F821";
}
.mdi-forward:before {
    content: "\F28D";
}
.mdi-fountain:before {
    content: "\F96A";
}
.mdi-foursquare:before {
    content: "\F28E";
}
.mdi-freebsd:before {
    content: "\F8DF";
}
.mdi-fridge:before {
    content: "\F28F";
}
.mdi-fridge-filled:before {
    content: "\F290";
}
.mdi-fridge-filled-bottom:before {
    content: "\F291";
}
.mdi-fridge-filled-top:before {
    content: "\F292";
}
.mdi-fuel:before {
    content: "\F7C9";
}
.mdi-fullscreen:before {
    content: "\F293";
}
.mdi-fullscreen-exit:before {
    content: "\F294";
}
.mdi-function:before {
    content: "\F295";
}
.mdi-function-variant:before {
    content: "\F870";
}
.mdi-gamepad:before {
    content: "\F296";
}
.mdi-gamepad-variant:before {
    content: "\F297";
}
.mdi-garage:before {
    content: "\F6D8";
}
.mdi-garage-alert:before {
    content: "\F871";
}
.mdi-garage-open:before {
    content: "\F6D9";
}
.mdi-gas-cylinder:before {
    content: "\F647";
}
.mdi-gas-station:before {
    content: "\F298";
}
.mdi-gate:before {
    content: "\F299";
}
.mdi-gate-and:before {
    content: "\F8E0";
}
.mdi-gate-nand:before {
    content: "\F8E1";
}
.mdi-gate-nor:before {
    content: "\F8E2";
}
.mdi-gate-not:before {
    content: "\F8E3";
}
.mdi-gate-or:before {
    content: "\F8E4";
}
.mdi-gate-xnor:before {
    content: "\F8E5";
}
.mdi-gate-xor:before {
    content: "\F8E6";
}
.mdi-gauge:before {
    content: "\F29A";
}
.mdi-gauge-empty:before {
    content: "\F872";
}
.mdi-gauge-full:before {
    content: "\F873";
}
.mdi-gauge-low:before {
    content: "\F874";
}
.mdi-gavel:before {
    content: "\F29B";
}
.mdi-gender-female:before {
    content: "\F29C";
}
.mdi-gender-male:before {
    content: "\F29D";
}
.mdi-gender-male-female:before {
    content: "\F29E";
}
.mdi-gender-transgender:before {
    content: "\F29F";
}
.mdi-gentoo:before {
    content: "\F8E7";
}
.mdi-gesture:before {
    content: "\F7CA";
}
.mdi-gesture-double-tap:before {
    content: "\F73B";
}
.mdi-gesture-pinch:before {
    content: "\FABC";
}
.mdi-gesture-spread:before {
    content: "\FABD";
}
.mdi-gesture-swipe-down:before {
    content: "\F73C";
}
.mdi-gesture-swipe-horizontal:before {
    content: "\FABE";
}
.mdi-gesture-swipe-left:before {
    content: "\F73D";
}
.mdi-gesture-swipe-right:before {
    content: "\F73E";
}
.mdi-gesture-swipe-up:before {
    content: "\F73F";
}
.mdi-gesture-swipe-vertical:before {
    content: "\FABF";
}
.mdi-gesture-tap:before {
    content: "\F740";
}
.mdi-gesture-two-double-tap:before {
    content: "\F741";
}
.mdi-gesture-two-tap:before {
    content: "\F742";
}
.mdi-ghost:before {
    content: "\F2A0";
}
.mdi-ghost-off:before {
    content: "\F9F4";
}
.mdi-gift:before {
    content: "\F2A1";
}
.mdi-git:before {
    content: "\F2A2";
}
.mdi-github-box:before {
    content: "\F2A3";
}
.mdi-github-circle:before {
    content: "\F2A4";
}
.mdi-github-face:before {
    content: "\F6DA";
}
.mdi-glass-cocktail:before {
    content: "\F356";
}
.mdi-glass-flute:before {
    content: "\F2A5";
}
.mdi-glass-mug:before {
    content: "\F2A6";
}
.mdi-glass-stange:before {
    content: "\F2A7";
}
.mdi-glass-tulip:before {
    content: "\F2A8";
}
.mdi-glass-wine:before {
    content: "\F875";
}
.mdi-glassdoor:before {
    content: "\F2A9";
}
.mdi-glasses:before {
    content: "\F2AA";
}
.mdi-globe-model:before {
    content: "\F8E8";
}
.mdi-gmail:before {
    content: "\F2AB";
}
.mdi-gnome:before {
    content: "\F2AC";
}
.mdi-golf:before {
    content: "\F822";
}
.mdi-gondola:before {
    content: "\F685";
}
.mdi-google:before {
    content: "\F2AD";
}
.mdi-google-allo:before {
    content: "\F801";
}
.mdi-google-analytics:before {
    content: "\F7CB";
}
.mdi-google-assistant:before {
    content: "\F7CC";
}
.mdi-google-cardboard:before {
    content: "\F2AE";
}
.mdi-google-chrome:before {
    content: "\F2AF";
}
.mdi-google-circles:before {
    content: "\F2B0";
}
.mdi-google-circles-communities:before {
    content: "\F2B1";
}
.mdi-google-circles-extended:before {
    content: "\F2B2";
}
.mdi-google-circles-group:before {
    content: "\F2B3";
}
.mdi-google-controller:before {
    content: "\F2B4";
}
.mdi-google-controller-off:before {
    content: "\F2B5";
}
.mdi-google-drive:before {
    content: "\F2B6";
}
.mdi-google-earth:before {
    content: "\F2B7";
}
.mdi-google-fit:before {
    content: "\F96B";
}
.mdi-google-glass:before {
    content: "\F2B8";
}
.mdi-google-hangouts:before {
    content: "\F2C9";
}
.mdi-google-home:before {
    content: "\F823";
}
.mdi-google-keep:before {
    content: "\F6DB";
}
.mdi-google-lens:before {
    content: "\F9F5";
}
.mdi-google-maps:before {
    content: "\F5F5";
}
.mdi-google-nearby:before {
    content: "\F2B9";
}
.mdi-google-pages:before {
    content: "\F2BA";
}
.mdi-google-photos:before {
    content: "\F6DC";
}
.mdi-google-physical-web:before {
    content: "\F2BB";
}
.mdi-google-play:before {
    content: "\F2BC";
}
.mdi-google-plus:before {
    content: "\F2BD";
}
.mdi-google-plus-box:before {
    content: "\F2BE";
}
.mdi-google-spreadsheet:before {
    content: "\F9F6";
}
.mdi-google-translate:before {
    content: "\F2BF";
}
.mdi-google-wallet:before {
    content: "\F2C0";
}
.mdi-gpu:before {
    content: "\F8AD";
}
.mdi-gradient:before {
    content: "\F69F";
}
.mdi-graphql:before {
    content: "\F876";
}
.mdi-grease-pencil:before {
    content: "\F648";
}
.mdi-greater-than:before {
    content: "\F96C";
}
.mdi-greater-than-or-equal:before {
    content: "\F96D";
}
.mdi-grid:before {
    content: "\F2C1";
}
.mdi-grid-large:before {
    content: "\F757";
}
.mdi-grid-off:before {
    content: "\F2C2";
}
.mdi-group:before {
    content: "\F2C3";
}
.mdi-guitar-acoustic:before {
    content: "\F770";
}
.mdi-guitar-electric:before {
    content: "\F2C4";
}
.mdi-guitar-pick:before {
    content: "\F2C5";
}
.mdi-guitar-pick-outline:before {
    content: "\F2C6";
}
.mdi-guy-fawkes-mask:before {
    content: "\F824";
}
.mdi-hackernews:before {
    content: "\F624";
}
.mdi-hail:before {
    content: "\FAC0";
}
.mdi-hamburger:before {
    content: "\F684";
}
.mdi-hammer:before {
    content: "\F8E9";
}
.mdi-hand:before {
    content: "\FA4E";
}
.mdi-hand-okay:before {
    content: "\FA4F";
}
.mdi-hand-peace:before {
    content: "\FA50";
}
.mdi-hand-peace-variant:before {
    content: "\FA51";
}
.mdi-hand-pointing-down:before {
    content: "\FA52";
}
.mdi-hand-pointing-left:before {
    content: "\FA53";
}
.mdi-hand-pointing-right:before {
    content: "\F2C7";
}
.mdi-hand-pointing-up:before {
    content: "\FA54";
}
.mdi-hanger:before {
    content: "\F2C8";
}
.mdi-hard-hat:before {
    content: "\F96E";
}
.mdi-harddisk:before {
    content: "\F2CA";
}
.mdi-headphones:before {
    content: "\F2CB";
}
.mdi-headphones-bluetooth:before {
    content: "\F96F";
}
.mdi-headphones-box:before {
    content: "\F2CC";
}
.mdi-headphones-off:before {
    content: "\F7CD";
}
.mdi-headphones-settings:before {
    content: "\F2CD";
}
.mdi-headset:before {
    content: "\F2CE";
}
.mdi-headset-dock:before {
    content: "\F2CF";
}
.mdi-headset-off:before {
    content: "\F2D0";
}
.mdi-heart:before {
    content: "\F2D1";
}
.mdi-heart-box:before {
    content: "\F2D2";
}
.mdi-heart-box-outline:before {
    content: "\F2D3";
}
.mdi-heart-broken:before {
    content: "\F2D4";
}
.mdi-heart-circle:before {
    content: "\F970";
}
.mdi-heart-circle-outline:before {
    content: "\F971";
}
.mdi-heart-half:before {
    content: "\F6DE";
}
.mdi-heart-half-full:before {
    content: "\F6DD";
}
.mdi-heart-half-outline:before {
    content: "\F6DF";
}
.mdi-heart-multiple:before {
    content: "\FA55";
}
.mdi-heart-multiple-outline:before {
    content: "\FA56";
}
.mdi-heart-off:before {
    content: "\F758";
}
.mdi-heart-outline:before {
    content: "\F2D5";
}
.mdi-heart-pulse:before {
    content: "\F5F6";
}
.mdi-helicopter:before {
    content: "\FAC1";
}
.mdi-help:before {
    content: "\F2D6";
}
.mdi-help-box:before {
    content: "\F78A";
}
.mdi-help-circle:before {
    content: "\F2D7";
}
.mdi-help-circle-outline:before {
    content: "\F625";
}
.mdi-help-network:before {
    content: "\F6F4";
}
.mdi-hexagon:before {
    content: "\F2D8";
}
.mdi-hexagon-multiple:before {
    content: "\F6E0";
}
.mdi-hexagon-outline:before {
    content: "\F2D9";
}
.mdi-hexagon-slice-1:before {
    content: "\FAC2";
}
.mdi-hexagon-slice-2:before {
    content: "\FAC3";
}
.mdi-hexagon-slice-3:before {
    content: "\FAC4";
}
.mdi-hexagon-slice-4:before {
    content: "\FAC5";
}
.mdi-hexagon-slice-5:before {
    content: "\FAC6";
}
.mdi-hexagon-slice-6:before {
    content: "\FAC7";
}
.mdi-hexagram:before {
    content: "\FAC8";
}
.mdi-hexagram-outline:before {
    content: "\FAC9";
}
.mdi-high-definition:before {
    content: "\F7CE";
}
.mdi-high-definition-box:before {
    content: "\F877";
}
.mdi-highway:before {
    content: "\F5F7";
}
.mdi-hinduism:before {
    content: "\F972";
}
.mdi-history:before {
    content: "\F2DA";
}
.mdi-hockey-puck:before {
    content: "\F878";
}
.mdi-hockey-sticks:before {
    content: "\F879";
}
.mdi-hololens:before {
    content: "\F2DB";
}
.mdi-home:before {
    content: "\F2DC";
}
.mdi-home-account:before {
    content: "\F825";
}
.mdi-home-alert:before {
    content: "\F87A";
}
.mdi-home-assistant:before {
    content: "\F7CF";
}
.mdi-home-automation:before {
    content: "\F7D0";
}
.mdi-home-circle:before {
    content: "\F7D1";
}
.mdi-home-currency-usd:before {
    content: "\F8AE";
}
.mdi-home-heart:before {
    content: "\F826";
}
.mdi-home-lock:before {
    content: "\F8EA";
}
.mdi-home-lock-open:before {
    content: "\F8EB";
}
.mdi-home-map-marker:before {
    content: "\F5F8";
}
.mdi-home-minus:before {
    content: "\F973";
}
.mdi-home-modern:before {
    content: "\F2DD";
}
.mdi-home-outline:before {
    content: "\F6A0";
}
.mdi-home-plus:before {
    content: "\F974";
}
.mdi-home-variant:before {
    content: "\F2DE";
}
.mdi-hook:before {
    content: "\F6E1";
}
.mdi-hook-off:before {
    content: "\F6E2";
}
.mdi-hops:before {
    content: "\F2DF";
}
.mdi-horseshoe:before {
    content: "\FA57";
}
.mdi-hospital:before {
    content: "\F2E0";
}
.mdi-hospital-building:before {
    content: "\F2E1";
}
.mdi-hospital-marker:before {
    content: "\F2E2";
}
.mdi-hot-tub:before {
    content: "\F827";
}
.mdi-hotel:before {
    content: "\F2E3";
}
.mdi-houzz:before {
    content: "\F2E4";
}
.mdi-houzz-box:before {
    content: "\F2E5";
}
.mdi-hulu:before {
    content: "\F828";
}
.mdi-human:before {
    content: "\F2E6";
}
.mdi-human-child:before {
    content: "\F2E7";
}
.mdi-human-female:before {
    content: "\F649";
}
.mdi-human-female-boy:before {
    content: "\FA58";
}
.mdi-human-female-female:before {
    content: "\FA59";
}
.mdi-human-female-girl:before {
    content: "\FA5A";
}
.mdi-human-greeting:before {
    content: "\F64A";
}
.mdi-human-handsdown:before {
    content: "\F64B";
}
.mdi-human-handsup:before {
    content: "\F64C";
}
.mdi-human-male:before {
    content: "\F64D";
}
.mdi-human-male-boy:before {
    content: "\FA5B";
}
.mdi-human-male-female:before {
    content: "\F2E8";
}
.mdi-human-male-girl:before {
    content: "\FA5C";
}
.mdi-human-male-male:before {
    content: "\FA5D";
}
.mdi-human-pregnant:before {
    content: "\F5CF";
}
.mdi-humble-bundle:before {
    content: "\F743";
}
.mdi-ice-cream:before {
    content: "\F829";
}
.mdi-image:before {
    content: "\F2E9";
}
.mdi-image-album:before {
    content: "\F2EA";
}
.mdi-image-area:before {
    content: "\F2EB";
}
.mdi-image-area-close:before {
    content: "\F2EC";
}
.mdi-image-broken:before {
    content: "\F2ED";
}
.mdi-image-broken-variant:before {
    content: "\F2EE";
}
.mdi-image-filter:before {
    content: "\F2EF";
}
.mdi-image-filter-black-white:before {
    content: "\F2F0";
}
.mdi-image-filter-center-focus:before {
    content: "\F2F1";
}
.mdi-image-filter-center-focus-weak:before {
    content: "\F2F2";
}
.mdi-image-filter-drama:before {
    content: "\F2F3";
}
.mdi-image-filter-frames:before {
    content: "\F2F4";
}
.mdi-image-filter-hdr:before {
    content: "\F2F5";
}
.mdi-image-filter-none:before {
    content: "\F2F6";
}
.mdi-image-filter-tilt-shift:before {
    content: "\F2F7";
}
.mdi-image-filter-vintage:before {
    content: "\F2F8";
}
.mdi-image-move:before {
    content: "\F9F7";
}
.mdi-image-multiple:before {
    content: "\F2F9";
}
.mdi-image-off:before {
    content: "\F82A";
}
.mdi-image-outline:before {
    content: "\F975";
}
.mdi-image-plus:before {
    content: "\F87B";
}
.mdi-image-search:before {
    content: "\F976";
}
.mdi-image-search-outline:before {
    content: "\F977";
}
.mdi-import:before {
    content: "\F2FA";
}
.mdi-inbox:before {
    content: "\F686";
}
.mdi-inbox-arrow-down:before {
    content: "\F2FB";
}
.mdi-inbox-arrow-up:before {
    content: "\F3D1";
}
.mdi-inbox-multiple:before {
    content: "\F8AF";
}
.mdi-incognito:before {
    content: "\F5F9";
}
.mdi-infinity:before {
    content: "\F6E3";
}
.mdi-information:before {
    content: "\F2FC";
}
.mdi-information-outline:before {
    content: "\F2FD";
}
.mdi-information-variant:before {
    content: "\F64E";
}
.mdi-instagram:before {
    content: "\F2FE";
}
.mdi-instapaper:before {
    content: "\F2FF";
}
.mdi-internet-explorer:before {
    content: "\F300";
}
.mdi-invert-colors:before {
    content: "\F301";
}
.mdi-ip:before {
    content: "\FA5E";
}
.mdi-ip-network:before {
    content: "\FA5F";
}
.mdi-islam:before {
    content: "\F978";
}
.mdi-itunes:before {
    content: "\F676";
}
.mdi-jeepney:before {
    content: "\F302";
}
.mdi-jira:before {
    content: "\F303";
}
.mdi-jquery:before {
    content: "\F87C";
}
.mdi-jsfiddle:before {
    content: "\F304";
}
.mdi-json:before {
    content: "\F626";
}
.mdi-judaism:before {
    content: "\F979";
}
.mdi-karate:before {
    content: "\F82B";
}
.mdi-keg:before {
    content: "\F305";
}
.mdi-kettle:before {
    content: "\F5FA";
}
.mdi-key:before {
    content: "\F306";
}
.mdi-key-change:before {
    content: "\F307";
}
.mdi-key-minus:before {
    content: "\F308";
}
.mdi-key-plus:before {
    content: "\F309";
}
.mdi-key-remove:before {
    content: "\F30A";
}
.mdi-key-variant:before {
    content: "\F30B";
}
.mdi-keyboard:before {
    content: "\F30C";
}
.mdi-keyboard-backspace:before {
    content: "\F30D";
}
.mdi-keyboard-caps:before {
    content: "\F30E";
}
.mdi-keyboard-close:before {
    content: "\F30F";
}
.mdi-keyboard-off:before {
    content: "\F310";
}
.mdi-keyboard-outline:before {
    content: "\F97A";
}
.mdi-keyboard-return:before {
    content: "\F311";
}
.mdi-keyboard-settings:before {
    content: "\F9F8";
}
.mdi-keyboard-settings-outline:before {
    content: "\F9F9";
}
.mdi-keyboard-tab:before {
    content: "\F312";
}
.mdi-keyboard-variant:before {
    content: "\F313";
}
.mdi-kickstarter:before {
    content: "\F744";
}
.mdi-knife:before {
    content: "\F9FA";
}
.mdi-knife-military:before {
    content: "\F9FB";
}
.mdi-kodi:before {
    content: "\F314";
}
.mdi-label:before {
    content: "\F315";
}
.mdi-label-off:before {
    content: "\FACA";
}
.mdi-label-off-outline:before {
    content: "\FACB";
}
.mdi-label-outline:before {
    content: "\F316";
}
.mdi-label-variant:before {
    content: "\FACC";
}
.mdi-label-variant-outline:before {
    content: "\FACD";
}
.mdi-ladybug:before {
    content: "\F82C";
}
.mdi-lambda:before {
    content: "\F627";
}
.mdi-lamp:before {
    content: "\F6B4";
}
.mdi-lan:before {
    content: "\F317";
}
.mdi-lan-connect:before {
    content: "\F318";
}
.mdi-lan-disconnect:before {
    content: "\F319";
}
.mdi-lan-pending:before {
    content: "\F31A";
}
.mdi-language-c:before {
    content: "\F671";
}
.mdi-language-cpp:before {
    content: "\F672";
}
.mdi-language-csharp:before {
    content: "\F31B";
}
.mdi-language-css3:before {
    content: "\F31C";
}
.mdi-language-go:before {
    content: "\F7D2";
}
.mdi-language-html5:before {
    content: "\F31D";
}
.mdi-language-java:before {
    content: "\FB1C";
}
.mdi-language-javascript:before {
    content: "\F31E";
}
.mdi-language-lua:before {
    content: "\F8B0";
}
.mdi-language-php:before {
    content: "\F31F";
}
.mdi-language-python:before {
    content: "\F320";
}
.mdi-language-python-text:before {
    content: "\F321";
}
.mdi-language-r:before {
    content: "\F7D3";
}
.mdi-language-ruby-on-rails:before {
    content: "\FACE";
}
.mdi-language-swift:before {
    content: "\F6E4";
}
.mdi-language-typescript:before {
    content: "\F6E5";
}
.mdi-laptop:before {
    content: "\F322";
}
.mdi-laptop-chromebook:before {
    content: "\F323";
}
.mdi-laptop-mac:before {
    content: "\F324";
}
.mdi-laptop-off:before {
    content: "\F6E6";
}
.mdi-laptop-windows:before {
    content: "\F325";
}
.mdi-laravel:before {
    content: "\FACF";
}
.mdi-lastfm:before {
    content: "\F326";
}
.mdi-lastpass:before {
    content: "\F446";
}
.mdi-launch:before {
    content: "\F327";
}
.mdi-lava-lamp:before {
    content: "\F7D4";
}
.mdi-layers:before {
    content: "\F328";
}
.mdi-layers-off:before {
    content: "\F329";
}
.mdi-layers-off-outline:before {
    content: "\F9FC";
}
.mdi-layers-outline:before {
    content: "\F9FD";
}
.mdi-lead-pencil:before {
    content: "\F64F";
}
.mdi-leaf:before {
    content: "\F32A";
}
.mdi-led-off:before {
    content: "\F32B";
}
.mdi-led-on:before {
    content: "\F32C";
}
.mdi-led-outline:before {
    content: "\F32D";
}
.mdi-led-strip:before {
    content: "\F7D5";
}
.mdi-led-variant-off:before {
    content: "\F32E";
}
.mdi-led-variant-on:before {
    content: "\F32F";
}
.mdi-led-variant-outline:before {
    content: "\F330";
}
.mdi-less-than:before {
    content: "\F97B";
}
.mdi-less-than-or-equal:before {
    content: "\F97C";
}
.mdi-library:before {
    content: "\F331";
}
.mdi-library-books:before {
    content: "\F332";
}
.mdi-library-music:before {
    content: "\F333";
}
.mdi-library-plus:before {
    content: "\F334";
}
.mdi-lifebuoy:before {
    content: "\F87D";
}
.mdi-light-switch:before {
    content: "\F97D";
}
.mdi-lightbulb:before {
    content: "\F335";
}
.mdi-lightbulb-on:before {
    content: "\F6E7";
}
.mdi-lightbulb-on-outline:before {
    content: "\F6E8";
}
.mdi-lightbulb-outline:before {
    content: "\F336";
}
.mdi-lighthouse:before {
    content: "\F9FE";
}
.mdi-lighthouse-on:before {
    content: "\F9FF";
}
.mdi-link:before {
    content: "\F337";
}
.mdi-link-off:before {
    content: "\F338";
}
.mdi-link-variant:before {
    content: "\F339";
}
.mdi-link-variant-off:before {
    content: "\F33A";
}
.mdi-linkedin:before {
    content: "\F33B";
}
.mdi-linkedin-box:before {
    content: "\F33C";
}
.mdi-linux:before {
    content: "\F33D";
}
.mdi-linux-mint:before {
    content: "\F8EC";
}
.mdi-litecoin:before {
    content: "\FA60";
}
.mdi-loading:before {
    content: "\F771";
}
.mdi-lock:before {
    content: "\F33E";
}
.mdi-lock-alert:before {
    content: "\F8ED";
}
.mdi-lock-clock:before {
    content: "\F97E";
}
.mdi-lock-open:before {
    content: "\F33F";
}
.mdi-lock-open-outline:before {
    content: "\F340";
}
.mdi-lock-outline:before {
    content: "\F341";
}
.mdi-lock-pattern:before {
    content: "\F6E9";
}
.mdi-lock-plus:before {
    content: "\F5FB";
}
.mdi-lock-question:before {
    content: "\F8EE";
}
.mdi-lock-reset:before {
    content: "\F772";
}
.mdi-lock-smart:before {
    content: "\F8B1";
}
.mdi-locker:before {
    content: "\F7D6";
}
.mdi-locker-multiple:before {
    content: "\F7D7";
}
.mdi-login:before {
    content: "\F342";
}
.mdi-login-variant:before {
    content: "\F5FC";
}
.mdi-logout:before {
    content: "\F343";
}
.mdi-logout-variant:before {
    content: "\F5FD";
}
.mdi-looks:before {
    content: "\F344";
}
.mdi-loop:before {
    content: "\F6EA";
}
.mdi-loupe:before {
    content: "\F345";
}
.mdi-lumx:before {
    content: "\F346";
}
.mdi-lyft:before {
    content: "\FB1D";
}
.mdi-magnet:before {
    content: "\F347";
}
.mdi-magnet-on:before {
    content: "\F348";
}
.mdi-magnify:before {
    content: "\F349";
}
.mdi-magnify-close:before {
    content: "\F97F";
}
.mdi-magnify-minus:before {
    content: "\F34A";
}
.mdi-magnify-minus-cursor:before {
    content: "\FA61";
}
.mdi-magnify-minus-outline:before {
    content: "\F6EB";
}
.mdi-magnify-plus:before {
    content: "\F34B";
}
.mdi-magnify-plus-cursor:before {
    content: "\FA62";
}
.mdi-magnify-plus-outline:before {
    content: "\F6EC";
}
.mdi-mail-ru:before {
    content: "\F34C";
}
.mdi-mailbox:before {
    content: "\F6ED";
}
.mdi-map:before {
    content: "\F34D";
}
.mdi-map-legend:before {
    content: "\FA00";
}
.mdi-map-marker:before {
    content: "\F34E";
}
.mdi-map-marker-circle:before {
    content: "\F34F";
}
.mdi-map-marker-distance:before {
    content: "\F8EF";
}
.mdi-map-marker-minus:before {
    content: "\F650";
}
.mdi-map-marker-multiple:before {
    content: "\F350";
}
.mdi-map-marker-off:before {
    content: "\F351";
}
.mdi-map-marker-outline:before {
    content: "\F7D8";
}
.mdi-map-marker-plus:before {
    content: "\F651";
}
.mdi-map-marker-radius:before {
    content: "\F352";
}
.mdi-map-minus:before {
    content: "\F980";
}
.mdi-map-outline:before {
    content: "\F981";
}
.mdi-map-plus:before {
    content: "\F982";
}
.mdi-map-search:before {
    content: "\F983";
}
.mdi-map-search-outline:before {
    content: "\F984";
}
.mdi-margin:before {
    content: "\F353";
}
.mdi-markdown:before {
    content: "\F354";
}
.mdi-marker:before {
    content: "\F652";
}
.mdi-marker-check:before {
    content: "\F355";
}
.mdi-mastodon:before {
    content: "\FAD0";
}
.mdi-mastodon-variant:before {
    content: "\FAD1";
}
.mdi-material-design:before {
    content: "\F985";
}
.mdi-material-ui:before {
    content: "\F357";
}
.mdi-math-compass:before {
    content: "\F358";
}
.mdi-matrix:before {
    content: "\F628";
}
.mdi-maxcdn:before {
    content: "\F359";
}
.mdi-medal:before {
    content: "\F986";
}
.mdi-medical-bag:before {
    content: "\F6EE";
}
.mdi-medium:before {
    content: "\F35A";
}
.mdi-meetup:before {
    content: "\FAD2";
}
.mdi-memory:before {
    content: "\F35B";
}
.mdi-menu:before {
    content: "\F35C";
}
.mdi-menu-down:before {
    content: "\F35D";
}
.mdi-menu-down-outline:before {
    content: "\F6B5";
}
.mdi-menu-left:before {
    content: "\F35E";
}
.mdi-menu-left-outline:before {
    content: "\FA01";
}
.mdi-menu-right:before {
    content: "\F35F";
}
.mdi-menu-right-outline:before {
    content: "\FA02";
}
.mdi-menu-swap:before {
    content: "\FA63";
}
.mdi-menu-swap-outline:before {
    content: "\FA64";
}
.mdi-menu-up:before {
    content: "\F360";
}
.mdi-menu-up-outline:before {
    content: "\F6B6";
}
.mdi-message:before {
    content: "\F361";
}
.mdi-message-alert:before {
    content: "\F362";
}
.mdi-message-alert-outline:before {
    content: "\FA03";
}
.mdi-message-bulleted:before {
    content: "\F6A1";
}
.mdi-message-bulleted-off:before {
    content: "\F6A2";
}
.mdi-message-draw:before {
    content: "\F363";
}
.mdi-message-image:before {
    content: "\F364";
}
.mdi-message-outline:before {
    content: "\F365";
}
.mdi-message-plus:before {
    content: "\F653";
}
.mdi-message-processing:before {
    content: "\F366";
}
.mdi-message-reply:before {
    content: "\F367";
}
.mdi-message-reply-text:before {
    content: "\F368";
}
.mdi-message-settings:before {
    content: "\F6EF";
}
.mdi-message-settings-variant:before {
    content: "\F6F0";
}
.mdi-message-text:before {
    content: "\F369";
}
.mdi-message-text-outline:before {
    content: "\F36A";
}
.mdi-message-video:before {
    content: "\F36B";
}
.mdi-meteor:before {
    content: "\F629";
}
.mdi-metronome:before {
    content: "\F7D9";
}
.mdi-metronome-tick:before {
    content: "\F7DA";
}
.mdi-micro-sd:before {
    content: "\F7DB";
}
.mdi-microphone:before {
    content: "\F36C";
}
.mdi-microphone-minus:before {
    content: "\F8B2";
}
.mdi-microphone-off:before {
    content: "\F36D";
}
.mdi-microphone-outline:before {
    content: "\F36E";
}
.mdi-microphone-plus:before {
    content: "\F8B3";
}
.mdi-microphone-settings:before {
    content: "\F36F";
}
.mdi-microphone-variant:before {
    content: "\F370";
}
.mdi-microphone-variant-off:before {
    content: "\F371";
}
.mdi-microscope:before {
    content: "\F654";
}
.mdi-microsoft:before {
    content: "\F372";
}
.mdi-microsoft-dynamics:before {
    content: "\F987";
}
.mdi-midi:before {
    content: "\F8F0";
}
.mdi-midi-port:before {
    content: "\F8F1";
}
.mdi-minecraft:before {
    content: "\F373";
}
.mdi-mini-sd:before {
    content: "\FA04";
}
.mdi-minidisc:before {
    content: "\FA05";
}
.mdi-minus:before {
    content: "\F374";
}
.mdi-minus-box:before {
    content: "\F375";
}
.mdi-minus-box-outline:before {
    content: "\F6F1";
}
.mdi-minus-circle:before {
    content: "\F376";
}
.mdi-minus-circle-outline:before {
    content: "\F377";
}
.mdi-minus-network:before {
    content: "\F378";
}
.mdi-mixcloud:before {
    content: "\F62A";
}
.mdi-mixed-reality:before {
    content: "\F87E";
}
.mdi-mixer:before {
    content: "\F7DC";
}
.mdi-monitor:before {
    content: "\F379";
}
.mdi-monitor-cellphone:before {
    content: "\F988";
}
.mdi-monitor-cellphone-star:before {
    content: "\F989";
}
.mdi-monitor-dashboard:before {
    content: "\FA06";
}
.mdi-monitor-multiple:before {
    content: "\F37A";
}
.mdi-more:before {
    content: "\F37B";
}
.mdi-motorbike:before {
    content: "\F37C";
}
.mdi-mouse:before {
    content: "\F37D";
}
.mdi-mouse-bluetooth:before {
    content: "\F98A";
}
.mdi-mouse-off:before {
    content: "\F37E";
}
.mdi-mouse-variant:before {
    content: "\F37F";
}
.mdi-mouse-variant-off:before {
    content: "\F380";
}
.mdi-move-resize:before {
    content: "\F655";
}
.mdi-move-resize-variant:before {
    content: "\F656";
}
.mdi-movie:before {
    content: "\F381";
}
.mdi-movie-roll:before {
    content: "\F7DD";
}
.mdi-muffin:before {
    content: "\F98B";
}
.mdi-multiplication:before {
    content: "\F382";
}
.mdi-multiplication-box:before {
    content: "\F383";
}
.mdi-mushroom:before {
    content: "\F7DE";
}
.mdi-mushroom-outline:before {
    content: "\F7DF";
}
.mdi-music:before {
    content: "\F759";
}
.mdi-music-box:before {
    content: "\F384";
}
.mdi-music-box-outline:before {
    content: "\F385";
}
.mdi-music-circle:before {
    content: "\F386";
}
.mdi-music-circle-outline:before {
    content: "\FAD3";
}
.mdi-music-note:before {
    content: "\F387";
}
.mdi-music-note-bluetooth:before {
    content: "\F5FE";
}
.mdi-music-note-bluetooth-off:before {
    content: "\F5FF";
}
.mdi-music-note-eighth:before {
    content: "\F388";
}
.mdi-music-note-half:before {
    content: "\F389";
}
.mdi-music-note-off:before {
    content: "\F38A";
}
.mdi-music-note-quarter:before {
    content: "\F38B";
}
.mdi-music-note-sixteenth:before {
    content: "\F38C";
}
.mdi-music-note-whole:before {
    content: "\F38D";
}
.mdi-music-off:before {
    content: "\F75A";
}
.mdi-nas:before {
    content: "\F8F2";
}
.mdi-nativescript:before {
    content: "\F87F";
}
.mdi-nature:before {
    content: "\F38E";
}
.mdi-nature-people:before {
    content: "\F38F";
}
.mdi-navigation:before {
    content: "\F390";
}
.mdi-near-me:before {
    content: "\F5CD";
}
.mdi-needle:before {
    content: "\F391";
}
.mdi-netflix:before {
    content: "\F745";
}
.mdi-network:before {
    content: "\F6F2";
}
.mdi-network-strength-1:before {
    content: "\F8F3";
}
.mdi-network-strength-1-alert:before {
    content: "\F8F4";
}
.mdi-network-strength-2:before {
    content: "\F8F5";
}
.mdi-network-strength-2-alert:before {
    content: "\F8F6";
}
.mdi-network-strength-3:before {
    content: "\F8F7";
}
.mdi-network-strength-3-alert:before {
    content: "\F8F8";
}
.mdi-network-strength-4:before {
    content: "\F8F9";
}
.mdi-network-strength-4-alert:before {
    content: "\F8FA";
}
.mdi-network-strength-off:before {
    content: "\F8FB";
}
.mdi-network-strength-off-outline:before {
    content: "\F8FC";
}
.mdi-network-strength-outline:before {
    content: "\F8FD";
}
.mdi-new-box:before {
    content: "\F394";
}
.mdi-newspaper:before {
    content: "\F395";
}
.mdi-nfc:before {
    content: "\F396";
}
.mdi-nfc-tap:before {
    content: "\F397";
}
.mdi-nfc-variant:before {
    content: "\F398";
}
.mdi-ninja:before {
    content: "\F773";
}
.mdi-nintendo-switch:before {
    content: "\F7E0";
}
.mdi-nodejs:before {
    content: "\F399";
}
.mdi-not-equal:before {
    content: "\F98C";
}
.mdi-not-equal-variant:before {
    content: "\F98D";
}
.mdi-note:before {
    content: "\F39A";
}
.mdi-note-multiple:before {
    content: "\F6B7";
}
.mdi-note-multiple-outline:before {
    content: "\F6B8";
}
.mdi-note-outline:before {
    content: "\F39B";
}
.mdi-note-plus:before {
    content: "\F39C";
}
.mdi-note-plus-outline:before {
    content: "\F39D";
}
.mdi-note-text:before {
    content: "\F39E";
}
.mdi-notebook:before {
    content: "\F82D";
}
.mdi-notification-clear-all:before {
    content: "\F39F";
}
.mdi-npm:before {
    content: "\F6F6";
}
.mdi-npm-variant:before {
    content: "\F98E";
}
.mdi-npm-variant-outline:before {
    content: "\F98F";
}
.mdi-nuke:before {
    content: "\F6A3";
}
.mdi-null:before {
    content: "\F7E1";
}
.mdi-numeric:before {
    content: "\F3A0";
}
.mdi-numeric-0:before {
    content: "\30";
}
.mdi-numeric-0-box:before {
    content: "\F3A1";
}
.mdi-numeric-0-box-multiple-outline:before {
    content: "\F3A2";
}
.mdi-numeric-0-box-outline:before {
    content: "\F3A3";
}
.mdi-numeric-1:before {
    content: "\31";
}
.mdi-numeric-1-box:before {
    content: "\F3A4";
}
.mdi-numeric-1-box-multiple-outline:before {
    content: "\F3A5";
}
.mdi-numeric-1-box-outline:before {
    content: "\F3A6";
}
.mdi-numeric-2:before {
    content: "\32";
}
.mdi-numeric-2-box:before {
    content: "\F3A7";
}
.mdi-numeric-2-box-multiple-outline:before {
    content: "\F3A8";
}
.mdi-numeric-2-box-outline:before {
    content: "\F3A9";
}
.mdi-numeric-3:before {
    content: "\33";
}
.mdi-numeric-3-box:before {
    content: "\F3AA";
}
.mdi-numeric-3-box-multiple-outline:before {
    content: "\F3AB";
}
.mdi-numeric-3-box-outline:before {
    content: "\F3AC";
}
.mdi-numeric-4:before {
    content: "\34";
}
.mdi-numeric-4-box:before {
    content: "\F3AD";
}
.mdi-numeric-4-box-multiple-outline:before {
    content: "\F3AE";
}
.mdi-numeric-4-box-outline:before {
    content: "\F3AF";
}
.mdi-numeric-5:before {
    content: "\35";
}
.mdi-numeric-5-box:before {
    content: "\F3B0";
}
.mdi-numeric-5-box-multiple-outline:before {
    content: "\F3B1";
}
.mdi-numeric-5-box-outline:before {
    content: "\F3B2";
}
.mdi-numeric-6:before {
    content: "\36";
}
.mdi-numeric-6-box:before {
    content: "\F3B3";
}
.mdi-numeric-6-box-multiple-outline:before {
    content: "\F3B4";
}
.mdi-numeric-6-box-outline:before {
    content: "\F3B5";
}
.mdi-numeric-7:before {
    content: "\37";
}
.mdi-numeric-7-box:before {
    content: "\F3B6";
}
.mdi-numeric-7-box-multiple-outline:before {
    content: "\F3B7";
}
.mdi-numeric-7-box-outline:before {
    content: "\F3B8";
}
.mdi-numeric-8:before {
    content: "\38";
}
.mdi-numeric-8-box:before {
    content: "\F3B9";
}
.mdi-numeric-8-box-multiple-outline:before {
    content: "\F3BA";
}
.mdi-numeric-8-box-outline:before {
    content: "\F3BB";
}
.mdi-numeric-9:before {
    content: "\39";
}
.mdi-numeric-9-box:before {
    content: "\F3BC";
}
.mdi-numeric-9-box-multiple-outline:before {
    content: "\F3BD";
}
.mdi-numeric-9-box-outline:before {
    content: "\F3BE";
}
.mdi-numeric-9-plus-box:before {
    content: "\F3BF";
}
.mdi-numeric-9-plus-box-multiple-outline:before {
    content: "\F3C0";
}
.mdi-numeric-9-plus-box-outline:before {
    content: "\F3C1";
}
.mdi-nut:before {
    content: "\F6F7";
}
.mdi-nutrition:before {
    content: "\F3C2";
}
.mdi-oar:before {
    content: "\F67B";
}
.mdi-octagon:before {
    content: "\F3C3";
}
.mdi-octagon-outline:before {
    content: "\F3C4";
}
.mdi-octagram:before {
    content: "\F6F8";
}
.mdi-octagram-outline:before {
    content: "\F774";
}
.mdi-odnoklassniki:before {
    content: "\F3C5";
}
.mdi-office:before {
    content: "\F3C6";
}
.mdi-office-building:before {
    content: "\F990";
}
.mdi-oil:before {
    content: "\F3C7";
}
.mdi-oil-temperature:before {
    content: "\F3C8";
}
.mdi-omega:before {
    content: "\F3C9";
}
.mdi-onedrive:before {
    content: "\F3CA";
}
.mdi-onenote:before {
    content: "\F746";
}
.mdi-onepassword:before {
    content: "\F880";
}
.mdi-opacity:before {
    content: "\F5CC";
}
.mdi-open-in-app:before {
    content: "\F3CB";
}
.mdi-open-in-new:before {
    content: "\F3CC";
}
.mdi-openid:before {
    content: "\F3CD";
}
.mdi-opera:before {
    content: "\F3CE";
}
.mdi-orbit:before {
    content: "\F018";
}
.mdi-origin:before {
    content: "\FB2B";
}
.mdi-ornament:before {
    content: "\F3CF";
}
.mdi-ornament-variant:before {
    content: "\F3D0";
}
.mdi-owl:before {
    content: "\F3D2";
}
.mdi-package:before {
    content: "\F3D3";
}
.mdi-package-down:before {
    content: "\F3D4";
}
.mdi-package-up:before {
    content: "\F3D5";
}
.mdi-package-variant:before {
    content: "\F3D6";
}
.mdi-package-variant-closed:before {
    content: "\F3D7";
}
.mdi-page-first:before {
    content: "\F600";
}
.mdi-page-last:before {
    content: "\F601";
}
.mdi-page-layout-body:before {
    content: "\F6F9";
}
.mdi-page-layout-footer:before {
    content: "\F6FA";
}
.mdi-page-layout-header:before {
    content: "\F6FB";
}
.mdi-page-layout-sidebar-left:before {
    content: "\F6FC";
}
.mdi-page-layout-sidebar-right:before {
    content: "\F6FD";
}
.mdi-palette:before {
    content: "\F3D8";
}
.mdi-palette-advanced:before {
    content: "\F3D9";
}
.mdi-palette-swatch:before {
    content: "\F8B4";
}
.mdi-panda:before {
    content: "\F3DA";
}
.mdi-pandora:before {
    content: "\F3DB";
}
.mdi-panorama:before {
    content: "\F3DC";
}
.mdi-panorama-fisheye:before {
    content: "\F3DD";
}
.mdi-panorama-horizontal:before {
    content: "\F3DE";
}
.mdi-panorama-vertical:before {
    content: "\F3DF";
}
.mdi-panorama-wide-angle:before {
    content: "\F3E0";
}
.mdi-paper-cut-vertical:before {
    content: "\F3E1";
}
.mdi-paperclip:before {
    content: "\F3E2";
}
.mdi-parking:before {
    content: "\F3E3";
}
.mdi-passport:before {
    content: "\F7E2";
}
.mdi-patreon:before {
    content: "\F881";
}
.mdi-pause:before {
    content: "\F3E4";
}
.mdi-pause-circle:before {
    content: "\F3E5";
}
.mdi-pause-circle-outline:before {
    content: "\F3E6";
}
.mdi-pause-octagon:before {
    content: "\F3E7";
}
.mdi-pause-octagon-outline:before {
    content: "\F3E8";
}
.mdi-paw:before {
    content: "\F3E9";
}
.mdi-paw-off:before {
    content: "\F657";
}
.mdi-paypal:before {
    content: "\F882";
}
.mdi-peace:before {
    content: "\F883";
}
.mdi-pen:before {
    content: "\F3EA";
}
.mdi-pencil:before {
    content: "\F3EB";
}
.mdi-pencil-box:before {
    content: "\F3EC";
}
.mdi-pencil-box-outline:before {
    content: "\F3ED";
}
.mdi-pencil-circle:before {
    content: "\F6FE";
}
.mdi-pencil-circle-outline:before {
    content: "\F775";
}
.mdi-pencil-lock:before {
    content: "\F3EE";
}
.mdi-pencil-off:before {
    content: "\F3EF";
}
.mdi-pentagon:before {
    content: "\F6FF";
}
.mdi-pentagon-outline:before {
    content: "\F700";
}
.mdi-percent:before {
    content: "\F3F0";
}
.mdi-periodic-table:before {
    content: "\F8B5";
}
.mdi-periodic-table-co2:before {
    content: "\F7E3";
}
.mdi-periscope:before {
    content: "\F747";
}
.mdi-pharmacy:before {
    content: "\F3F1";
}
.mdi-phone:before {
    content: "\F3F2";
}
.mdi-phone-bluetooth:before {
    content: "\F3F3";
}
.mdi-phone-classic:before {
    content: "\F602";
}
.mdi-phone-forward:before {
    content: "\F3F4";
}
.mdi-phone-hangup:before {
    content: "\F3F5";
}
.mdi-phone-in-talk:before {
    content: "\F3F6";
}
.mdi-phone-incoming:before {
    content: "\F3F7";
}
.mdi-phone-lock:before {
    content: "\F3F8";
}
.mdi-phone-log:before {
    content: "\F3F9";
}
.mdi-phone-minus:before {
    content: "\F658";
}
.mdi-phone-missed:before {
    content: "\F3FA";
}
.mdi-phone-outgoing:before {
    content: "\F3FB";
}
.mdi-phone-paused:before {
    content: "\F3FC";
}
.mdi-phone-plus:before {
    content: "\F659";
}
.mdi-phone-return:before {
    content: "\F82E";
}
.mdi-phone-rotate-landscape:before {
    content: "\F884";
}
.mdi-phone-rotate-portrait:before {
    content: "\F885";
}
.mdi-phone-settings:before {
    content: "\F3FD";
}
.mdi-phone-voip:before {
    content: "\F3FE";
}
.mdi-pi:before {
    content: "\F3FF";
}
.mdi-pi-box:before {
    content: "\F400";
}
.mdi-piano:before {
    content: "\F67C";
}
.mdi-pickaxe:before {
    content: "\F8B6";
}
.mdi-pier:before {
    content: "\F886";
}
.mdi-pier-crane:before {
    content: "\F887";
}
.mdi-pig:before {
    content: "\F401";
}
.mdi-pill:before {
    content: "\F402";
}
.mdi-pillar:before {
    content: "\F701";
}
.mdi-pin:before {
    content: "\F403";
}
.mdi-pin-off:before {
    content: "\F404";
}
.mdi-pin-off-outline:before {
    content: "\F92F";
}
.mdi-pin-outline:before {
    content: "\F930";
}
.mdi-pine-tree:before {
    content: "\F405";
}
.mdi-pine-tree-box:before {
    content: "\F406";
}
.mdi-pinterest:before {
    content: "\F407";
}
.mdi-pinterest-box:before {
    content: "\F408";
}
.mdi-pinwheel:before {
    content: "\FAD4";
}
.mdi-pinwheel-outline:before {
    content: "\FAD5";
}
.mdi-pipe:before {
    content: "\F7E4";
}
.mdi-pipe-disconnected:before {
    content: "\F7E5";
}
.mdi-pipe-leak:before {
    content: "\F888";
}
.mdi-pirate:before {
    content: "\FA07";
}
.mdi-pistol:before {
    content: "\F702";
}
.mdi-piston:before {
    content: "\F889";
}
.mdi-pizza:before {
    content: "\F409";
}
.mdi-plane-shield:before {
    content: "\F6BA";
}
.mdi-play:before {
    content: "\F40A";
}
.mdi-play-box-outline:before {
    content: "\F40B";
}
.mdi-play-circle:before {
    content: "\F40C";
}
.mdi-play-circle-outline:before {
    content: "\F40D";
}
.mdi-play-network:before {
    content: "\F88A";
}
.mdi-play-pause:before {
    content: "\F40E";
}
.mdi-play-protected-content:before {
    content: "\F40F";
}
.mdi-play-speed:before {
    content: "\F8FE";
}
.mdi-playlist-check:before {
    content: "\F5C7";
}
.mdi-playlist-edit:before {
    content: "\F8FF";
}
.mdi-playlist-minus:before {
    content: "\F410";
}
.mdi-playlist-play:before {
    content: "\F411";
}
.mdi-playlist-plus:before {
    content: "\F412";
}
.mdi-playlist-remove:before {
    content: "\F413";
}
.mdi-playstation:before {
    content: "\F414";
}
.mdi-plex:before {
    content: "\F6B9";
}
.mdi-plus:before {
    content: "\F415";
}
.mdi-plus-box:before {
    content: "\F416";
}
.mdi-plus-box-outline:before {
    content: "\F703";
}
.mdi-plus-circle:before {
    content: "\F417";
}
.mdi-plus-circle-multiple-outline:before {
    content: "\F418";
}
.mdi-plus-circle-outline:before {
    content: "\F419";
}
.mdi-plus-minus:before {
    content: "\F991";
}
.mdi-plus-minus-box:before {
    content: "\F992";
}
.mdi-plus-network:before {
    content: "\F41A";
}
.mdi-plus-one:before {
    content: "\F41B";
}
.mdi-plus-outline:before {
    content: "\F704";
}
.mdi-pocket:before {
    content: "\F41C";
}
.mdi-podcast:before {
    content: "\F993";
}
.mdi-pokeball:before {
    content: "\F41D";
}
.mdi-pokemon-go:before {
    content: "\FA08";
}
.mdi-poker-chip:before {
    content: "\F82F";
}
.mdi-polaroid:before {
    content: "\F41E";
}
.mdi-poll:before {
    content: "\F41F";
}
.mdi-poll-box:before {
    content: "\F420";
}
.mdi-polymer:before {
    content: "\F421";
}
.mdi-pool:before {
    content: "\F606";
}
.mdi-popcorn:before {
    content: "\F422";
}
.mdi-pot:before {
    content: "\F65A";
}
.mdi-pot-mix:before {
    content: "\F65B";
}
.mdi-pound:before {
    content: "\F423";
}
.mdi-pound-box:before {
    content: "\F424";
}
.mdi-power:before {
    content: "\F425";
}
.mdi-power-cycle:before {
    content: "\F900";
}
.mdi-power-off:before {
    content: "\F901";
}
.mdi-power-on:before {
    content: "\F902";
}
.mdi-power-plug:before {
    content: "\F6A4";
}
.mdi-power-plug-off:before {
    content: "\F6A5";
}
.mdi-power-settings:before {
    content: "\F426";
}
.mdi-power-sleep:before {
    content: "\F903";
}
.mdi-power-socket:before {
    content: "\F427";
}
.mdi-power-socket-au:before {
    content: "\F904";
}
.mdi-power-socket-eu:before {
    content: "\F7E6";
}
.mdi-power-socket-uk:before {
    content: "\F7E7";
}
.mdi-power-socket-us:before {
    content: "\F7E8";
}
.mdi-power-standby:before {
    content: "\F905";
}
.mdi-powershell:before {
    content: "\FA09";
}
.mdi-prescription:before {
    content: "\F705";
}
.mdi-presentation:before {
    content: "\F428";
}
.mdi-presentation-play:before {
    content: "\F429";
}
.mdi-printer:before {
    content: "\F42A";
}
.mdi-printer-3d:before {
    content: "\F42B";
}
.mdi-printer-alert:before {
    content: "\F42C";
}
.mdi-printer-settings:before {
    content: "\F706";
}
.mdi-printer-wireless:before {
    content: "\FA0A";
}
.mdi-priority-high:before {
    content: "\F603";
}
.mdi-priority-low:before {
    content: "\F604";
}
.mdi-professional-hexagon:before {
    content: "\F42D";
}
.mdi-progress-check:before {
    content: "\F994";
}
.mdi-progress-clock:before {
    content: "\F995";
}
.mdi-progress-download:before {
    content: "\F996";
}
.mdi-progress-upload:before {
    content: "\F997";
}
.mdi-projector:before {
    content: "\F42E";
}
.mdi-projector-screen:before {
    content: "\F42F";
}
.mdi-publish:before {
    content: "\F6A6";
}
.mdi-pulse:before {
    content: "\F430";
}
.mdi-puzzle:before {
    content: "\F431";
}
.mdi-puzzle-outline:before {
    content: "\FA65";
}
.mdi-qi:before {
    content: "\F998";
}
.mdi-qqchat:before {
    content: "\F605";
}
.mdi-qrcode:before {
    content: "\F432";
}
.mdi-qrcode-edit:before {
    content: "\F8B7";
}
.mdi-qrcode-scan:before {
    content: "\F433";
}
.mdi-quadcopter:before {
    content: "\F434";
}
.mdi-quality-high:before {
    content: "\F435";
}
.mdi-quality-low:before {
    content: "\FA0B";
}
.mdi-quality-medium:before {
    content: "\FA0C";
}
.mdi-quicktime:before {
    content: "\F436";
}
.mdi-rabbit:before {
    content: "\F906";
}
.mdi-radar:before {
    content: "\F437";
}
.mdi-radiator:before {
    content: "\F438";
}
.mdi-radiator-disabled:before {
    content: "\FAD6";
}
.mdi-radiator-off:before {
    content: "\FAD7";
}
.mdi-radio:before {
    content: "\F439";
}
.mdi-radio-handheld:before {
    content: "\F43A";
}
.mdi-radio-tower:before {
    content: "\F43B";
}
.mdi-radioactive:before {
    content: "\F43C";
}
.mdi-radiobox-blank:before {
    content: "\F43D";
}
.mdi-radiobox-marked:before {
    content: "\F43E";
}
.mdi-raspberrypi:before {
    content: "\F43F";
}
.mdi-ray-end:before {
    content: "\F440";
}
.mdi-ray-end-arrow:before {
    content: "\F441";
}
.mdi-ray-start:before {
    content: "\F442";
}
.mdi-ray-start-arrow:before {
    content: "\F443";
}
.mdi-ray-start-end:before {
    content: "\F444";
}
.mdi-ray-vertex:before {
    content: "\F445";
}
.mdi-react:before {
    content: "\F707";
}
.mdi-read:before {
    content: "\F447";
}
.mdi-receipt:before {
    content: "\F449";
}
.mdi-record:before {
    content: "\F44A";
}
.mdi-record-player:before {
    content: "\F999";
}
.mdi-record-rec:before {
    content: "\F44B";
}
.mdi-recycle:before {
    content: "\F44C";
}
.mdi-reddit:before {
    content: "\F44D";
}
.mdi-redo:before {
    content: "\F44E";
}
.mdi-redo-variant:before {
    content: "\F44F";
}
.mdi-reflect-horizontal:before {
    content: "\FA0D";
}
.mdi-reflect-vertical:before {
    content: "\FA0E";
}
.mdi-refresh:before {
    content: "\F450";
}
.mdi-regex:before {
    content: "\F451";
}
.mdi-registered-trademark:before {
    content: "\FA66";
}
.mdi-relative-scale:before {
    content: "\F452";
}
.mdi-reload:before {
    content: "\F453";
}
.mdi-reminder:before {
    content: "\F88B";
}
.mdi-remote:before {
    content: "\F454";
}
.mdi-remote-desktop:before {
    content: "\F8B8";
}
.mdi-rename-box:before {
    content: "\F455";
}
.mdi-reorder-horizontal:before {
    content: "\F687";
}
.mdi-reorder-vertical:before {
    content: "\F688";
}
.mdi-repeat:before {
    content: "\F456";
}
.mdi-repeat-off:before {
    content: "\F457";
}
.mdi-repeat-once:before {
    content: "\F458";
}
.mdi-replay:before {
    content: "\F459";
}
.mdi-reply:before {
    content: "\F45A";
}
.mdi-reply-all:before {
    content: "\F45B";
}
.mdi-reproduction:before {
    content: "\F45C";
}
.mdi-resistor:before {
    content: "\FB1F";
}
.mdi-resistor-nodes:before {
    content: "\FB20";
}
.mdi-resize:before {
    content: "\FA67";
}
.mdi-resize-bottom-right:before {
    content: "\F45D";
}
.mdi-responsive:before {
    content: "\F45E";
}
.mdi-restart:before {
    content: "\F708";
}
.mdi-restore:before {
    content: "\F99A";
}
.mdi-restore-clock:before {
    content: "\F6A7";
}
.mdi-rewind:before {
    content: "\F45F";
}
.mdi-rewind-outline:before {
    content: "\F709";
}
.mdi-rhombus:before {
    content: "\F70A";
}
.mdi-rhombus-medium:before {
    content: "\FA0F";
}
.mdi-rhombus-outline:before {
    content: "\F70B";
}
.mdi-rhombus-split:before {
    content: "\FA10";
}
.mdi-ribbon:before {
    content: "\F460";
}
.mdi-rice:before {
    content: "\F7E9";
}
.mdi-ring:before {
    content: "\F7EA";
}
.mdi-road:before {
    content: "\F461";
}
.mdi-road-variant:before {
    content: "\F462";
}
.mdi-robot:before {
    content: "\F6A8";
}
.mdi-robot-industrial:before {
    content: "\FB21";
}
.mdi-robot-vacuum:before {
    content: "\F70C";
}
.mdi-robot-vacuum-variant:before {
    content: "\F907";
}
.mdi-rocket:before {
    content: "\F463";
}
.mdi-room-service:before {
    content: "\F88C";
}
.mdi-rotate-3d:before {
    content: "\F464";
}
.mdi-rotate-left:before {
    content: "\F465";
}
.mdi-rotate-left-variant:before {
    content: "\F466";
}
.mdi-rotate-right:before {
    content: "\F467";
}
.mdi-rotate-right-variant:before {
    content: "\F468";
}
.mdi-rounded-corner:before {
    content: "\F607";
}
.mdi-router-wireless:before {
    content: "\F469";
}
.mdi-router-wireless-settings:before {
    content: "\FA68";
}
.mdi-routes:before {
    content: "\F46A";
}
.mdi-rowing:before {
    content: "\F608";
}
.mdi-rss:before {
    content: "\F46B";
}
.mdi-rss-box:before {
    content: "\F46C";
}
.mdi-ruler:before {
    content: "\F46D";
}
.mdi-run:before {
    content: "\F70D";
}
.mdi-run-fast:before {
    content: "\F46E";
}
.mdi-safe:before {
    content: "\FA69";
}
.mdi-sale:before {
    content: "\F46F";
}
.mdi-salesforce:before {
    content: "\F88D";
}
.mdi-sass:before {
    content: "\F7EB";
}
.mdi-satellite:before {
    content: "\F470";
}
.mdi-satellite-uplink:before {
    content: "\F908";
}
.mdi-satellite-variant:before {
    content: "\F471";
}
.mdi-sausage:before {
    content: "\F8B9";
}
.mdi-saxophone:before {
    content: "\F609";
}
.mdi-scale:before {
    content: "\F472";
}
.mdi-scale-balance:before {
    content: "\F5D1";
}
.mdi-scale-bathroom:before {
    content: "\F473";
}
.mdi-scanner:before {
    content: "\F6AA";
}
.mdi-scanner-off:before {
    content: "\F909";
}
.mdi-school:before {
    content: "\F474";
}
.mdi-scissors-cutting:before {
    content: "\FA6A";
}
.mdi-screen-rotation:before {
    content: "\F475";
}
.mdi-screen-rotation-lock:before {
    content: "\F476";
}
.mdi-screwdriver:before {
    content: "\F477";
}
.mdi-script:before {
    content: "\F478";
}
.mdi-sd:before {
    content: "\F479";
}
.mdi-seal:before {
    content: "\F47A";
}
.mdi-search-web:before {
    content: "\F70E";
}
.mdi-seat-flat:before {
    content: "\F47B";
}
.mdi-seat-flat-angled:before {
    content: "\F47C";
}
.mdi-seat-individual-suite:before {
    content: "\F47D";
}
.mdi-seat-legroom-extra:before {
    content: "\F47E";
}
.mdi-seat-legroom-normal:before {
    content: "\F47F";
}
.mdi-seat-legroom-reduced:before {
    content: "\F480";
}
.mdi-seat-recline-extra:before {
    content: "\F481";
}
.mdi-seat-recline-normal:before {
    content: "\F482";
}
.mdi-security:before {
    content: "\F483";
}
.mdi-security-account:before {
    content: "\F88E";
}
.mdi-security-account-outline:before {
    content: "\FA11";
}
.mdi-security-close:before {
    content: "\F99B";
}
.mdi-security-home:before {
    content: "\F689";
}
.mdi-security-lock:before {
    content: "\F99C";
}
.mdi-security-network:before {
    content: "\F484";
}
.mdi-security-off:before {
    content: "\F99D";
}
.mdi-select:before {
    content: "\F485";
}
.mdi-select-all:before {
    content: "\F486";
}
.mdi-select-compare:before {
    content: "\FAD8";
}
.mdi-select-drag:before {
    content: "\FA6B";
}
.mdi-select-inverse:before {
    content: "\F487";
}
.mdi-select-off:before {
    content: "\F488";
}
.mdi-selection:before {
    content: "\F489";
}
.mdi-selection-drag:before {
    content: "\FA6C";
}
.mdi-selection-off:before {
    content: "\F776";
}
.mdi-send:before {
    content: "\F48A";
}
.mdi-send-lock:before {
    content: "\F7EC";
}
.mdi-serial-port:before {
    content: "\F65C";
}
.mdi-server:before {
    content: "\F48B";
}
.mdi-server-minus:before {
    content: "\F48C";
}
.mdi-server-network:before {
    content: "\F48D";
}
.mdi-server-network-off:before {
    content: "\F48E";
}
.mdi-server-off:before {
    content: "\F48F";
}
.mdi-server-plus:before {
    content: "\F490";
}
.mdi-server-remove:before {
    content: "\F491";
}
.mdi-server-security:before {
    content: "\F492";
}
.mdi-set-all:before {
    content: "\F777";
}
.mdi-set-center:before {
    content: "\F778";
}
.mdi-set-center-right:before {
    content: "\F779";
}
.mdi-set-left:before {
    content: "\F77A";
}
.mdi-set-left-center:before {
    content: "\F77B";
}
.mdi-set-left-right:before {
    content: "\F77C";
}
.mdi-set-none:before {
    content: "\F77D";
}
.mdi-set-right:before {
    content: "\F77E";
}
.mdi-set-top-box:before {
    content: "\F99E";
}
.mdi-settings:before {
    content: "\F493";
}
.mdi-settings-box:before {
    content: "\F494";
}
.mdi-settings-helper:before {
    content: "\FA6D";
}
.mdi-settings-outline:before {
    content: "\F8BA";
}
.mdi-shape:before {
    content: "\F830";
}
.mdi-shape-circle-plus:before {
    content: "\F65D";
}
.mdi-shape-outline:before {
    content: "\F831";
}
.mdi-shape-plus:before {
    content: "\F495";
}
.mdi-shape-polygon-plus:before {
    content: "\F65E";
}
.mdi-shape-rectangle-plus:before {
    content: "\F65F";
}
.mdi-shape-square-plus:before {
    content: "\F660";
}
.mdi-share:before {
    content: "\F496";
}
.mdi-share-outline:before {
    content: "\F931";
}
.mdi-share-variant:before {
    content: "\F497";
}
.mdi-shield:before {
    content: "\F498";
}
.mdi-shield-half-full:before {
    content: "\F77F";
}
.mdi-shield-outline:before {
    content: "\F499";
}
.mdi-shield-plus:before {
    content: "\FAD9";
}
.mdi-shield-plus-outline:before {
    content: "\FADA";
}
.mdi-shield-remove:before {
    content: "\FADB";
}
.mdi-shield-remove-outline:before {
    content: "\FADC";
}
.mdi-ship-wheel:before {
    content: "\F832";
}
.mdi-shoe-formal:before {
    content: "\FB22";
}
.mdi-shoe-heel:before {
    content: "\FB23";
}
.mdi-shopify:before {
    content: "\FADD";
}
.mdi-shopping:before {
    content: "\F49A";
}
.mdi-shopping-music:before {
    content: "\F49B";
}
.mdi-shovel:before {
    content: "\F70F";
}
.mdi-shovel-off:before {
    content: "\F710";
}
.mdi-shower:before {
    content: "\F99F";
}
.mdi-shower-head:before {
    content: "\F9A0";
}
.mdi-shredder:before {
    content: "\F49C";
}
.mdi-shuffle:before {
    content: "\F49D";
}
.mdi-shuffle-disabled:before {
    content: "\F49E";
}
.mdi-shuffle-variant:before {
    content: "\F49F";
}
.mdi-sigma:before {
    content: "\F4A0";
}
.mdi-sigma-lower:before {
    content: "\F62B";
}
.mdi-sign-caution:before {
    content: "\F4A1";
}
.mdi-sign-direction:before {
    content: "\F780";
}
.mdi-sign-text:before {
    content: "\F781";
}
.mdi-signal:before {
    content: "\F4A2";
}
.mdi-signal-2g:before {
    content: "\F711";
}
.mdi-signal-3g:before {
    content: "\F712";
}
.mdi-signal-4g:before {
    content: "\F713";
}
.mdi-signal-5g:before {
    content: "\FA6E";
}
.mdi-signal-cellular-1:before {
    content: "\F8BB";
}
.mdi-signal-cellular-2:before {
    content: "\F8BC";
}
.mdi-signal-cellular-3:before {
    content: "\F8BD";
}
.mdi-signal-cellular-outline:before {
    content: "\F8BE";
}
.mdi-signal-hspa:before {
    content: "\F714";
}
.mdi-signal-hspa-plus:before {
    content: "\F715";
}
.mdi-signal-off:before {
    content: "\F782";
}
.mdi-signal-variant:before {
    content: "\F60A";
}
.mdi-silo:before {
    content: "\FB24";
}
.mdi-silverware:before {
    content: "\F4A3";
}
.mdi-silverware-fork:before {
    content: "\F4A4";
}
.mdi-silverware-fork-knife:before {
    content: "\FA6F";
}
.mdi-silverware-spoon:before {
    content: "\F4A5";
}
.mdi-silverware-variant:before {
    content: "\F4A6";
}
.mdi-sim:before {
    content: "\F4A7";
}
.mdi-sim-alert:before {
    content: "\F4A8";
}
.mdi-sim-off:before {
    content: "\F4A9";
}
.mdi-sina-weibo:before {
    content: "\FADE";
}
.mdi-sitemap:before {
    content: "\F4AA";
}
.mdi-skip-backward:before {
    content: "\F4AB";
}
.mdi-skip-forward:before {
    content: "\F4AC";
}
.mdi-skip-next:before {
    content: "\F4AD";
}
.mdi-skip-next-circle:before {
    content: "\F661";
}
.mdi-skip-next-circle-outline:before {
    content: "\F662";
}
.mdi-skip-previous:before {
    content: "\F4AE";
}
.mdi-skip-previous-circle:before {
    content: "\F663";
}
.mdi-skip-previous-circle-outline:before {
    content: "\F664";
}
.mdi-skull:before {
    content: "\F68B";
}
.mdi-skype:before {
    content: "\F4AF";
}
.mdi-skype-business:before {
    content: "\F4B0";
}
.mdi-slack:before {
    content: "\F4B1";
}
.mdi-slackware:before {
    content: "\F90A";
}
.mdi-sleep:before {
    content: "\F4B2";
}
.mdi-sleep-off:before {
    content: "\F4B3";
}
.mdi-smog:before {
    content: "\FA70";
}
.mdi-smoke-detector:before {
    content: "\F392";
}
.mdi-smoking:before {
    content: "\F4B4";
}
.mdi-smoking-off:before {
    content: "\F4B5";
}
.mdi-snapchat:before {
    content: "\F4B6";
}
.mdi-snowflake:before {
    content: "\F716";
}
.mdi-snowman:before {
    content: "\F4B7";
}
.mdi-soccer:before {
    content: "\F4B8";
}
.mdi-soccer-field:before {
    content: "\F833";
}
.mdi-sofa:before {
    content: "\F4B9";
}
.mdi-solar-power:before {
    content: "\FA71";
}
.mdi-solid:before {
    content: "\F68C";
}
.mdi-sort:before {
    content: "\F4BA";
}
.mdi-sort-alphabetical:before {
    content: "\F4BB";
}
.mdi-sort-ascending:before {
    content: "\F4BC";
}
.mdi-sort-descending:before {
    content: "\F4BD";
}
.mdi-sort-numeric:before {
    content: "\F4BE";
}
.mdi-sort-variant:before {
    content: "\F4BF";
}
.mdi-soundcloud:before {
    content: "\F4C0";
}
.mdi-source-branch:before {
    content: "\F62C";
}
.mdi-source-commit:before {
    content: "\F717";
}
.mdi-source-commit-end:before {
    content: "\F718";
}
.mdi-source-commit-end-local:before {
    content: "\F719";
}
.mdi-source-commit-local:before {
    content: "\F71A";
}
.mdi-source-commit-next-local:before {
    content: "\F71B";
}
.mdi-source-commit-start:before {
    content: "\F71C";
}
.mdi-source-commit-start-next-local:before {
    content: "\F71D";
}
.mdi-source-fork:before {
    content: "\F4C1";
}
.mdi-source-merge:before {
    content: "\F62D";
}
.mdi-source-pull:before {
    content: "\F4C2";
}
.mdi-soy-sauce:before {
    content: "\F7ED";
}
.mdi-speaker:before {
    content: "\F4C3";
}
.mdi-speaker-bluetooth:before {
    content: "\F9A1";
}
.mdi-speaker-off:before {
    content: "\F4C4";
}
.mdi-speaker-wireless:before {
    content: "\F71E";
}
.mdi-speedometer:before {
    content: "\F4C5";
}
.mdi-spellcheck:before {
    content: "\F4C6";
}
.mdi-spotify:before {
    content: "\F4C7";
}
.mdi-spotlight:before {
    content: "\F4C8";
}
.mdi-spotlight-beam:before {
    content: "\F4C9";
}
.mdi-spray:before {
    content: "\F665";
}
.mdi-spray-bottle:before {
    content: "\FADF";
}
.mdi-square:before {
    content: "\F763";
}
.mdi-square-edit-outline:before {
    content: "\F90B";
}
.mdi-square-inc:before {
    content: "\F4CA";
}
.mdi-square-inc-cash:before {
    content: "\F4CB";
}
.mdi-square-medium:before {
    content: "\FA12";
}
.mdi-square-medium-outline:before {
    content: "\FA13";
}
.mdi-square-outline:before {
    content: "\F762";
}
.mdi-square-root:before {
    content: "\F783";
}
.mdi-square-root-box:before {
    content: "\F9A2";
}
.mdi-square-small:before {
    content: "\FA14";
}
.mdi-squeegee:before {
    content: "\FAE0";
}
.mdi-ssh:before {
    content: "\F8BF";
}
.mdi-stack-exchange:before {
    content: "\F60B";
}
.mdi-stack-overflow:before {
    content: "\F4CC";
}
.mdi-stadium:before {
    content: "\F71F";
}
.mdi-stairs:before {
    content: "\F4CD";
}
.mdi-standard-definition:before {
    content: "\F7EE";
}
.mdi-star:before {
    content: "\F4CE";
}
.mdi-star-box:before {
    content: "\FA72";
}
.mdi-star-box-outline:before {
    content: "\FA73";
}
.mdi-star-circle:before {
    content: "\F4CF";
}
.mdi-star-circle-outline:before {
    content: "\F9A3";
}
.mdi-star-face:before {
    content: "\F9A4";
}
.mdi-star-four-points:before {
    content: "\FAE1";
}
.mdi-star-four-points-outline:before {
    content: "\FAE2";
}
.mdi-star-half:before {
    content: "\F4D0";
}
.mdi-star-off:before {
    content: "\F4D1";
}
.mdi-star-outline:before {
    content: "\F4D2";
}
.mdi-star-three-points:before {
    content: "\FAE3";
}
.mdi-star-three-points-outline:before {
    content: "\FAE4";
}
.mdi-steam:before {
    content: "\F4D3";
}
.mdi-steam-box:before {
    content: "\F90C";
}
.mdi-steering:before {
    content: "\F4D4";
}
.mdi-steering-off:before {
    content: "\F90D";
}
.mdi-step-backward:before {
    content: "\F4D5";
}
.mdi-step-backward-2:before {
    content: "\F4D6";
}
.mdi-step-forward:before {
    content: "\F4D7";
}
.mdi-step-forward-2:before {
    content: "\F4D8";
}
.mdi-stethoscope:before {
    content: "\F4D9";
}
.mdi-sticker:before {
    content: "\F5D0";
}
.mdi-sticker-emoji:before {
    content: "\F784";
}
.mdi-stocking:before {
    content: "\F4DA";
}
.mdi-stop:before {
    content: "\F4DB";
}
.mdi-stop-circle:before {
    content: "\F666";
}
.mdi-stop-circle-outline:before {
    content: "\F667";
}
.mdi-store:before {
    content: "\F4DC";
}
.mdi-store-24-hour:before {
    content: "\F4DD";
}
.mdi-stove:before {
    content: "\F4DE";
}
.mdi-strava:before {
    content: "\FB25";
}
.mdi-subdirectory-arrow-left:before {
    content: "\F60C";
}
.mdi-subdirectory-arrow-right:before {
    content: "\F60D";
}
.mdi-subtitles:before {
    content: "\FA15";
}
.mdi-subtitles-outline:before {
    content: "\FA16";
}
.mdi-subway:before {
    content: "\F6AB";
}
.mdi-subway-variant:before {
    content: "\F4DF";
}
.mdi-summit:before {
    content: "\F785";
}
.mdi-sunglasses:before {
    content: "\F4E0";
}
.mdi-surround-sound:before {
    content: "\F5C5";
}
.mdi-surround-sound-2-0:before {
    content: "\F7EF";
}
.mdi-surround-sound-3-1:before {
    content: "\F7F0";
}
.mdi-surround-sound-5-1:before {
    content: "\F7F1";
}
.mdi-surround-sound-7-1:before {
    content: "\F7F2";
}
.mdi-svg:before {
    content: "\F720";
}
.mdi-swap-horizontal:before {
    content: "\F4E1";
}
.mdi-swap-horizontal-variant:before {
    content: "\F8C0";
}
.mdi-swap-vertical:before {
    content: "\F4E2";
}
.mdi-swap-vertical-variant:before {
    content: "\F8C1";
}
.mdi-swim:before {
    content: "\F4E3";
}
.mdi-switch:before {
    content: "\F4E4";
}
.mdi-sword:before {
    content: "\F4E5";
}
.mdi-sword-cross:before {
    content: "\F786";
}
.mdi-symfony:before {
    content: "\FAE5";
}
.mdi-sync:before {
    content: "\F4E6";
}
.mdi-sync-alert:before {
    content: "\F4E7";
}
.mdi-sync-off:before {
    content: "\F4E8";
}
.mdi-tab:before {
    content: "\F4E9";
}
.mdi-tab-minus:before {
    content: "\FB26";
}
.mdi-tab-plus:before {
    content: "\F75B";
}
.mdi-tab-remove:before {
    content: "\FB27";
}
.mdi-tab-unselected:before {
    content: "\F4EA";
}
.mdi-table:before {
    content: "\F4EB";
}
.mdi-table-border:before {
    content: "\FA17";
}
.mdi-table-column:before {
    content: "\F834";
}
.mdi-table-column-plus-after:before {
    content: "\F4EC";
}
.mdi-table-column-plus-before:before {
    content: "\F4ED";
}
.mdi-table-column-remove:before {
    content: "\F4EE";
}
.mdi-table-column-width:before {
    content: "\F4EF";
}
.mdi-table-edit:before {
    content: "\F4F0";
}
.mdi-table-large:before {
    content: "\F4F1";
}
.mdi-table-merge-cells:before {
    content: "\F9A5";
}
.mdi-table-of-contents:before {
    content: "\F835";
}
.mdi-table-plus:before {
    content: "\FA74";
}
.mdi-table-remove:before {
    content: "\FA75";
}
.mdi-table-row:before {
    content: "\F836";
}
.mdi-table-row-height:before {
    content: "\F4F2";
}
.mdi-table-row-plus-after:before {
    content: "\F4F3";
}
.mdi-table-row-plus-before:before {
    content: "\F4F4";
}
.mdi-table-row-remove:before {
    content: "\F4F5";
}
.mdi-table-search:before {
    content: "\F90E";
}
.mdi-table-settings:before {
    content: "\F837";
}
.mdi-tablet:before {
    content: "\F4F6";
}
.mdi-tablet-android:before {
    content: "\F4F7";
}
.mdi-tablet-cellphone:before {
    content: "\F9A6";
}
.mdi-tablet-ipad:before {
    content: "\F4F8";
}
.mdi-taco:before {
    content: "\F761";
}
.mdi-tag:before {
    content: "\F4F9";
}
.mdi-tag-faces:before {
    content: "\F4FA";
}
.mdi-tag-heart:before {
    content: "\F68A";
}
.mdi-tag-minus:before {
    content: "\F90F";
}
.mdi-tag-multiple:before {
    content: "\F4FB";
}
.mdi-tag-outline:before {
    content: "\F4FC";
}
.mdi-tag-plus:before {
    content: "\F721";
}
.mdi-tag-remove:before {
    content: "\F722";
}
.mdi-tag-text-outline:before {
    content: "\F4FD";
}
.mdi-tape-measure:before {
    content: "\FB28";
}
.mdi-target:before {
    content: "\F4FE";
}
.mdi-target-variant:before {
    content: "\FA76";
}
.mdi-taxi:before {
    content: "\F4FF";
}
.mdi-teach:before {
    content: "\F88F";
}
.mdi-teamviewer:before {
    content: "\F500";
}
.mdi-telegram:before {
    content: "\F501";
}
.mdi-telescope:before {
    content: "\FB29";
}
.mdi-television:before {
    content: "\F502";
}
.mdi-television-box:before {
    content: "\F838";
}
.mdi-television-classic:before {
    content: "\F7F3";
}
.mdi-television-classic-off:before {
    content: "\F839";
}
.mdi-television-guide:before {
    content: "\F503";
}
.mdi-television-off:before {
    content: "\F83A";
}
.mdi-temperature-celsius:before {
    content: "\F504";
}
.mdi-temperature-fahrenheit:before {
    content: "\F505";
}
.mdi-temperature-kelvin:before {
    content: "\F506";
}
.mdi-tennis:before {
    content: "\F507";
}
.mdi-tent:before {
    content: "\F508";
}
.mdi-terrain:before {
    content: "\F509";
}
.mdi-test-tube:before {
    content: "\F668";
}
.mdi-test-tube-empty:before {
    content: "\F910";
}
.mdi-test-tube-off:before {
    content: "\F911";
}
.mdi-text:before {
    content: "\F9A7";
}
.mdi-text-shadow:before {
    content: "\F669";
}
.mdi-text-short:before {
    content: "\F9A8";
}
.mdi-text-subject:before {
    content: "\F9A9";
}
.mdi-text-to-speech:before {
    content: "\F50A";
}
.mdi-text-to-speech-off:before {
    content: "\F50B";
}
.mdi-textbox:before {
    content: "\F60E";
}
.mdi-textbox-password:before {
    content: "\F7F4";
}
.mdi-texture:before {
    content: "\F50C";
}
.mdi-theater:before {
    content: "\F50D";
}
.mdi-theme-light-dark:before {
    content: "\F50E";
}
.mdi-thermometer:before {
    content: "\F50F";
}
.mdi-thermometer-lines:before {
    content: "\F510";
}
.mdi-thermostat:before {
    content: "\F393";
}
.mdi-thermostat-box:before {
    content: "\F890";
}
.mdi-thought-bubble:before {
    content: "\F7F5";
}
.mdi-thought-bubble-outline:before {
    content: "\F7F6";
}
.mdi-thumb-down:before {
    content: "\F511";
}
.mdi-thumb-down-outline:before {
    content: "\F512";
}
.mdi-thumb-up:before {
    content: "\F513";
}
.mdi-thumb-up-outline:before {
    content: "\F514";
}
.mdi-thumbs-up-down:before {
    content: "\F515";
}
.mdi-ticket:before {
    content: "\F516";
}
.mdi-ticket-account:before {
    content: "\F517";
}
.mdi-ticket-confirmation:before {
    content: "\F518";
}
.mdi-ticket-outline:before {
    content: "\F912";
}
.mdi-ticket-percent:before {
    content: "\F723";
}
.mdi-tie:before {
    content: "\F519";
}
.mdi-tilde:before {
    content: "\F724";
}
.mdi-timelapse:before {
    content: "\F51A";
}
.mdi-timer:before {
    content: "\F51B";
}
.mdi-timer-10:before {
    content: "\F51C";
}
.mdi-timer-3:before {
    content: "\F51D";
}
.mdi-timer-off:before {
    content: "\F51E";
}
.mdi-timer-sand:before {
    content: "\F51F";
}
.mdi-timer-sand-empty:before {
    content: "\F6AC";
}
.mdi-timer-sand-full:before {
    content: "\F78B";
}
.mdi-timetable:before {
    content: "\F520";
}
.mdi-toggle-switch:before {
    content: "\F521";
}
.mdi-toggle-switch-off:before {
    content: "\F522";
}
.mdi-toggle-switch-off-outline:before {
    content: "\FA18";
}
.mdi-toggle-switch-outline:before {
    content: "\FA19";
}
.mdi-toilet:before {
    content: "\F9AA";
}
.mdi-toolbox:before {
    content: "\F9AB";
}
.mdi-toolbox-outline:before {
    content: "\F9AC";
}
.mdi-tooltip:before {
    content: "\F523";
}
.mdi-tooltip-edit:before {
    content: "\F524";
}
.mdi-tooltip-image:before {
    content: "\F525";
}
.mdi-tooltip-outline:before {
    content: "\F526";
}
.mdi-tooltip-outline-plus:before {
    content: "\F527";
}
.mdi-tooltip-text:before {
    content: "\F528";
}
.mdi-tooth:before {
    content: "\F8C2";
}
.mdi-tooth-outline:before {
    content: "\F529";
}
.mdi-tor:before {
    content: "\F52A";
}
.mdi-tournament:before {
    content: "\F9AD";
}
.mdi-tower-beach:before {
    content: "\F680";
}
.mdi-tower-fire:before {
    content: "\F681";
}
.mdi-towing:before {
    content: "\F83B";
}
.mdi-track-light:before {
    content: "\F913";
}
.mdi-trackpad:before {
    content: "\F7F7";
}
.mdi-trackpad-lock:before {
    content: "\F932";
}
.mdi-tractor:before {
    content: "\F891";
}
.mdi-trademark:before {
    content: "\FA77";
}
.mdi-traffic-light:before {
    content: "\F52B";
}
.mdi-train:before {
    content: "\F52C";
}
.mdi-train-variant:before {
    content: "\F8C3";
}
.mdi-tram:before {
    content: "\F52D";
}
.mdi-transcribe:before {
    content: "\F52E";
}
.mdi-transcribe-close:before {
    content: "\F52F";
}
.mdi-transfer:before {
    content: "\F530";
}
.mdi-transit-transfer:before {
    content: "\F6AD";
}
.mdi-transition:before {
    content: "\F914";
}
.mdi-transition-masked:before {
    content: "\F915";
}
.mdi-translate:before {
    content: "\F5CA";
}
.mdi-trash-can:before {
    content: "\FA78";
}
.mdi-trash-can-outline:before {
    content: "\FA79";
}
.mdi-treasure-chest:before {
    content: "\F725";
}
.mdi-tree:before {
    content: "\F531";
}
.mdi-trello:before {
    content: "\F532";
}
.mdi-trending-down:before {
    content: "\F533";
}
.mdi-trending-neutral:before {
    content: "\F534";
}
.mdi-trending-up:before {
    content: "\F535";
}
.mdi-triangle:before {
    content: "\F536";
}
.mdi-triangle-outline:before {
    content: "\F537";
}
.mdi-trophy:before {
    content: "\F538";
}
.mdi-trophy-award:before {
    content: "\F539";
}
.mdi-trophy-outline:before {
    content: "\F53A";
}
.mdi-trophy-variant:before {
    content: "\F53B";
}
.mdi-trophy-variant-outline:before {
    content: "\F53C";
}
.mdi-truck:before {
    content: "\F53D";
}
.mdi-truck-delivery:before {
    content: "\F53E";
}
.mdi-truck-fast:before {
    content: "\F787";
}
.mdi-truck-trailer:before {
    content: "\F726";
}
.mdi-tshirt-crew:before {
    content: "\FA7A";
}
.mdi-tshirt-crew-outline:before {
    content: "\F53F";
}
.mdi-tshirt-v:before {
    content: "\FA7B";
}
.mdi-tshirt-v-outline:before {
    content: "\F540";
}
.mdi-tumble-dryer:before {
    content: "\F916";
}
.mdi-tumblr:before {
    content: "\F541";
}
.mdi-tumblr-box:before {
    content: "\F917";
}
.mdi-tumblr-reblog:before {
    content: "\F542";
}
.mdi-tune:before {
    content: "\F62E";
}
.mdi-tune-vertical:before {
    content: "\F66A";
}
.mdi-twitch:before {
    content: "\F543";
}
.mdi-twitter:before {
    content: "\F544";
}
.mdi-twitter-box:before {
    content: "\F545";
}
.mdi-twitter-circle:before {
    content: "\F546";
}
.mdi-twitter-retweet:before {
    content: "\F547";
}
.mdi-two-factor-authentication:before {
    content: "\F9AE";
}
.mdi-uber:before {
    content: "\F748";
}
.mdi-ubuntu:before {
    content: "\F548";
}
.mdi-ultra-high-definition:before {
    content: "\F7F8";
}
.mdi-umbraco:before {
    content: "\F549";
}
.mdi-umbrella:before {
    content: "\F54A";
}
.mdi-umbrella-closed:before {
    content: "\F9AF";
}
.mdi-umbrella-outline:before {
    content: "\F54B";
}
.mdi-undo:before {
    content: "\F54C";
}
.mdi-undo-variant:before {
    content: "\F54D";
}
.mdi-unfold-less-horizontal:before {
    content: "\F54E";
}
.mdi-unfold-less-vertical:before {
    content: "\F75F";
}
.mdi-unfold-more-horizontal:before {
    content: "\F54F";
}
.mdi-unfold-more-vertical:before {
    content: "\F760";
}
.mdi-ungroup:before {
    content: "\F550";
}
.mdi-unity:before {
    content: "\F6AE";
}
.mdi-unreal:before {
    content: "\F9B0";
}
.mdi-untappd:before {
    content: "\F551";
}
.mdi-update:before {
    content: "\F6AF";
}
.mdi-upload:before {
    content: "\F552";
}
.mdi-upload-multiple:before {
    content: "\F83C";
}
.mdi-upload-network:before {
    content: "\F6F5";
}
.mdi-usb:before {
    content: "\F553";
}
.mdi-van-passenger:before {
    content: "\F7F9";
}
.mdi-van-utility:before {
    content: "\F7FA";
}
.mdi-vanish:before {
    content: "\F7FB";
}
.mdi-variable:before {
    content: "\FAE6";
}
.mdi-vector-arrange-above:before {
    content: "\F554";
}
.mdi-vector-arrange-below:before {
    content: "\F555";
}
.mdi-vector-bezier:before {
    content: "\FAE7";
}
.mdi-vector-circle:before {
    content: "\F556";
}
.mdi-vector-circle-variant:before {
    content: "\F557";
}
.mdi-vector-combine:before {
    content: "\F558";
}
.mdi-vector-curve:before {
    content: "\F559";
}
.mdi-vector-difference:before {
    content: "\F55A";
}
.mdi-vector-difference-ab:before {
    content: "\F55B";
}
.mdi-vector-difference-ba:before {
    content: "\F55C";
}
.mdi-vector-ellipse:before {
    content: "\F892";
}
.mdi-vector-intersection:before {
    content: "\F55D";
}
.mdi-vector-line:before {
    content: "\F55E";
}
.mdi-vector-point:before {
    content: "\F55F";
}
.mdi-vector-polygon:before {
    content: "\F560";
}
.mdi-vector-polyline:before {
    content: "\F561";
}
.mdi-vector-radius:before {
    content: "\F749";
}
.mdi-vector-rectangle:before {
    content: "\F5C6";
}
.mdi-vector-selection:before {
    content: "\F562";
}
.mdi-vector-square:before {
    content: "\F001";
}
.mdi-vector-triangle:before {
    content: "\F563";
}
.mdi-vector-union:before {
    content: "\F564";
}
.mdi-venmo:before {
    content: "\F578";
}
.mdi-verified:before {
    content: "\F565";
}
.mdi-vhs:before {
    content: "\FA1A";
}
.mdi-vibrate:before {
    content: "\F566";
}
.mdi-video:before {
    content: "\F567";
}
.mdi-video-3d:before {
    content: "\F7FC";
}
.mdi-video-4k-box:before {
    content: "\F83D";
}
.mdi-video-account:before {
    content: "\F918";
}
.mdi-video-image:before {
    content: "\F919";
}
.mdi-video-input-antenna:before {
    content: "\F83E";
}
.mdi-video-input-component:before {
    content: "\F83F";
}
.mdi-video-input-hdmi:before {
    content: "\F840";
}
.mdi-video-input-svideo:before {
    content: "\F841";
}
.mdi-video-minus:before {
    content: "\F9B1";
}
.mdi-video-off:before {
    content: "\F568";
}
.mdi-video-plus:before {
    content: "\F9B2";
}
.mdi-video-stabilization:before {
    content: "\F91A";
}
.mdi-video-switch:before {
    content: "\F569";
}
.mdi-video-vintage:before {
    content: "\FA1B";
}
.mdi-view-agenda:before {
    content: "\F56A";
}
.mdi-view-array:before {
    content: "\F56B";
}
.mdi-view-carousel:before {
    content: "\F56C";
}
.mdi-view-column:before {
    content: "\F56D";
}
.mdi-view-dashboard:before {
    content: "\F56E";
}
.mdi-view-dashboard-outline:before {
    content: "\FA1C";
}
.mdi-view-dashboard-variant:before {
    content: "\F842";
}
.mdi-view-day:before {
    content: "\F56F";
}
.mdi-view-grid:before {
    content: "\F570";
}
.mdi-view-headline:before {
    content: "\F571";
}
.mdi-view-list:before {
    content: "\F572";
}
.mdi-view-module:before {
    content: "\F573";
}
.mdi-view-parallel:before {
    content: "\F727";
}
.mdi-view-quilt:before {
    content: "\F574";
}
.mdi-view-sequential:before {
    content: "\F728";
}
.mdi-view-stream:before {
    content: "\F575";
}
.mdi-view-week:before {
    content: "\F576";
}
.mdi-vimeo:before {
    content: "\F577";
}
.mdi-violin:before {
    content: "\F60F";
}
.mdi-virtual-reality:before {
    content: "\F893";
}
.mdi-visual-studio:before {
    content: "\F610";
}
.mdi-visual-studio-code:before {
    content: "\FA1D";
}
.mdi-vk:before {
    content: "\F579";
}
.mdi-vk-box:before {
    content: "\F57A";
}
.mdi-vk-circle:before {
    content: "\F57B";
}
.mdi-vlc:before {
    content: "\F57C";
}
.mdi-voice:before {
    content: "\F5CB";
}
.mdi-voicemail:before {
    content: "\F57D";
}
.mdi-volleyball:before {
    content: "\F9B3";
}
.mdi-volume-high:before {
    content: "\F57E";
}
.mdi-volume-low:before {
    content: "\F57F";
}
.mdi-volume-medium:before {
    content: "\F580";
}
.mdi-volume-minus:before {
    content: "\F75D";
}
.mdi-volume-mute:before {
    content: "\F75E";
}
.mdi-volume-off:before {
    content: "\F581";
}
.mdi-volume-plus:before {
    content: "\F75C";
}
.mdi-vote:before {
    content: "\FA1E";
}
.mdi-vote-outline:before {
    content: "\FA1F";
}
.mdi-vpn:before {
    content: "\F582";
}
.mdi-vuejs:before {
    content: "\F843";
}
.mdi-walk:before {
    content: "\F583";
}
.mdi-wall:before {
    content: "\F7FD";
}
.mdi-wall-sconce:before {
    content: "\F91B";
}
.mdi-wall-sconce-flat:before {
    content: "\F91C";
}
.mdi-wall-sconce-variant:before {
    content: "\F91D";
}
.mdi-wallet:before {
    content: "\F584";
}
.mdi-wallet-giftcard:before {
    content: "\F585";
}
.mdi-wallet-membership:before {
    content: "\F586";
}
.mdi-wallet-travel:before {
    content: "\F587";
}
.mdi-wan:before {
    content: "\F588";
}
.mdi-washing-machine:before {
    content: "\F729";
}
.mdi-watch:before {
    content: "\F589";
}
.mdi-watch-export:before {
    content: "\F58A";
}
.mdi-watch-export-variant:before {
    content: "\F894";
}
.mdi-watch-import:before {
    content: "\F58B";
}
.mdi-watch-import-variant:before {
    content: "\F895";
}
.mdi-watch-variant:before {
    content: "\F896";
}
.mdi-watch-vibrate:before {
    content: "\F6B0";
}
.mdi-water:before {
    content: "\F58C";
}
.mdi-water-off:before {
    content: "\F58D";
}
.mdi-water-percent:before {
    content: "\F58E";
}
.mdi-water-pump:before {
    content: "\F58F";
}
.mdi-watermark:before {
    content: "\F612";
}
.mdi-waves:before {
    content: "\F78C";
}
.mdi-weather-cloudy:before {
    content: "\F590";
}
.mdi-weather-fog:before {
    content: "\F591";
}
.mdi-weather-hail:before {
    content: "\F592";
}
.mdi-weather-hurricane:before {
    content: "\F897";
}
.mdi-weather-lightning:before {
    content: "\F593";
}
.mdi-weather-lightning-rainy:before {
    content: "\F67D";
}
.mdi-weather-night:before {
    content: "\F594";
}
.mdi-weather-partlycloudy:before {
    content: "\F595";
}
.mdi-weather-pouring:before {
    content: "\F596";
}
.mdi-weather-rainy:before {
    content: "\F597";
}
.mdi-weather-snowy:before {
    content: "\F598";
}
.mdi-weather-snowy-rainy:before {
    content: "\F67E";
}
.mdi-weather-sunny:before {
    content: "\F599";
}
.mdi-weather-sunset:before {
    content: "\F59A";
}
.mdi-weather-sunset-down:before {
    content: "\F59B";
}
.mdi-weather-sunset-up:before {
    content: "\F59C";
}
.mdi-weather-windy:before {
    content: "\F59D";
}
.mdi-weather-windy-variant:before {
    content: "\F59E";
}
.mdi-web:before {
    content: "\F59F";
}
.mdi-webcam:before {
    content: "\F5A0";
}
.mdi-webhook:before {
    content: "\F62F";
}
.mdi-webpack:before {
    content: "\F72A";
}
.mdi-wechat:before {
    content: "\F611";
}
.mdi-weight:before {
    content: "\F5A1";
}
.mdi-weight-kilogram:before {
    content: "\F5A2";
}
.mdi-weight-pound:before {
    content: "\F9B4";
}
.mdi-whatsapp:before {
    content: "\F5A3";
}
.mdi-wheelchair-accessibility:before {
    content: "\F5A4";
}
.mdi-whistle:before {
    content: "\F9B5";
}
.mdi-white-balance-auto:before {
    content: "\F5A5";
}
.mdi-white-balance-incandescent:before {
    content: "\F5A6";
}
.mdi-white-balance-iridescent:before {
    content: "\F5A7";
}
.mdi-white-balance-sunny:before {
    content: "\F5A8";
}
.mdi-widgets:before {
    content: "\F72B";
}
.mdi-wifi:before {
    content: "\F5A9";
}
.mdi-wifi-off:before {
    content: "\F5AA";
}
.mdi-wifi-strength-1:before {
    content: "\F91E";
}
.mdi-wifi-strength-1-alert:before {
    content: "\F91F";
}
.mdi-wifi-strength-1-lock:before {
    content: "\F920";
}
.mdi-wifi-strength-2:before {
    content: "\F921";
}
.mdi-wifi-strength-2-alert:before {
    content: "\F922";
}
.mdi-wifi-strength-2-lock:before {
    content: "\F923";
}
.mdi-wifi-strength-3:before {
    content: "\F924";
}
.mdi-wifi-strength-3-alert:before {
    content: "\F925";
}
.mdi-wifi-strength-3-lock:before {
    content: "\F926";
}
.mdi-wifi-strength-4:before {
    content: "\F927";
}
.mdi-wifi-strength-4-alert:before {
    content: "\F928";
}
.mdi-wifi-strength-4-lock:before {
    content: "\F929";
}
.mdi-wifi-strength-alert-outline:before {
    content: "\F92A";
}
.mdi-wifi-strength-lock-outline:before {
    content: "\F92B";
}
.mdi-wifi-strength-off:before {
    content: "\F92C";
}
.mdi-wifi-strength-off-outline:before {
    content: "\F92D";
}
.mdi-wifi-strength-outline:before {
    content: "\F92E";
}
.mdi-wii:before {
    content: "\F5AB";
}
.mdi-wiiu:before {
    content: "\F72C";
}
.mdi-wikipedia:before {
    content: "\F5AC";
}
.mdi-window-close:before {
    content: "\F5AD";
}
.mdi-window-closed:before {
    content: "\F5AE";
}
.mdi-window-maximize:before {
    content: "\F5AF";
}
.mdi-window-minimize:before {
    content: "\F5B0";
}
.mdi-window-open:before {
    content: "\F5B1";
}
.mdi-window-restore:before {
    content: "\F5B2";
}
.mdi-windows:before {
    content: "\F5B3";
}
.mdi-windows-classic:before {
    content: "\FA20";
}
.mdi-wiper:before {
    content: "\FAE8";
}
.mdi-wordpress:before {
    content: "\F5B4";
}
.mdi-worker:before {
    content: "\F5B5";
}
.mdi-wrap:before {
    content: "\F5B6";
}
.mdi-wrench:before {
    content: "\F5B7";
}
.mdi-wunderlist:before {
    content: "\F5B8";
}
.mdi-xamarin:before {
    content: "\F844";
}
.mdi-xamarin-outline:before {
    content: "\F845";
}
.mdi-xaml:before {
    content: "\F673";
}
.mdi-xbox:before {
    content: "\F5B9";
}
.mdi-xbox-controller:before {
    content: "\F5BA";
}
.mdi-xbox-controller-battery-alert:before {
    content: "\F74A";
}
.mdi-xbox-controller-battery-charging:before {
    content: "\FA21";
}
.mdi-xbox-controller-battery-empty:before {
    content: "\F74B";
}
.mdi-xbox-controller-battery-full:before {
    content: "\F74C";
}
.mdi-xbox-controller-battery-low:before {
    content: "\F74D";
}
.mdi-xbox-controller-battery-medium:before {
    content: "\F74E";
}
.mdi-xbox-controller-battery-unknown:before {
    content: "\F74F";
}
.mdi-xbox-controller-off:before {
    content: "\F5BB";
}
.mdi-xda:before {
    content: "\F5BC";
}
.mdi-xing:before {
    content: "\F5BD";
}
.mdi-xing-box:before {
    content: "\F5BE";
}
.mdi-xing-circle:before {
    content: "\F5BF";
}
.mdi-xml:before {
    content: "\F5C0";
}
.mdi-xmpp:before {
    content: "\F7FE";
}
.mdi-yahoo:before {
    content: "\FB2A";
}
.mdi-yammer:before {
    content: "\F788";
}
.mdi-yeast:before {
    content: "\F5C1";
}
.mdi-yelp:before {
    content: "\F5C2";
}
.mdi-yin-yang:before {
    content: "\F67F";
}
.mdi-youtube:before {
    content: "\F5C3";
}
.mdi-youtube-creator-studio:before {
    content: "\F846";
}
.mdi-youtube-gaming:before {
    content: "\F847";
}
.mdi-youtube-tv:before {
    content: "\F448";
}
.mdi-z-wave:before {
    content: "\FAE9";
}
.mdi-zend:before {
    content: "\FAEA";
}
.mdi-zip-box:before {
    content: "\F5C4";
}
.mdi-zip-disk:before {
    content: "\FA22";
}
.mdi-zodiac-aquarius:before {
    content: "\FA7C";
}
.mdi-zodiac-aries:before {
    content: "\FA7D";
}
.mdi-zodiac-cancer:before {
    content: "\FA7E";
}
.mdi-zodiac-capricorn:before {
    content: "\FA7F";
}
.mdi-zodiac-gemini:before {
    content: "\FA80";
}
.mdi-zodiac-leo:before {
    content: "\FA81";
}
.mdi-zodiac-libra:before {
    content: "\FA82";
}
.mdi-zodiac-pisces:before {
    content: "\FA83";
}
.mdi-zodiac-sagittarius:before {
    content: "\FA84";
}
.mdi-zodiac-scorpio:before {
    content: "\FA85";
}
.mdi-zodiac-taurus:before {
    content: "\FA86";
}
.mdi-zodiac-virgo:before {
    content: "\FA87";
}
.mdi-blank:before {
    content: "";
    visibility: hidden;
}
.mdi-18px.mdi-set,
.mdi-18px.mdi:before {
    font-size: 18px;
}
.mdi-24px.mdi-set,
.mdi-24px.mdi:before {
    font-size: 24px;
}
.mdi-36px.mdi-set,
.mdi-36px.mdi:before {
    font-size: 36px;
}
.mdi-48px.mdi-set,
.mdi-48px.mdi:before {
    font-size: 48px;
}
.mdi-dark:before {
    color: rgba(0, 0, 0, 0.54);
}
.mdi-dark.mdi-inactive:before {
    color: rgba(0, 0, 0, 0.26);
}
.mdi-light:before {
    color: white;
}
.mdi-light.mdi-inactive:before {
    color: rgba(255, 255, 255, 0.3);
}
.mdi-rotate-45 {
}
.mdi-rotate-45:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.mdi-rotate-90 {
}
.mdi-rotate-90:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.mdi-rotate-135 {
}
.mdi-rotate-135:before {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}
.mdi-rotate-180 {
}
.mdi-rotate-180:before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.mdi-rotate-225 {
}
.mdi-rotate-225:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
}
.mdi-rotate-270 {
}
.mdi-rotate-270:before {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.mdi-rotate-315 {
}
.mdi-rotate-315:before {
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg);
}
.mdi-flip-h:before {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}
.mdi-flip-v:before {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
}
.mdi-spin:before {
    -webkit-animation: mdi-spin 2s infinite linear;
    animation: mdi-spin 2s infinite linear;
}
@-webkit-keyframes mdi-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes mdi-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@font-face {
    font-family: "Ionicons";
    src: url("../fonts/ionicons.eot?v=4.0.0-0");
    src: url("../fonts/ionicons.eot?v=4.0.0-0#iefix") format("embedded-opentype"),
        url("../fonts/ionicons.woff2?v=4.0.0-0") format("woff2"), url("../fonts/ionicons.woff?v=4.0.0-0") format("woff"),
        url("../fonts/ionicons.ttf?v=4.0.0-0") format("truetype"),
        url("../fonts/ionicons.svg?v=4.0.0-0#Ionicons") format("svg");
    font-weight: normal;
    font-style: normal;
}
.ion {
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
    font-family: "themify";
    src: url("../fonts/themify.eot?-fvbane");
    src: url("../fonts/themify.eot?#iefix-fvbane") format("embedded-opentype"),
        url("../fonts/themify.woff?-fvbane") format("woff"), url("../fonts/themify.ttf?-fvbane") format("truetype"),
        url("../fonts/themify.svg?-fvbane#themify") format("svg");
    font-weight: normal;
    font-style: normal;
}
[class^="ti-"],
[class*=" ti-"] {
    font-family: "themify";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ti-wand:before {
    content: "";
}
.ti-volume:before {
    content: "";
}
.ti-user:before {
    content: "";
}
.ti-unlock:before {
    content: "";
}
.ti-unlink:before {
    content: "";
}
.ti-trash:before {
    content: "";
}
.ti-thought:before {
    content: "";
}
.ti-target:before {
    content: "";
}
.ti-tag:before {
    content: "";
}
.ti-tablet:before {
    content: "";
}
.ti-star:before {
    content: "";
}
.ti-spray:before {
    content: "";
}
.ti-signal:before {
    content: "";
}
.ti-shopping-cart:before {
    content: "";
}
.ti-shopping-cart-full:before {
    content: "";
}
.ti-settings:before {
    content: "";
}
.ti-search:before {
    content: "";
}
.ti-zoom-in:before {
    content: "";
}
.ti-zoom-out:before {
    content: "";
}
.ti-cut:before {
    content: "";
}
.ti-ruler:before {
    content: "";
}
.ti-ruler-pencil:before {
    content: "";
}
.ti-ruler-alt:before {
    content: "";
}
.ti-bookmark:before {
    content: "";
}
.ti-bookmark-alt:before {
    content: "";
}
.ti-reload:before {
    content: "";
}
.ti-plus:before {
    content: "";
}
.ti-pin:before {
    content: "";
}
.ti-pencil:before {
    content: "";
}
.ti-pencil-alt:before {
    content: "";
}
.ti-paint-roller:before {
    content: "";
}
.ti-paint-bucket:before {
    content: "";
}
.ti-na:before {
    content: "";
}
.ti-mobile:before {
    content: "";
}
.ti-minus:before {
    content: "";
}
.ti-medall:before {
    content: "";
}
.ti-medall-alt:before {
    content: "";
}
.ti-marker:before {
    content: "";
}
.ti-marker-alt:before {
    content: "";
}
.ti-arrow-up:before {
    content: "";
}
.ti-arrow-right:before {
    content: "";
}
.ti-arrow-left:before {
    content: "";
}
.ti-arrow-down:before {
    content: "";
}
.ti-lock:before {
    content: "";
}
.ti-location-arrow:before {
    content: "";
}
.ti-link:before {
    content: "";
}
.ti-layout:before {
    content: "";
}
.ti-layers:before {
    content: "";
}
.ti-layers-alt:before {
    content: "";
}
.ti-key:before {
    content: "";
}
.ti-import:before {
    content: "";
}
.ti-image:before {
    content: "";
}
.ti-heart:before {
    content: "";
}
.ti-heart-broken:before {
    content: "";
}
.ti-hand-stop:before {
    content: "";
}
.ti-hand-open:before {
    content: "";
}
.ti-hand-drag:before {
    content: "";
}
.ti-folder:before {
    content: "";
}
.ti-flag:before {
    content: "";
}
.ti-flag-alt:before {
    content: "";
}
.ti-flag-alt-2:before {
    content: "";
}
.ti-eye:before {
    content: "";
}
.ti-export:before {
    content: "";
}
.ti-exchange-vertical:before {
    content: "";
}
.ti-desktop:before {
    content: "";
}
.ti-cup:before {
    content: "";
}
.ti-crown:before {
    content: "";
}
.ti-comments:before {
    content: "";
}
.ti-comment:before {
    content: "";
}
.ti-comment-alt:before {
    content: "";
}
.ti-close:before {
    content: "";
}
.ti-clip:before {
    content: "";
}
.ti-angle-up:before {
    content: "";
}
.ti-angle-right:before {
    content: "";
}
.ti-angle-left:before {
    content: "";
}
.ti-angle-down:before {
    content: "";
}
.ti-check:before {
    content: "";
}
.ti-check-box:before {
    content: "";
}
.ti-camera:before {
    content: "";
}
.ti-announcement:before {
    content: "";
}
.ti-brush:before {
    content: "";
}
.ti-briefcase:before {
    content: "";
}
.ti-bolt:before {
    content: "";
}
.ti-bolt-alt:before {
    content: "";
}
.ti-blackboard:before {
    content: "";
}
.ti-bag:before {
    content: "";
}
.ti-move:before {
    content: "";
}
.ti-arrows-vertical:before {
    content: "";
}
.ti-arrows-horizontal:before {
    content: "";
}
.ti-fullscreen:before {
    content: "";
}
.ti-arrow-top-right:before {
    content: "";
}
.ti-arrow-top-left:before {
    content: "";
}
.ti-arrow-circle-up:before {
    content: "";
}
.ti-arrow-circle-right:before {
    content: "";
}
.ti-arrow-circle-left:before {
    content: "";
}
.ti-arrow-circle-down:before {
    content: "";
}
.ti-angle-double-up:before {
    content: "";
}
.ti-angle-double-right:before {
    content: "";
}
.ti-angle-double-left:before {
    content: "";
}
.ti-angle-double-down:before {
    content: "";
}
.ti-zip:before {
    content: "";
}
.ti-world:before {
    content: "";
}
.ti-wheelchair:before {
    content: "";
}
.ti-view-list:before {
    content: "";
}
.ti-view-list-alt:before {
    content: "";
}
.ti-view-grid:before {
    content: "";
}
.ti-uppercase:before {
    content: "";
}
.ti-upload:before {
    content: "";
}
.ti-underline:before {
    content: "";
}
.ti-truck:before {
    content: "";
}
.ti-timer:before {
    content: "";
}
.ti-ticket:before {
    content: "";
}
.ti-thumb-up:before {
    content: "";
}
.ti-thumb-down:before {
    content: "";
}
.ti-text:before {
    content: "";
}
.ti-stats-up:before {
    content: "";
}
.ti-stats-down:before {
    content: "";
}
.ti-split-v:before {
    content: "";
}
.ti-split-h:before {
    content: "";
}
.ti-smallcap:before {
    content: "";
}
.ti-shine:before {
    content: "";
}
.ti-shift-right:before {
    content: "";
}
.ti-shift-left:before {
    content: "";
}
.ti-shield:before {
    content: "";
}
.ti-notepad:before {
    content: "";
}
.ti-server:before {
    content: "";
}
.ti-quote-right:before {
    content: "";
}
.ti-quote-left:before {
    content: "";
}
.ti-pulse:before {
    content: "";
}
.ti-printer:before {
    content: "";
}
.ti-power-off:before {
    content: "";
}
.ti-plug:before {
    content: "";
}
.ti-pie-chart:before {
    content: "";
}
.ti-paragraph:before {
    content: "";
}
.ti-panel:before {
    content: "";
}
.ti-package:before {
    content: "";
}
.ti-music:before {
    content: "";
}
.ti-music-alt:before {
    content: "";
}
.ti-mouse:before {
    content: "";
}
.ti-mouse-alt:before {
    content: "";
}
.ti-money:before {
    content: "";
}
.ti-microphone:before {
    content: "";
}
.ti-menu:before {
    content: "";
}
.ti-menu-alt:before {
    content: "";
}
.ti-map:before {
    content: "";
}
.ti-map-alt:before {
    content: "";
}
.ti-loop:before {
    content: "";
}
.ti-location-pin:before {
    content: "";
}
.ti-list:before {
    content: "";
}
.ti-light-bulb:before {
    content: "";
}
.ti-Italic:before {
    content: "";
}
.ti-info:before {
    content: "";
}
.ti-infinite:before {
    content: "";
}
.ti-id-badge:before {
    content: "";
}
.ti-hummer:before {
    content: "";
}
.ti-home:before {
    content: "";
}
.ti-help:before {
    content: "";
}
.ti-headphone:before {
    content: "";
}
.ti-harddrives:before {
    content: "";
}
.ti-harddrive:before {
    content: "";
}
.ti-gift:before {
    content: "";
}
.ti-game:before {
    content: "";
}
.ti-filter:before {
    content: "";
}
.ti-files:before {
    content: "";
}
.ti-file:before {
    content: "";
}
.ti-eraser:before {
    content: "";
}
.ti-envelope:before {
    content: "";
}
.ti-download:before {
    content: "";
}
.ti-direction:before {
    content: "";
}
.ti-direction-alt:before {
    content: "";
}
.ti-dashboard:before {
    content: "";
}
.ti-control-stop:before {
    content: "";
}
.ti-control-shuffle:before {
    content: "";
}
.ti-control-play:before {
    content: "";
}
.ti-control-pause:before {
    content: "";
}
.ti-control-forward:before {
    content: "";
}
.ti-control-backward:before {
    content: "";
}
.ti-cloud:before {
    content: "";
}
.ti-cloud-up:before {
    content: "";
}
.ti-cloud-down:before {
    content: "";
}
.ti-clipboard:before {
    content: "";
}
.ti-car:before {
    content: "";
}
.ti-calendar:before {
    content: "";
}
.ti-book:before {
    content: "";
}
.ti-bell:before {
    content: "";
}
.ti-basketball:before {
    content: "";
}
.ti-bar-chart:before {
    content: "";
}
.ti-bar-chart-alt:before {
    content: "";
}
.ti-back-right:before {
    content: "";
}
.ti-back-left:before {
    content: "";
}
.ti-arrows-corner:before {
    content: "";
}
.ti-archive:before {
    content: "";
}
.ti-anchor:before {
    content: "";
}
.ti-align-right:before {
    content: "";
}
.ti-align-left:before {
    content: "";
}
.ti-align-justify:before {
    content: "";
}
.ti-align-center:before {
    content: "";
}
.ti-alert:before {
    content: "";
}
.ti-alarm-clock:before {
    content: "";
}
.ti-agenda:before {
    content: "";
}
.ti-write:before {
    content: "";
}
.ti-window:before {
    content: "";
}
.ti-widgetized:before {
    content: "";
}
.ti-widget:before {
    content: "";
}
.ti-widget-alt:before {
    content: "";
}
.ti-wallet:before {
    content: "";
}
.ti-video-clapper:before {
    content: "";
}
.ti-video-camera:before {
    content: "";
}
.ti-vector:before {
    content: "";
}
.ti-themify-logo:before {
    content: "";
}
.ti-themify-favicon:before {
    content: "";
}
.ti-themify-favicon-alt:before {
    content: "";
}
.ti-support:before {
    content: "";
}
.ti-stamp:before {
    content: "";
}
.ti-split-v-alt:before {
    content: "";
}
.ti-slice:before {
    content: "";
}
.ti-shortcode:before {
    content: "";
}
.ti-shift-right-alt:before {
    content: "";
}
.ti-shift-left-alt:before {
    content: "";
}
.ti-ruler-alt-2:before {
    content: "";
}
.ti-receipt:before {
    content: "";
}
.ti-pin2:before {
    content: "";
}
.ti-pin-alt:before {
    content: "";
}
.ti-pencil-alt2:before {
    content: "";
}
.ti-palette:before {
    content: "";
}
.ti-more:before {
    content: "";
}
.ti-more-alt:before {
    content: "";
}
.ti-microphone-alt:before {
    content: "";
}
.ti-magnet:before {
    content: "";
}
.ti-line-double:before {
    content: "";
}
.ti-line-dotted:before {
    content: "";
}
.ti-line-dashed:before {
    content: "";
}
.ti-layout-width-full:before {
    content: "";
}
.ti-layout-width-default:before {
    content: "";
}
.ti-layout-width-default-alt:before {
    content: "";
}
.ti-layout-tab:before {
    content: "";
}
.ti-layout-tab-window:before {
    content: "";
}
.ti-layout-tab-v:before {
    content: "";
}
.ti-layout-tab-min:before {
    content: "";
}
.ti-layout-slider:before {
    content: "";
}
.ti-layout-slider-alt:before {
    content: "";
}
.ti-layout-sidebar-right:before {
    content: "";
}
.ti-layout-sidebar-none:before {
    content: "";
}
.ti-layout-sidebar-left:before {
    content: "";
}
.ti-layout-placeholder:before {
    content: "";
}
.ti-layout-menu:before {
    content: "";
}
.ti-layout-menu-v:before {
    content: "";
}
.ti-layout-menu-separated:before {
    content: "";
}
.ti-layout-menu-full:before {
    content: "";
}
.ti-layout-media-right-alt:before {
    content: "";
}
.ti-layout-media-right:before {
    content: "";
}
.ti-layout-media-overlay:before {
    content: "";
}
.ti-layout-media-overlay-alt:before {
    content: "";
}
.ti-layout-media-overlay-alt-2:before {
    content: "";
}
.ti-layout-media-left-alt:before {
    content: "";
}
.ti-layout-media-left:before {
    content: "";
}
.ti-layout-media-center-alt:before {
    content: "";
}
.ti-layout-media-center:before {
    content: "";
}
.ti-layout-list-thumb:before {
    content: "";
}
.ti-layout-list-thumb-alt:before {
    content: "";
}
.ti-layout-list-post:before {
    content: "";
}
.ti-layout-list-large-image:before {
    content: "";
}
.ti-layout-line-solid:before {
    content: "";
}
.ti-layout-grid4:before {
    content: "";
}
.ti-layout-grid3:before {
    content: "";
}
.ti-layout-grid2:before {
    content: "";
}
.ti-layout-grid2-thumb:before {
    content: "";
}
.ti-layout-cta-right:before {
    content: "";
}
.ti-layout-cta-left:before {
    content: "";
}
.ti-layout-cta-center:before {
    content: "";
}
.ti-layout-cta-btn-right:before {
    content: "";
}
.ti-layout-cta-btn-left:before {
    content: "";
}
.ti-layout-column4:before {
    content: "";
}
.ti-layout-column3:before {
    content: "";
}
.ti-layout-column2:before {
    content: "";
}
.ti-layout-accordion-separated:before {
    content: "";
}
.ti-layout-accordion-merged:before {
    content: "";
}
.ti-layout-accordion-list:before {
    content: "";
}
.ti-ink-pen:before {
    content: "";
}
.ti-info-alt:before {
    content: "";
}
.ti-help-alt:before {
    content: "";
}
.ti-headphone-alt:before {
    content: "";
}
.ti-hand-point-up:before {
    content: "";
}
.ti-hand-point-right:before {
    content: "";
}
.ti-hand-point-left:before {
    content: "";
}
.ti-hand-point-down:before {
    content: "";
}
.ti-gallery:before {
    content: "";
}
.ti-face-smile:before {
    content: "";
}
.ti-face-sad:before {
    content: "";
}
.ti-credit-card:before {
    content: "";
}
.ti-control-skip-forward:before {
    content: "";
}
.ti-control-skip-backward:before {
    content: "";
}
.ti-control-record:before {
    content: "";
}
.ti-control-eject:before {
    content: "";
}
.ti-comments-smiley:before {
    content: "";
}
.ti-brush-alt:before {
    content: "";
}
.ti-youtube:before {
    content: "";
}
.ti-vimeo:before {
    content: "";
}
.ti-twitter:before {
    content: "";
}
.ti-time:before {
    content: "";
}
.ti-tumblr:before {
    content: "";
}
.ti-skype:before {
    content: "";
}
.ti-share:before {
    content: "";
}
.ti-share-alt:before {
    content: "";
}
.ti-rocket:before {
    content: "";
}
.ti-pinterest:before {
    content: "";
}
.ti-new-window:before {
    content: "";
}
.ti-microsoft:before {
    content: "";
}
.ti-list-ol:before {
    content: "";
}
.ti-linkedin:before {
    content: "";
}
.ti-layout-sidebar-2:before {
    content: "";
}
.ti-layout-grid4-alt:before {
    content: "";
}
.ti-layout-grid3-alt:before {
    content: "";
}
.ti-layout-grid2-alt:before {
    content: "";
}
.ti-layout-column4-alt:before {
    content: "";
}
.ti-layout-column3-alt:before {
    content: "";
}
.ti-layout-column2-alt:before {
    content: "";
}
.ti-instagram:before {
    content: "";
}
.ti-google:before {
    content: "";
}
.ti-github:before {
    content: "";
}
.ti-flickr:before {
    content: "";
}
.ti-facebook:before {
    content: "";
}
.ti-dropbox:before {
    content: "";
}
.ti-dribbble:before {
    content: "";
}
.ti-apple:before {
    content: "";
}
.ti-android:before {
    content: "";
}
.ti-save:before {
    content: "";
}
.ti-save-alt:before {
    content: "";
}
.ti-yahoo:before {
    content: "";
}
.ti-wordpress:before {
    content: "";
}
.ti-vimeo-alt:before {
    content: "";
}
.ti-twitter-alt:before {
    content: "";
}
.ti-tumblr-alt:before {
    content: "";
}
.ti-trello:before {
    content: "";
}
.ti-stack-overflow:before {
    content: "";
}
.ti-soundcloud:before {
    content: "";
}
.ti-sharethis:before {
    content: "";
}
.ti-sharethis-alt:before {
    content: "";
}
.ti-reddit:before {
    content: "";
}
.ti-pinterest-alt:before {
    content: "";
}
.ti-microsoft-alt:before {
    content: "";
}
.ti-linux:before {
    content: "";
}
.ti-jsfiddle:before {
    content: "";
}
.ti-joomla:before {
    content: "";
}
.ti-html5:before {
    content: "";
}
.ti-flickr-alt:before {
    content: "";
}
.ti-email:before {
    content: "";
}
.ti-drupal:before {
    content: "";
}
.ti-dropbox-alt:before {
    content: "";
}
.ti-css3:before {
    content: "";
}
.ti-rss:before {
    content: "";
}
.ti-rss-alt:before {
    content: "";
}
@font-face {
    font-family: "dripicons-v2";
    src: url("../fonts/dripicons-v2.eot");
    src: url("../fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../fonts/dripicons-v2.woff") format("woff"),
        url("../fonts/dripicons-v2.ttf") format("truetype"), url("../fonts/dripicons-v2.svg#dripicons-v2") format("svg");
    font-weight: normal;
    font-style: normal;
}
[data-icon]:before {
    font-family: "dripicons-v2" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
[class^="dripicons-"]:before,
[class*=" dripicons-"]:before {
    font-family: "dripicons-v2" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.dripicons-alarm:before {
    content: "a";
}
.dripicons-align-center:before {
    content: "b";
}
.dripicons-align-justify:before {
    content: "c";
}
.dripicons-align-left:before {
    content: "d";
}
.dripicons-align-right:before {
    content: "e";
}
.dripicons-anchor:before {
    content: "f";
}
.dripicons-archive:before {
    content: "g";
}
.dripicons-arrow-down:before {
    content: "h";
}
.dripicons-arrow-left:before {
    content: "i";
}
.dripicons-arrow-right:before {
    content: "j";
}
.dripicons-arrow-thin-down:before {
    content: "k";
}
.dripicons-arrow-thin-left:before {
    content: "l";
}
.dripicons-arrow-thin-right:before {
    content: "m";
}
.dripicons-arrow-thin-up:before {
    content: "n";
}
.dripicons-arrow-up:before {
    content: "o";
}
.dripicons-article:before {
    content: "p";
}
.dripicons-backspace:before {
    content: "q";
}
.dripicons-basket:before {
    content: "r";
}
.dripicons-basketball:before {
    content: "s";
}
.dripicons-battery-empty:before {
    content: "t";
}
.dripicons-battery-full:before {
    content: "u";
}
.dripicons-battery-low:before {
    content: "v";
}
.dripicons-battery-medium:before {
    content: "w";
}
.dripicons-bell:before {
    content: "x";
}
.dripicons-blog:before {
    content: "y";
}
.dripicons-bluetooth:before {
    content: "z";
}
.dripicons-bold:before {
    content: "A";
}
.dripicons-bookmark:before {
    content: "B";
}
.dripicons-bookmarks:before {
    content: "C";
}
.dripicons-box:before {
    content: "D";
}
.dripicons-briefcase:before {
    content: "E";
}
.dripicons-brightness-low:before {
    content: "F";
}
.dripicons-brightness-max:before {
    content: "G";
}
.dripicons-brightness-medium:before {
    content: "H";
}
.dripicons-broadcast:before {
    content: "I";
}
.dripicons-browser:before {
    content: "J";
}
.dripicons-browser-upload:before {
    content: "K";
}
.dripicons-brush:before {
    content: "L";
}
.dripicons-calendar:before {
    content: "M";
}
.dripicons-camcorder:before {
    content: "N";
}
.dripicons-camera:before {
    content: "O";
}
.dripicons-card:before {
    content: "P";
}
.dripicons-cart:before {
    content: "Q";
}
.dripicons-checklist:before {
    content: "R";
}
.dripicons-checkmark:before {
    content: "S";
}
.dripicons-chevron-down:before {
    content: "T";
}
.dripicons-chevron-left:before {
    content: "U";
}
.dripicons-chevron-right:before {
    content: "V";
}
.dripicons-chevron-up:before {
    content: "W";
}
.dripicons-clipboard:before {
    content: "X";
}
.dripicons-clock:before {
    content: "Y";
}
.dripicons-clockwise:before {
    content: "Z";
}
.dripicons-cloud:before {
    content: "0";
}
.dripicons-cloud-download:before {
    content: "1";
}
.dripicons-cloud-upload:before {
    content: "2";
}
.dripicons-code:before {
    content: "3";
}
.dripicons-contract:before {
    content: "4";
}
.dripicons-contract-2:before {
    content: "5";
}
.dripicons-conversation:before {
    content: "6";
}
.dripicons-copy:before {
    content: "7";
}
.dripicons-crop:before {
    content: "8";
}
.dripicons-cross:before {
    content: "9";
}
.dripicons-crosshair:before {
    content: "!";
}
.dripicons-cutlery:before {
    content: '"';
}
.dripicons-device-desktop:before {
    content: "#";
}
.dripicons-device-mobile:before {
    content: "$";
}
.dripicons-device-tablet:before {
    content: "%";
}
.dripicons-direction:before {
    content: "&";
}
.dripicons-disc:before {
    content: "'";
}
.dripicons-document:before {
    content: "(";
}
.dripicons-document-delete:before {
    content: ")";
}
.dripicons-document-edit:before {
    content: "*";
}
.dripicons-document-new:before {
    content: "+";
}
.dripicons-document-remove:before {
    content: ",";
}
.dripicons-dot:before {
    content: "-";
}
.dripicons-dots-2:before {
    content: ".";
}
.dripicons-dots-3:before {
    content: "/";
}
.dripicons-download:before {
    content: ":";
}
.dripicons-duplicate:before {
    content: ";";
}
.dripicons-enter:before {
    content: "<";
}
.dripicons-exit:before {
    content: "=";
}
.dripicons-expand:before {
    content: ">";
}
.dripicons-expand-2:before {
    content: "?";
}
.dripicons-experiment:before {
    content: "@";
}
.dripicons-export:before {
    content: "[";
}
.dripicons-feed:before {
    content: "]";
}
.dripicons-flag:before {
    content: "^";
}
.dripicons-flashlight:before {
    content: "_";
}
.dripicons-folder:before {
    content: "`";
}
.dripicons-folder-open:before {
    content: "{";
}
.dripicons-forward:before {
    content: "|";
}
.dripicons-gaming:before {
    content: "}";
}
.dripicons-gear:before {
    content: "~";
}
.dripicons-graduation:before {
    content: "\\";
}
.dripicons-graph-bar:before {
    content: "";
}
.dripicons-graph-line:before {
    content: "";
}
.dripicons-graph-pie:before {
    content: "";
}
.dripicons-headset:before {
    content: "";
}
.dripicons-heart:before {
    content: "";
}
.dripicons-help:before {
    content: "";
}
.dripicons-home:before {
    content: "";
}
.dripicons-hourglass:before {
    content: "";
}
.dripicons-inbox:before {
    content: "";
}
.dripicons-information:before {
    content: "";
}
.dripicons-italic:before {
    content: "";
}
.dripicons-jewel:before {
    content: "";
}
.dripicons-lifting:before {
    content: "";
}
.dripicons-lightbulb:before {
    content: "";
}
.dripicons-link:before {
    content: "";
}
.dripicons-link-broken:before {
    content: "";
}
.dripicons-list:before {
    content: "";
}
.dripicons-loading:before {
    content: "";
}
.dripicons-location:before {
    content: "";
}
.dripicons-lock:before {
    content: "";
}
.dripicons-lock-open:before {
    content: "";
}
.dripicons-mail:before {
    content: "";
}
.dripicons-map:before {
    content: "";
}
.dripicons-media-loop:before {
    content: "";
}
.dripicons-media-next:before {
    content: "";
}
.dripicons-media-pause:before {
    content: "";
}
.dripicons-media-play:before {
    content: "";
}
.dripicons-media-previous:before {
    content: "";
}
.dripicons-media-record:before {
    content: "";
}
.dripicons-media-shuffle:before {
    content: "";
}
.dripicons-media-stop:before {
    content: "";
}
.dripicons-medical:before {
    content: "";
}
.dripicons-menu:before {
    content: "";
}
.dripicons-message:before {
    content: "";
}
.dripicons-meter:before {
    content: "";
}
.dripicons-microphone:before {
    content: "";
}
.dripicons-minus:before {
    content: "";
}
.dripicons-monitor:before {
    content: "";
}
.dripicons-move:before {
    content: "";
}
.dripicons-music:before {
    content: "";
}
.dripicons-network-1:before {
    content: "";
}
.dripicons-network-2:before {
    content: "";
}
.dripicons-network-3:before {
    content: "";
}
.dripicons-network-4:before {
    content: "";
}
.dripicons-network-5:before {
    content: "";
}
.dripicons-pamphlet:before {
    content: "";
}
.dripicons-paperclip:before {
    content: "";
}
.dripicons-pencil:before {
    content: "";
}
.dripicons-phone:before {
    content: "";
}
.dripicons-photo:before {
    content: "";
}
.dripicons-photo-group:before {
    content: "";
}
.dripicons-pill:before {
    content: "";
}
.dripicons-pin:before {
    content: "";
}
.dripicons-plus:before {
    content: "";
}
.dripicons-power:before {
    content: "";
}
.dripicons-preview:before {
    content: "";
}
.dripicons-print:before {
    content: "";
}
.dripicons-pulse:before {
    content: "";
}
.dripicons-question:before {
    content: "";
}
.dripicons-reply:before {
    content: "";
}
.dripicons-reply-all:before {
    content: "";
}
.dripicons-return:before {
    content: "";
}
.dripicons-retweet:before {
    content: "";
}
.dripicons-rocket:before {
    content: "";
}
.dripicons-scale:before {
    content: "";
}
.dripicons-search:before {
    content: "";
}
.dripicons-shopping-bag:before {
    content: "";
}
.dripicons-skip:before {
    content: "";
}
.dripicons-stack:before {
    content: "";
}
.dripicons-star:before {
    content: "";
}
.dripicons-stopwatch:before {
    content: "";
}
.dripicons-store:before {
    content: "";
}
.dripicons-suitcase:before {
    content: "";
}
.dripicons-swap:before {
    content: "";
}
.dripicons-tag:before {
    content: "";
}
.dripicons-tag-delete:before {
    content: "";
}
.dripicons-tags:before {
    content: "";
}
.dripicons-thumbs-down:before {
    content: "";
}
.dripicons-thumbs-up:before {
    content: "";
}
.dripicons-ticket:before {
    content: "";
}
.dripicons-time-reverse:before {
    content: "";
}
.dripicons-to-do:before {
    content: "";
}
.dripicons-toggles:before {
    content: "";
}
.dripicons-trash:before {
    content: "";
}
.dripicons-trophy:before {
    content: "";
}
.dripicons-upload:before {
    content: "";
}
.dripicons-user:before {
    content: "";
}
.dripicons-user-group:before {
    content: "";
}
.dripicons-user-id:before {
    content: "";
}
.dripicons-vibrate:before {
    content: "";
}
.dripicons-view-apps:before {
    content: "";
}
.dripicons-view-list:before {
    content: "";
}
.dripicons-view-list-large:before {
    content: "";
}
.dripicons-view-thumb:before {
    content: "";
}
.dripicons-volume-full:before {
    content: "";
}
.dripicons-volume-low:before {
    content: "";
}
.dripicons-volume-medium:before {
    content: "";
}
.dripicons-volume-off:before {
    content: "";
}
.dripicons-wallet:before {
    content: "";
}
.dripicons-warning:before {
    content: "";
}
.dripicons-web:before {
    content: "";
}
.dripicons-weight:before {
    content: "";
}
.dripicons-wifi:before {
    content: "";
}
.dripicons-wrong:before {
    content: "";
}
.dripicons-zoom-in:before {
    content: "";
}
.dripicons-zoom-out:before {
    content: "";
}
@font-face {
    font-family: "typicons";
    font-weight: normal;
    font-style: normal;
    src: url("../fonts/typicons.eot");
    src: url("../fonts/typicons.eot?#iefix") format("embedded-opentype"), url("../fonts/typicons.woff") format("woff"),
        url("../fonts/typicons.ttf") format("truetype"), url("../fonts/typicons.svg#typicons") format("svg");
}
.typcn:before {
    font-family: "typicons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    height: 1em;
    font-size: 1em;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
.typcn-adjust-brightness:before {
    content: "";
}
.typcn-adjust-contrast:before {
    content: "";
}
.typcn-anchor-outline:before {
    content: "";
}
.typcn-anchor:before {
    content: "";
}
.typcn-archive:before {
    content: "";
}
.typcn-arrow-back-outline:before {
    content: "";
}
.typcn-arrow-back:before {
    content: "";
}
.typcn-arrow-down-outline:before {
    content: "";
}
.typcn-arrow-down-thick:before {
    content: "";
}
.typcn-arrow-down:before {
    content: "";
}
.typcn-arrow-forward-outline:before {
    content: "";
}
.typcn-arrow-forward:before {
    content: "";
}
.typcn-arrow-left-outline:before {
    content: "";
}
.typcn-arrow-left-thick:before {
    content: "";
}
.typcn-arrow-left:before {
    content: "";
}
.typcn-arrow-loop-outline:before {
    content: "";
}
.typcn-arrow-loop:before {
    content: "";
}
.typcn-arrow-maximise-outline:before {
    content: "";
}
.typcn-arrow-maximise:before {
    content: "";
}
.typcn-arrow-minimise-outline:before {
    content: "";
}
.typcn-arrow-minimise:before {
    content: "";
}
.typcn-arrow-move-outline:before {
    content: "";
}
.typcn-arrow-move:before {
    content: "";
}
.typcn-arrow-repeat-outline:before {
    content: "";
}
.typcn-arrow-repeat:before {
    content: "";
}
.typcn-arrow-right-outline:before {
    content: "";
}
.typcn-arrow-right-thick:before {
    content: "";
}
.typcn-arrow-right:before {
    content: "";
}
.typcn-arrow-shuffle:before {
    content: "";
}
.typcn-arrow-sorted-down:before {
    content: "";
}
.typcn-arrow-sorted-up:before {
    content: "";
}
.typcn-arrow-sync-outline:before {
    content: "";
}
.typcn-arrow-sync:before {
    content: "";
}
.typcn-arrow-unsorted:before {
    content: "";
}
.typcn-arrow-up-outline:before {
    content: "";
}
.typcn-arrow-up-thick:before {
    content: "";
}
.typcn-arrow-up:before {
    content: "";
}
.typcn-at:before {
    content: "";
}
.typcn-attachment-outline:before {
    content: "";
}
.typcn-attachment:before {
    content: "";
}
.typcn-backspace-outline:before {
    content: "";
}
.typcn-backspace:before {
    content: "";
}
.typcn-battery-charge:before {
    content: "";
}
.typcn-battery-full:before {
    content: "";
}
.typcn-battery-high:before {
    content: "";
}
.typcn-battery-low:before {
    content: "";
}
.typcn-battery-mid:before {
    content: "";
}
.typcn-beaker:before {
    content: "";
}
.typcn-beer:before {
    content: "";
}
.typcn-bell:before {
    content: "";
}
.typcn-book:before {
    content: "";
}
.typcn-bookmark:before {
    content: "";
}
.typcn-briefcase:before {
    content: "";
}
.typcn-brush:before {
    content: "";
}
.typcn-business-card:before {
    content: "";
}
.typcn-calculator:before {
    content: "";
}
.typcn-calendar-outline:before {
    content: "";
}
.typcn-calendar:before {
    content: "";
}
.typcn-camera-outline:before {
    content: "";
}
.typcn-camera:before {
    content: "";
}
.typcn-cancel-outline:before {
    content: "";
}
.typcn-cancel:before {
    content: "";
}
.typcn-chart-area-outline:before {
    content: "";
}
.typcn-chart-area:before {
    content: "";
}
.typcn-chart-bar-outline:before {
    content: "";
}
.typcn-chart-bar:before {
    content: "";
}
.typcn-chart-line-outline:before {
    content: "";
}
.typcn-chart-line:before {
    content: "";
}
.typcn-chart-pie-outline:before {
    content: "";
}
.typcn-chart-pie:before {
    content: "";
}
.typcn-chevron-left-outline:before {
    content: "";
}
.typcn-chevron-left:before {
    content: "";
}
.typcn-chevron-right-outline:before {
    content: "";
}
.typcn-chevron-right:before {
    content: "";
}
.typcn-clipboard:before {
    content: "";
}
.typcn-cloud-storage:before {
    content: "";
}
.typcn-cloud-storage-outline:before {
    content: "";
}
.typcn-code-outline:before {
    content: "";
}
.typcn-code:before {
    content: "";
}
.typcn-coffee:before {
    content: "";
}
.typcn-cog-outline:before {
    content: "";
}
.typcn-cog:before {
    content: "";
}
.typcn-compass:before {
    content: "";
}
.typcn-contacts:before {
    content: "";
}
.typcn-credit-card:before {
    content: "";
}
.typcn-css3:before {
    content: "";
}
.typcn-database:before {
    content: "";
}
.typcn-delete-outline:before {
    content: "";
}
.typcn-delete:before {
    content: "";
}
.typcn-device-desktop:before {
    content: "";
}
.typcn-device-laptop:before {
    content: "";
}
.typcn-device-phone:before {
    content: "";
}
.typcn-device-tablet:before {
    content: "";
}
.typcn-directions:before {
    content: "";
}
.typcn-divide-outline:before {
    content: "";
}
.typcn-divide:before {
    content: "";
}
.typcn-document-add:before {
    content: "";
}
.typcn-document-delete:before {
    content: "";
}
.typcn-document-text:before {
    content: "";
}
.typcn-document:before {
    content: "";
}
.typcn-download-outline:before {
    content: "";
}
.typcn-download:before {
    content: "";
}
.typcn-dropbox:before {
    content: "";
}
.typcn-edit:before {
    content: "";
}
.typcn-eject-outline:before {
    content: "";
}
.typcn-eject:before {
    content: "";
}
.typcn-equals-outline:before {
    content: "";
}
.typcn-equals:before {
    content: "";
}
.typcn-export-outline:before {
    content: "";
}
.typcn-export:before {
    content: "";
}
.typcn-eye-outline:before {
    content: "";
}
.typcn-eye:before {
    content: "";
}
.typcn-feather:before {
    content: "";
}
.typcn-film:before {
    content: "";
}
.typcn-filter:before {
    content: "";
}
.typcn-flag-outline:before {
    content: "";
}
.typcn-flag:before {
    content: "";
}
.typcn-flash-outline:before {
    content: "";
}
.typcn-flash:before {
    content: "";
}
.typcn-flow-children:before {
    content: "";
}
.typcn-flow-merge:before {
    content: "";
}
.typcn-flow-parallel:before {
    content: "";
}
.typcn-flow-switch:before {
    content: "";
}
.typcn-folder-add:before {
    content: "";
}
.typcn-folder-delete:before {
    content: "";
}
.typcn-folder-open:before {
    content: "";
}
.typcn-folder:before {
    content: "";
}
.typcn-gift:before {
    content: "";
}
.typcn-globe-outline:before {
    content: "";
}
.typcn-globe:before {
    content: "";
}
.typcn-group-outline:before {
    content: "";
}
.typcn-group:before {
    content: "";
}
.typcn-headphones:before {
    content: "";
}
.typcn-heart-full-outline:before {
    content: "";
}
.typcn-heart-half-outline:before {
    content: "";
}
.typcn-heart-outline:before {
    content: "";
}
.typcn-heart:before {
    content: "";
}
.typcn-home-outline:before {
    content: "";
}
.typcn-home:before {
    content: "";
}
.typcn-html5:before {
    content: "";
}
.typcn-image-outline:before {
    content: "";
}
.typcn-image:before {
    content: "";
}
.typcn-infinity-outline:before {
    content: "";
}
.typcn-infinity:before {
    content: "";
}
.typcn-info-large-outline:before {
    content: "";
}
.typcn-info-large:before {
    content: "";
}
.typcn-info-outline:before {
    content: "";
}
.typcn-info:before {
    content: "";
}
.typcn-input-checked-outline:before {
    content: "";
}
.typcn-input-checked:before {
    content: "";
}
.typcn-key-outline:before {
    content: "";
}
.typcn-key:before {
    content: "";
}
.typcn-keyboard:before {
    content: "";
}
.typcn-leaf:before {
    content: "";
}
.typcn-lightbulb:before {
    content: "";
}
.typcn-link-outline:before {
    content: "";
}
.typcn-link:before {
    content: "";
}
.typcn-location-arrow-outline:before {
    content: "";
}
.typcn-location-arrow:before {
    content: "";
}
.typcn-location-outline:before {
    content: "";
}
.typcn-location:before {
    content: "";
}
.typcn-lock-closed-outline:before {
    content: "";
}
.typcn-lock-closed:before {
    content: "";
}
.typcn-lock-open-outline:before {
    content: "";
}
.typcn-lock-open:before {
    content: "";
}
.typcn-mail:before {
    content: "";
}
.typcn-map:before {
    content: "";
}
.typcn-media-eject-outline:before {
    content: "";
}
.typcn-media-eject:before {
    content: "";
}
.typcn-media-fast-forward-outline:before {
    content: "";
}
.typcn-media-fast-forward:before {
    content: "";
}
.typcn-media-pause-outline:before {
    content: "";
}
.typcn-media-pause:before {
    content: "";
}
.typcn-media-play-outline:before {
    content: "";
}
.typcn-media-play-reverse-outline:before {
    content: "";
}
.typcn-media-play-reverse:before {
    content: "";
}
.typcn-media-play:before {
    content: "";
}
.typcn-media-record-outline:before {
    content: "";
}
.typcn-media-record:before {
    content: "";
}
.typcn-media-rewind-outline:before {
    content: "";
}
.typcn-media-rewind:before {
    content: "";
}
.typcn-media-stop-outline:before {
    content: "";
}
.typcn-media-stop:before {
    content: "";
}
.typcn-message-typing:before {
    content: "";
}
.typcn-message:before {
    content: "";
}
.typcn-messages:before {
    content: "";
}
.typcn-microphone-outline:before {
    content: "";
}
.typcn-microphone:before {
    content: "";
}
.typcn-minus-outline:before {
    content: "";
}
.typcn-minus:before {
    content: "";
}
.typcn-mortar-board:before {
    content: "";
}
.typcn-news:before {
    content: "";
}
.typcn-notes-outline:before {
    content: "";
}
.typcn-notes:before {
    content: "";
}
.typcn-pen:before {
    content: "";
}
.typcn-pencil:before {
    content: "";
}
.typcn-phone-outline:before {
    content: "";
}
.typcn-phone:before {
    content: "";
}
.typcn-pi-outline:before {
    content: "";
}
.typcn-pi:before {
    content: "";
}
.typcn-pin-outline:before {
    content: "";
}
.typcn-pin:before {
    content: "";
}
.typcn-pipette:before {
    content: "";
}
.typcn-plane-outline:before {
    content: "";
}
.typcn-plane:before {
    content: "";
}
.typcn-plug:before {
    content: "";
}
.typcn-plus-outline:before {
    content: "";
}
.typcn-plus:before {
    content: "";
}
.typcn-point-of-interest-outline:before {
    content: "";
}
.typcn-point-of-interest:before {
    content: "";
}
.typcn-power-outline:before {
    content: "";
}
.typcn-power:before {
    content: "";
}
.typcn-printer:before {
    content: "";
}
.typcn-puzzle-outline:before {
    content: "";
}
.typcn-puzzle:before {
    content: "";
}
.typcn-radar-outline:before {
    content: "";
}
.typcn-radar:before {
    content: "";
}
.typcn-refresh-outline:before {
    content: "";
}
.typcn-refresh:before {
    content: "";
}
.typcn-rss-outline:before {
    content: "";
}
.typcn-rss:before {
    content: "";
}
.typcn-scissors-outline:before {
    content: "";
}
.typcn-scissors:before {
    content: "";
}
.typcn-shopping-bag:before {
    content: "";
}
.typcn-shopping-cart:before {
    content: "";
}
.typcn-social-at-circular:before {
    content: "";
}
.typcn-social-dribbble-circular:before {
    content: "";
}
.typcn-social-dribbble:before {
    content: "";
}
.typcn-social-facebook-circular:before {
    content: "";
}
.typcn-social-facebook:before {
    content: "";
}
.typcn-social-flickr-circular:before {
    content: "";
}
.typcn-social-flickr:before {
    content: "";
}
.typcn-social-github-circular:before {
    content: "";
}
.typcn-social-github:before {
    content: "";
}
.typcn-social-google-plus-circular:before {
    content: "";
}
.typcn-social-google-plus:before {
    content: "";
}
.typcn-social-instagram-circular:before {
    content: "";
}
.typcn-social-instagram:before {
    content: "";
}
.typcn-social-last-fm-circular:before {
    content: "";
}
.typcn-social-last-fm:before {
    content: "";
}
.typcn-social-linkedin-circular:before {
    content: "";
}
.typcn-social-linkedin:before {
    content: "";
}
.typcn-social-pinterest-circular:before {
    content: "";
}
.typcn-social-pinterest:before {
    content: "";
}
.typcn-social-skype-outline:before {
    content: "";
}
.typcn-social-skype:before {
    content: "";
}
.typcn-social-tumbler-circular:before {
    content: "";
}
.typcn-social-tumbler:before {
    content: "";
}
.typcn-social-twitter-circular:before {
    content: "";
}
.typcn-social-twitter:before {
    content: "";
}
.typcn-social-vimeo-circular:before {
    content: "";
}
.typcn-social-vimeo:before {
    content: "";
}
.typcn-social-youtube-circular:before {
    content: "";
}
.typcn-social-youtube:before {
    content: "";
}
.typcn-sort-alphabetically-outline:before {
    content: "";
}
.typcn-sort-alphabetically:before {
    content: "";
}
.typcn-sort-numerically-outline:before {
    content: "";
}
.typcn-sort-numerically:before {
    content: "";
}
.typcn-spanner-outline:before {
    content: "";
}
.typcn-spanner:before {
    content: "";
}
.typcn-spiral:before {
    content: "";
}
.typcn-star-full-outline:before {
    content: "";
}
.typcn-star-half-outline:before {
    content: "";
}
.typcn-star-half:before {
    content: "";
}
.typcn-star-outline:before {
    content: "";
}
.typcn-star:before {
    content: "";
}
.typcn-starburst-outline:before {
    content: "";
}
.typcn-starburst:before {
    content: "";
}
.typcn-stopwatch:before {
    content: "";
}
.typcn-support:before {
    content: "";
}
.typcn-tabs-outline:before {
    content: "";
}
.typcn-tag:before {
    content: "";
}
.typcn-tags:before {
    content: "";
}
.typcn-th-large-outline:before {
    content: "";
}
.typcn-th-large:before {
    content: "";
}
.typcn-th-list-outline:before {
    content: "";
}
.typcn-th-list:before {
    content: "";
}
.typcn-th-menu-outline:before {
    content: "";
}
.typcn-th-menu:before {
    content: "";
}
.typcn-th-small-outline:before {
    content: "";
}
.typcn-th-small:before {
    content: "";
}
.typcn-thermometer:before {
    content: "";
}
.typcn-thumbs-down:before {
    content: "";
}
.typcn-thumbs-ok:before {
    content: "";
}
.typcn-thumbs-up:before {
    content: "";
}
.typcn-tick-outline:before {
    content: "";
}
.typcn-tick:before {
    content: "";
}
.typcn-ticket:before {
    content: "";
}
.typcn-time:before {
    content: "";
}
.typcn-times-outline:before {
    content: "";
}
.typcn-times:before {
    content: "";
}
.typcn-trash:before {
    content: "";
}
.typcn-tree:before {
    content: "";
}
.typcn-upload-outline:before {
    content: "";
}
.typcn-upload:before {
    content: "";
}
.typcn-user-add-outline:before {
    content: "";
}
.typcn-user-add:before {
    content: "";
}
.typcn-user-delete-outline:before {
    content: "";
}
.typcn-user-delete:before {
    content: "";
}
.typcn-user-outline:before {
    content: "";
}
.typcn-user:before {
    content: "";
}
.typcn-vendor-android:before {
    content: "";
}
.typcn-vendor-apple:before {
    content: "";
}
.typcn-vendor-microsoft:before {
    content: "";
}
.typcn-video-outline:before {
    content: "";
}
.typcn-video:before {
    content: "";
}
.typcn-volume-down:before {
    content: "";
}
.typcn-volume-mute:before {
    content: "";
}
.typcn-volume-up:before {
    content: "";
}
.typcn-volume:before {
    content: "";
}
.typcn-warning-outline:before {
    content: "";
}
.typcn-warning:before {
    content: "";
}
.typcn-watch:before {
    content: "";
}
.typcn-waves-outline:before {
    content: "";
}
.typcn-waves:before {
    content: "";
}
.typcn-weather-cloudy:before {
    content: "";
}
.typcn-weather-downpour:before {
    content: "";
}
.typcn-weather-night:before {
    content: "";
}
.typcn-weather-partly-sunny:before {
    content: "";
}
.typcn-weather-shower:before {
    content: "";
}
.typcn-weather-snow:before {
    content: "";
}
.typcn-weather-stormy:before {
    content: "";
}
.typcn-weather-sunny:before {
    content: "";
}
.typcn-weather-windy-cloudy:before {
    content: "";
}
.typcn-weather-windy:before {
    content: "";
}
.typcn-wi-fi-outline:before {
    content: "";
}
.typcn-wi-fi:before {
    content: "";
}
.typcn-wine:before {
    content: "";
}
.typcn-world-outline:before {
    content: "";
}
.typcn-world:before {
    content: "";
}
.typcn-zoom-in-outline:before {
    content: "";
}
.typcn-zoom-in:before {
    content: "";
}
.typcn-zoom-out-outline:before {
    content: "";
}
.typcn-zoom-out:before {
    content: "";
}
.typcn-zoom-outline:before {
    content: "";
}
.typcn-zoom:before {
    content: "";
}
@font-face {
    font-family: "simple-line-icons";
    src: url("../fonts/Simple-Line-Icons.eot?v=2.4.0");
    src: url("../fonts/Simple-Line-Icons.eot?v=2.4.0#iefix") format("embedded-opentype"),
        url("../fonts/Simple-Line-Icons.woff2?v=2.4.0") format("woff2"),
        url("../fonts/Simple-Line-Icons.ttf?v=2.4.0") format("truetype"),
        url("../fonts/Simple-Line-Icons.woff?v=2.4.0") format("woff"),
        url("../fonts/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}
.icon-user,
.icon-people,
.icon-user-female,
.icon-user-follow,
.icon-user-following,
.icon-user-unfollow,
.icon-login,
.icon-logout,
.icon-emotsmile,
.icon-phone,
.icon-call-end,
.icon-call-in,
.icon-call-out,
.icon-map,
.icon-location-pin,
.icon-direction,
.icon-directions,
.icon-compass,
.icon-layers,
.icon-menu,
.icon-list,
.icon-options-vertical,
.icon-options,
.icon-arrow-down,
.icon-arrow-left,
.icon-arrow-right,
.icon-arrow-up,
.icon-arrow-up-circle,
.icon-arrow-left-circle,
.icon-arrow-right-circle,
.icon-arrow-down-circle,
.icon-check,
.icon-clock,
.icon-plus,
.icon-minus,
.icon-close,
.icon-event,
.icon-exclamation,
.icon-organization,
.icon-trophy,
.icon-screen-smartphone,
.icon-screen-desktop,
.icon-plane,
.icon-notebook,
.icon-mustache,
.icon-mouse,
.icon-magnet,
.icon-energy,
.icon-disc,
.icon-cursor,
.icon-cursor-move,
.icon-crop,
.icon-chemistry,
.icon-speedometer,
.icon-shield,
.icon-screen-tablet,
.icon-magic-wand,
.icon-hourglass,
.icon-graduation,
.icon-ghost,
.icon-game-controller,
.icon-fire,
.icon-eyeglass,
.icon-envelope-open,
.icon-envelope-letter,
.icon-bell,
.icon-badge,
.icon-anchor,
.icon-wallet,
.icon-vector,
.icon-speech,
.icon-puzzle,
.icon-printer,
.icon-present,
.icon-playlist,
.icon-pin,
.icon-picture,
.icon-handbag,
.icon-globe-alt,
.icon-globe,
.icon-folder-alt,
.icon-folder,
.icon-film,
.icon-feed,
.icon-drop,
.icon-drawer,
.icon-docs,
.icon-doc,
.icon-diamond,
.icon-cup,
.icon-calculator,
.icon-bubbles,
.icon-briefcase,
.icon-book-open,
.icon-basket-loaded,
.icon-basket,
.icon-bag,
.icon-action-undo,
.icon-action-redo,
.icon-wrench,
.icon-umbrella,
.icon-trash,
.icon-tag,
.icon-support,
.icon-frame,
.icon-size-fullscreen,
.icon-size-actual,
.icon-shuffle,
.icon-share-alt,
.icon-share,
.icon-rocket,
.icon-question,
.icon-pie-chart,
.icon-pencil,
.icon-note,
.icon-loop,
.icon-home,
.icon-grid,
.icon-graph,
.icon-microphone,
.icon-music-tone-alt,
.icon-music-tone,
.icon-earphones-alt,
.icon-earphones,
.icon-equalizer,
.icon-like,
.icon-dislike,
.icon-control-start,
.icon-control-rewind,
.icon-control-play,
.icon-control-pause,
.icon-control-forward,
.icon-control-end,
.icon-volume-1,
.icon-volume-2,
.icon-volume-off,
.icon-calendar,
.icon-bulb,
.icon-chart,
.icon-ban,
.icon-bubble,
.icon-camrecorder,
.icon-camera,
.icon-cloud-download,
.icon-cloud-upload,
.icon-envelope,
.icon-eye,
.icon-flag,
.icon-heart,
.icon-info,
.icon-key,
.icon-link,
.icon-lock,
.icon-lock-open,
.icon-magnifier,
.icon-magnifier-add,
.icon-magnifier-remove,
.icon-paper-clip,
.icon-paper-plane,
.icon-power,
.icon-refresh,
.icon-reload,
.icon-settings,
.icon-star,
.icon-symbol-female,
.icon-symbol-male,
.icon-target,
.icon-credit-card,
.icon-paypal,
.icon-social-tumblr,
.icon-social-twitter,
.icon-social-facebook,
.icon-social-instagram,
.icon-social-linkedin,
.icon-social-pinterest,
.icon-social-github,
.icon-social-google,
.icon-social-reddit,
.icon-social-skype,
.icon-social-dribbble,
.icon-social-behance,
.icon-social-foursqare,
.icon-social-soundcloud,
.icon-social-spotify,
.icon-social-stumbleupon,
.icon-social-youtube,
.icon-social-dropbox,
.icon-social-vkontakte,
.icon-social-steam {
    font-family: "simple-line-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-user:before {
    content: "";
}
.icon-people:before {
    content: "";
}
.icon-user-female:before {
    content: "";
}
.icon-user-follow:before {
    content: "";
}
.icon-user-following:before {
    content: "";
}
.icon-user-unfollow:before {
    content: "";
}
.icon-login:before {
    content: "";
}
.icon-logout:before {
    content: "";
}
.icon-emotsmile:before {
    content: "";
}
.icon-phone:before {
    content: "";
}
.icon-call-end:before {
    content: "";
}
.icon-call-in:before {
    content: "";
}
.icon-call-out:before {
    content: "";
}
.icon-map:before {
    content: "";
}
.icon-location-pin:before {
    content: "";
}
.icon-direction:before {
    content: "";
}
.icon-directions:before {
    content: "";
}
.icon-compass:before {
    content: "";
}
.icon-layers:before {
    content: "";
}
.icon-menu:before {
    content: "";
}
.icon-list:before {
    content: "";
}
.icon-options-vertical:before {
    content: "";
}
.icon-options:before {
    content: "";
}
.icon-arrow-down:before {
    content: "";
}
.icon-arrow-left:before {
    content: "";
}
.icon-arrow-right:before {
    content: "";
}
.icon-arrow-up:before {
    content: "";
}
.icon-arrow-up-circle:before {
    content: "";
}
.icon-arrow-left-circle:before {
    content: "";
}
.icon-arrow-right-circle:before {
    content: "";
}
.icon-arrow-down-circle:before {
    content: "";
}
.icon-check:before {
    content: "";
}
.icon-clock:before {
    content: "";
}
.icon-plus:before {
    content: "";
}
.icon-minus:before {
    content: "";
}
.icon-close:before {
    content: "";
}
.icon-event:before {
    content: "";
}
.icon-exclamation:before {
    content: "";
}
.icon-organization:before {
    content: "";
}
.icon-trophy:before {
    content: "";
}
.icon-screen-smartphone:before {
    content: "";
}
.icon-screen-desktop:before {
    content: "";
}
.icon-plane:before {
    content: "";
}
.icon-notebook:before {
    content: "";
}
.icon-mustache:before {
    content: "";
}
.icon-mouse:before {
    content: "";
}
.icon-magnet:before {
    content: "";
}
.icon-energy:before {
    content: "";
}
.icon-disc:before {
    content: "";
}
.icon-cursor:before {
    content: "";
}
.icon-cursor-move:before {
    content: "";
}
.icon-crop:before {
    content: "";
}
.icon-chemistry:before {
    content: "";
}
.icon-speedometer:before {
    content: "";
}
.icon-shield:before {
    content: "";
}
.icon-screen-tablet:before {
    content: "";
}
.icon-magic-wand:before {
    content: "";
}
.icon-hourglass:before {
    content: "";
}
.icon-graduation:before {
    content: "";
}
.icon-ghost:before {
    content: "";
}
.icon-game-controller:before {
    content: "";
}
.icon-fire:before {
    content: "";
}
.icon-eyeglass:before {
    content: "";
}
.icon-envelope-open:before {
    content: "";
}
.icon-envelope-letter:before {
    content: "";
}
.icon-bell:before {
    content: "";
}
.icon-badge:before {
    content: "";
}
.icon-anchor:before {
    content: "";
}
.icon-wallet:before {
    content: "";
}
.icon-vector:before {
    content: "";
}
.icon-speech:before {
    content: "";
}
.icon-puzzle:before {
    content: "";
}
.icon-printer:before {
    content: "";
}
.icon-present:before {
    content: "";
}
.icon-playlist:before {
    content: "";
}
.icon-pin:before {
    content: "";
}
.icon-picture:before {
    content: "";
}
.icon-handbag:before {
    content: "";
}
.icon-globe-alt:before {
    content: "";
}
.icon-globe:before {
    content: "";
}
.icon-folder-alt:before {
    content: "";
}
.icon-folder:before {
    content: "";
}
.icon-film:before {
    content: "";
}
.icon-feed:before {
    content: "";
}
.icon-drop:before {
    content: "";
}
.icon-drawer:before {
    content: "";
}
.icon-docs:before {
    content: "";
}
.icon-doc:before {
    content: "";
}
.icon-diamond:before {
    content: "";
}
.icon-cup:before {
    content: "";
}
.icon-calculator:before {
    content: "";
}
.icon-bubbles:before {
    content: "";
}
.icon-briefcase:before {
    content: "";
}
.icon-book-open:before {
    content: "";
}
.icon-basket-loaded:before {
    content: "";
}
.icon-basket:before {
    content: "";
}
.icon-bag:before {
    content: "";
}
.icon-action-undo:before {
    content: "";
}
.icon-action-redo:before {
    content: "";
}
.icon-wrench:before {
    content: "";
}
.icon-umbrella:before {
    content: "";
}
.icon-trash:before {
    content: "";
}
.icon-tag:before {
    content: "";
}
.icon-support:before {
    content: "";
}
.icon-frame:before {
    content: "";
}
.icon-size-fullscreen:before {
    content: "";
}
.icon-size-actual:before {
    content: "";
}
.icon-shuffle:before {
    content: "";
}
.icon-share-alt:before {
    content: "";
}
.icon-share:before {
    content: "";
}
.icon-rocket:before {
    content: "";
}
.icon-question:before {
    content: "";
}
.icon-pie-chart:before {
    content: "";
}
.icon-pencil:before {
    content: "";
}
.icon-note:before {
    content: "";
}
.icon-loop:before {
    content: "";
}
.icon-home:before {
    content: "";
}
.icon-grid:before {
    content: "";
}
.icon-graph:before {
    content: "";
}
.icon-microphone:before {
    content: "";
}
.icon-music-tone-alt:before {
    content: "";
}
.icon-music-tone:before {
    content: "";
}
.icon-earphones-alt:before {
    content: "";
}
.icon-earphones:before {
    content: "";
}
.icon-equalizer:before {
    content: "";
}
.icon-like:before {
    content: "";
}
.icon-dislike:before {
    content: "";
}
.icon-control-start:before {
    content: "";
}
.icon-control-rewind:before {
    content: "";
}
.icon-control-play:before {
    content: "";
}
.icon-control-pause:before {
    content: "";
}
.icon-control-forward:before {
    content: "";
}
.icon-control-end:before {
    content: "";
}
.icon-volume-1:before {
    content: "";
}
.icon-volume-2:before {
    content: "";
}
.icon-volume-off:before {
    content: "";
}
.icon-calendar:before {
    content: "";
}
.icon-bulb:before {
    content: "";
}
.icon-chart:before {
    content: "";
}
.icon-ban:before {
    content: "";
}
.icon-bubble:before {
    content: "";
}
.icon-camrecorder:before {
    content: "";
}
.icon-camera:before {
    content: "";
}
.icon-cloud-download:before {
    content: "";
}
.icon-cloud-upload:before {
    content: "";
}
.icon-envelope:before {
    content: "";
}
.icon-eye:before {
    content: "";
}
.icon-flag:before {
    content: "";
}
.icon-heart:before {
    content: "";
}
.icon-info:before {
    content: "";
}
.icon-key:before {
    content: "";
}
.icon-link:before {
    content: "";
}
.icon-lock:before {
    content: "";
}
.icon-lock-open:before {
    content: "";
}
.icon-magnifier:before {
    content: "";
}
.icon-magnifier-add:before {
    content: "";
}
.icon-magnifier-remove:before {
    content: "";
}
.icon-paper-clip:before {
    content: "";
}
.icon-paper-plane:before {
    content: "";
}
.icon-power:before {
    content: "";
}
.icon-refresh:before {
    content: "";
}
.icon-reload:before {
    content: "";
}
.icon-settings:before {
    content: "";
}
.icon-star:before {
    content: "";
}
.icon-symbol-female:before {
    content: "";
}
.icon-symbol-male:before {
    content: "";
}
.icon-target:before {
    content: "";
}
.icon-credit-card:before {
    content: "";
}
.icon-paypal:before {
    content: "";
}
.icon-social-tumblr:before {
    content: "";
}
.icon-social-twitter:before {
    content: "";
}
.icon-social-facebook:before {
    content: "";
}
.icon-social-instagram:before {
    content: "";
}
.icon-social-linkedin:before {
    content: "";
}
.icon-social-pinterest:before {
    content: "";
}
.icon-social-github:before {
    content: "";
}
.icon-social-google:before {
    content: "";
}
.icon-social-reddit:before {
    content: "";
}
.icon-social-skype:before {
    content: "";
}
.icon-social-dribbble:before {
    content: "";
}
.icon-social-behance:before {
    content: "";
}
.icon-social-foursqare:before {
    content: "";
}
.icon-social-soundcloud:before {
    content: "";
}
.icon-social-spotify:before {
    content: "";
}
.icon-social-stumbleupon:before {
    content: "";
}
.icon-social-youtube:before {
    content: "";
}
.icon-social-dropbox:before {
    content: "";
}
.icon-social-vkontakte:before {
    content: "";
}
.icon-social-steam:before {
    content: "";
}
@font-face {
    font-family: "feather";
    src: url("../fonts/feather.eot?t=1525787366991");
    src: url("../fonts/feather.eot?t=1525787366991#iefix") format("embedded-opentype"),
        url("../fonts/feather.woff?t=1525787366991") format("woff"),
        url("../fonts/feather.ttf?t=1525787366991") format("truetype"),
        url("../fonts/feather.svg?t=1525787366991#feather") format("svg");
}
.feather {
    font-family: "feather" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-alert-octagon:before {
    content: "";
}
.icon-alert-circle:before {
    content: "";
}
.icon-activity:before {
    content: "";
}
.icon-alert-triangle:before {
    content: "";
}
.icon-align-center:before {
    content: "";
}
.icon-airplay:before {
    content: "";
}
.icon-align-justify:before {
    content: "";
}
.icon-align-left:before {
    content: "";
}
.icon-align-right:before {
    content: "";
}
.icon-arrow-down-left:before {
    content: "";
}
.icon-arrow-down-right:before {
    content: "";
}
.icon-anchor:before {
    content: "";
}
.icon-aperture:before {
    content: "";
}
.icon-arrow-left:before {
    content: "";
}
.icon-arrow-right:before {
    content: "";
}
.icon-arrow-down:before {
    content: "";
}
.icon-arrow-up-left:before {
    content: "";
}
.icon-arrow-up-right:before {
    content: "";
}
.icon-arrow-up:before {
    content: "";
}
.icon-award:before {
    content: "";
}
.icon-bar-chart:before {
    content: "";
}
.icon-at-sign:before {
    content: "";
}
.icon-bar-chart-2:before {
    content: "";
}
.icon-battery-charging:before {
    content: "";
}
.icon-bell-off:before {
    content: "";
}
.icon-battery:before {
    content: "";
}
.icon-bluetooth:before {
    content: "";
}
.icon-bell:before {
    content: "";
}
.icon-book:before {
    content: "";
}
.icon-briefcase:before {
    content: "";
}
.icon-camera-off:before {
    content: "";
}
.icon-calendar:before {
    content: "";
}
.icon-bookmark:before {
    content: "";
}
.icon-box:before {
    content: "";
}
.icon-camera:before {
    content: "";
}
.icon-check-circle:before {
    content: "";
}
.icon-check:before {
    content: "";
}
.icon-check-square:before {
    content: "";
}
.icon-cast:before {
    content: "";
}
.icon-chevron-down:before {
    content: "";
}
.icon-chevron-left:before {
    content: "";
}
.icon-chevron-right:before {
    content: "";
}
.icon-chevron-up:before {
    content: "";
}
.icon-chevrons-down:before {
    content: "";
}
.icon-chevrons-right:before {
    content: "";
}
.icon-chevrons-up:before {
    content: "";
}
.icon-chevrons-left:before {
    content: "";
}
.icon-circle:before {
    content: "";
}
.icon-clipboard:before {
    content: "";
}
.icon-chrome:before {
    content: "";
}
.icon-clock:before {
    content: "";
}
.icon-cloud-lightning:before {
    content: "";
}
.icon-cloud-drizzle:before {
    content: "";
}
.icon-cloud-rain:before {
    content: "";
}
.icon-cloud-off:before {
    content: "";
}
.icon-codepen:before {
    content: "";
}
.icon-cloud-snow:before {
    content: "";
}
.icon-compass:before {
    content: "";
}
.icon-copy:before {
    content: "";
}
.icon-corner-down-right:before {
    content: "";
}
.icon-corner-down-left:before {
    content: "";
}
.icon-corner-left-down:before {
    content: "";
}
.icon-corner-left-up:before {
    content: "";
}
.icon-corner-up-left:before {
    content: "";
}
.icon-corner-up-right:before {
    content: "";
}
.icon-corner-right-down:before {
    content: "";
}
.icon-corner-right-up:before {
    content: "";
}
.icon-cpu:before {
    content: "";
}
.icon-credit-card:before {
    content: "";
}
.icon-crosshair:before {
    content: "";
}
.icon-disc:before {
    content: "";
}
.icon-delete:before {
    content: "";
}
.icon-download-cloud:before {
    content: "";
}
.icon-download:before {
    content: "";
}
.icon-droplet:before {
    content: "";
}
.icon-edit-2:before {
    content: "";
}
.icon-edit:before {
    content: "";
}
.icon-edit-1:before {
    content: "";
}
.icon-external-link:before {
    content: "";
}
.icon-eye:before {
    content: "";
}
.icon-feather:before {
    content: "";
}
.icon-facebook:before {
    content: "";
}
.icon-file-minus:before {
    content: "";
}
.icon-eye-off:before {
    content: "";
}
.icon-fast-forward:before {
    content: "";
}
.icon-file-text:before {
    content: "";
}
.icon-film:before {
    content: "";
}
.icon-file:before {
    content: "";
}
.icon-file-plus:before {
    content: "";
}
.icon-folder:before {
    content: "";
}
.icon-filter:before {
    content: "";
}
.icon-flag:before {
    content: "";
}
.icon-globe:before {
    content: "";
}
.icon-grid:before {
    content: "";
}
.icon-heart:before {
    content: "";
}
.icon-home:before {
    content: "";
}
.icon-github:before {
    content: "";
}
.icon-image:before {
    content: "";
}
.icon-inbox:before {
    content: "";
}
.icon-layers:before {
    content: "";
}
.icon-info:before {
    content: "";
}
.icon-instagram:before {
    content: "";
}
.icon-layout:before {
    content: "";
}
.icon-link-2:before {
    content: "";
}
.icon-life-buoy:before {
    content: "";
}
.icon-link:before {
    content: "";
}
.icon-log-in:before {
    content: "";
}
.icon-list:before {
    content: "";
}
.icon-lock:before {
    content: "";
}
.icon-log-out:before {
    content: "";
}
.icon-loader:before {
    content: "";
}
.icon-mail:before {
    content: "";
}
.icon-maximize-2:before {
    content: "";
}
.icon-map:before {
    content: "";
}
.icon-map-pin:before {
    content: "";
}
.icon-menu:before {
    content: "";
}
.icon-message-circle:before {
    content: "";
}
.icon-message-square:before {
    content: "";
}
.icon-minimize-2:before {
    content: "";
}
.icon-mic-off:before {
    content: "";
}
.icon-minus-circle:before {
    content: "";
}
.icon-mic:before {
    content: "";
}
.icon-minus-square:before {
    content: "";
}
.icon-minus:before {
    content: "";
}
.icon-moon:before {
    content: "";
}
.icon-monitor:before {
    content: "";
}
.icon-more-vertical:before {
    content: "";
}
.icon-more-horizontal:before {
    content: "";
}
.icon-move:before {
    content: "";
}
.icon-music:before {
    content: "";
}
.icon-navigation-2:before {
    content: "";
}
.icon-navigation:before {
    content: "";
}
.icon-octagon:before {
    content: "";
}
.icon-package:before {
    content: "";
}
.icon-pause-circle:before {
    content: "";
}
.icon-pause:before {
    content: "";
}
.icon-percent:before {
    content: "";
}
.icon-phone-call:before {
    content: "";
}
.icon-phone-forwarded:before {
    content: "";
}
.icon-phone-missed:before {
    content: "";
}
.icon-phone-off:before {
    content: "";
}
.icon-phone-incoming:before {
    content: "";
}
.icon-phone:before {
    content: "";
}
.icon-phone-outgoing:before {
    content: "";
}
.icon-pie-chart:before {
    content: "";
}
.icon-play-circle:before {
    content: "";
}
.icon-play:before {
    content: "";
}
.icon-plus-square:before {
    content: "";
}
.icon-plus-circle:before {
    content: "";
}
.icon-plus:before {
    content: "";
}
.icon-pocket:before {
    content: "";
}
.icon-printer:before {
    content: "";
}
.icon-power:before {
    content: "";
}
.icon-radio:before {
    content: "";
}
.icon-repeat:before {
    content: "";
}
.icon-refresh-ccw:before {
    content: "";
}
.icon-rewind:before {
    content: "";
}
.icon-rotate-ccw:before {
    content: "";
}
.icon-refresh-cw:before {
    content: "";
}
.icon-rotate-cw:before {
    content: "";
}
.icon-save:before {
    content: "";
}
.icon-search:before {
    content: "";
}
.icon-server:before {
    content: "";
}
.icon-scissors:before {
    content: "";
}
.icon-share-2:before {
    content: "";
}
.icon-share:before {
    content: "";
}
.icon-shield:before {
    content: "";
}
.icon-settings:before {
    content: "";
}
.icon-skip-back:before {
    content: "";
}
.icon-shuffle:before {
    content: "";
}
.icon-sidebar:before {
    content: "";
}
.icon-skip-forward:before {
    content: "";
}
.icon-slack:before {
    content: "";
}
.icon-slash:before {
    content: "";
}
.icon-smartphone:before {
    content: "";
}
.icon-square:before {
    content: "";
}
.icon-speaker:before {
    content: "";
}
.icon-star:before {
    content: "";
}
.icon-stop-circle:before {
    content: "";
}
.icon-sun:before {
    content: "";
}
.icon-sunrise:before {
    content: "";
}
.icon-tablet:before {
    content: "";
}
.icon-tag:before {
    content: "";
}
.icon-sunset:before {
    content: "";
}
.icon-target:before {
    content: "";
}
.icon-thermometer:before {
    content: "";
}
.icon-thumbs-up:before {
    content: "";
}
.icon-thumbs-down:before {
    content: "";
}
.icon-toggle-left:before {
    content: "";
}
.icon-toggle-right:before {
    content: "";
}
.icon-trash-2:before {
    content: "";
}
.icon-trash:before {
    content: "";
}
.icon-trending-up:before {
    content: "";
}
.icon-trending-down:before {
    content: "";
}
.icon-triangle:before {
    content: "";
}
.icon-type:before {
    content: "";
}
.icon-twitter:before {
    content: "";
}
.icon-upload:before {
    content: "";
}
.icon-umbrella:before {
    content: "";
}
.icon-upload-cloud:before {
    content: "";
}
.icon-unlock:before {
    content: "";
}
.icon-user-check:before {
    content: "";
}
.icon-user-minus:before {
    content: "";
}
.icon-user-plus:before {
    content: "";
}
.icon-user-x:before {
    content: "";
}
.icon-user:before {
    content: "";
}
.icon-users:before {
    content: "";
}
.icon-video-off:before {
    content: "";
}
.icon-video:before {
    content: "";
}
.icon-voicemail:before {
    content: "";
}
.icon-volume-x:before {
    content: "";
}
.icon-volume-2:before {
    content: "";
}
.icon-volume-1:before {
    content: "";
}
.icon-volume:before {
    content: "";
}
.icon-watch:before {
    content: "";
}
.icon-wifi:before {
    content: "";
}
.icon-x-square:before {
    content: "";
}
.icon-wind:before {
    content: "";
}
.icon-x:before {
    content: "";
}
.icon-x-circle:before {
    content: "";
}
.icon-zap:before {
    content: "";
}
.icon-zoom-in:before {
    content: "";
}
.icon-zoom-out:before {
    content: "";
}
.icon-command:before {
    content: "";
}
.icon-cloud:before {
    content: "";
}
.icon-hash:before {
    content: "";
}
.icon-headphones:before {
    content: "";
}
.icon-underline:before {
    content: "";
}
.icon-italic:before {
    content: "";
}
.icon-bold:before {
    content: "";
}
.icon-crop:before {
    content: "";
}
.icon-help-circle:before {
    content: "";
}
.icon-paperclip:before {
    content: "";
}
.icon-shopping-cart:before {
    content: "";
}
.icon-tv:before {
    content: "";
}
.icon-wifi-off:before {
    content: "";
}
.icon-minimize:before {
    content: "";
}
.icon-maximize:before {
    content: "";
}
.icon-gitlab:before {
    content: "";
}
.icon-sliders:before {
    content: "";
}
.icon-star-on:before {
    content: "";
}
.icon-heart-on:before {
    content: "";
}
.icon-archive:before {
    content: "";
}
.icon-arrow-down-circle:before {
    content: "";
}
.icon-arrow-up-circle:before {
    content: "";
}
.icon-arrow-left-circle:before {
    content: "";
}
.icon-arrow-right-circle:before {
    content: "";
}
.icon-bar-chart-line-:before {
    content: "";
}
.icon-bar-chart-line:before {
    content: "";
}
.icon-book-open:before {
    content: "";
}
.icon-code:before {
    content: "";
}
.icon-database:before {
    content: "";
}
.icon-dollar-sign:before {
    content: "";
}
.icon-folder-plus:before {
    content: "";
}
.icon-gift:before {
    content: "";
}
.icon-folder-minus:before {
    content: "";
}
.icon-git-commit:before {
    content: "";
}
.icon-git-branch:before {
    content: "";
}
.icon-git-pull-request:before {
    content: "";
}
.icon-git-merge:before {
    content: "";
}
.icon-linkedin:before {
    content: "";
}
.icon-hard-drive:before {
    content: "";
}
.icon-more-vertical-:before {
    content: "";
}
.icon-more-horizontal-:before {
    content: "";
}
.icon-rss:before {
    content: "";
}
.icon-send:before {
    content: "";
}
.icon-shield-off:before {
    content: "";
}
.icon-shopping-bag:before {
    content: "";
}
.icon-terminal:before {
    content: "";
}
.icon-truck:before {
    content: "";
}
.icon-zap-off:before {
    content: "";
}
.icon-youtube:before {
    content: "";
}
@font-face {
    font-family: "LineAwesome";
    src: url("../fonts/line-awesome.eot?v=1.1.");
    src: url("../fonts/line-awesome.eot??v=1.1.#iefix") format("embedded-opentype"),
        url("../fonts/line-awesome.woff2?v=1.1.") format("woff2"), url("../fonts/line-awesome.woff?v=1.1.") format("woff"),
        url("../fonts/line-awesome.ttf?v=1.1.") format("truetype"), url("../fonts/line-awesome.svg?v=1.1.#fa") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "LineAwesome";
        src: url("../fonts/line-awesome.svg?v=1.1.#fa") format("svg");
    }
}
.la {
    display: inline-block;
    font: normal normal normal 16px/1 "LineAwesome";
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}
.la-lg {
    font-size: 1.33333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.la-2x {
    font-size: 2em;
}
.la-3x {
    font-size: 3em;
}
.la-4x {
    font-size: 4em;
}
.la-5x {
    font-size: 5em;
}
.la-fw {
    width: 1.28571429em;
    text-align: center;
}
.la-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.la-ul > li {
    position: relative;
}
.la-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.la-li.la-lg {
    left: -1.85714286em;
}
.la-border {
    padding: 0.2em 0.25em 0.15em;
    border: solid 0.08em #eeeeee;
    border-radius: 0.1em;
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.li.pull-left {
    margin-right: 0.3em;
}
.li.pull-right {
    margin-left: 0.3em;
}
.la-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}
@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
.la-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.la-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.la-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.la-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.la-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
:root .la-rotate-90,
:root .la-rotate-180,
:root .la-rotate-270,
:root .la-flip-horizontal,
:root .la-flip-vertical {
    filter: none;
}
.la-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}
.la-stack-1x,
.la-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}
.la-stack-1x {
    line-height: inherit;
}
.la-stack-2x {
    font-size: 2em;
}
.la-inverse {
    color: #ffffff;
}
.la-500px:before {
    content: "";
}
.la-adjust:before {
    content: "";
}
.la-adn:before {
    content: "";
}
.la-align-center:before {
    content: "";
}
.la-align-justify:before {
    content: "";
}
.la-align-left:before {
    content: "";
}
.la-align-right:before {
    content: "";
}
.la-amazon:before {
    content: "";
}
.la-ambulance:before {
    content: "";
}
.la-anchor:before {
    content: "";
}
.la-android:before {
    content: "";
}
.la-angellist:before {
    content: "";
}
.la-angle-double-down:before {
    content: "";
}
.la-angle-double-left:before {
    content: "";
}
.la-angle-double-right:before {
    content: "";
}
.la-angle-double-up:before {
    content: "";
}
.la-angle-down:before {
    content: "";
}
.la-angle-left:before {
    content: "";
}
.la-angle-right:before {
    content: "";
}
.la-angle-up:before {
    content: "";
}
.la-apple:before {
    content: "";
}
.la-archive:before {
    content: "";
}
.la-area-chart:before {
    content: "";
}
.la-arrow-circle-down:before {
    content: "";
}
.la-arrow-circle-left:before {
    content: "";
}
.la-arrow-circle-o-down:before {
    content: "";
}
.la-arrow-circle-o-left:before {
    content: "";
}
.la-arrow-circle-o-right:before {
    content: "";
}
.la-arrow-circle-o-up:before {
    content: "";
}
.la-arrow-circle-right:before {
    content: "";
}
.la-arrow-circle-up:before {
    content: "";
}
.la-arrow-down:before {
    content: "";
}
.la-arrow-left:before {
    content: "";
}
.la-arrow-right:before {
    content: "";
}
.la-arrow-up:before {
    content: "";
}
.la-arrows:before {
    content: "";
}
.la-arrows-alt:before {
    content: "";
}
.la-arrows-h:before {
    content: "";
}
.la-arrows-v:before {
    content: "";
}
.la-asterisk:before {
    content: "";
}
.la-at:before {
    content: "";
}
.la-automobile:before {
    content: "";
}
.la-backward:before {
    content: "";
}
.la-balance-scale:before {
    content: "";
}
.la-ban:before {
    content: "";
}
.la-bank:before {
    content: "";
}
.la-bar-chart:before {
    content: "";
}
.la-bar-chart-o:before {
    content: "";
}
.la-barcode:before {
    content: "";
}
.la-bars:before {
    content: "";
}
.la-battery-0:before {
    content: "";
}
.la-battery-1:before {
    content: "";
}
.la-battery-2:before {
    content: "";
}
.la-battery-3:before {
    content: "";
}
.la-battery-4:before {
    content: "";
}
.la-battery-empty:before {
    content: "";
}
.la-battery-full:before {
    content: "";
}
.la-battery-half:before {
    content: "";
}
.la-battery-quarter:before {
    content: "";
}
.la-battery-three-quarters:before {
    content: "";
}
.la-bed:before {
    content: "";
}
.la-beer:before {
    content: "";
}
.la-behance:before {
    content: "";
}
.la-behance-square:before {
    content: "";
}
.la-bell:before {
    content: "";
}
.la-bell-o:before {
    content: "";
}
.la-bell-slash:before {
    content: "";
}
.la-bell-slash-o:before {
    content: "";
}
.la-bicycle:before {
    content: "";
}
.la-binoculars:before {
    content: "";
}
.la-birthday-cake:before {
    content: "";
}
.la-bitbucket:before {
    content: "";
}
.la-bitbucket-square:before {
    content: "";
}
.la-bitcoin:before {
    content: "";
}
.la-black-tie:before {
    content: "";
}
.la-bold:before {
    content: "";
}
.la-bolt:before {
    content: "";
}
.la-bomb:before {
    content: "";
}
.la-book:before {
    content: "";
}
.la-bookmark:before {
    content: "";
}
.la-bookmark-o:before {
    content: "";
}
.la-briefcase:before {
    content: "";
}
.la-btc:before {
    content: "";
}
.la-bug:before {
    content: "";
}
.la-building:before {
    content: "";
}
.la-building-o:before {
    content: "";
}
.la-bullhorn:before {
    content: "";
}
.la-bullseye:before {
    content: "";
}
.la-bus:before {
    content: "";
}
.la-buysellads:before {
    content: "";
}
.la-cab:before {
    content: "";
}
.la-calculator:before {
    content: "";
}
.la-calendar:before {
    content: "";
}
.la-calendar-check-o:before {
    content: "";
}
.la-calendar-minus-o:before {
    content: "";
}
.la-calendar-o:before {
    content: "";
}
.la-calendar-plus-o:before {
    content: "";
}
.la-calendar-times-o:before {
    content: "";
}
.la-camera:before {
    content: "";
}
.la-camera-retro:before {
    content: "";
}
.la-car:before {
    content: "";
}
.la-caret-down:before {
    content: "";
}
.la-caret-left:before {
    content: "";
}
.la-caret-right:before {
    content: "";
}
.la-caret-square-o-down:before,
.la-toggle-down:before {
    content: "";
}
.la-caret-square-o-left:before,
.la-toggle-left:before {
    content: "";
}
.la-caret-square-o-right:before,
.la-toggle-right:before {
    content: "";
}
.la-caret-square-o-up:before,
.la-toggle-up:before {
    content: "";
}
.la-caret-up:before {
    content: "";
}
.la-cart-arrow-down:before {
    content: "";
}
.la-cart-plus:before {
    content: "";
}
.la-cc:before {
    content: "";
}
.la-cc-amex:before {
    content: "";
}
.la-cc-diners-club:before {
    content: "";
}
.la-cc-discover:before {
    content: "";
}
.la-cc-jcb:before {
    content: "";
}
.la-cc-mastercard:before {
    content: "";
}
.la-cc-paypal:before {
    content: "";
}
.la-cc-stripe:before {
    content: "";
}
.la-cc-visa:before {
    content: "";
}
.la-certificate:before {
    content: "";
}
.la-chain:before {
    content: "";
}
.la-chain-broken:before {
    content: "";
}
.la-check:before {
    content: "";
}
.la-check-circle:before {
    content: "";
}
.la-check-circle-o:before {
    content: "";
}
.la-check-square:before {
    content: "";
}
.la-check-square-o:before {
    content: "";
}
.la-chevron-circle-down:before {
    content: "";
}
.la-chevron-circle-left:before {
    content: "";
}
.la-chevron-circle-right:before {
    content: "";
}
.la-chevron-circle-up:before {
    content: "";
}
.la-chevron-down:before {
    content: "";
}
.la-chevron-left:before {
    content: "";
}
.la-chevron-right:before {
    content: "";
}
.la-chevron-up:before {
    content: "";
}
.la-child:before {
    content: "";
}
.la-chrome:before {
    content: "";
}
.la-circle:before {
    content: "";
}
.la-circle-o:before {
    content: "";
}
.la-circle-o-notch:before {
    content: "";
}
.la-circle-thin:before {
    content: "";
}
.la-clipboard:before {
    content: "";
}
.la-clock-o:before {
    content: "";
}
.la-clone:before {
    content: "";
}
.la-close:before {
    content: "";
}
.la-cloud:before {
    content: "";
}
.la-cloud-download:before {
    content: "";
}
.la-cloud-upload:before {
    content: "";
}
.la-cny:before {
    content: "";
}
.la-code:before {
    content: "";
}
.la-code-fork:before {
    content: "";
}
.la-codepen:before {
    content: "";
}
.la-coffee:before {
    content: "";
}
.la-cog:before {
    content: "";
}
.la-cogs:before {
    content: "";
}
.la-columns:before {
    content: "";
}
.la-comment:before {
    content: "";
}
.la-comment-o:before {
    content: "";
}
.la-commenting:before {
    content: "";
}
.la-commenting-o:before {
    content: "";
}
.la-comments:before {
    content: "";
}
.la-comments-o:before {
    content: "";
}
.la-compass:before {
    content: "";
}
.la-compress:before {
    content: "";
}
.la-connectdevelop:before {
    content: "";
}
.la-contao:before {
    content: "";
}
.la-copy:before {
    content: "";
}
.la-copyright:before {
    content: "";
}
.la-creative-commons:before {
    content: "";
}
.la-credit-card:before {
    content: "";
}
.la-crop:before {
    content: "";
}
.la-crosshairs:before {
    content: "";
}
.la-css3:before {
    content: "";
}
.la-cube:before {
    content: "";
}
.la-cubes:before {
    content: "";
}
.la-cut:before {
    content: "";
}
.la-cutlery:before {
    content: "";
}
.la-dashboard:before {
    content: "";
}
.la-dashcube:before {
    content: "";
}
.la-database:before {
    content: "";
}
.la-dedent:before {
    content: "";
}
.la-delicious:before {
    content: "";
}
.la-desktop:before {
    content: "";
}
.la-deviantart:before {
    content: "";
}
.la-diamond:before {
    content: "";
}
.la-digg:before {
    content: "";
}
.la-dollar:before {
    content: "";
}
.la-dot-circle-o:before {
    content: "";
}
.la-download:before {
    content: "";
}
.la-dribbble:before {
    content: "";
}
.la-dropbox:before {
    content: "";
}
.la-drupal:before {
    content: "";
}
.la-edit:before {
    content: "";
}
.la-eject:before {
    content: "";
}
.la-ellipsis-h:before {
    content: "";
}
.la-ellipsis-v:before {
    content: "";
}
.la-empire:before,
.la-ge:before {
    content: "";
}
.la-envelope:before {
    content: "";
}
.la-envelope-o:before {
    content: "";
}
.la-envelope-square:before {
    content: "";
}
.la-eraser:before {
    content: "";
}
.la-eur:before {
    content: "";
}
.la-euro:before {
    content: "";
}
.la-exchange:before {
    content: "";
}
.la-exclamation:before {
    content: "";
}
.la-exclamation-circle:before {
    content: "";
}
.la-exclamation-triangle:before {
    content: "";
}
.la-expand:before {
    content: "";
}
.la-expeditedssl:before {
    content: "";
}
.la-external-link:before {
    content: "";
}
.la-external-link-square:before {
    content: "";
}
.la-eye:before {
    content: "";
}
.la-eye-slash:before {
    content: "";
}
.la-eyedropper:before {
    content: "";
}
.la-facebook:before,
.la-facebook-f:before {
    content: "";
}
.la-facebook-official:before {
    content: "";
}
.la-facebook-square:before {
    content: "";
}
.la-fast-backward:before {
    content: "";
}
.la-fast-forward:before {
    content: "";
}
.la-fax:before {
    content: "";
}
.la-female:before {
    content: "";
}
.la-fighter-jet:before {
    content: "";
}
.la-file:before {
    content: "";
}
.la-file-archive-o:before {
    content: "";
}
.la-file-audio-o:before {
    content: "";
}
.la-file-code-o:before {
    content: "";
}
.la-file-excel-o:before {
    content: "";
}
.la-file-image-o:before {
    content: "";
}
.la-file-movie-o:before {
    content: "";
}
.la-file-o:before {
    content: "";
}
.la-file-pdf-o:before {
    content: "";
}
.la-file-photo-o:before {
    content: "";
}
.la-file-picture-o:before {
    content: "";
}
.la-file-powerpoint-o:before {
    content: "";
}
.la-file-sound-o:before {
    content: "";
}
.la-file-text:before {
    content: "";
}
.la-file-text-o:before {
    content: "";
}
.la-file-video-o:before {
    content: "";
}
.la-file-word-o:before {
    content: "";
}
.la-file-zip-o:before {
    content: "";
}
.la-files-o:before {
    content: "";
}
.la-film:before {
    content: "";
}
.la-filter:before {
    content: "";
}
.la-fire:before {
    content: "";
}
.la-fire-extinguisher:before {
    content: "";
}
.la-firefox:before {
    content: "";
}
.la-flag:before {
    content: "";
}
.la-flag-checkered:before {
    content: "";
}
.la-flag-o:before {
    content: "";
}
.la-flash:before {
    content: "";
}
.la-flask:before {
    content: "";
}
.la-flickr:before {
    content: "";
}
.la-floppy-o:before {
    content: "";
}
.la-folder:before {
    content: "";
}
.la-folder-o:before {
    content: "";
}
.la-folder-open:before {
    content: "";
}
.la-folder-open-o:before {
    content: "";
}
.la-font:before {
    content: "";
}
.la-fonticons:before {
    content: "";
}
.la-forumbee:before {
    content: "";
}
.la-forward:before {
    content: "";
}
.la-foursquare:before {
    content: "";
}
.la-frown-o:before {
    content: "";
}
.la-futbol-o:before,
.la-soccer-ball-o:before {
    content: "";
}
.la-gamepad:before {
    content: "";
}
.la-gavel:before {
    content: "";
}
.la-gbp:before {
    content: "";
}
.la-gear:before {
    content: "";
}
.la-gears:before {
    content: "";
}
.la-genderless:before {
    content: "";
}
.la-get-pocket:before {
    content: "";
}
.la-gg:before {
    content: "";
}
.la-gg-circle:before {
    content: "";
}
.la-gift:before {
    content: "";
}
.la-git:before {
    content: "";
}
.la-git-square:before {
    content: "";
}
.la-github:before {
    content: "";
}
.la-github-alt:before {
    content: "";
}
.la-github-square:before {
    content: "";
}
.la-glass:before {
    content: "";
}
.la-globe:before {
    content: "";
}
.la-google:before {
    content: "";
}
.la-google-plus:before {
    content: "";
}
.la-google-plus-square:before {
    content: "";
}
.la-google-wallet:before {
    content: "";
}
.la-graduation-cap:before {
    content: "";
}
.la-gratipay:before,
.la-gittip:before {
    content: "";
}
.la-group:before {
    content: "";
}
.la-h-square:before {
    content: "";
}
.la-hacker-news:before {
    content: "";
}
.la-hand-grab-o:before {
    content: "";
}
.la-hand-lizard-o:before {
    content: "";
}
.la-hand-o-down:before {
    content: "";
}
.la-hand-o-left:before {
    content: "";
}
.la-hand-o-right:before {
    content: "";
}
.la-hand-o-up:before {
    content: "";
}
.la-hand-paper-o:before {
    content: "";
}
.la-hand-peace-o:before {
    content: "";
}
.la-hand-pointer-o:before {
    content: "";
}
.la-hand-rock-o:before {
    content: "";
}
.la-hand-scissors-o:before {
    content: "";
}
.la-hand-spock-o:before {
    content: "";
}
.la-hand-stop-o:before {
    content: "";
}
.la-hdd-o:before {
    content: "";
}
.la-header:before {
    content: "";
}
.la-headphones:before {
    content: "";
}
.la-heart:before {
    content: "";
}
.la-heart-o:before {
    content: "";
}
.la-heartbeat:before {
    content: "";
}
.la-history:before {
    content: "";
}
.la-home:before {
    content: "";
}
.la-hospital-o:before {
    content: "";
}
.la-hotel:before {
    content: "";
}
.la-hourglass:before {
    content: "";
}
.la-hourglass-1:before {
    content: "";
}
.la-hourglass-2:before {
    content: "";
}
.la-hourglass-3:before {
    content: "";
}
.la-hourglass-end:before {
    content: "";
}
.la-hourglass-half:before {
    content: "";
}
.la-hourglass-o:before {
    content: "";
}
.la-hourglass-start:before {
    content: "";
}
.la-houzz:before {
    content: "";
}
.la-html5:before {
    content: "";
}
.la-i-cursor:before {
    content: "";
}
.la-ils:before {
    content: "";
}
.la-image:before {
    content: "";
}
.la-inbox:before {
    content: "";
}
.la-indent:before {
    content: "";
}
.la-industry:before {
    content: "";
}
.la-info:before {
    content: "";
}
.la-info-circle:before {
    content: "";
}
.la-inr:before {
    content: "";
}
.la-instagram:before {
    content: "";
}
.la-institution:before {
    content: "";
}
.la-internet-explorer:before {
    content: "";
}
.la-ioxhost:before {
    content: "";
}
.la-italic:before {
    content: "";
}
.la-joomla:before {
    content: "";
}
.la-jpy:before {
    content: "";
}
.la-jsfiddle:before {
    content: "";
}
.la-key:before {
    content: "";
}
.la-keyboard-o:before {
    content: "";
}
.la-krw:before {
    content: "";
}
.la-language:before {
    content: "";
}
.la-laptop:before {
    content: "";
}
.la-lastfm:before {
    content: "";
}
.la-lastfm-square:before {
    content: "";
}
.la-leaf:before {
    content: "";
}
.la-leanpub:before {
    content: "";
}
.la-legal:before {
    content: "";
}
.la-lemon-o:before {
    content: "";
}
.la-level-down:before {
    content: "";
}
.la-level-up:before {
    content: "";
}
.la-life-bouy:before {
    content: "";
}
.la-life-buoy:before {
    content: "";
}
.la-life-ring:before,
.la-support:before {
    content: "";
}
.la-life-saver:before {
    content: "";
}
.la-lightbulb-o:before {
    content: "";
}
.la-line-chart:before {
    content: "";
}
.la-link:before {
    content: "";
}
.la-linkedin:before {
    content: "";
}
.la-linkedin-square:before {
    content: "";
}
.la-linux:before {
    content: "";
}
.la-list:before {
    content: "";
}
.la-list-alt:before {
    content: "";
}
.la-list-ol:before {
    content: "";
}
.la-list-ul:before {
    content: "";
}
.la-location-arrow:before {
    content: "";
}
.la-lock:before {
    content: "";
}
.la-long-arrow-down:before {
    content: "";
}
.la-long-arrow-left:before {
    content: "";
}
.la-long-arrow-right:before {
    content: "";
}
.la-long-arrow-up:before {
    content: "";
}
.la-magic:before {
    content: "";
}
.la-magnet:before {
    content: "";
}
.la-mail-forward:before {
    content: "";
}
.la-mail-reply:before {
    content: "";
}
.la-mail-reply-all:before {
    content: "";
}
.la-male:before {
    content: "";
}
.la-map:before {
    content: "";
}
.la-map-marker:before {
    content: "";
}
.la-map-o:before {
    content: "";
}
.la-map-pin:before {
    content: "";
}
.la-map-signs:before {
    content: "";
}
.la-mars:before {
    content: "";
}
.la-mars-double:before {
    content: "";
}
.la-mars-stroke:before {
    content: "";
}
.la-mars-stroke-h:before {
    content: "";
}
.la-mars-stroke-v:before {
    content: "";
}
.la-maxcdn:before {
    content: "";
}
.la-meanpath:before {
    content: "";
}
.la-medium:before {
    content: "";
}
.la-medkit:before {
    content: "";
}
.la-meh-o:before {
    content: "";
}
.la-mercury:before {
    content: "";
}
.la-microphone:before {
    content: "";
}
.la-microphone-slash:before {
    content: "";
}
.la-minus:before {
    content: "";
}
.la-minus-circle:before {
    content: "";
}
.la-minus-square:before {
    content: "";
}
.la-minus-square-o:before {
    content: "";
}
.la-mobile:before {
    content: "";
}
.la-mobile-phone:before {
    content: "";
}
.la-money:before {
    content: "";
}
.la-moon-o:before {
    content: "";
}
.la-mortar-board:before {
    content: "";
}
.la-motorcycle:before {
    content: "";
}
.la-mouse-pointer:before {
    content: "";
}
.la-music:before {
    content: "";
}
.la-navicon:before {
    content: "";
}
.la-neuter:before {
    content: "";
}
.la-newspaper-o:before {
    content: "";
}
.la-object-group:before {
    content: "";
}
.la-object-ungroup:before {
    content: "";
}
.la-odnoklassniki:before {
    content: "";
}
.la-odnoklassniki-square:before {
    content: "";
}
.la-opencart:before {
    content: "";
}
.la-openid:before {
    content: "";
}
.la-opera:before {
    content: "";
}
.la-optin-monster:before {
    content: "";
}
.la-outdent:before {
    content: "";
}
.la-pagelines:before {
    content: "";
}
.la-paint-brush:before {
    content: "";
}
.la-paper-plane:before,
.la-send:before {
    content: "";
}
.la-paper-plane-o:before,
.la-send-o:before {
    content: "";
}
.la-paperclip:before {
    content: "";
}
.la-paragraph:before {
    content: "";
}
.la-paste:before {
    content: "";
}
.la-pause:before {
    content: "";
}
.la-paw:before {
    content: "";
}
.la-paypal:before {
    content: "";
}
.la-pencil:before {
    content: "";
}
.la-pencil-square:before {
    content: "";
}
.la-pencil-square-o:before {
    content: "";
}
.la-phone:before {
    content: "";
}
.la-phone-square:before {
    content: "";
}
.la-photo:before {
    content: "";
}
.la-picture-o:before {
    content: "";
}
.la-pie-chart:before {
    content: "";
}
.la-pied-piper:before {
    content: "";
}
.la-pied-piper-alt:before {
    content: "";
}
.la-pinterest:before {
    content: "";
}
.la-pinterest-p:before {
    content: "";
}
.la-pinterest-square:before {
    content: "";
}
.la-plane:before {
    content: "";
}
.la-play:before {
    content: "";
}
.la-play-circle:before {
    content: "";
}
.la-play-circle-o:before {
    content: "";
}
.la-plug:before {
    content: "";
}
.la-plus:before {
    content: "";
}
.la-plus-circle:before {
    content: "";
}
.la-plus-square:before {
    content: "";
}
.la-plus-square-o:before {
    content: "";
}
.la-power-off:before {
    content: "";
}
.la-print:before {
    content: "";
}
.la-puzzle-piece:before {
    content: "";
}
.la-qq:before {
    content: "";
}
.la-qrcode:before {
    content: "";
}
.la-question:before {
    content: "";
}
.la-question-circle:before {
    content: "";
}
.la-quote-left:before {
    content: "";
}
.la-quote-right:before {
    content: "";
}
.la-ra:before {
    content: "";
}
.la-random:before {
    content: "";
}
.la-rebel:before {
    content: "";
}
.la-recycle:before {
    content: "";
}
.la-reddit:before {
    content: "";
}
.la-reddit-square:before {
    content: "";
}
.la-refresh:before {
    content: "";
}
.la-registered:before {
    content: "";
}
.la-renren:before {
    content: "";
}
.la-reorder:before {
    content: "";
}
.la-repeat:before {
    content: "";
}
.la-reply:before {
    content: "";
}
.la-reply-all:before {
    content: "";
}
.la-retweet:before {
    content: "";
}
.la-rmb:before {
    content: "";
}
.la-road:before {
    content: "";
}
.la-rocket:before {
    content: "";
}
.la-rotate-left:before {
    content: "";
}
.la-rotate-right:before {
    content: "";
}
.la-rouble:before {
    content: "";
}
.la-rss:before,
.la-feed:before {
    content: "";
}
.la-rss-square:before {
    content: "";
}
.la-rub:before {
    content: "";
}
.la-ruble:before {
    content: "";
}
.la-rupee:before {
    content: "";
}
.la-safari:before {
    content: "";
}
.la-save:before {
    content: "";
}
.la-scissors:before {
    content: "";
}
.la-search:before {
    content: "";
}
.la-search-minus:before {
    content: "";
}
.la-search-plus:before {
    content: "";
}
.la-sellsy:before {
    content: "";
}
.la-server:before {
    content: "";
}
.la-share:before {
    content: "";
}
.la-share-alt:before {
    content: "";
}
.la-share-alt-square:before {
    content: "";
}
.la-share-square:before {
    content: "";
}
.la-share-square-o:before {
    content: "";
}
.la-shekel:before {
    content: "";
}
.la-sheqel:before {
    content: "";
}
.la-shield:before {
    content: "";
}
.la-ship:before {
    content: "";
}
.la-shirtsinbulk:before {
    content: "";
}
.la-shopping-cart:before {
    content: "";
}
.la-sign-in:before {
    content: "";
}
.la-sign-out:before {
    content: "";
}
.la-signal:before {
    content: "";
}
.la-simplybuilt:before {
    content: "";
}
.la-sitemap:before {
    content: "";
}
.la-skyatlas:before {
    content: "";
}
.la-skype:before {
    content: "";
}
.la-slack:before {
    content: "";
}
.la-sliders:before {
    content: "";
}
.la-slideshare:before {
    content: "";
}
.la-smile-o:before {
    content: "";
}
.la-sort:before,
.la-unsorted:before {
    content: "";
}
.la-sort-alpha-asc:before {
    content: "";
}
.la-sort-alpha-desc:before {
    content: "";
}
.la-sort-amount-asc:before {
    content: "";
}
.la-sort-amount-desc:before {
    content: "";
}
.la-sort-asc:before,
.la-sort-up:before {
    content: "";
}
.la-sort-desc:before,
.la-sort-down:before {
    content: "";
}
.la-sort-numeric-asc:before {
    content: "";
}
.la-sort-numeric-desc:before {
    content: "";
}
.la-soundcloud:before {
    content: "";
}
.la-space-shuttle:before {
    content: "";
}
.la-spinner:before {
    content: "";
}
.la-spoon:before {
    content: "";
}
.la-spotify:before {
    content: "";
}
.la-square:before {
    content: "";
}
.la-square-o:before {
    content: "";
}
.la-stack-exchange:before {
    content: "";
}
.la-stack-overflow:before {
    content: "";
}
.la-star:before {
    content: "";
}
.la-star-half:before {
    content: "";
}
.la-star-half-o:before,
.la-star-half-full:before,
.la-star-half-empty:before {
    content: "";
}
.la-star-o:before {
    content: "";
}
.la-steam:before {
    content: "";
}
.la-steam-square:before {
    content: "";
}
.la-step-backward:before {
    content: "";
}
.la-step-forward:before {
    content: "";
}
.la-stethoscope:before {
    content: "";
}
.la-sticky-note:before {
    content: "";
}
.la-sticky-note-o:before {
    content: "";
}
.la-stop:before {
    content: "";
}
.la-street-view:before {
    content: "";
}
.la-strikethrough:before {
    content: "";
}
.la-stumbleupon:before {
    content: "";
}
.la-stumbleupon-circle:before {
    content: "";
}
.la-subscript:before {
    content: "";
}
.la-subway:before {
    content: "";
}
.la-suitcase:before {
    content: "";
}
.la-sun-o:before {
    content: "";
}
.la-superscript:before {
    content: "";
}
.la-table:before {
    content: "";
}
.la-tablet:before {
    content: "";
}
.la-tachometer:before {
    content: "";
}
.la-tag:before {
    content: "";
}
.la-tags:before {
    content: "";
}
.la-tasks:before {
    content: "";
}
.la-taxi:before {
    content: "";
}
.la-television:before,
.la-tv:before {
    content: "";
}
.la-tencent-weibo:before {
    content: "";
}
.la-terminal:before {
    content: "";
}
.la-text-height:before {
    content: "";
}
.la-text-width:before {
    content: "";
}
.la-th:before {
    content: "";
}
.la-th-large:before {
    content: "";
}
.la-th-list:before {
    content: "";
}
.la-thumb-tack:before {
    content: "";
}
.la-thumbs-down:before {
    content: "";
}
.la-thumbs-o-down:before {
    content: "";
}
.la-thumbs-o-up:before {
    content: "";
}
.la-thumbs-up:before {
    content: "";
}
.la-ticket:before {
    content: "";
}
.la-times:before,
.la-remove:before {
    content: "";
}
.la-times-circle:before {
    content: "";
}
.la-times-circle-o:before {
    content: "";
}
.la-tint:before {
    content: "";
}
.la-toggle-off:before {
    content: "";
}
.la-toggle-on:before {
    content: "";
}
.la-trademark:before {
    content: "";
}
.la-train:before {
    content: "";
}
.la-transgender:before,
.la-intersex:before {
    content: "";
}
.la-transgender-alt:before {
    content: "";
}
.la-trash:before {
    content: "";
}
.la-trash-o:before {
    content: "";
}
.la-tree:before {
    content: "";
}
.la-trello:before {
    content: "";
}
.la-tripadvisor:before {
    content: "";
}
.la-trophy:before {
    content: "";
}
.la-truck:before {
    content: "";
}
.la-try:before {
    content: "";
}
.la-tty:before {
    content: "";
}
.la-tumblr:before {
    content: "";
}
.la-tumblr-square:before {
    content: "";
}
.la-turkish-lira:before {
    content: "";
}
.la-twitch:before {
    content: "";
}
.la-twitter:before {
    content: "";
}
.la-twitter-square:before {
    content: "";
}
.la-umbrella:before {
    content: "";
}
.la-underline:before {
    content: "";
}
.la-undo:before {
    content: "";
}
.la-university:before {
    content: "";
}
.la-unlink:before {
    content: "";
}
.la-unlock:before {
    content: "";
}
.la-unlock-alt:before {
    content: "";
}
.la-upload:before {
    content: "";
}
.la-usd:before {
    content: "";
}
.la-user:before {
    content: "";
}
.la-user-md:before {
    content: "";
}
.la-user-plus:before {
    content: "";
}
.la-user-secret:before {
    content: "";
}
.la-user-times:before {
    content: "";
}
.la-users:before {
    content: "";
}
.la-venus:before {
    content: "";
}
.la-venus-double:before {
    content: "";
}
.la-venus-mars:before {
    content: "";
}
.la-viacoin:before {
    content: "";
}
.la-video-camera:before {
    content: "";
}
.la-vimeo:before {
    content: "";
}
.la-vimeo-square:before {
    content: "";
}
.la-vine:before {
    content: "";
}
.la-vk:before {
    content: "";
}
.la-volume-down:before {
    content: "";
}
.la-volume-off:before {
    content: "";
}
.la-volume-up:before {
    content: "";
}
.la-warning:before {
    content: "";
}
.la-wechat:before {
    content: "";
}
.la-weibo:before {
    content: "";
}
.la-weixin:before {
    content: "";
}
.la-whatsapp:before {
    content: "";
}
.la-wheelchair:before {
    content: "";
}
.la-wifi:before {
    content: "";
}
.la-wikipedia-w:before {
    content: "";
}
.la-windows:before {
    content: "";
}
.la-won:before {
    content: "";
}
.la-wordpress:before {
    content: "";
}
.la-wrench:before {
    content: "";
}
.la-xing:before {
    content: "";
}
.la-xing-square:before {
    content: "";
}
.la-y-combinator:before {
    content: "";
}
.la-y-combinator-square:before {
    content: "";
}
.la-yahoo:before {
    content: "";
}
.la-yc:before {
    content: "";
}
.la-yc-square:before {
    content: "";
}
.la-yelp:before {
    content: "";
}
.la-yen:before {
    content: "";
}
.la-youtube:before {
    content: "";
}
.la-youtube-play:before {
    content: "";
}
.la-youtube-square:before {
    content: "";
}
@font-face {
    font-family: "Socicon";
    src: url("../fonts/Socicon.eot?87visu");
    src: url("../fonts/Socicon.eot?87visu#iefix") format("embedded-opentype"),
        url("../fonts/Socicon.woff2?87visu") format("woff2"), url("../fonts/Socicon.ttf?87visu") format("truetype"),
        url("../fonts/Socicon.woff?87visu") format("woff"), url("../fonts/Socicon.svg?87visu#Socicon") format("svg");
    font-weight: normal;
    font-style: normal;
}
[class^="socicon-"],
[class*=" socicon-"] {
    font-family: "Socicon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.socicon-eitaa:before {
    content: "";
}
.socicon-soroush:before {
    content: "";
}
.socicon-bale:before {
    content: "";
}
.socicon-zazzle:before {
    content: "";
}
.socicon-society6:before {
    content: "";
}
.socicon-redbubble:before {
    content: "";
}
.socicon-avvo:before {
    content: "";
}
.socicon-stitcher:before {
    content: "";
}
.socicon-googlehangouts:before {
    content: "";
}
.socicon-dlive:before {
    content: "";
}
.socicon-vsco:before {
    content: "";
}
.socicon-flipboard:before {
    content: "";
}
.socicon-ubuntu:before {
    content: "";
}
.socicon-artstation:before {
    content: "";
}
.socicon-invision:before {
    content: "";
}
.socicon-torial:before {
    content: "";
}
.socicon-collectorz:before {
    content: "";
}
.socicon-seenthis:before {
    content: "";
}
.socicon-googleplaymusic:before {
    content: "";
}
.socicon-debian:before {
    content: "";
}
.socicon-filmfreeway:before {
    content: "";
}
.socicon-gnome:before {
    content: "";
}
.socicon-itchio:before {
    content: "";
}
.socicon-jamendo:before {
    content: "";
}
.socicon-mix:before {
    content: "";
}
.socicon-sharepoint:before {
    content: "";
}
.socicon-tinder:before {
    content: "";
}
.socicon-windguru:before {
    content: "";
}
.socicon-cdbaby:before {
    content: "";
}
.socicon-elementaryos:before {
    content: "";
}
.socicon-stage32:before {
    content: "";
}
.socicon-tiktok:before {
    content: "";
}
.socicon-gitter:before {
    content: "";
}
.socicon-letterboxd:before {
    content: "";
}
.socicon-threema:before {
    content: "";
}
.socicon-splice:before {
    content: "";
}
.socicon-metapop:before {
    content: "";
}
.socicon-naver:before {
    content: "";
}
.socicon-remote:before {
    content: "";
}
.socicon-internet:before {
    content: "";
}
.socicon-moddb:before {
    content: "";
}
.socicon-indiedb:before {
    content: "";
}
.socicon-traxsource:before {
    content: "";
}
.socicon-gamefor:before {
    content: "";
}
.socicon-pixiv:before {
    content: "";
}
.socicon-myanimelist:before {
    content: "";
}
.socicon-blackberry:before {
    content: "";
}
.socicon-wickr:before {
    content: "";
}
.socicon-spip:before {
    content: "";
}
.socicon-napster:before {
    content: "";
}
.socicon-beatport:before {
    content: "";
}
.socicon-hackerone:before {
    content: "";
}
.socicon-hackernews:before {
    content: "";
}
.socicon-smashwords:before {
    content: "";
}
.socicon-kobo:before {
    content: "";
}
.socicon-bookbub:before {
    content: "";
}
.socicon-mailru:before {
    content: "";
}
.socicon-gitlab:before {
    content: "";
}
.socicon-instructables:before {
    content: "";
}
.socicon-portfolio:before {
    content: "";
}
.socicon-codered:before {
    content: "";
}
.socicon-origin:before {
    content: "";
}
.socicon-nextdoor:before {
    content: "";
}
.socicon-udemy:before {
    content: "";
}
.socicon-livemaster:before {
    content: "";
}
.socicon-crunchbase:before {
    content: "";
}
.socicon-homefy:before {
    content: "";
}
.socicon-calendly:before {
    content: "";
}
.socicon-realtor:before {
    content: "";
}
.socicon-tidal:before {
    content: "";
}
.socicon-qobuz:before {
    content: "";
}
.socicon-natgeo:before {
    content: "";
}
.socicon-mastodon:before {
    content: "";
}
.socicon-unsplash:before {
    content: "";
}
.socicon-homeadvisor:before {
    content: "";
}
.socicon-angieslist:before {
    content: "";
}
.socicon-codepen:before {
    content: "";
}
.socicon-slack:before {
    content: "";
}
.socicon-openaigym:before {
    content: "";
}
.socicon-logmein:before {
    content: "";
}
.socicon-fiverr:before {
    content: "";
}
.socicon-gotomeeting:before {
    content: "";
}
.socicon-aliexpress:before {
    content: "";
}
.socicon-guru:before {
    content: "";
}
.socicon-appstore:before {
    content: "";
}
.socicon-homes:before {
    content: "";
}
.socicon-zoom:before {
    content: "";
}
.socicon-alibaba:before {
    content: "";
}
.socicon-craigslist:before {
    content: "";
}
.socicon-wix:before {
    content: "";
}
.socicon-redfin:before {
    content: "";
}
.socicon-googlecalendar:before {
    content: "";
}
.socicon-shopify:before {
    content: "";
}
.socicon-freelancer:before {
    content: "";
}
.socicon-seedrs:before {
    content: "";
}
.socicon-bing:before {
    content: "";
}
.socicon-doodle:before {
    content: "";
}
.socicon-bonanza:before {
    content: "";
}
.socicon-squarespace:before {
    content: "";
}
.socicon-toptal:before {
    content: "";
}
.socicon-gust:before {
    content: "";
}
.socicon-ask:before {
    content: "";
}
.socicon-trulia:before {
    content: "";
}
.socicon-loomly:before {
    content: "";
}
.socicon-ghost:before {
    content: "";
}
.socicon-upwork:before {
    content: "";
}
.socicon-fundable:before {
    content: "";
}
.socicon-booking:before {
    content: "";
}
.socicon-googlemaps:before {
    content: "";
}
.socicon-zillow:before {
    content: "";
}
.socicon-niconico:before {
    content: "";
}
.socicon-toneden:before {
    content: "";
}
.socicon-augment:before {
    content: "";
}
.socicon-bitbucket:before {
    content: "";
}
.socicon-fyuse:before {
    content: "";
}
.socicon-yt-gaming:before {
    content: "";
}
.socicon-sketchfab:before {
    content: "";
}
.socicon-mobcrush:before {
    content: "";
}
.socicon-microsoft:before {
    content: "";
}
.socicon-pandora:before {
    content: "";
}
.socicon-messenger:before {
    content: "";
}
.socicon-gamewisp:before {
    content: "";
}
.socicon-bloglovin:before {
    content: "";
}
.socicon-tunein:before {
    content: "";
}
.socicon-gamejolt:before {
    content: "";
}
.socicon-trello:before {
    content: "";
}
.socicon-spreadshirt:before {
    content: "";
}
.socicon-500px:before {
    content: "";
}
.socicon-8tracks:before {
    content: "";
}
.socicon-airbnb:before {
    content: "";
}
.socicon-alliance:before {
    content: "";
}
.socicon-amazon:before {
    content: "";
}
.socicon-amplement:before {
    content: "";
}
.socicon-android:before {
    content: "";
}
.socicon-angellist:before {
    content: "";
}
.socicon-apple:before {
    content: "";
}
.socicon-appnet:before {
    content: "";
}
.socicon-baidu:before {
    content: "";
}
.socicon-bandcamp:before {
    content: "";
}
.socicon-battlenet:before {
    content: "";
}
.socicon-mixer:before {
    content: "";
}
.socicon-bebee:before {
    content: "";
}
.socicon-bebo:before {
    content: "";
}
.socicon-behance:before {
    content: "";
}
.socicon-blizzard:before {
    content: "";
}
.socicon-blogger:before {
    content: "";
}
.socicon-buffer:before {
    content: "";
}
.socicon-chrome:before {
    content: "";
}
.socicon-coderwall:before {
    content: "";
}
.socicon-curse:before {
    content: "";
}
.socicon-dailymotion:before {
    content: "";
}
.socicon-deezer:before {
    content: "";
}
.socicon-delicious:before {
    content: "";
}
.socicon-deviantart:before {
    content: "";
}
.socicon-diablo:before {
    content: "";
}
.socicon-digg:before {
    content: "";
}
.socicon-discord:before {
    content: "";
}
.socicon-disqus:before {
    content: "";
}
.socicon-douban:before {
    content: "";
}
.socicon-draugiem:before {
    content: "";
}
.socicon-dribbble:before {
    content: "";
}
.socicon-drupal:before {
    content: "";
}
.socicon-ebay:before {
    content: "";
}
.socicon-ello:before {
    content: "";
}
.socicon-endomodo:before {
    content: "";
}
.socicon-etsy:before {
    content: "";
}
.socicon-facebook:before {
    content: "";
}
.socicon-feedburner:before {
    content: "";
}
.socicon-filmweb:before {
    content: "";
}
.socicon-firefox:before {
    content: "";
}
.socicon-flattr:before {
    content: "";
}
.socicon-flickr:before {
    content: "";
}
.socicon-formulr:before {
    content: "";
}
.socicon-forrst:before {
    content: "";
}
.socicon-foursquare:before {
    content: "";
}
.socicon-friendfeed:before {
    content: "";
}
.socicon-github:before {
    content: "";
}
.socicon-goodreads:before {
    content: "";
}
.socicon-google:before {
    content: "";
}
.socicon-googlescholar:before {
    content: "";
}
.socicon-googlegroups:before {
    content: "";
}
.socicon-googlephotos:before {
    content: "";
}
.socicon-googleplus:before {
    content: "";
}
.socicon-grooveshark:before {
    content: "";
}
.socicon-hackerrank:before {
    content: "";
}
.socicon-hearthstone:before {
    content: "";
}
.socicon-hellocoton:before {
    content: "";
}
.socicon-heroes:before {
    content: "";
}
.socicon-smashcast:before {
    content: "";
}
.socicon-horde:before {
    content: "";
}
.socicon-houzz:before {
    content: "";
}
.socicon-icq:before {
    content: "";
}
.socicon-identica:before {
    content: "";
}
.socicon-imdb:before {
    content: "";
}
.socicon-instagram:before {
    content: "";
}
.socicon-issuu:before {
    content: "";
}
.socicon-istock:before {
    content: "";
}
.socicon-itunes:before {
    content: "";
}
.socicon-keybase:before {
    content: "";
}
.socicon-lanyrd:before {
    content: "";
}
.socicon-lastfm:before {
    content: "";
}
.socicon-line:before {
    content: "";
}
.socicon-linkedin:before {
    content: "";
}
.socicon-livejournal:before {
    content: "";
}
.socicon-lyft:before {
    content: "";
}
.socicon-macos:before {
    content: "";
}
.socicon-mail:before {
    content: "";
}
.socicon-medium:before {
    content: "";
}
.socicon-meetup:before {
    content: "";
}
.socicon-mixcloud:before {
    content: "";
}
.socicon-modelmayhem:before {
    content: "";
}
.socicon-mumble:before {
    content: "";
}
.socicon-myspace:before {
    content: "";
}
.socicon-newsvine:before {
    content: "";
}
.socicon-nintendo:before {
    content: "";
}
.socicon-npm:before {
    content: "";
}
.socicon-odnoklassniki:before {
    content: "";
}
.socicon-openid:before {
    content: "";
}
.socicon-opera:before {
    content: "";
}
.socicon-outlook:before {
    content: "";
}
.socicon-overwatch:before {
    content: "";
}
.socicon-patreon:before {
    content: "";
}
.socicon-paypal:before {
    content: "";
}
.socicon-periscope:before {
    content: "";
}
.socicon-persona:before {
    content: "";
}
.socicon-pinterest:before {
    content: "";
}
.socicon-play:before {
    content: "";
}
.socicon-player:before {
    content: "";
}
.socicon-playstation:before {
    content: "";
}
.socicon-pocket:before {
    content: "";
}
.socicon-qq:before {
    content: "";
}
.socicon-quora:before {
    content: "";
}
.socicon-raidcall:before {
    content: "";
}
.socicon-ravelry:before {
    content: "";
}
.socicon-reddit:before {
    content: "";
}
.socicon-renren:before {
    content: "";
}
.socicon-researchgate:before {
    content: "";
}
.socicon-residentadvisor:before {
    content: "";
}
.socicon-reverbnation:before {
    content: "";
}
.socicon-rss:before {
    content: "";
}
.socicon-sharethis:before {
    content: "";
}
.socicon-skype:before {
    content: "";
}
.socicon-slideshare:before {
    content: "";
}
.socicon-smugmug:before {
    content: "";
}
.socicon-snapchat:before {
    content: "";
}
.socicon-songkick:before {
    content: "";
}
.socicon-soundcloud:before {
    content: "";
}
.socicon-spotify:before {
    content: "";
}
.socicon-stackexchange:before {
    content: "";
}
.socicon-stackoverflow:before {
    content: "";
}
.socicon-starcraft:before {
    content: "";
}
.socicon-stayfriends:before {
    content: "";
}
.socicon-steam:before {
    content: "";
}
.socicon-storehouse:before {
    content: "";
}
.socicon-strava:before {
    content: "";
}
.socicon-streamjar:before {
    content: "";
}
.socicon-stumbleupon:before {
    content: "";
}
.socicon-swarm:before {
    content: "";
}
.socicon-teamspeak:before {
    content: "";
}
.socicon-teamviewer:before {
    content: "";
}
.socicon-technorati:before {
    content: "";
}
.socicon-telegram:before {
    content: "";
}
.socicon-tripadvisor:before {
    content: "";
}
.socicon-tripit:before {
    content: "";
}
.socicon-triplej:before {
    content: "";
}
.socicon-tumblr:before {
    content: "";
}
.socicon-twitch:before {
    content: "";
}
.socicon-twitter:before {
    content: "";
}
.socicon-uber:before {
    content: "";
}
.socicon-ventrilo:before {
    content: "";
}
.socicon-viadeo:before {
    content: "";
}
.socicon-viber:before {
    content: "";
}
.socicon-viewbug:before {
    content: "";
}
.socicon-vimeo:before {
    content: "";
}
.socicon-vine:before {
    content: "";
}
.socicon-vkontakte:before {
    content: "";
}
.socicon-warcraft:before {
    content: "";
}
.socicon-wechat:before {
    content: "";
}
.socicon-weibo:before {
    content: "";
}
.socicon-whatsapp:before {
    content: "";
}
.socicon-wikipedia:before {
    content: "";
}
.socicon-windows:before {
    content: "";
}
.socicon-wordpress:before {
    content: "";
}
.socicon-wykop:before {
    content: "";
}
.socicon-xbox:before {
    content: "";
}
.socicon-xing:before {
    content: "";
}
.socicon-yahoo:before {
    content: "";
}
.socicon-yammer:before {
    content: "";
}
.socicon-yandex:before {
    content: "";
}
.socicon-yelp:before {
    content: "";
}
.socicon-younow:before {
    content: "";
}
.socicon-youtube:before {
    content: "";
}
.socicon-zapier:before {
    content: "";
}
.socicon-zerply:before {
    content: "";
}
.socicon-zomato:before {
    content: "";
}
.socicon-zynga:before {
    content: "";
}
.vertical-menu {
    list-style: none;
    margin: 0;
    padding: 0 0 200px 0;
}
.vertical-menu > li {
    position: relative;
    margin: 0;
    padding: 0;
}
.vertical-menu > li > a {
    padding: 10px 30px;
    display: block;
    color: #282828;
}
.vertical-menu > li > a > i {
    display: inline-block;
    width: 30px;
    font-size: 16px;
    vertical-align: middle;
}
.vertical-menu > li > a > img {
    display: inline-block;
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
}
.vertical-menu > li > a > span {
    vertical-align: middle;
}
.vertical-menu > li:hover > a {
    background-color: transparent;
    color: var(--main-color-primary);
}
.vertical-menu > li.active > a {
    background-color: transparent;
    color: var(--main-color-primary);
}
.vertical-menu > li.active {
    background-color: transparent;
}
.vertical-menu > li .label,
.vertical-menu > li .badge {
    margin-top: 4px;
}
.vertical-menu li.vertical-header {
    padding: 15px 25px 5px 25px;
    color: #8a98ac;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.vertical-menu li:first-child.vertical-header {
    padding: 5px 25px 5px 25px;
}
.vertical-menu li > a > .icon-chevron-right {
    width: auto;
    height: auto;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    -webkit-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
}
.vertical-menu li.active > a > .icon-chevron-right {
    transform: rotate(90deg);
}
.vertical-menu li.active > .vertical-submenu {
    display: block;
}
.vertical-menu a {
    color: #8a98ac;
    text-decoration: none;
}
.vertical-menu .vertical-submenu {
    display: none;
    list-style: none;
    padding-left: 5px;
    padding: 5px 0 5px 5px;
    margin: 0 1px;
    background: transparent;
}
.vertical-menu .vertical-submenu .vertical-submenu {
    padding-left: 15px;
}
.vertical-menu .vertical-submenu > li > a {
    padding: 8px 25px 8px 55px;
    display: block;
    font-size: 14px;
    color: #282828;
}
.vertical-menu .vertical-submenu > li > a > .mdi {
    font-size: 5px;
    margin-right: 10px;
    vertical-align: middle;
    color: #282828;
}
.vertical-menu .vertical-submenu > li > a > .icon-chevron-right,
.vertical-menu .vertical-submenu > li > a > .icon-chevron-down {
    width: auto;
}
.vertical-menu .vertical-submenu > li.active > a {
    color: var(--main-color-primary);
}
.vertical-menu .vertical-submenu > li.active > a > .mdi {
    color: var(--main-color-primary);
}
.vertical-menu .vertical-submenu > li > a:hover {
    color: var(--main-color-primary);
}
.vertical-menu .vertical-submenu > li > a:hover > .mdi {
    color: var(--main-color-primary);
}
.vertical-menu-rtl {
    list-style: none;
    margin: 0;
    padding: 0 0 200px 0;
}
.vertical-menu-rtl > li {
    position: relative;
    margin: 0;
    padding: 0;
}
.vertical-menu-rtl > li > a {
    padding: 30px 10px;
    display: block;
    color: #282828;
}
.vertical-menu-rtl > li > a > i {
    display: inline-block;
    width: 30px;
    font-size: 16px;
    vertical-align: middle;
}
.vertical-menu-rtl > li > a > img {
    display: inline-block;
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
}
.vertical-menu-rtl > li > a > span {
    vertical-align: middle;
}
.vertical-menu-rtl > li:hover > a {
    background-color: transparent;
    color: var(--main-color-primary);
}
.vertical-menu-rtl > li.active > a {
    background-color: transparent;
    color: var(--main-color-primary);
}
.vertical-menu-rtl > li.active {
    background-color: transparent;
}
.vertical-menu-rtl > li .label,
.vertical-menu-rtl > li .badge {
    margin-top: 4px;
}
.vertical-menu-rtl li.vertical-header {
    padding: 10px 15px 10px 25px;
    color: #8a98ac;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.vertical-menu-rtl li:first-child.vertical-header {
    padding: 5px 25px 5px 25px;
}
.vertical-menu-rtl li > a > .icon-chevron-right {
    width: auto;
    height: auto;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    -webkit-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
}
.vertical-menu-rtl li.active > a > .icon-chevron-right {
    transform: rotate(90deg);
}
.vertical-menu-rtl li.active > .vertical-submenu {
    display: block;
}
.vertical-menu-rtl a {
    color: #8a98ac;
    text-decoration: none;
}
.vertical-menu-rtl .vertical-submenu {
    display: none;
    list-style: none;
    padding-right: 5px;
    margin: 0 1px;
    background: transparent;
}
.vertical-menu-rtl .vertical-submenu .vertical-submenu {
    padding-right: 15px;
}
.vertical-menu-rtl .vertical-submenu > li > a {
    padding: 8px 45px 8px 55px;
    display: block;
    font-size: 14px;
    color: #282828;
}
.vertical-menu-rtl .vertical-submenu > li > a > .mdi {
    font-size: 5px;
    margin-right: 10px;
    vertical-align: middle;
    color: #282828;
}
.vertical-menu-rtl .vertical-submenu > li > a > .icon-chevron-right,
.vertical-menu-rtl .vertical-submenu > li > a > .icon-chevron-down {
    width: auto;
}
.vertical-menu-rtl .vertical-submenu > li.active > a {
    color: var(--main-color-primary);
}
.vertical-menu-rtl .vertical-submenu > li.active > a > .mdi {
    color: var(--main-color-primary);
}
.vertical-menu-rtl .vertical-submenu > li > a:hover {
    color: var(--main-color-primary);
}
.vertical-menu-rtl .vertical-submenu > li > a:hover > .mdi {
    color: var(--main-color-primary);
}
.toggle-menu .leftbar {
    position: fixed;
    width: 250px;
    margin-left: 0;
    transition: all 0.3s ease;
}
.toggle-menu .rightbar {
    margin-left: 80px;
}
.toggle-menu .rightbar .topbar {
    left: 80px;
}
.topbar-mobile {
    display: none;
    background-color: #ffffff;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.topbar-mobile .mobile-logobarHo {
    display: inline-block;
}
.topbar-mobile .mobile-logobarHo img {
    width: 120px;
}
.topbar-mobile .mobile-togglebar {
    display: inline-block;
    float: right;
    margin-top: 2px;
}
.topbar-mobile .mobile-togglebar a.topbar-toggle-hamburger img {
    width: 24px;
}
.topbar-mobile .mobile-togglebar a.topbar-toggle-hamburger img.menu-hamburger-vertical {
    display: none;
}
.topbar-mobile .mobile-togglebar .menu-hamburger img {
    width: 24px;
}
.topbar-mobile .mobile-togglebar .menu-hamburger img.menu-hamburger-close {
    display: none;
}
.topbar {
    background-color: #ffffff;
    padding: 15px 30px;
    position: fixed;
    z-index: 1;
    left: 250px;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.topbar .dropdown-toggle::after {
    display: none;
}
.topbar .searchbar input[type="search"] {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    font-size: 16px;
    padding-left: 20px;
    border: none;
    border-radius: 50px 0 0 50px;
}
.topbar .searchbar ::placeholder {
    color: var(--main-color-primary);
}
.topbar .searchbar .input-group-append {
    margin-left: 0px;
}
.topbar .searchbar .btn {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    font-weight: 700;
    font-size: 18px;
    font-weight: 700;
    border-radius: 0 50px 50px 0;
    padding: 3px 15px 3px 5px;
    box-shadow: none;
}
.topbar .searchbar .btn img {
    width: 20px;
}
.topbar .togglebar {
    display: inline-block;
    padding-top: 1px;
}
.topbar .togglebar .menu-hamburger img {
    width: 20px;
}
.topbar .togglebar .menu-hamburger img.menu-hamburger-close {
    display: none;
}
.topbar .infobar {
    display: inline-block;
    float: right;
}
.topbar .infobar > ul > li {
    margin-left: 15px;
    margin-right: 0;
}
.topbar .infobar > ul > li:first-child {
    margin-left: 0;
}
.topbar .infobar .infobar-icon {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    border-radius: 50%;
    display: flex;
    line-height: 40px;
    width: 40px;
    height: 40px;
    padding: 10px;
}
.topbar .infobar .infobar-icon i {
    padding: 12px;
}
.topbar .infobar .infobar-icon .live-icon {
    position: absolute;
    bottom: -5px;
    margin: 0px 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-color: var(--main-color-primary);
}
.topbar .infobar .infobar-icon:hover {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.topbar .infobar .infobar-icon:hover img {
    filter: invert(0) sepia(0) saturate(14) hue-rotate(225deg);
}
.topbar .infobar .flag {
    display: inline-block;
    font-size: 18px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    vertical-align: middle;
    text-align: center;
    background-color: #ffffff;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-top: -12px;
}
.breadcrumbbar {
    padding: 30px 30px 0 30px;
    margin-top: 70px;
}
.breadcrumbbar .page-title {
    font-size: 20px;
    color: #282828;
    margin-bottom: 5px;
    margin-top: 0;
}
.breadcrumbbar .breadcrumb-list {
    text-align: left;
}
.breadcrumbbar .breadcrumb {
    padding: 0 0 0;
}
.breadcrumbbar .breadcrumb .breadcrumb-item a {
    color: #8a98ac;
}
.breadcrumbbar .breadcrumb .breadcrumb-item.active {
    color: #282828;
}
.breadcrumbbar .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 5px;
    vertical-align: middle;
}
.breadcrumbbar .widgetbar {
    text-align: right;
}
.infobar-notifications-sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
}
.infobar-notifications-sidebar.sidebarshow {
    width: 420px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-head {
    background: #ffffff;
    color: #282828;
    padding: 14px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-head h4 {
    margin-bottom: 0;
    line-height: 40px;
    font-size: 20px;
    color: #282828;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-head .infobar-notifications-close {
    background-color: rgba(110, 129, 220, 0.1);
    padding: 4px 10px;
    color: #ffffff;
    border-radius: 50%;
    font-size: 20px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-head .infobar-notifications-close:hover {
    background-color: var(--main-color-primary);
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-head .infobar-notifications-close img {
    width: 20px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .nav {
    padding: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .nav-link {
    padding: 8px 14px;
    border-radius: 0;
    color: var(--main-color-primary);
    background-color: rgba(110, 129, 220, 0.1);
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .nav-link:hover {
    background-color: rgba(110, 129, 220, 0.25);
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .nav-link.active:hover {
    background-color: var(--main-color-primary);
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content {
    padding: 15px 0;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content .media {
    padding: 15px 30px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content .media:hover {
    background-color: rgba(113, 128, 147, 0.1);
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content .media img {
    width: 42px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content .media .img-icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    background-color: #dcdde1;
    color: #8a98ac;
    font-weight: 600;
    border-radius: 50%;
    font-size: 18px;
    text-align: center;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content .media .action-icon {
    width: 46px;
    height: 46px;
    line-height: 38px;
    font-weight: 600;
    border-radius: 50%;
    font-size: 18px;
    text-align: center;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content h5 {
    font-size: 16px;
    color: #282828;
    margin-top: 0;
    margin-bottom: 5px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content h5 .badge {
    margin-left: 7px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content h5 .timing {
    color: #8a98ac;
    float: right;
    font-size: 12px;
    margin-top: 3px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content h5.action-title {
    margin-top: 12px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content p {
    margin-bottom: 0;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content p span {
    margin-right: 5px;
}
.infobar-notifications-sidebar .infobar-notifications-sidebar-body .tab-content p .timing {
    color: #8a98ac;
    float: right;
    font-size: 12px;
    margin-top: 3px;
}
.infobar-notifications-sidebar-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
}
.infobar-settings-sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
}
.infobar-settings-sidebar.sidebarshow {
    width: 420px;
}
.infobar-settings-sidebar .infobar-settings-sidebar-head {
    background-color: #ffffff;
    color: #282828;
    padding: 14px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.infobar-settings-sidebar .infobar-settings-sidebar-head h4 {
    margin-bottom: 0;
    line-height: 40px;
    font-size: 20px;
    color: #282828;
}
.infobar-settings-sidebar .infobar-settings-sidebar-head .infobar-settings-close {
    background-color: rgba(110, 129, 220, 0.1);
    padding: 4px 10px;
    color: #282828;
    border-radius: 50%;
    font-size: 20px;
}
.infobar-settings-sidebar .infobar-settings-sidebar-head .infobar-settings-close:hover {
    background-color: var(--main-color-primary);
}
.infobar-settings-sidebar .infobar-settings-sidebar-head .infobar-settings-close img {
    width: 20px;
}
.infobar-settings-sidebar .infobar-settings-sidebar-body .custom-color-setting {
    padding: 30px;
}
.infobar-settings-sidebar .infobar-settings-sidebar-body .custom-mode-setting {
    padding: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.infobar-settings-sidebar .infobar-settings-sidebar-body .custom-layout-setting {
    padding: 30px;
}
.infobar-settings-sidebar-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
}
.languagebar .dropdown-menu {
    top: 18px !important;
    border-radius: 3px;
}
.languagebar .dropdown-menu .dropdown-item {
    color: #8a98ac;
}
.languagebar .dropdown-menu .dropdown-item:focus {
    color: #282828;
    background-color: rgba(0, 0, 0, 0.05);
}
.languagebar .dropdown-menu .dropdown-item:hover {
    color: #282828;
    background-color: rgba(0, 0, 0, 0.05);
}
.languagebar .dropdown-menu i {
    margin-right: 10px;
}
.languagebar .dropdown-menu .flag {
    width: 26px;
    height: 26px;
    line-height: 26px;
    margin-top: 0;
}
.account-box {
    background-color: #dcdde1;
    border-radius: 3px;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 30px;
}
.account-box h5 {
    color: #282828;
    margin: 15px 0 5px;
}
.account-box p {
    margin-bottom: 0;
}
.account-box.active {
    background-color: var(--main-color-primary);
}
.account-box.active h5 {
    color: #ffffff;
}
.account-box.active p {
    color: #ffffff;
}
.account-box:hover {
    background-color: var(--main-color-primary);
}
.account-box:hover h5 {
    color: #ffffff;
}
.account-box:hover p {
    color: #ffffff;
}
.alert {
    border: 0;
}
.alert .alert-heading {
    font-size: 20px;
}
.alert .alert-link {
    font-weight: 800;
}
.alert-primary {
    color: var(--main-color-primary);
    background-color: rgba(110, 129, 220, 0.1);
    border-color: rgba(110, 129, 220, 0.1);
}
.alert-primary .alert-link {
    color: var(--main-color-primary);
}
.alert-primary hr {
    border-top-color: var(--main-color-primary);
}
.alert-secondary {
    color: #718093;
    background-color: rgba(113, 128, 147, 0.1);
    border-color: rgba(113, 128, 147, 0.1);
}
.alert-secondary .alert-link {
    color: #718093;
}
.alert-secondary hr {
    border-top-color: #718093;
}
.alert-success {
    color: #5fc27e;
    background-color: rgba(95, 194, 126, 0.1);
    border-color: rgba(95, 194, 126, 0.1);
}
.alert-success .alert-link {
    color: #5fc27e;
}
.alert-success hr {
    border-top-color: #5fc27e;
}
.alert-danger {
    color: #f44455;
    background-color: rgba(244, 68, 85, 0.1);
    border-color: rgba(244, 68, 85, 0.1);
}
.alert-danger .alert-link {
    color: #f44455;
}
.alert-danger hr {
    border-top-color: #f44455;
}
.alert-warning {
    color: #fcc100;
    background-color: rgba(252, 193, 0, 0.1);
    border-color: rgba(252, 193, 0, 0.1);
}
.alert-warning .alert-link {
    color: #fcc100;
}
.alert-warning hr {
    border-top-color: #fcc100;
}
.alert-info {
    color: #72d0fb;
    background-color: rgba(114, 208, 251, 0.1);
    border-color: rgba(114, 208, 251, 0.1);
}
.alert-info .alert-link {
    color: #72d0fb;
}
.alert-info hr {
    border-top-color: #72d0fb;
}
.alert-light {
    color: #8a98ac;
    background-color: rgba(220, 221, 225, 0.1);
    border-color: rgba(220, 221, 225, 0.1);
}
.alert-light .alert-link {
    color: #8a98ac;
}
.alert-light hr {
    border-top-color: #8a98ac;
}
.alert-dark {
    color: #2d3646;
    background-color: rgba(45, 54, 70, 0.1);
    border-color: rgba(45, 54, 70, 0.1);
}
.alert-dark .alert-link {
    color: #2d3646;
}
.alert-dark hr {
    border-top-color: #2d3646;
}
.badge {
    font-weight: 600;
}
.badge-default {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.badge-default[href]:hover {
    background-color: var(--main-color-primary-hover);
}
.badge-default[href]:focus {
    background-color: var(--main-color-primary-hover);
}
.badge-primary {
    background-color: var(--main-color-primary);
}
.badge-primary[href]:hover {
    background-color: var(--main-color-primary-hover);
}
.badge-primary[href]:focus {
    background-color: var(--main-color-primary-hover);
}
.badge-secondary {
    background-color: #718093;
}
.badge-secondary[href]:hover {
    background-color: #657385;
}
.badge-secondary[href]:focus {
    background-color: #657385;
}
.badge-success {
    background-color: #5fc27e;
}
.badge-success[href]:hover {
    background-color: #4dbb6f;
}
.badge-success[href]:focus {
    background-color: #4dbb6f;
}
.badge-danger {
    background-color: #f44455;
}
.badge-danger[href]:hover {
    background-color: #f32c3f;
}
.badge-danger[href]:focus {
    background-color: #f32c3f;
}
.badge-warning {
    color: #ffffff;
    background-color: #fcc100;
}
.badge-warning[href]:hover {
    background-color: #e3ad00;
}
.badge-warning[href]:focus {
    background-color: #e3ad00;
}
.badge-info {
    background-color: #72d0fb;
}
.badge-info[href]:hover {
    background-color: #59c8fa;
}
.badge-info[href]:focus {
    background-color: #59c8fa;
}
.badge-light {
    color: #8a98ac;
    background-color: #dcdde1;
}
.badge-light[href]:hover {
    background-color: #ced0d5;
}
.badge-light[href]:focus {
    background-color: #ced0d5;
}
.badge-dark {
    background-color: #2d3646;
}
.badge-dark[href]:hover {
    background-color: #232a36;
}
.badge-dark[href]:focus {
    background-color: #232a36;
}
.badge-default-inverse {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
}
.badge-default-inverse[href]:hover {
    background-color: var(--main-color-primary-hover);
}
.badge-default-inverse[href]:focus {
    background-color: var(--main-color-primary-hover);
}
.badge-primary-inverse {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
}
.badge-primary-inverse[href]:hover {
    background-color: var(--main-color-primary-hover);
    color: #ffffff;
}
.badge-primary-inverse[href]:focus {
    background-color: var(--main-color-primary-hover);
    color: #ffffff;
}
.badge-secondary-inverse {
    background-color: rgba(113, 128, 147, 0.1);
    color: #718093;
}
.badge-secondary-inverse[href]:hover {
    background-color: #657385;
    color: #ffffff;
}
.badge-secondary-inverse[href]:focus {
    background-color: #657385;
    color: #ffffff;
}
.badge-success-inverse {
    background-color: rgba(95, 194, 126, 0.1);
    color: #5fc27e;
}
.badge-success-inverse[href]:hover {
    background-color: #4dbb6f;
    color: #ffffff;
}
.badge-success-inverse[href]:focus {
    background-color: #4dbb6f;
    color: #ffffff;
}
.badge-danger-inverse {
    background-color: rgba(244, 68, 85, 0.1);
    color: #f44455;
}
.badge-danger-inverse[href]:hover {
    background-color: #f32c3f;
    color: #ffffff;
}
.badge-danger-inverse[href]:focus {
    background-color: #f32c3f;
    color: #ffffff;
}
.badge-warning-inverse {
    background-color: rgba(252, 193, 0, 0.1);
    color: #fcc100;
}
.badge-warning-inverse[href]:hover {
    background-color: #e3ad00;
    color: #ffffff;
}
.badge-warning-inverse[href]:focus {
    background-color: #e3ad00;
    color: #ffffff;
}
.badge-info-inverse {
    background-color: rgba(114, 208, 251, 0.1);
    color: #72d0fb;
}
.badge-info-inverse[href]:hover {
    background-color: #59c8fa;
    color: #ffffff;
}
.badge-info-inverse[href]:focus {
    background-color: #59c8fa;
    color: #ffffff;
}
.badge-light-inverse {
    background-color: rgba(220, 221, 225, 0.1);
    color: #8a98ac;
}
.badge-light-inverse[href]:hover {
    background-color: #ced0d5;
    color: #8a98ac;
}
.badge-light-inverse[href]:focus {
    background-color: #ced0d5;
    color: #8a98ac;
}
.badge-dark-inverse {
    background-color: rgba(45, 54, 70, 0.1);
    color: #2d3646;
}
.badge-dark-inverse[href]:hover {
    background-color: #232a36;
    color: #ffffff;
}
.badge-dark-inverse[href]:focus {
    background-color: #232a36;
    color: #ffffff;
}
.button-list .btn {
    margin-right: 10px;
    margin-bottom: 10px;
}
.btn-group .btn {
    margin-right: 0;
}
.dropdown-list .btn {
    margin-bottom: 5px;
}
.btn {
    border-radius: 3px;
    font-size: 16px;
    padding: 6px 12px;
}
.btn-rounded {
    border-radius: 20px;
}
.btn-round {
    width: 40px;
    height: 40px;
    padding: 6px 11px;
    border-radius: 50%;
}
.btn-sm {
    padding: 4px 18px;
}
.btn-white {
    color: #282828;
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: none;
}
.btn-link {
    color: var(--main-color-primary);
    text-decoration: none;
}
.btn-link:hover {
    color: var(--main-color-primary);
    text-decoration: none;
}
.btn-link.focus {
    text-decoration: none;
}
.btn-link:focus {
    text-decoration: none;
}
.btn-primary {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
    box-shadow: none;
}
.btn-primary .disabled {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.btn-primary:disabled {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.btn-primary:active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-primary:hover {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-primary.focus {
    color: #ffffff;
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-primary:focus {
    color: #ffffff;
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-primary:visited {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-primary:not(:disabled):not(.disabled).active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-primary {
    color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.btn-outline-primary:active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-primary:hover {
    color: #ffffff;
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-primary:visited {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.open > .dropdown-toggle.btn-primary {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.show > .btn-outline-primary.dropdown-toggle {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.show > .btn-primary.dropdown-toggle {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-secondary {
    color: #ffffff;
    background-color: #718093;
    border-color: #718093;
    box-shadow: none;
}
.btn-secondary .disabled {
    color: #ffffff;
    background-color: #718093;
    border-color: #718093;
}
.btn-secondary:disabled {
    color: #ffffff;
    background-color: #718093;
    border-color: #718093;
}
.btn-secondary:active {
    background-color: #657385;
    border-color: #657385;
}
.btn-secondary:hover {
    background-color: #657385;
    border-color: #657385;
}
.btn-secondary.focus {
    color: #ffffff;
    background-color: #657385;
    border-color: #657385;
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-secondary:focus {
    color: #ffffff;
    background-color: #657385;
    border-color: #657385;
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-secondary:visited {
    background-color: #657385;
    border-color: #657385;
}
.btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #657385;
    border-color: #657385;
}
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #657385;
    border-color: #657385;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-outline-secondary {
    color: #718093;
    border-color: #718093;
}
.btn-outline-secondary:active {
    background-color: #657385;
    border-color: #657385;
}
.btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #657385;
    border-color: #657385;
}
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-outline-secondary:visited {
    background-color: #657385;
    border-color: #657385;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active {
    background-color: #657385;
    border-color: #657385;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active {
    background-color: #657385;
    border-color: #657385;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.open > .dropdown-toggle.btn-secondary {
    background-color: #657385;
    border-color: #657385;
}
.show > .btn-outline-secondary.dropdown-toggle {
    background-color: #657385;
    border-color: #657385;
}
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #abb4bf;
}
.show > .btn-secondary.dropdown-toggle {
    background-color: #657385;
    border-color: #657385;
}
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #abb4bf;
}
.btn-success {
    color: #ffffff;
    background-color: #5fc27e;
    border-color: #5fc27e;
    box-shadow: none;
}
.btn-success .disabled {
    color: #ffffff;
    background-color: #5fc27e;
    border-color: #5fc27e;
}
.btn-success:disabled {
    color: #ffffff;
    background-color: #5fc27e;
    border-color: #5fc27e;
}
.btn-success:active {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-success:hover {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-success.focus {
    color: #ffffff;
    background-color: #4dbb6f;
    border-color: #4dbb6f;
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-success:focus {
    color: #ffffff;
    background-color: #4dbb6f;
    border-color: #4dbb6f;
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-success:visited {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-success:not(:disabled):not(.disabled).active {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-success:not(:disabled):not(.disabled):active {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-success:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-outline-success {
    color: #5fc27e;
    border-color: #5fc27e;
}
.btn-outline-success:active {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-outline-success:hover {
    color: #ffffff;
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-outline-success:visited {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-outline-success:not(:disabled):not(.disabled).active {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-outline-success:not(:disabled):not(.disabled):active {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.open > .dropdown-toggle.btn-success {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.show > .btn-outline-success.dropdown-toggle {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.show > .btn-success.dropdown-toggle {
    background-color: #4dbb6f;
    border-color: #4dbb6f;
}
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-danger {
    color: #ffffff;
    background-color: #f44455;
    border-color: #f44455;
    box-shadow: none;
}
.btn-danger .disabled {
    color: #ffffff;
    background-color: #f44455;
    border-color: #f44455;
}
.btn-danger:disabled {
    color: #ffffff;
    background-color: #f44455;
    border-color: #f44455;
}
.btn-danger:active {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-danger:hover {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-danger.focus {
    color: #ffffff;
    background-color: #f32c3f;
    border-color: #f32c3f;
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-danger:focus {
    color: #ffffff;
    background-color: #f32c3f;
    border-color: #f32c3f;
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-danger:visited {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-danger:not(:disabled):not(.disabled).active {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-danger:not(:disabled):not(.disabled):active {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-danger:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-outline-danger {
    color: #f44455;
    border-color: #f44455;
}
.btn-outline-danger:active {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-outline-danger:hover {
    color: #ffffff;
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-outline-danger:visited {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-outline-danger:not(:disabled):not(.disabled).active {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-outline-danger:not(:disabled):not(.disabled):active {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.open > .dropdown-toggle.btn-danger {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.show > .btn-outline-danger.dropdown-toggle {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.show > .btn-danger.dropdown-toggle {
    background-color: #f32c3f;
    border-color: #f32c3f;
}
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-warning {
    color: #ffffff;
    background-color: #fcc100;
    border-color: #fcc100;
    box-shadow: none;
}
.btn-warning .disabled {
    color: #ffffff;
    background-color: #fcc100;
    border-color: #fcc100;
}
.btn-warning:disabled {
    color: #ffffff;
    background-color: #fcc100;
    border-color: #fcc100;
}
.btn-warning:active {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-warning:hover {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-warning.focus {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-warning:focus {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-warning:visited {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-warning:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-warning:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-warning:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-outline-warning {
    color: #fcc100;
    border-color: #fcc100;
}
.btn-outline-warning:active {
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-outline-warning:hover {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-outline-warning:visited {
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-outline-warning:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.open > .dropdown-toggle.btn-warning {
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.show > .btn-outline-warning.dropdown-toggle {
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.show > .btn-warning.dropdown-toggle {
    background-color: #e3ad00;
    border-color: #e3ad00;
}
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-info {
    color: #ffffff;
    background-color: #72d0fb;
    border-color: #72d0fb;
    box-shadow: none;
}
.btn-info .disabled {
    color: #ffffff;
    background-color: #72d0fb;
    border-color: #72d0fb;
}
.btn-info:disabled {
    color: #ffffff;
    background-color: #72d0fb;
    border-color: #72d0fb;
}
.btn-info:active {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-info:hover {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-info.focus {
    color: #ffffff;
    background-color: #59c8fa;
    border-color: #59c8fa;
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-info:focus {
    color: #ffffff;
    background-color: #59c8fa;
    border-color: #59c8fa;
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-info:visited {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-info:not(:disabled):not(.disabled).active {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-info:not(:disabled):not(.disabled):active {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-info:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-outline-info {
    color: #72d0fb;
    border-color: #72d0fb;
}
.btn-outline-info:active {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-outline-info:hover {
    color: #ffffff;
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-outline-info:visited {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-outline-info:not(:disabled):not(.disabled).active {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-outline-info:not(:disabled):not(.disabled):active {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.open > .dropdown-toggle.btn-info {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.show > .btn-outline-info.dropdown-toggle {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.show > .btn-info.dropdown-toggle {
    background-color: #59c8fa;
    border-color: #59c8fa;
}
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-light {
    color: #8a98ac;
    background-color: #dcdde1;
    border-color: #dcdde1;
    box-shadow: none;
}
.btn-light .disabled {
    color: #ffffff;
    background-color: #dcdde1;
    border-color: #dcdde1;
}
.btn-light:disabled {
    color: #ffffff;
    background-color: #dcdde1;
    border-color: #dcdde1;
}
.btn-light:active {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-light:hover {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-light.focus {
    color: #8a98ac;
    background-color: #ced0d5;
    border-color: #ced0d5;
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-light:focus {
    color: #8a98ac;
    background-color: #ced0d5;
    border-color: #ced0d5;
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-light:visited {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-light:not(:disabled):not(.disabled).active {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-light:not(:disabled):not(.disabled):active {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-outline-light {
    color: #dcdde1;
    border-color: #dcdde1;
}
.btn-outline-light:active {
    color: #ffffff;
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-outline-light:hover {
    color: #ffffff;
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-outline-light:visited {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-outline-light:not(:disabled):not(.disabled).active {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-outline-light:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.open > .dropdown-toggle.btn-light {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.show > .btn-outline-light.dropdown-toggle {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.show > .btn-light.dropdown-toggle {
    background-color: #ced0d5;
    border-color: #ced0d5;
}
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #dfe0e3;
}
.btn-dark {
    color: #ffffff;
    background-color: #2d3646;
    border-color: #2d3646;
    box-shadow: none;
}
.btn-dark .disabled {
    color: #ffffff;
    background-color: #2d3646;
    border-color: #2d3646;
}
.btn-dark:disabled {
    color: #ffffff;
    background-color: #2d3646;
    border-color: #2d3646;
}
.btn-dark:active {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-dark:hover {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-dark.focus {
    color: #ffffff;
    background-color: #232a36;
    border-color: #232a36;
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-dark:focus {
    color: #ffffff;
    background-color: #232a36;
    border-color: #232a36;
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-dark:visited {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-dark:not(:disabled):not(.disabled).active {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-dark:not(:disabled):not(.disabled):active {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-dark:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-outline-dark {
    color: #2d3646;
    background-image: none;
    background-color: transparent;
    border-color: #2d3646;
}
.btn-outline-dark:active {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-outline-dark:hover {
    color: #ffffff;
    background-color: #232a36;
    border-color: #232a36;
}
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-outline-dark:visited {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-outline-dark:not(:disabled):not(.disabled).active {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-outline-dark:not(:disabled):not(.disabled):active {
    background-color: #232a36;
    border-color: #232a36;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.open > .dropdown-toggle.btn-dark {
    background-color: #232a36;
    border-color: #232a36;
}
.show > .btn-outline-dark.dropdown-toggle {
    background-color: #232a36;
    border-color: #232a36;
}
.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.show > .btn-dark.dropdown-toggle {
    background-color: #232a36;
    border-color: #232a36;
}
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #556684;
}
.btn-default {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
    box-shadow: none;
}
.btn-default .disabled {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.btn-default:disabled {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.btn-default:active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-default:hover {
    color: #ffffff;
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-default.focus {
    color: #ffffff;
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-default:focus {
    color: #ffffff;
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-default:visited {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-default:not(:disabled):not(.disabled).active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-default:not(:disabled):not(.disabled):active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-default:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-default:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-default {
    color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.btn-outline-default:active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-default:hover {
    color: #ffffff;
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-default.focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-default:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-default:visited {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-default:not(:disabled):not(.disabled).active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-default:not(:disabled):not(.disabled):active {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.btn-outline-default:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-outline-default:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.open > .dropdown-toggle.btn-default {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.show > .btn-outline-default.dropdown-toggle {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.show > .btn-outline-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.show > .btn-default.dropdown-toggle {
    background-color: var(--main-color-primary-hover);
    border-color: var(--main-color-primary-hover);
}
.show > .btn-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-primary-rgba {
    background-color: rgba(110, 129, 220, 0.1);
    border: none;
    color: var(--main-color-primary);
}
.btn-primary-rgba:hover {
    background-color: var(--main-color-primary);
    border: none;
    color: #ffffff;
}
.btn-primary-rgba:focus {
    background-color: var(--main-color-primary);
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #c0c8f0;
}
.btn-secondary-rgba {
    background-color: rgba(113, 128, 147, 0.1);
    border: none;
    color: #718093;
}
.btn-secondary-rgba:hover {
    background-color: #718093;
    border: none;
    color: #ffffff;
}
.btn-secondary-rgba:focus {
    background-color: #718093;
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #7f8d9e;
}
.btn-success-rgba {
    background-color: rgba(95, 194, 126, 0.1);
    border: none;
    color: #5fc27e;
}
.btn-success-rgba:hover {
    background-color: #5fc27e;
    border: none;
    color: #ffffff;
}
.btn-success-rgba:focus {
    background-color: #5fc27e;
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.btn-danger-rgba {
    background-color: rgba(244, 68, 85, 0.1);
    border: none;
    color: #f44455;
}
.btn-danger-rgba:hover {
    background-color: #f44455;
    border: none;
    color: #ffffff;
}
.btn-danger-rgba:focus {
    background-color: #f44455;
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.btn-warning-rgba {
    background-color: rgba(252, 193, 0, 0.1);
    border: none;
    color: #fcc100;
}
.btn-warning-rgba:hover {
    background-color: #fcc100;
    border: none;
    color: #ffffff;
}
.btn-warning-rgba:focus {
    background-color: #fcc100;
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #ffda63;
}
.btn-info-rgba {
    background-color: rgba(114, 208, 251, 0.1);
    border: none;
    color: #72d0fb;
}
.btn-info-rgba:hover {
    background-color: #72d0fb;
    border: none;
    color: #ffffff;
}
.btn-info-rgba:focus {
    background-color: #72d0fb;
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #d5f1fe;
}
.btn-light-rgba {
    background-color: rgba(220, 221, 225, 0.1);
    border: none;
    color: #8a98ac;
}
.btn-light-rgba:hover {
    background-color: #dcdde1;
    border: none;
    color: #ffffff;
}
.btn-light-rgba:focus {
    background-color: #dcdde1;
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #e4e5e8;
}
.btn-dark-rgba {
    background-color: rgba(45, 54, 70, 0.1);
    border: none;
    color: #2d3646;
}
.btn-dark-rgba:hover {
    background-color: #2d3646;
    border: none;
    color: #ffffff;
}
.btn-dark-rgba:focus {
    background-color: #2d3646;
    border: none;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem #556684;
}
.fc-toolbar h2 {
    margin: 0;
    font-size: 18px;
    color: #282828;
}
.fc-toolbar .fc-state-active {
    z-index: 4;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
    color: #ffffff;
}
.fc-toolbar .ui-state-active {
    z-index: 4;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
    color: #ffffff;
}
.fc-toolbar .fc-left {
    margin-bottom: 10px;
}
.fc-toolbar .fc-center {
    margin-bottom: 10px;
}
.fc-toolbar .fc-right {
    margin-bottom: 10px;
}
.fc-button {
    background: #dcdde1;
    border: 1px solid #ced0d5;
    color: #282828;
    outline: 0;
    box-shadow: none;
    text-transform: capitalize;
    height: auto !important;
    padding: 6px 15px !important;
}
.fc-state-hover {
    background: #ced0d5;
    border: 1px solid #ced0d5;
}
.fc-state-default.fc-corner-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.fc-state-default.fc-corner-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.fc-widget-header {
    background-color: #eaeaed;
    border: 1px solid #ced0d5;
}
.fc th.fc-widget-header {
    padding: 12px 0;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
}
.fc-unthemed .fc-content {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-unthemed .fc-divider {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-unthemed .fc-popover {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-unthemed .fc-row {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-unthemed tbody {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-unthemed td {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-unthemed th {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-unthemed thead {
    border-color: rgba(0, 0, 0, 0.05);
}
.fc-event {
    background-color: var(--main-color-primary);
    color: #ffffff !important;
    border: none;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    margin: 5px 0;
    padding: 5px 5px;
    cursor: move;
}
.card {
    border: none;
    border-radius: 3px;
    background-color: #ffffff;
}
.card.border-primary {
    border: 1px solid;
}
.card.border-secondary {
    border: 1px solid;
}
.card.border-success {
    border: 1px solid;
}
.card.border-danger {
    border: 1px solid;
}
.card.border-warning {
    border: 1px solid;
}
.card.border-info {
    border: 1px solid;
}
.card.border-light {
    border: 1px solid;
}
.card.border-dark {
    border: 1px solid;
}
.card-primary {
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.card-secondary {
    background-color: #718093;
    border-color: #718093;
}
.card-success {
    background-color: #5fc27e;
    border-color: #5fc27e;
}
.card-danger {
    background-color: #f44455;
    border-color: #f44455;
}
.card-warning {
    background-color: #fcc100;
    border-color: #fcc100;
}
.card-info {
    background-color: #72d0fb;
    border-color: #72d0fb;
}
.card-light {
    background-color: #dcdde1;
    border-color: #dcdde1;
}
.card-dark {
    background-color: #2d3646;
    border-color: #2d3646;
}
.card-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: transparent;
}
.card-header:first-child {
    border-radius: calc(5px - 1px) calc(5px - 1px) 0 0;
    padding: 15px 20px;
}
.card .card-header .card-title {
    font-size: 16px;
    margin-bottom: 0;
}
.card .card-header .card-title:before {
    content: "";
    background-color: var(--main-color-primary);
    width: 7px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 16px;
    border-radius: 0 50px 50px 0;
    display: none;
}
.card .card-header .row .card-title {
    font-size: 16px;
}
.card .card-header .row .card-title:before {
    left: -5px;
    top: 0;
}
.card-subtitle {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #8a98ac;
    margin-bottom: 15px;
}
.card-img {
    width: 100%;
    border-radius: calc(5px - 1px);
}
.card-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background-color: transparent;
}
.card-arrow {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    border-radius: 50%;
    display: flex;
    line-height: 25px;
    width: 25px;
    height: 25px;
}
.card-arrow i {
    padding: 5px;
}
.card-arrow:hover {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.amount-spent-select .form-control {
    font-size: 12px;
    padding: 0px 5px;
    line-height: 24px;
    height: 24px;
}
.card-statistics .nav-pills {
    float: right;
}
.card-statistics .nav-pills .nav-link {
    padding: 0 6px 16px 6px;
    margin-bottom: -16px;
    font-size: 14px;
}
.card-statistics .nav-pills .nav-link.active {
    color: var(--main-color-primary);
    background-color: transparent;
    border-bottom: 2px solid var(--main-color-primary);
    border-radius: 0;
}
.card-statistics .nav-tabs {
    float: right;
}
.chart-label {
    text-align: center;
}
.chart-label li {
    margin: 0 15px;
    padding: 0;
    border: none;
}
.chart-label li p {
    font-size: 16px;
    color: #8a98ac;
}
.chart-label li p i {
    margin-right: 5px;
}
.chart-label li.list-inline-item:not(:last-child) {
    margin-right: 0;
}
.ct-chart {
    height: 320px;
}
.ct-golden-section:before {
    float: none;
    padding-bottom: 0;
}
.ct-grid {
    stroke: rgba(0, 0, 0, 0.05);
    stroke-width: 0.5px;
    stroke-dasharray: 0;
}
.ct-label {
    fill: #8a98ac;
    color: #8a98ac;
    font-size: 14px;
}
.chartist-simple-pie-chart .ct-label {
    fill: #ffffff;
    color: #ffffff;
}
.chartist-donut-fill-rather-chart .ct-label {
    fill: #ffffff;
    color: #ffffff;
}
.chartist-gauge-fill-rather-chart .ct-label {
    fill: #ffffff;
    color: #ffffff;
}
.ct-point {
    cursor: pointer;
}
.ct-series-a .ct-point {
    stroke: var(--main-color-primary);
}
.ct-series-a .ct-line {
    stroke: var(--main-color-primary);
}
.ct-series-a .ct-bar {
    stroke: var(--main-color-primary);
}
.ct-series-a .ct-slice-donut {
    stroke: var(--main-color-primary);
}
.ct-series-a .ct-slice-pie {
    fill: var(--main-color-primary);
}
.ct-series-a .ct-slice-donut-solid {
    fill: var(--main-color-primary);
}
.ct-series-a .ct-area {
    fill: var(--main-color-primary);
}
.ct-series-b .ct-point {
    stroke: #fcc100;
}
.ct-series-b .ct-line {
    stroke: #fcc100;
}
.ct-series-b .ct-bar {
    stroke: #fcc100;
}
.ct-series-b .ct-slice-donut {
    stroke: #fcc100;
}
.ct-series-b .ct-slice-pie {
    fill: #fcc100;
}
.ct-series-b .ct-slice-donut-solid {
    fill: #fcc100;
}
.ct-series-b .ct-area {
    fill: #fcc100;
}
.ct-series-c .ct-point {
    stroke: #dcdde1;
}
.ct-series-c .ct-line {
    stroke: #dcdde1;
}
.ct-series-c .ct-bar {
    stroke: #dcdde1;
}
.ct-series-c .ct-slice-donut {
    stroke: #dcdde1;
}
.ct-series-c .ct-slice-pie {
    fill: #dcdde1;
}
.ct-series-c .ct-slice-donut-solid {
    fill: #dcdde1;
}
.ct-series-c .ct-area {
    fill: #dcdde1;
}
.ct-series-d .ct-point {
    stroke: #5fc27e;
}
.ct-series-d .ct-line {
    stroke: #5fc27e;
}
.ct-series-d .ct-bar {
    stroke: #5fc27e;
}
.ct-series-d .ct-slice-donut {
    stroke: #5fc27e;
}
.ct-series-d .ct-slice-pie {
    fill: #5fc27e;
}
.ct-series-d .ct-slice-donut-solid {
    fill: #5fc27e;
}
.ct-series-d .ct-area {
    fill: #5fc27e;
}
.ct-series-e .ct-point {
    stroke: #f44455;
}
.ct-series-e .ct-line {
    stroke: #f44455;
}
.ct-series-e .ct-bar {
    stroke: #f44455;
}
.ct-series-e .ct-slice-donut {
    stroke: #f44455;
}
.ct-series-e .ct-slice-pie {
    fill: #f44455;
}
.ct-series-e .ct-slice-donut-solid {
    fill: #f44455;
}
.ct-series-e .ct-area {
    fill: #f44455;
}
.ct-series-f .ct-point {
    stroke: #72d0fb;
}
.ct-series-f .ct-line {
    stroke: #72d0fb;
}
.ct-series-f .ct-bar {
    stroke: #72d0fb;
}
.ct-series-f .ct-slice-donut {
    stroke: #72d0fb;
}
.ct-series-f .ct-slice-pie {
    fill: #72d0fb;
}
.ct-series-f .ct-slice-donut-solid {
    fill: #72d0fb;
}
.ct-series-f .ct-area {
    fill: #72d0fb;
}
.ct-series-g .ct-point {
    stroke: #2d3646;
}
.ct-series-g .ct-line {
    stroke: #2d3646;
}
.ct-series-g .ct-bar {
    stroke: #2d3646;
}
.ct-series-g .ct-slice-donut {
    stroke: #2d3646;
}
.ct-series-g .ct-slice-pie {
    fill: #2d3646;
}
.ct-series-g .ct-slice-donut-solid {
    fill: #2d3646;
}
.ct-series-g .ct-area {
    fill: #2d3646;
}
.ct-series-h .ct-point {
    stroke: #718093;
}
.ct-series-h .ct-line {
    stroke: #718093;
}
.ct-series-h .ct-bar {
    stroke: #718093;
}
.ct-series-h .ct-slice-donut {
    stroke: #718093;
}
.ct-series-h .ct-slice-pie {
    fill: #718093;
}
.ct-series-h .ct-slice-donut-solid {
    fill: #718093;
}
.ct-series-h .ct-area {
    fill: #718093;
}
.ct-series-i .ct-point {
    stroke: #f05b4f;
}
.ct-series-i .ct-line {
    stroke: #f05b4f;
}
.ct-series-i .ct-bar {
    stroke: #f05b4f;
}
.ct-series-i .ct-slice-donut {
    stroke: #f05b4f;
}
.ct-series-i .ct-slice-pie {
    fill: #f05b4f;
}
.ct-series-i .ct-slice-donut-solid {
    fill: #f05b4f;
}
.ct-series-i .ct-area {
    fill: #f05b4f;
}
.ct-series-j .ct-point {
    stroke: #dda458;
}
.ct-series-j .ct-line {
    stroke: #dda458;
}
.ct-series-j .ct-bar {
    stroke: #dda458;
}
.ct-series-j .ct-slice-donut {
    stroke: #dda458;
}
.ct-series-j .ct-slice-pie {
    fill: #dda458;
}
.ct-series-j .ct-slice-donut-solid {
    fill: #dda458;
}
.ct-series-j .ct-area {
    fill: #dda458;
}
.ct-series-k .ct-point {
    stroke: #eacf7d;
}
.ct-series-k .ct-line {
    stroke: #eacf7d;
}
.ct-series-k .ct-bar {
    stroke: #eacf7d;
}
.ct-series-k .ct-slice-donut {
    stroke: #eacf7d;
}
.ct-series-k .ct-slice-pie {
    fill: #eacf7d;
}
.ct-series-k .ct-slice-donut-solid {
    fill: #eacf7d;
}
.ct-series-k .ct-area {
    fill: #eacf7d;
}
.ct-series-l .ct-point {
    stroke: #86797d;
}
.ct-series-l .ct-line {
    stroke: #86797d;
}
.ct-series-l .ct-bar {
    stroke: #86797d;
}
.ct-series-l .ct-slice-donut {
    stroke: #86797d;
}
.ct-series-l .ct-slice-pie {
    fill: #86797d;
}
.ct-series-l .ct-slice-donut-solid {
    fill: #86797d;
}
.ct-series-l .ct-area {
    fill: #86797d;
}
.ct-series-m .ct-point {
    stroke: #b2c326;
}
.ct-series-m .ct-line {
    stroke: #b2c326;
}
.ct-series-m .ct-bar {
    stroke: #b2c326;
}
.ct-series-m .ct-slice-donut {
    stroke: #b2c326;
}
.ct-series-m .ct-slice-pie {
    fill: #b2c326;
}
.ct-series-m .ct-slice-donut-solid {
    fill: #b2c326;
}
.ct-series-m .ct-area {
    fill: #b2c326;
}
.ct-series-n .ct-point {
    stroke: #6188e2;
}
.ct-series-n .ct-line {
    stroke: #6188e2;
}
.ct-series-n .ct-bar {
    stroke: #6188e2;
}
.ct-series-n .ct-slice-donut {
    stroke: #6188e2;
}
.ct-series-n .ct-slice-pie {
    fill: #6188e2;
}
.ct-series-n .ct-slice-donut-solid {
    fill: #6188e2;
}
.ct-series-n .ct-area {
    fill: #6188e2;
}
.ct-series-o .ct-point {
    stroke: #a748ca;
}
.ct-series-o .ct-line {
    stroke: #a748ca;
}
.ct-series-o .ct-bar {
    stroke: #a748ca;
}
.ct-series-o .ct-slice-donut {
    stroke: #a748ca;
}
.ct-series-o .ct-slice-pie {
    fill: #a748ca;
}
.ct-series-o .ct-slice-donut-solid {
    fill: #a748ca;
}
.ct-series-o .ct-area {
    fill: #a748ca;
}
.chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 12px;
    padding: 3px 12px;
    background: #2d3646;
    color: #ffffff;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    text-align: center;
    border-radius: 3px;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #2d3646;
}
.chartist-tooltip.tooltip-show {
    opacity: 1;
}
.ct-area,
.ct-line {
    pointer-events: none;
}
.chartjs-chart {
    height: 350px;
}
canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.c3 svg {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
}
.c3 path {
    fill: none;
    stroke: rgba(0, 0, 0, 0.05);
}
.c3 line {
    fill: none;
    stroke: rgba(0, 0, 0, 0.05);
}
.c3-line {
    stroke-width: 3px;
}
.c3-bar {
    stroke-width: 0;
}
.c3 text {
    fill: #8a98ac;
}
.c3-chart-arc path {
    stroke: transparent;
    fill: #ffffff;
}
.c3-chart-arc .c3-gauge-value {
    fill: #ffffff;
}
.c3-chart-arc text {
    fill: #ffffff;
}
.c3-chart-arcs-title {
    font-size: 14px;
    color: #ffffff;
}
.c3-legend-item {
    font-size: 14px;
    padding-right: 10px;
}
.c3-tooltip {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
    empty-cells: show;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 3px;
}
.c3-tooltip tr {
    border: none;
}
.c3-tooltip th {
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 5px;
    text-align: left;
    color: #282828;
}
.c3-tooltip td {
    font-size: 13px;
    padding: 3px 6px;
    background-color: #ffffff;
    border-left: none;
    color: #8a98ac;
}
.c3-tooltip td > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
}
.c3-tooltip td.value {
    text-align: right;
}
.flot-chart {
    height: 320px;
}
.flot-chart .legend div {
    background-color: transparent !important;
}
.flotTip {
    padding: 3px 5px;
    background-color: #ffffff;
    z-index: 100;
    color: #ffffff;
    opacity: 0.8;
    filter: alpha(opacity=85);
    font-size: 14px;
}
.legendLabel {
    color: #8a98ac;
    font-size: 14px;
}
.morris-chart {
    width: 100%;
    height: 320px;
}
.morris-chart tspan {
    font-family: "Nunito Sans", sans-serif;
    color: #8a98ac;
    font-size: 14px;
    font-weight: 400;
}
.morris-hover.morris-default-style {
    border-radius: 3px;
    padding: 10px;
    color: #ffffff;
    background: #2d3646;
    border: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    text-align: center;
}
.morris-hover.morris-default-style .morris-hover-point {
    font-weight: 400;
    font-size: 14px;
    color: #8a98ac !important;
}
.morris-hover.morris-default-style .morris-hover-row-label {
    background-color: #2d3646;
    font-weight: 500;
    color: #ffffff;
    padding: 5px;
    border-radius: 3px 3px 0 0;
    margin: -5px -5px 0;
}
.knob {
    font: 600 30px "Nunito Sans", sans-serif !important;
}
.knob-superpose {
    position: relative;
    width: 150px;
    margin: 0 auto;
}
.knob-superpose .knob-minute {
    position: absolute;
    left: 30px;
    top: 30px;
}
.knob-superpose .knob-second {
    position: absolute;
    left: 55px;
    top: 55px;
}
.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    border: none !important;
    border-radius: 3px !important;
    background-color: #282828 !important;
    padding: 5px 10px !important;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.jqsfield {
    font: 12px poppins !important;
    color: #ffffff !important;
    text-align: center !important;
}
.chat-list {
    background-color: #ffffff;
    border-radius: 3px;
    padding-bottom: 15px;
    margin-bottom: 30px;
}
.chat-list .chat-search {
    padding: 21px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.chat-list .chat-search input[type="search"] {
    background-color: rgba(113, 128, 147, 0.1);
    color: #8a98ac;
    font-size: 16px;
    padding-left: 20px;
    border: none;
    border-radius: 3px 0 0 3px;
}
.chat-list .chat-search ::placeholder {
    color: #8a98ac;
}
.chat-list .chat-search .input-group-append {
    margin-left: 0px;
}
.chat-list .chat-search .btn {
    background-color: rgba(113, 128, 147, 0.1);
    color: #8a98ac;
    font-size: 18px;
    border-radius: 0 3px 3px 0;
    padding: 5px 15px 3px 5px;
    box-shadow: none;
    z-index: 0;
}
.chat-list .chat-user-list li {
    padding: 15px 30px;
}
.chat-list .chat-user-list li img {
    width: 35px;
    margin-right: 10px;
}
.chat-list .chat-user-list li h5 {
    font-size: 16px;
    margin-bottom: 3px;
}
.chat-list .chat-user-list li h5 .timing {
    color: #8a98ac;
    float: right;
    font-size: 12px;
    margin-top: 3px;
}
.chat-list .chat-user-list li p {
    margin-bottom: 0;
}
.chat-list .chat-user-list li:hover {
    background-color: rgba(113, 128, 147, 0.1);
}
.chat-detail {
    background-color: #ffffff;
    border-radius: 3px;
    margin-bottom: 30px;
}
.chat-detail .chat-head {
    padding: 15px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.chat-detail.order-chat-detail .chat-body {
    padding: 0 20px;
}
.chat-detail .chat-body {
    padding: 30px 40px;
}
.chat-detail .chat-body .chat-message {
    margin-bottom: 20px;
}
.chat-detail .chat-body .chat-message .chat-message-text {
    background-color: #dcdde1;
    color: #282828;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 3px;
    position: relative;
}
.chat-detail .chat-body .chat-message .chat-message-meta {
    margin-top: 5px;
    font-size: 14px;
}
.chat-detail .chat-body .chat-message.chat-message-left {
    text-align: left;
}
.chat-detail .chat-body .chat-message.chat-message-left .chat-message-text:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -10px;
    right: auto;
    top: auto;
    bottom: 0;
    border: 6px solid #dcdde1;
    border-color: transparent #dcdde1 #dcdde1 transparent;
}
.chat-detail .chat-body .chat-message.chat-message-right {
    text-align: right;
}
.chat-detail .chat-body .chat-message.chat-message-right .chat-message-text {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.chat-detail .chat-body .chat-message.chat-message-right .chat-message-text:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -10px;
    top: auto;
    bottom: 0;
    border: 6px solid var(--main-color-primary);
    border-color: transparent transparent var(--main-color-primary) var(--main-color-primary);
}
.chat-detail .chat-body .chat-message.chat-message-right .chat-message-meta {
    text-align: right;
}
.chat-detail .chat-bottom {
    padding: 15px 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.chat-detail .chat-bottom .chat-messagebar input[type="text"] {
    background-color: transparent;
    color: #8a98ac;
    font-size: 16px;
    border: none;
    border-radius: 0;
}
.chat-detail .chat-bottom .chat-messagebar ::placeholder {
    color: #8a98ac;
}
.chat-detail .chat-bottom .chat-messagebar .input-group-append {
    margin-left: 0px;
}
.chat-detail .chat-bottom .chat-messagebar .btn {
    border-radius: 50% !important;
    margin: 0 5px;
}
.accordion > .card {
    overflow: hidden;
}
.accordion > .card .card-header {
    background-color: rgba(113, 128, 147, 0.1);
    border: none;
    padding: 0;
    margin-bottom: 0;
}
.accordion > .card h2 {
    line-height: 20px;
}
.accordion > .card h2 .btn-link {
    font-size: 16px;
    font-weight: 600;
    color: var(--main-color-primary);
    width: 100%;
    text-align: left;
    padding: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid transparent;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
}
.accordion > .card h2 .btn-link.collapsed {
    color: #282828;
}
.accordion > .card h2 .btn-link:hover {
    text-decoration: none;
}
.accordion > .card h2 .btn-link:focus {
    text-decoration: none;
}
.accordion > .card .collapse {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}
.accordion > .card .collapse.show {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.accordion > .card:last-of-type h2 .btn-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.accordion > .card:last-of-type .collapse {
    border-bottom: 1px solid transparent;
}
.accordion > .card:last-of-type .collapse.show {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.accordion.accordion-outline > .card {
    border-radius: 0;
    margin-bottom: 10px;
}
.accordion.accordion-outline > .card .card-header {
    background-color: transparent;
}
.accordion.accordion-outline > .card h2 {
    line-height: 20px;
}
.accordion.accordion-outline > .card h2 .btn-link {
    border-top: 1px solid var(--main-color-primary);
    border-left: 1px solid var(--main-color-primary);
    border-bottom: 1px solid var(--main-color-primary);
    border-right: 1px solid var(--main-color-primary);
}
.accordion.accordion-outline > .card h2 .btn-link.collapsed {
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.accordion.accordion-outline > .card .collapse {
    border-left: 1px solid var(--main-color-primary);
    border-bottom: 1px solid var(--main-color-primary);
    border-right: 1px solid var(--main-color-primary);
}
.accordion.accordion-outline > .card:last-of-type .collapse {
    border-bottom: 1px solid transparent;
}
.accordion.accordion-outline > .card:last-of-type .collapse.show {
    border-bottom: 1px solid var(--main-color-primary);
}
.accordion.accordion-light > .card {
    border-radius: 0;
    margin-bottom: 10px;
}
.accordion.accordion-light > .card .card-header {
    background-color: transparent;
}
.accordion.accordion-light > .card h2 {
    line-height: 20px;
}
.accordion.accordion-light > .card h2 .btn-link {
    padding: 15px 0;
    border-top: none;
    border-left: none;
    border-bottom: 1px solid var(--main-color-primary);
    border-right: none;
}
.accordion.accordion-light > .card h2 .btn-link.collapsed {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.accordion.accordion-light > .card .collapse {
    border-left: none;
    border-bottom: none;
    border-right: none;
}
.accordion.accordion-light > .card:last-of-type .collapse {
    border-bottom: none;
}
.dash-social-icon {
    margin-top: 15px;
    margin-bottom: 30px;
}
.dash-social-icon i {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    border-radius: 50%;
    font-size: 24px;
    padding: 14px 15px;
}
.dash-flag-icon .flag {
    display: inline-block;
    font-size: 18px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    vertical-align: middle;
    text-align: center;
    background-color: #ffffff;
    border-radius: 50%;
}
.dash-analytic-icon {
    margin-top: 15px;
    margin-bottom: 30px;
}
.dash-analytic-icon i {
    border-radius: 50%;
    font-size: 24px;
    padding: 14px 15px;
}
.analytic-chart-label .analytic-label-perform {
    margin-bottom: 0;
    text-align: center;
}
.analytic-chart-label .list-inline {
    text-align: center;
}
.analytic-chart-label .analytic-chart-piety {
    text-align: right;
}
.ecommerce-widget .card-header {
    border-bottom: none;
}
.ecommerce-widget .card-header .dash-analytic-icon {
    margin-top: 20px;
    margin-bottom: 20px;
}
.ecommerce-widget .card-footer {
    border-top: none;
}
.product-file-type .img-icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    font-weight: 600;
    border-radius: 50%;
    font-size: 18px;
    text-align: center;
}
.ecommerce-upload .dropzone {
    min-height: 278px;
    border-top: 1px dashed var(--main-color-primary);
    border-right: 1px dashed var(--main-color-primary);
    border-left: 1px dashed var(--main-color-primary);
    border-bottom: none;
    background: rgba(110, 129, 220, 0.1);
    border-radius: 0;
    margin: 0 1px 0 0;
}
.ecommerce-upload .dropzone .dz-message {
    font-size: 20px;
}
.ecommerce-upload img {
    margin-top: -22%;
    width: 100%;
}
#latestorder_wrapper .dataTables_length {
    display: none !important;
}
#topproducts_wrapper .dataTables_length {
    display: none !important;
}
.product-bar .product-head p {
    position: relative;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 40px;
}
.ecommerce-sortby {
    margin-bottom: 30px;
}
.ecommerce-sortby .button-list {
    text-align: right;
}
.ecommerce-sortby .button-list .btn {
    margin-bottom: 0;
}
.ecommerce-pagination p {
    margin-bottom: 0;
}
.ecommerce-pagination .pagination {
    float: right;
}
.product-slider-box.product-box-for .product-preview img {
    width: 100%;
}
.product-slider-box.product-box-for .product-preview p {
    position: relative;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 50px;
}
.product-slider-box.product-box-nav .product-preview img {
    padding: 0 5px;
}
.rating-average span {
    font-weight: 600;
    border-radius: 50%;
    font-size: 18px;
    padding: 15px;
}
.cart-container {
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 30px;
}
.cart-container .cart-body {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 30px 0 20px;
    margin: 20px 0 30px;
}
.cart-qty {
    width: 75px;
}
.forgot-psw-link {
    text-align: right;
}
.partition {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 600;
}
.partition hr {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.partition h6 {
    position: relative;
    margin-top: -27px;
}
.partition h6 span {
    background-color: #ffffff;
    padding: 0 10px;
}
.jp-card .jp-card-front {
    background: #718093 !important;
}
.jp-card .jp-card-back {
    background: #718093 !important;
}
.ecom-dashboard-widget i {
    font-size: 40px;
    margin-right: 20px;
}
.ecom-dashboard-widget h5 {
    font-size: 18px;
    color: #282828;
    margin-bottom: 5px;
}
.ecom-dashboard-widget p {
    margin-bottom: 0;
}
.ecom-dashboard-widget:hover {
    color: var(--main-color-primary);
}
.ecom-dashboard-widget:hover h5 {
    color: var(--main-color-primary);
}
.order-box .card-header h5 {
    margin-bottom: 0;
}
.order-box .card-header h6 {
    text-align: right;
}
.order-box .card-footer h5 {
    margin-bottom: 0;
    font-size: 16px;
}
.order-box .card-footer h6 {
    text-align: right;
}
.wallet-box {
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 15px;
    margin-top: 60px;
    border-radius: 3px;
}
.wallet-box h4 {
    margin-bottom: 0;
}
.wallet-box p {
    text-align: right;
}
.ecom-notification-box .media {
    padding: 15px;
}
.ecom-notification-box .media:hover {
    background-color: rgba(188, 196, 220, 0.1);
}
.ecom-notification-box .media .action-icon {
    width: 46px;
    height: 46px;
    line-height: 38px;
    font-weight: 600;
    border-radius: 50%;
    font-size: 18px;
    text-align: center;
}
.ecom-notification-box .media h5 {
    font-size: 16px;
    color: #282828;
    margin-top: 12px;
    margin-bottom: 5px;
}
.ecom-notification-box .media p {
    margin-bottom: 0;
}
.ecom-notification-box .media p span {
    margin-right: 8px;
}
@media (max-width: 1199px) {
    .ecommerce-sortby .button-list {
        margin-top: 15px;
        text-align: left;
    }
    .ecommerce-pagination p {
        margin-bottom: 15px;
    }
    .ecommerce-pagination .pagination {
        float: none;
    }
}
@media (max-width: 767px) {
    .profilebox img {
        width: 100px;
    }
    .cart-container {
        padding: 10px;
    }
}
@media (max-width: 575px) {
    .order-box .card-header h5 {
        text-align: center;
        margin-bottom: 10px;
    }
    .order-box .card-header h6 {
        text-align: center;
    }
    .order-box .card-footer h5 {
        text-align: center;
        margin-bottom: 15px;
    }
    .order-box .card-footer h6 {
        text-align: center;
    }
    .wallet-box h4 {
        text-align: center;
        margin-bottom: 15px;
    }
    .wallet-box p {
        text-align: center;
    }
    .forgot-psw-link {
        text-align: left;
    }
}
@media (max-width: 420px) {
    .jp-card {
        left: -80px;
    }
}
.email-leftbar ul li {
    border: none;
    padding: 12px 0 !important;
    background-color: transparent;
}
.email-leftbar ul li a {
    color: #8a98ac;
}
.email-leftbar ul li a:hover {
    color: var(--main-color-primary);
}
.email-leftbar ul li a.active {
    color: var(--main-color-primary);
}
.email-rightbar .card-header ul li a i {
    padding: 8px 5px;
    color: #8a98ac;
}
.email-rightbar .card-header ul li a:hover i {
    color: var(--main-color-primary);
}
.email-rightbar .card-body tr td {
    color: #8a98ac;
    vertical-align: top;
}
.email-rightbar .card-body tr td a {
    color: #8a98ac;
}
.email-rightbar .card-body tr:hover {
    background-color: rgba(113, 128, 147, 0.1);
}
.email-rightbar .card-body tr.email-unread td {
    font-weight: 600;
    color: #2d3646;
}
.email-rightbar .card-body tr.email-unread td a {
    color: #2d3646;
}
.email-rightbar .email-open-box .media img {
    width: 50px;
    border-radius: 50%;
}
.email-rightbar .email-open-box .open-email-head ul {
    text-align: right;
}
.email-rightbar .email-open-box .open-email-head ul li a i {
    color: #8a98ac;
}
.email-rightbar .email-open-box .open-email-head ul li a:hover i {
    color: var(--main-color-primary);
}
.email-rightbar .email-open-box .open-email-head ul {
    text-align: right;
}
.email-rightbar .email-open-box .open-email-head ul li a i {
    color: #8a98ac;
}
.email-rightbar .email-open-box .open-email-head ul li a:hover i {
    color: var(--main-color-primary);
}
.colorpicker-input-addon:before {
    height: 20px;
    width: 20px;
    border-radius: 3px;
}
.colorpicker-input-addon i {
    height: 20px;
    width: 20px;
    border-radius: 3px;
}
.colorpicker-saturation {
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
}
.colorpicker-saturation .colorpicker-guide {
    height: 10px;
    width: 10px;
    box-shadow: none;
}
.colorpicker-hue {
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
}
.colorpicker-hue .colorpicker-guide {
    height: 5px;
}
.colorpicker-alpha {
    cursor: pointer;
}
.colorpicker-alpha .colorpicker-alpha-color {
    border-radius: 5px;
    box-shadow: none;
}
.colorpicker-alpha .colorpicker-guide {
    height: 5px;
}
.colorpicker-bar {
    border-radius: 5px;
    box-shadow: none;
}
.colorpicker-bar > div {
    border-radius: 5px;
    box-shadow: none;
}
.datepicker {
    font-family: "Nunito Sans", sans-serif;
    background: #ffffff;
    color: #8a98ac;
    border: none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.datepicker--day-name {
    color: var(--main-color-primary);
    font-size: 13px;
    font-weight: 700;
    text-transform: capitalize;
}
.datepicker--cell-day.-other-month- {
    color: #dcdde1;
}
.datepicker--cell-year.-other-decade- {
    color: #dcdde1;
}
.datepicker--nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.datepicker--nav .datepicker--nav-title {
    color: #282828;
    border-radius: 50px;
    font-weight: 700;
}
.datepicker--nav .datepicker--nav-title i {
    font-style: normal;
    color: #282828;
    margin-left: 5px;
}
.datepicker--nav-action:hover {
    background: transparent;
}
.datepicker--nav-title:hover {
    background: transparent;
}
.datepicker--pointer {
    border-top: none;
    border-right: none;
    background: #ffffff;
}
.datepicker--cell {
    border-radius: 50px;
}
.datepicker--cell.-selected- {
    color: #ffffff;
    background: var(--main-color-primary);
}
.datepicker--cell.-selected-.-current- {
    color: #ffffff;
    background: var(--main-color-primary);
}
.datepicker--cell.-selected-.-focus- {
    background: var(--main-color-primary);
}
.datepicker--cell.-focus- {
    background: transparent;
    color: #282828;
}
.datepicker--cell.-current- {
    color: var(--main-color-primary);
}
.datepicker--cell.-range-from- {
    border: 1px solid var(--main-color-primary);
    background-color: var(--main-color-primary);
    border-radius: 50px 0 0 50px;
}
.datepicker--cell.-range-to- {
    border: 1px solid var(--main-color-primary);
    background-color: var(--main-color-primary);
    border-radius: 0 50px 50px 0;
    color: #ffffff;
}
.datepicker--cell.-in-range- {
    background: rgba(110, 129, 220, 0.1);
    color: #282828;
    border-radius: 0;
}
.datepicker--time-current-hours {
    font-family: "Nunito Sans", sans-serif;
}
.datepicker--time-current-minutes {
    font-family: "Nunito Sans", sans-serif;
}
.datepicker--buttons {
    border-top: 1px solid #eaeaed;
    padding: 5px;
}
.datepicker--time {
    border-top: 1px solid #eaeaed;
    padding: 5px;
}
.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade- {
    color: #ffffff;
    background: #e9ecfa;
}
.datepicker--time-row {
    background: linear-gradient(to right, #dcdde1, #dcdde1) left 50%/100% 1px no-repeat;
}
.datepicker--time-row input[type="range"]:hover::-webkit-slider-thumb {
    background: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.datepicker--time-row input[type="range"]:hover::-moz-range-thumb {
    background: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.datepicker--time-row input[type="range"]:hover::-ms-thumb {
    background: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.datepicker--time-row input[type="range"]:focus::-webkit-slider-thumb {
    background: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.datepicker--time-row input[type="range"]:focus::-moz-range-thumb {
    background: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.datepicker--time-row input[type="range"]:focus::-ms-thumb {
    background: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.datepicker--time-row input[type="range"]::-webkit-slider-thumb {
    box-sizing: border-box;
    height: 12px;
    width: 12px;
    border-radius: 50px;
    border: 1px solid var(--main-color-primary);
    background: #ffffff;
    cursor: pointer;
    transition: background 0.2s;
}
.datepicker--time-row input[type="range"]::-moz-range-thumb {
    box-sizing: border-box;
    height: 12px;
    width: 12px;
    border-radius: 50px;
    border: 1px solid var(--main-color-primary);
    background: #ffffff;
    cursor: pointer;
    transition: background 0.2s;
}
.datepicker--time-row input[type="range"]::-ms-thumb {
    box-sizing: border-box;
    height: 12px;
    width: 12px;
    border-radius: 50px;
    border: 1px solid var(--main-color-primary);
    background: #ffffff;
    cursor: pointer;
    transition: background 0.2s;
}
.datepicker-inline .datepicker {
    border-color: transparent;
    box-shadow: none;
    position: static;
    left: auto;
    right: auto;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    width: 100%;
}
.datepicker-inline .datepicker .datepicker--day-name {
    color: var(--main-color-primary);
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
}
.datepicker-inline .datepicker .datepicker--cell-day {
    width: 14.28571%;
    height: 43px;
}
.datepicker-inline .datepicker .datepicker--cell.-current- {
    color: var(--main-color-primary);
    background-color: transparent;
}
.datepicker-inline .datepicker .datepicker--nav-title {
    border-radius: 15px;
    padding: 4px 15px;
    font-size: 16px;
}
.mce-panel {
    border-color: rgba(0, 0, 0, 0.05) !important;
    background-color: #ffffff !important;
    border-radius: 3px !important;
}
.mce-flow-layout-item {
    margin: 5px 0 5px 5px !important;
}
.mce-menubar .mce-menubtn {
    border-color: transparent !important;
    background: #dcdde1 !important;
    border-radius: 3px;
}
.mce-menubar .mce-menubtn button span {
    color: #8a98ac !important;
}
.mce-btn {
    border: 1px solid transparent !important;
    background-color: #dcdde1 !important;
    border-radius: 3px;
}
.mce-btn .mce-txt {
    color: #8a98ac !important;
}
.mce-btn button {
    padding: 5px 10px !important;
}
.mce-btn-group .mce-btn {
    margin-left: 5px !important;
}
.mce-btn-group:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
    padding-left: 0 !important;
}
.mce-caret {
    border-top-color: #8a98ac !important;
}
.mce-ico {
    color: #8a98ac !important;
}
.mce-label {
    text-shadow: none !important;
    color: #282828 !important;
}
.mce-menu-item .mce-ico {
    color: #8a98ac !important;
}
.mce-menu-item .mce-text {
    color: #8a98ac !important;
}
.mce-menu-item:hover {
    background-color: var(--main-color-primary) !important;
}
.mce-menu-item:hover .mce-ico {
    color: #ffffff !important;
}
.mce-menu-item:hover .mce-text {
    color: #ffffff !important;
}
.mce-menu-item.mce-selected {
    background-color: var(--main-color-primary) !important;
}
.mce-menu-item.mce-selected .mce-ico {
    color: #ffffff !important;
}
.mce-menu-item.mce-selected .mce-text {
    color: #ffffff !important;
}
.mce-menu-item:focus {
    background-color: var(--main-color-primary) !important;
}
.mce-menu-item:focus .mce-ico {
    color: #ffffff !important;
}
.mce-menu-item:focus .mce-text {
    color: #ffffff !important;
}
.mce-grid-border a {
    border-color: #8a98ac !important;
}
.mce-grid-border a:hover {
    border-color: var(--main-color-primary) !important;
    background: var(--main-color-primary) !important;
}
.mce-grid-border a.mce-active {
    border-color: var(--main-color-primary) !important;
    background: var(--main-color-primary) !important;
}
label {
    font-weight: 500;
}
input:-internal-autofill-selected {
    background-color: transparent !important;
    color: #8a98ac !important;
}
.form-control {
    background-color: #ffffff;
    font-size: 16px;
    color: #8a98ac;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}
.form-control:focus {
    border-color: var(--main-color-primary);
    box-shadow: none !important;
}
.form-control:focus:disabled {
    background-color: #dcdde1;
    opacity: 1;
}
.form-control:disabled {
    background-color: #dcdde1;
    opacity: 1;
}
.form-control[readonly] {
    background-color: #dcdde1;
    opacity: 1;
}
.form-control .form-check-input {
    margin-top: 0.25rem;
}
.form-control-plaintext {
    color: #8a98ac;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--main-color-primary);
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--main-color-primary);
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem #d5daf5;
}
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #dcdde1;
    border-color: rgba(0, 0, 0, 0.05);
}
.custom-control-label::before {
    background-color: transparent;
    border: 1px solid #8a98ac;
}
.custom-control-label::before:focus {
    border-color: var(--main-color-primary);
}
.input-group-addon {
    border-radius: 5px;
    border: none;
}
.input-group-text {
    border: 1px solid #dcdde1;
    background: #e4e5e8;
    color: #8a98ac;
}
.custom-select {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    color: #8a98ac;
}
.custom-select:focus {
    border-color: var(--main-color-primary);
    outline: 0;
    box-shadow: none;
}
.custom-file-label {
    color: #8a98ac;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    line-height: 1.9;
    font-weight: 400;
}
.custom-file-label::after {
    background-color: var(--main-color-primary);
    border: none;
    color: #ffffff;
    height: 36px;
    line-height: 24px;
}
.custom-file-input:focus ~ .custom-file-label {
    border-color: var(--main-color-primary);
    box-shadow: none !important;
}
.custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #ceecd7;
    color: #8a98ac;
}
.custom-file-input.is-invalid ~ .custom-file-label .form-control[readonly] {
    background-color: #dcdde1;
    opacity: 1;
}
.custom-select.is-valid {
    border-color: #ceecd7;
    background-color: #ffffff;
}
.custom-select.is-valid:focus {
    border-color: #5fc27e;
}
.form-control.is-valid {
    border-color: #ceecd7;
    background-color: #ffffff;
}
.form-control.is-valid:focus {
    border-color: #5fc27e;
}
.was-validated .custom-select:valid {
    border-color: #ceecd7;
    background-color: #ffffff;
}
.was-validated .custom-select:valid:focus {
    border-color: #5fc27e;
    box-shadow: none !important;
}
.was-validated .custom-select:invalid {
    border-color: #fdd5d8;
    background-color: #ffffff;
}
.was-validated .custom-select:invalid:focus {
    border-color: #f44455;
    box-shadow: none !important;
}
.was-validated .form-control:valid {
    border-color: #ceecd7;
    background-color: #ffffff;
}
.was-validated .form-control:valid:focus {
    border-color: #5fc27e;
}
.was-validated .form-control:invalid {
    border-color: #fdd5d8;
    background-color: #ffffff;
}
.was-validated .form-control:invalid:focus {
    border-color: #f44455;
}
.was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #5fc27e;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #f44455;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: #5fc27e;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #fdd5d8;
    color: #8a98ac;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #f44455;
}
.valid-feedback {
    color: #5fc27e;
}
.custom-select.is-invalid {
    border-color: #fdd5d8;
    background-color: #ffffff;
}
.form-control.is-invalid {
    border-color: #fdd5d8;
    background-color: #ffffff;
}
.form-control.is-invalid:focus {
    border-color: #f44455;
}
.valid-tooltip {
    background-color: #71c98d;
}
.invalid-tooltip {
    background-color: #f55c6b;
}
.custom-control-input.is-valid ~ .custom-control-label {
    color: #5fc27e;
}
.custom-control-input.is-invalid ~ .custom-control-label {
    color: #f44455;
}
.custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #f44455;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #5fc27e;
}
.invalid-feedback {
    color: #f44455;
}
.form-check-input.is-invalid ~ .form-check-label {
    color: #f44455;
}
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: #f44455;
}
.editable-click,
a.editable-click,
a.editable-click:hover {
    border-bottom: dashed 1px var(--main-color-primary);
}
.editable-empty,
.editable-empty:hover,
.editable-empty:focus {
    color: #f44455;
}
.editable-submit {
    padding: 4px 8px;
}
.editable-cancel {
    padding: 4px 8px;
}
.custom-range {
    width: 100%;
    height: calc(1rem + 0.4rem);
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.custom-range:focus {
    outline: 0;
}
.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem #d5daf5;
}
.custom-range::-moz-focus-outer {
    border: 0;
}
.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: var(--main-color-primary);
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}
.custom-range::-webkit-slider-thumb:active {
    background-color: #dcdde1;
}
.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dcdde1;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}
.custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
.custom-range::-ms-thumb:active {
    background-color: #b3d7ff;
}
.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
    cursor: default;
}
.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}
input[type="range"] {
    -webkit-appearance: none;
    color: rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.05);
    height: 5px;
    cursor: default;
    padding: initial;
    border: initial;
    margin: 2px;
}
input[type="range"]:focus {
    background-color: rgba(0, 0, 0, 0.05);
}
.select2-container {
    width: 100% !important;
}
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 0;
    line-height: 38px;
    margin-top: 0;
    line-height: 38px;
    color: #282828;
}
.select2-container .select2-selection--single {
    border: none;
    height: 38px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
    padding-left: 15px;
    color: #8a98ac;
}
.select2-container .select2-selection--multiple {
    border: none;
    min-height: 38px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid var(--main-color-primary);
    outline: 0;
    background-color: #ffffff;
}
.select2-container--default .select2-selection--multiple {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 15px;
    vertical-align: bottom;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--main-color-primary);
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 2px 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #ffffff;
    margin-right: 5px;
}
.select2-container--default .select2-selection--single {
    background-color: #ffffff;
    font-size: 16px;
    color: #8a98ac;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
    width: 30px;
    top: 0;
    right: 0;
}
.select2-container--default .select2-results__option {
    padding: 6px 15px;
    font-size: 14px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--main-color-primary) !important;
    color: #ffffff;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.05);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: #282828;
}
.select2-dropdown {
    background-color: #ffffff;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.bootstrap-tagsinput {
    background-color: #ffffff;
    font-size: 12px;
    color: #8a98ac;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 0.375rem 0.75rem;
    line-height: 24px;
    box-shadow: none;
    width: 100%;
}
.bootstrap-tagsinput.focus {
    border: 1px solid var(--main-color-primary);
}
.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-radius: 5px;
    padding: 4px 10px;
    line-height: 25px;
}
.bootstrap-tagsinput .tag.label.label-success {
    background-color: #5fc27e;
}
.bootstrap-tagsinput .tag.label.label-danger {
    background-color: #f44455;
}
.bootstrap-tagsinput .tag.label.label-warning {
    background-color: #fcc100;
}
.bootstrap-tagsinput .tag.label.label-default {
    background-color: #72d0fb;
}
.tt-menu {
    border: 1px solid #eaeaed;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.tt-suggestion {
    color: #282828;
}
.tt-suggestion:hover,
.tt-suggestion:focus {
    background-color: var(--main-color-primary);
}
.dropzone {
    min-height: 250px;
    border: 2px dashed rgba(0, 0, 0, 0.05);
    background: white;
    border-radius: 6px;
}
.dropzone .dz-message {
    font-size: 30px;
}
.error {
    color: ff0000;
}
.parsley-error {
    border-color: #f44455;
}
.parsley-errors-list {
    display: none;
    margin: 0;
    padding: 0;
}
.parsley-errors-list > li {
    font-size: 12px;
    list-style: none;
    color: #f44455;
    margin-top: 5px;
}
.parsley-errors-list.filled {
    display: block;
}
.wizard > .steps {
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}
.wizard > .steps .form-wizard-line {
    height: 3px;
    background-color: #dcdde1;
    -webkit-transform: translateY(1.7rem);
    transform: translateY(1.7rem);
    margin: 0 15%;
    position: relative;
    z-index: 0;
    top: 26px;
}
.wizard > .steps > ul > li {
    display: flex;
    z-index: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-basis: auto;
    font-weight: 500;
    font-size: 1.1rem;
}
.wizard > .steps a {
    display: block;
    width: auto;
    background-color: #eaeaed;
    color: #8a98ac;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 0;
    text-decoration: none;
    border-radius: 30px;
    cursor: default;
    text-align: center;
}
.wizard > .steps .current a {
    color: #282828;
    cursor: default;
}
.wizard > .steps .current a .number {
    background: var(--main-color-primary);
    color: #ffffff;
}
.wizard > .steps .done a {
    color: #282828;
}
.wizard > .steps .done a .number {
    background: #dcdde1;
    color: #8a98ac;
}
.wizard > .steps .current-info {
    position: absolute;
    left: -999em;
}
.wizard > .steps .number {
    background-color: #dcdde1;
    color: #8a98ac;
    font-size: 20px;
    font-weight: 600;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 15px auto;
}
.wizard ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
    display: flex;
}
.wizard > .actions {
    position: relative;
    display: block;
    text-align: right;
}
.wizard > .actions > ul {
    display: inline-block;
    text-align: right;
}
.wizard > .actions > ul > li {
    display: inline-block;
    margin: 0 0 15px 15px;
}
.wizard > .actions > ul > li:first-child a {
    background-color: rgba(110, 129, 220, 0.1);
    border: none;
    color: var(--main-color-primary);
    box-shadow: none;
}
.wizard > .actions a {
    display: block;
    padding: 6px 12px;
    text-decoration: none;
    border-radius: 3px;
    color: #ffffff;
    background-color: var(--main-color-primary);
    border: none;
    box-shadow: none;
}
.wizard > .actions .disabled a {
    display: none;
}
.wizard > .content {
    background: #ffffff;
    display: block;
    padding: 30px;
    margin: 30px 0;
    border-radius: 3px;
    min-height: 250px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.wizard > .content > .title {
    position: absolute;
    left: -999em;
}
.wizard > .content > .body ul {
    display: block !important;
}
.wizard > .content > .body ul > li {
    line-height: 30px;
}
.wizard > .content > .body ul.nav-pills {
    display: flex !important;
}
.tabcontrol ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}
.tabcontrol > .steps .current-info {
    position: absolute;
    left: -999em;
}
.tabcontrol > .content > .title {
    position: absolute;
    left: -999em;
}
.wizard.vertical > .steps {
    display: inline;
    float: left;
    width: 30%;
    padding: 10px;
}
.wizard.vertical > .steps > ul {
    display: block;
}
.wizard.vertical > .steps > ul > li {
    display: block;
}
.wizard.vertical > .steps > ul > li a {
    display: block;
    text-align: left;
}
.wizard.vertical > .steps > ul > li a .number {
    display: inline-block;
    padding: 8px 16px;
    margin: 0 10px;
}
.wizard.vertical > .content {
    display: inline;
    float: left;
    margin: 0 2.5% 30px 2.5%;
    width: 65%;
}
.wizard.vertical > .actions {
    display: inline;
    float: right;
    margin: 0 2.5%;
    width: 95%;
}
@media (max-width: 991px) {
    .wizard.vertical > .content {
        width: 100%;
        margin: 30px 0;
    }
    .wizard.vertical > .steps {
        width: 100%;
    }
    .wizard > .steps > ul > li {
        width: 100%;
    }
}
.gallery-filter ul {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.gallery-filter ul .filter-item {
    line-height: 46px;
    text-transform: capitalize;
    cursor: pointer;
    color: #282828 !important;
    font-weight: 600;
    border-radius: 0;
    letter-spacing: 0.5px;
    border-bottom: 2px solid transparent;
    padding: 0.7rem 0;
    margin-right: 1.5rem;
}
.gallery-filter ul .filter-item:hover {
    color: var(--main-color-primary) !important;
    border-bottom: 2px solid var(--main-color-primary);
}
.gallery-filter ul .filter-item.current {
    color: var(--main-color-primary) !important;
    border-bottom: 2px solid var(--main-color-primary);
}
.gallery-box {
    overflow: hidden;
    position: relative;
    padding: 0;
    border-radius: 3px;
    margin-bottom: 30px;
    transition: transform 0.8s;
}
.gallery-box .gallery-preview {
    position: relative;
    overflow: hidden;
    text-align: center;
}
.gallery-box .gallery-preview img {
    transition: all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.gallery-box .gallery-preview:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}
.gallery-box .gallery-content {
    opacity: 0;
    color: #ffffff;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    overflow: hidden;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}
.gallery-box .gallery-content p {
    margin-bottom: 0;
    color: #ccc;
    font-size: 15px;
}
.gallery-box .gallery-content h5 {
    margin-bottom: 0;
    margin-top: 5px;
}
.gallery-box .gallery-content h5 a {
    font-size: 18px;
    color: #ffffff;
}
.gallery-box:hover {
    transform: scale(1.1);
}
.gallery-box:hover .gallery-preview img {
    transform: scale(1.1);
}
.gallery-box:hover .gallery-preview:after {
    background: rgba(110, 129, 220, 0.8);
}
.gallery-box:hover .gallery-content {
    transform: translateY(-100%);
    z-index: 3;
    opacity: 1;
}
.invoice .invoice-logo img {
    width: 120px;
    margin-bottom: 20px;
}
.invoice .invoice-head {
    margin-top: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.invoice .invoice-head .invoice-name {
    text-align: right;
}
.invoice .invoice-billing {
    margin-top: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.invoice .invoice-billing .invoice-address {
    margin-bottom: 30px;
}
.invoice .invoice-summary {
    padding-top: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.invoice .invoice-summary-total {
    padding-top: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.invoice .invoice-meta {
    padding-top: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.invoice .invoice-meta .invoice-meta-box {
    margin-bottom: 30px;
}
.invoice .invoice-footer {
    padding-top: 20px;
}
.invoice .invoice-footer .invoice-footer-btn {
    text-align: right;
}
@media (max-width: 767px) {
    .invoice .invoice-head .invoice-name {
        margin-top: 30px;
        text-align: left;
    }
    .invoice .invoice-meta {
        text-align: left;
    }
    .invoice .invoice-footer {
        text-align: center;
    }
    .invoice .invoice-footer .invoice-footer-btn {
        text-align: center;
        margin-top: 30px;
    }
}
.jconfirm.jconfirm-white .jconfirm-box {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
.jconfirm.jconfirm-white .jconfirm-box .jconfirm-buttons button {
    border-radius: 3px;
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.jconfirm.jconfirm-white .jconfirm-box .jconfirm-buttons button.btn-default:hover {
    background: #657385;
}
.jconfirm.jconfirm-light .jconfirm-box {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
.jconfirm.jconfirm-light .jconfirm-box .jconfirm-buttons button {
    border-radius: 3px;
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.jconfirm.jconfirm-light .jconfirm-box .jconfirm-buttons button.btn-default:hover {
    background: #657385;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-default {
    background-color: #718093;
    color: #ffffff !important;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-blue {
    background-color: var(--main-color-primary);
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-blue:hover {
    background-color: var(--main-color-primary-hover);
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-green {
    background-color: #5fc27e;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-green:hover {
    background-color: #4dbb6f;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-green:focus {
    background-color: #4dbb6f;
    box-shadow: 0 0 0 0.2rem #a9deba;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-red {
    background-color: #f44455;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-red:hover {
    background-color: #f32c3f;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-red:focus {
    background-color: #f32c3f;
    box-shadow: 0 0 0 0.2rem #faa4ad;
}
.jconfirm .jconfirm-box .jconfirm-title-c .jconfirm-title {
    font-size: 20px !important;
    color: #282828;
}
.jconfirm .jconfirm-box .jconfirm-content {
    color: #8a98ac;
}
.jconfirm .jconfirm-box.jconfirm-type-red {
    border-top: none;
}
.jconfirm .jconfirm-box.jconfirm-type-green {
    border-top: none;
}
.jstree-default .jstree-hovered {
    background: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    border-radius: 3px;
    box-shadow: inset 0 0 1px rgba(110, 129, 220, 0.1);
}
.jstree-default .jstree-clicked {
    background: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    border-radius: 3px;
    box-shadow: inset 0 0 1px rgba(110, 129, 220, 0.1);
}
.jstree-default .jstree-wholerow-hovered {
    background: rgba(110, 129, 220, 0.1);
}
.jstree-default .jstree-wholerow-clicked {
    background: rgba(110, 129, 220, 0.1);
}
.jstree-default .jstree-node {
    min-height: 30px;
    line-height: 30px;
    margin-left: 30px;
    min-width: 30px;
}
.jstree-default .jstree-icon:empty {
    width: 26px;
    height: 26px;
    line-height: 26px;
}
.kanban-menu {
    text-align: right;
}
.kanban-users {
    text-align: right;
}
.avatar-group {
    display: inline-flex;
}
.avatar-group .avatar {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    font-size: 1rem;
    margin-left: -0.8rem;
    transition: transform 0.2s;
}
.avatar-group .avatar img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border: 2px solid #ffffff;
}
.avatar-group .avatar:hover {
    -webkit-mask-image: none;
    mask-image: none;
    z-index: 1;
    transform: scale(1.1);
}
.gmaps {
    height: 300px;
    background: #dcdde1;
    border-radius: 3px;
}
.gmaps-panaroma {
    height: 300px;
    background: #dcdde1;
    border-radius: 3px;
}
.gmaps-overlay {
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    line-height: 40px;
    background: var(--main-color-primary);
    border-radius: 4px;
    padding: 10px 20px;
}
.gmaps-overlay_arrow {
    left: 50%;
    margin-left: -16px;
    width: 0;
    height: 0;
    position: absolute;
}
.gmaps-overlay_arrow.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid var(--main-color-primary);
}
.gmaps-overlay_arrow.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid var(--main-color-primary);
}
.vector-world-map {
    height: 420px;
    margin-bottom: 40px;
}
@media (max-width: 991px) {
    .vector-world-map {
        height: 250px;
        margin-bottom: 30px;
    }
}
@media (max-width: 767px) {
    .vector-world-map {
        height: 200px;
    }
}
.example-modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}
.modal-content {
    border: none;
    background-color: #ffffff;
}
.modal-header {
    background-color: var(--main-color-primary);
    border: 1px solid var(--main-color-primary);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.modal-header .close {
    color: #ffffff;
}
.modal-title {
    color: #ffffff;
    font-size: 18px;
}
.modal-body {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
}
.modal-footer {
    border: 1px solid rgba(0, 0, 0, 0.05);
}
#session-timeout-dialog .modal-header {
    display: block;
}
.dd-list .dd-item > button {
    height: 27px;
    margin: 5px 0 5px 10px;
}
.dd-list .dd-item > button:before {
    color: #8a98ac;
}
.dd-list .dd-item .dd-list {
    padding-left: 40px;
}
.dd-list .dd-item .dd-handle {
    display: block;
    height: auto;
    margin: 10px 0;
    padding: 10px 15px;
    color: #8a98ac;
    text-decoration: none;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #ffffff;
    border-radius: 3px;
    box-sizing: border-box;
}
.dd-list .dd-item .dd-handle:hover {
    color: var(--main-color-primary);
    background: #ffffff;
    border: 1px solid var(--main-color-primary);
    cursor: move;
}
#nestable2 .dd-list .dd-item > button :before {
    color: #8a98ac;
}
#nestable2 .dd-list .dd-item .dd-handle {
    color: #8a98ac;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #ffffff;
}
#nestable2 .dd-list .dd-item .dd-handle:hover {
    color: var(--main-color-primary);
    background: #ffffff;
    border: 1px solid var(--main-color-primary);
}
.dd-list .dd-item.dd3-item > button {
    margin-left: 40px;
}
.dd-list .dd-item .dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 40px;
    text-indent: 40px;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #dcdde1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
}
.dd-list .dd-item .dd3-handle:hover {
    color: var(--main-color-primary);
    background: rgba(110, 129, 220, 0.1);
    border: 1px solid var(--main-color-primary);
    cursor: move;
}
.dd-list .dd-item .dd3-handle:hover:before {
    color: var(--main-color-primary);
}
.dd-list .dd-item .dd3-handle:before {
    content: "";
    font-family: "feather" !important;
    display: block;
    position: absolute;
    left: 0;
    top: 11px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    color: #8a98ac;
    font-size: 20px;
    font-weight: 400;
}
.dd-list .dd-item .dd3-content {
    display: block;
    margin: 10px 10px 10px 0;
    padding: 10px 15px 10px 50px;
    color: #8a98ac;
    text-decoration: none;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #ffffff;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.dd-list .dd-item .dd3-content:hover {
    color: var(--main-color-primary);
    background: #ffffff;
    border: 1px solid var(--main-color-primary);
}
.dd-placeholder {
    background-color: rgba(110, 129, 220, 0.1);
    border-color: var(--main-color-primary);
    border-radius: 3px;
}
.onboard-screen {
    margin-bottom: 90px !important;
}
.onboard-screen .onboard-screen-list {
    text-align: center;
}
.onboard-screen .onboard-screen-list img {
    margin: 0 auto 60px;
    width: 60%;
}
.onboard-screen .slick-dots {
    bottom: -65px;
    left: 12px;
}
.onboard-screen .slick-dots li {
    margin: 0;
}
.onboard-screen .slick-dots li button:before {
    font-size: 12px;
    opacity: 0.25;
    color: var(--main-color-primary);
}
.onboard-screen .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: var(--main-color-primary);
}
.onboard-screen .slick-arrow {
    font-family: "Nunito Sans", sans-serif;
    width: auto;
    height: auto;
    top: auto;
    bottom: -90px;
    z-index: 999;
}
.onboard-screen .slick-prev {
    left: 0;
}
.onboard-screen .slick-prev:before {
    content: none;
}
.onboard-screen .slick-next {
    right: 0;
}
.onboard-screen .slick-next:before {
    content: none;
}
.page-item .page-link {
    color: var(--main-color-primary);
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.page-item .page-link:focus {
    background-color: #dcdde1;
    border-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 0.2rem #e9ecfa;
}
.page-item .page-link:hover {
    color: #2d3646;
    background-color: #dcdde1;
    border-color: rgba(0, 0, 0, 0.05);
}
.page-item.active .page-link {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border-color: var(--main-color-primary);
}
.page-item.disabled .page-link {
    color: #8a98ac;
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.05);
}
.ui-pnotify .ui-pnotify-shadow {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.ui-pnotify .brighttheme {
    border: none;
    border-radius: 3px;
}
.ui-pnotify .brighttheme .ui-pnotify-action-button {
    text-transform: capitalize;
    font-weight: 600;
    padding: 4px 8px;
    border: none;
    background: 0 0;
}
.ui-pnotify .brighttheme .ui-pnotify-action-button.btn-primary {
    background-color: #fcc100;
    color: #282828;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.ui-pnotify .brighttheme .ui-pnotify-action-button.btn-primary:focus {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.ui-pnotify .brighttheme.ui-pnotify-container {
    padding: 15px;
}
.ui-pnotify .brighttheme.brighttheme-notice {
    background-color: #fcc100;
    color: #282828;
}
.ui-pnotify .brighttheme.brighttheme-success {
    background-color: #5fc27e;
    color: #282828;
}
.ui-pnotify .brighttheme.brighttheme-info {
    background-color: #72d0fb;
    color: #282828;
}
.ui-pnotify .brighttheme.brighttheme-error {
    background-color: #f44455;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 35px, rgba(255, 255, 255, 0.3) 35px, rgba(255, 255, 255, 0.3) 70px);
    border: none;
    color: #282828;
}
.ui-pnotify-title {
    display: block;
    margin-bottom: 0.4em;
    margin-top: 0;
    font-size: 20px;
}
.popover {
    font-family: "Nunito Sans", sans-serif;
    border-radius: 3px;
    border: none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}
.popover-header {
    margin-top: 0;
    background-color: #ffffff;
    font-size: 16px;
    color: #282828;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.popover-body {
    padding: 0.5rem 0.75rem;
    color: #8a98ac;
    background-color: #ffffff;
}
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
    border-top-color: #ffffff;
}
.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
    border-right-color: #ffffff;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
    border-bottom-color: #ffffff;
}
.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
    border-left-color: #ffffff;
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-top .arrow::after {
    border-top-color: #ffffff;
}
.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
    border-right-color: #ffffff;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
    border-bottom-color: #ffffff;
}
.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
    border-left-color: #ffffff;
}
.popover-title {
    margin-top: 0;
}
.tooltip .tooltip-inner {
    padding: 4px 10px;
}
.pricing > p span {
    padding: 8px 20px;
    border-radius: 0 0 3px 3px;
}
.pricing .pricing-top {
    padding-top: 30px;
    padding-bottom: 20px;
}
.pricing .pricing-top img {
    width: 150px;
}
.pricing .pricing-top img.price-pro-image {
    width: 180px;
}
.pricing .pricing-middle .list-group-item {
    border: none;
    padding: 5px 0;
}
.pricing .pricing-bottom.pricing-bottom-basic {
    /* background: url(../images/pricing/pricing-basic-bg.svg); */
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 30px;
}
.pricing .pricing-bottom.pricing-bottom-professional {
    /* background: url(../images/pricing/pricing-professional-bg.svg); */
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 30px;
}
.pricing .pricing-bottom.pricing-bottom-enterprise {
    /* background: url(../images/pricing/pricing-enterprise-bg.svg); */
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 30px;
}
@media print {
    .leftbar {
        display: none;
    }
    .topbar {
        display: none;
    }
    .footerbar {
        display: none;
    }
    .breadcrumbbar {
        display: none;
    }
    .rightbar {
        margin-left: 0;
    }
}
.progress {
    background-color: #dcdde1;
}
.progress-bar {
    background-color: var(--main-color-primary);
}

.irs {
    height: 40px;
}
.irs-with-grid {
    height: 60px;
}
.irs-line {
    height: 8px;
    top: 25px;
    border-radius: 15px;
}
.irs-line-left {
    height: 8px;
    background-position: 0 -30px;
    border-radius: 15px;
}
.irs-line-mid {
    height: 8px;
    background-position: 0 0;
}
.irs-line-right {
    height: 8px;
    background-position: 100% -30px;
    border-radius: 15px;
}
.irs-bar {
    height: 8px;
    top: 25px;
    background-position: 0 -60px;
}
.irs-bar-edge {
    top: 25px;
    height: 8px;
    width: 10px;
    background-position: 0 -90px;
    border-radius: 15px 0 0 15px;
}
.irs-shadow {
    height: 3px;
    top: 34px;
    background: #282828;
    opacity: 0.25;
}
.lt-ie9 .irs-shadow {
    filter: alpha(opacity=25);
}
.irs-slider {
    width: 20px;
    height: 20px;
    top: 18px;
    background-color: #ffffff;
    border: 2px solid var(--main-color-primary);
    border-radius: 50%;
    background: #ffffff;
    background-position: 0 -120px;
    z-index: 0;
}
.irs-slider.type_last {
    z-index: 0;
}
.irs-slider.state_hover {
    background-position: 0 -150px;
}
.irs-slider:hover {
    background-position: 0 -150px;
}
.irs-min {
    color: #8a98ac;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: #dcdde1;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.irs-max {
    color: #8a98ac;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: #dcdde1;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.irs-from {
    color: #ffffff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: var(--main-color-primary);
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.irs-to {
    color: #ffffff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: var(--main-color-primary);
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.irs-single {
    color: #ffffff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: var(--main-color-primary);
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.irs-from:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: var(--main-color-primary);
}
.irs-to:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: var(--main-color-primary);
}
.irs-single:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: var(--main-color-primary);
}
.irs-grid-pol {
    background: #dcdde1;
}
.irs-grid-text {
    color: #8a98ac;
}
.br-theme-bars-1to10 .br-widget {
    height: 50px;
    white-space: nowrap;
}
.br-theme-bars-1to10 .br-widget a {
    display: block;
    width: 12px;
    padding: 5px 0;
    height: 28px;
    float: left;
    background-color: rgba(110, 129, 220, 0.1);
    margin: 1px;
    text-align: center;
}
.br-theme-bars-1to10 .br-widget a.br-active {
    background-color: var(--main-color-primary);
}
.br-theme-bars-1to10 .br-widget a.br-selected {
    background-color: var(--main-color-primary);
}
.br-theme-bars-1to10 .br-widget .br-current-rating {
    font-size: 20px;
    line-height: 30px;
    float: left;
    padding: 0 20px 0 20px;
    color: var(--main-color-primary);
    font-weight: 400;
    vertical-align: middle;
}
.br-theme-bars-1to10 .br-readonly a {
    cursor: default;
}
.br-theme-bars-1to10 .br-readonly a.br-active {
    background-color: #f2cd95;
}
.br-theme-bars-1to10 .br-readonly a.br-selected {
    background-color: #f2cd95;
}
.br-theme-bars-1to10 .br-readonly .br-current-rating {
    color: #f2cd95;
}
@media print {
    .br-theme-bars-1to10 .br-widget a {
        border: 1px solid var(--main-color-primary);
        background: #ffffff;
        height: 38px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .br-theme-bars-1to10 .br-widget a.br-active {
        border: 1px solid var(--main-color-primary);
        background: var(--main-color-primary);
    }
    .br-theme-bars-1to10 .br-widget a.br-selected {
        border: 1px solid var(--main-color-primary);
        background: var(--main-color-primary);
    }
    .br-theme-bars-1to10 .br-widget .br-current-rating {
        color: var(--main-color-primary);
    }
}
.br-theme-bars-movie .br-widget {
    height: 50px;
    white-space: nowrap;
}
.br-theme-bars-movie .br-widget a {
    display: block;
    width: 60px;
    height: 8px;
    float: left;
    background-color: rgba(110, 129, 220, 0.1);
    margin: 1px;
}
.br-theme-bars-movie .br-widget a.br-active {
    background-color: var(--main-color-primary);
}
.br-theme-bars-movie .br-widget a.br-selected {
    background-color: var(--main-color-primary);
}
.br-theme-bars-movie .br-widget .br-current-rating {
    clear: both;
    width: 240px;
    text-align: center;
    font-weight: 600;
    display: block;
    padding: 0.5em 0;
    color: var(--main-color-primary);
    font-weight: 400;
}
.br-theme-bars-movie .br-readonly a {
    cursor: default;
}
.br-theme-bars-movie .br-readonly a.br-active {
    background-color: rgba(110, 129, 220, 0.1);
}
.br-theme-bars-movie .br-readonly a.br-selected {
    background-color: rgba(110, 129, 220, 0.1);
}
.br-theme-bars-movie .br-readonly .br-current-rating {
    color: rgba(110, 129, 220, 0.1);
}
@media print {
    .br-theme-bars-movie .br-widget a {
        border: 1px solid var(--main-color-primary);
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .br-theme-bars-movie .br-widget a.br-active {
        border: 1px solid var(--main-color-primary);
        background: var(--main-color-primary);
    }
    .br-theme-bars-movie .br-widget a.br-selected {
        border: 1px solid var(--main-color-primary);
        background: var(--main-color-primary);
    }
    .br-theme-bars-movie .br-widget .br-current-rating {
        color: var(--main-color-primary);
    }
}
.br-theme-bars-square .br-widget {
    height: 50px;
    white-space: nowrap;
}
.br-theme-bars-square .br-widget a {
    display: block;
    width: 30px;
    height: 30px;
    float: left;
    border: 2px solid rgba(110, 129, 220, 0.1);
    background-color: #ffffff;
    margin: 2px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 2;
    text-align: center;
    color: rgba(110, 129, 220, 0.1);
    font-weight: 600;
}
.br-theme-bars-square .br-widget a.br-active {
    border: 2px solid var(--main-color-primary);
    color: var(--main-color-primary);
}
.br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid var(--main-color-primary);
    color: var(--main-color-primary);
}
.br-theme-bars-square .br-widget .br-current-rating {
    clear: both;
    width: 330px;
    text-align: center;
    font-weight: 600;
    display: block;
    padding: 0.5em 0;
    color: var(--main-color-primary);
}
.br-theme-bars-square .br-readonly a {
    cursor: default;
}
.br-theme-bars-square .br-readonly a.br-active {
    border: 2px solid #primary;
    color: var(--main-color-primary);
}
.br-theme-bars-square .br-readonly a.br-selected {
    border: 2px solid #primary;
    color: var(--main-color-primary);
}
@media print {
    .br-theme-bars-square .br-widget a {
        border: 2px solid var(--main-color-primary);
        color: var(--main-color-primary);
    }
    .br-theme-bars-square .br-widget a.br-active {
        border: 2px solid var(--main-color-primary);
        color: var(--main-color-primary);
    }
    .br-theme-bars-square .br-widget a.br-selected {
        border: 2px solid var(--main-color-primary);
        color: var(--main-color-primary);
    }
}
.br-theme-bars-pill .br-widget {
    height: 50px;
    white-space: nowrap;
}
.br-theme-bars-pill .br-widget a {
    padding: 7px 15px;
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
    text-decoration: none;
    font-size: 13px;
    line-height: 3;
    text-align: center;
    font-weight: 400;
}
.br-theme-bars-pill .br-widget a:first-child {
    -webkit-border-top-left-radius: 999px;
    -webkit-border-bottom-left-radius: 999px;
    -moz-border-radius-topleft: 999px;
    -moz-border-radius-bottomleft: 999px;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
}
.br-theme-bars-pill .br-widget a:last-child {
    -webkit-border-top-right-radius: 999px;
    -webkit-border-bottom-right-radius: 999px;
    -moz-border-radius-topright: 999px;
    -moz-border-radius-bottomright: 999px;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
}
.br-theme-bars-pill .br-widget a.br-active {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.br-theme-bars-pill .br-widget a.br-selected {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.br-theme-bars-pill .br-readonly a {
    cursor: default;
}
.br-theme-bars-pill .br-readonly a.br-active {
    background-color: rgba(110, 129, 220, 0.1);
}
.br-theme-bars-pill .br-readonly a.br-selected {
    background-color: rgba(110, 129, 220, 0.1);
}
@media print {
    .br-theme-bars-pill .br-widget a {
        border: 1px solid var(--main-color-primary);
        border-left: none;
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .br-theme-bars-pill .br-widget a:first-child {
        border-left: 1px solid var(--main-color-primary);
    }
    .br-theme-bars-pill .br-widget a.br-active {
        border: 1px solid var(--main-color-primary);
        border-left: none;
        background: #ffffff;
        color: var(--main-color-primary);
    }
    .br-theme-bars-pill .br-widget a.br-selected {
        border: 1px solid var(--main-color-primary);
        border-left: none;
        background: #ffffff;
        color: var(--main-color-primary);
    }
}
.br-theme-bars-reversed .br-widget {
    height: 50px;
    white-space: nowrap;
}
.br-theme-bars-reversed .br-widget a {
    display: block;
    width: 22px;
    height: 22px;
    float: left;
    background-color: rgba(110, 129, 220, 0.1);
    margin: 1px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    color: var(--main-color-primary);
    text-align: center;
}
.br-theme-bars-reversed .br-widget a.br-active {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.br-theme-bars-reversed .br-widget a.br-selected {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.br-theme-bars-reversed .br-widget .br-current-rating {
    line-height: 1.3;
    float: left;
    padding: 0 20px 0 20px;
    color: var(--main-color-primary);
    font-size: 17px;
    font-weight: 400;
}
.br-theme-bars-reversed .br-readonly a {
    cursor: default;
}
.br-theme-bars-reversed .br-readonly a.br-active {
    background-color: var(--main-color-primary);
}
.br-theme-bars-reversed .br-readonly a.br-selected {
    background-color: var(--main-color-primary);
}
.br-theme-bars-reversed .br-readonly .br-current-rating {
    color: var(--main-color-primary);
}
@media print {
    .br-theme-bars-reversed .br-widget a {
        border: 1px solid var(--main-color-primary);
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .br-theme-bars-reversed .br-widget a.br-active {
        border: 1px solid var(--main-color-primary);
        background: white;
    }
    .br-theme-bars-reversed .br-widget a.br-selected {
        border: 1px solid var(--main-color-primary);
        background: white;
    }
    .br-theme-bars-reversed .br-widget .br-current-rating {
        color: var(--main-color-primary);
    }
}
.br-theme-bars-horizontal .br-widget {
    width: 50px;
    white-space: nowrap;
}
.br-theme-bars-horizontal .br-widget a {
    display: block;
    width: 120px;
    height: 5px;
    background-color: rgba(110, 129, 220, 0.1);
    margin: 1px;
}
.br-theme-bars-horizontal .br-widget a.br-active {
    background-color: var(--main-color-primary);
}
.br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: var(--main-color-primary);
}
.br-theme-bars-horizontal .br-widget .br-current-rating {
    width: 120px;
    font-size: 18px;
    font-weight: 600;
    line-height: 2;
    text-align: center;
    color: var(--main-color-primary);
}
.br-theme-bars-horizontal .br-readonly a {
    cursor: default;
}
.br-theme-bars-horizontal .br-readonly a.br-active {
    background-color: var(--main-color-primary);
}
.br-theme-bars-horizontal .br-readonly a.br-selected {
    background-color: var(--main-color-primary);
}
.br-theme-bars-horizontal .br-readonly .br-current-rating {
    color: var(--main-color-primary);
}
@media print {
    .br-theme-bars-horizontal .br-widget a {
        border: 1px solid var(--main-color-primary);
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .br-theme-bars-horizontal .br-widget a.br-active {
        border: 1px solid var(--main-color-primary);
        background: white;
    }
    .br-theme-bars-horizontal .br-widget a.br-selected {
        border: 1px solid var(--main-color-primary);
        background: white;
    }
    .br-theme-bars-horizontal .br-widget .br-current-rating {
        color: var(--main-color-primary);
    }
}
.br-theme-css-stars .br-widget {
    height: 50px;
    white-space: nowrap;
}
.br-theme-css-stars .br-widget a {
    text-decoration: none;
    height: 18px;
    width: 18px;
    float: left;
    font-size: 23px;
    margin-right: 5px;
}
.br-theme-css-stars .br-widget a:after {
    content: "★";
    color: rgba(110, 129, 220, 0.1);
}
.br-theme-css-stars .br-widget a.br-active:after {
    color: var(--main-color-primary);
}
.br-theme-css-stars .br-widget a.br-selected:after {
    color: var(--main-color-primary);
}
.br-theme-css-stars .br-widget .br-current-rating {
    display: none;
}
.br-theme-css-stars .br-readonly a {
    cursor: default;
}
@media print {
    .br-theme-css-stars .br-widget a:after {
        content: "☆";
        color: var(--main-color-primary);
    }
    .br-theme-css-stars .br-widget a.br-active:after {
        content: "★";
        color: var(--main-color-primary);
    }
    .br-theme-css-stars .br-widget a.br-selected:after {
        content: "★";
        color: var(--main-color-primary);
    }
}
.br-theme-fontawesome-stars .br-widget {
    height: 50px;
    white-space: nowrap;
}
.br-theme-fontawesome-stars .br-widget a {
    font: normal normal normal 20px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    margin-right: 2px;
}
.br-theme-fontawesome-stars .br-widget a:after {
    content: "";
    color: rgba(110, 129, 220, 0.1);
}
.br-theme-fontawesome-stars .br-widget a.br-active:after {
    color: var(--main-color-primary);
}
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: var(--main-color-primary);
}
.br-theme-fontawesome-stars .br-widget .br-current-rating {
    display: none;
}
.br-theme-fontawesome-stars .br-readonly a {
    cursor: default;
}
@media print {
    .br-theme-fontawesome-stars .br-widget a:after {
        content: "";
        color: var(--main-color-primary);
    }
    .br-theme-fontawesome-stars .br-widget a.br-active:after {
        content: "";
        color: var(--main-color-primary);
    }
    .br-theme-fontawesome-stars .br-widget a.br-selected:after {
        content: "";
        color: var(--main-color-primary);
    }
}
.br-theme-fontawesome-stars-o .br-widget {
    height: 30px;
    white-space: nowrap;
}
.br-theme-fontawesome-stars-o .br-widget a {
    font: normal normal normal 20px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    margin-right: 2px;
}
.br-theme-fontawesome-stars-o .br-widget a:after {
    content: "";
    color: rgba(110, 129, 220, 0.1);
}
.br-theme-fontawesome-stars-o .br-widget a.br-active:after {
    content: "";
    color: var(--main-color-primary);
}
.br-theme-fontawesome-stars-o .br-widget a.br-selected:after {
    content: "";
    color: var(--main-color-primary);
}
.br-theme-fontawesome-stars-o .br-widget a.br-fractional:after {
    content: "";
    color: var(--main-color-primary);
}
.br-theme-fontawesome-stars-o .br-widget .br-current-rating {
    display: none;
}
.br-theme-fontawesome-stars-o .br-readonly a {
    cursor: default;
}
.br-theme-fontawesome-stars-o .br-reverse a.br-fractional {
    display: inline-block;
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}
@media print {
    .br-theme-fontawesome-stars-o .br-widget a:after {
        content: "";
        color: var(--main-color-primary);
    }
    .br-theme-fontawesome-stars-o .br-widget a.br-active:after {
        content: "";
        color: var(--main-color-primary);
    }
    .br-theme-fontawesome-stars-o .br-widget a.br-selected:after {
        content: "";
        color: var(--main-color-primary);
    }
    .br-theme-fontawesome-stars-o .br-widget a.br-fractional:after {
        content: "";
        color: var(--main-color-primary);
    }
}
.authenticate-bg {
    /* background: url(../images/authentication/authentication-bg.svg); */
    background-size: cover;
    background-position: center;
}
.auth-box > .row {
    
    padding: 15px 0;
}
.auth-box .auth-box-left .card {
    background-color: #ffffff;
    border-radius: 3px 0 0 3px;
}
.auth-box .auth-box-left .card .card-body {
    padding: 30px;
}
.auth-box .auth-box-left .auth-box-icon {
    margin: 83px 0;
}
.auth-box .auth-box-left .auth-box-logo {
    width: 150px;
}
.auth-box .auth-box-right .card {
    border-radius: 0 3px 3px 0;
}
.auth-box .auth-box-right .card .card-body {
    padding: 30px;
}
.auth-box .auth-box-right .error-title {
    font-size: 90px;
}
.auth-box .auth-box-right .web-logo img {
    margin: 30px 0;
}
.auth-box .auth-box-right .user-logo {
    position: relative;
    z-index: 999;
}
.auth-box .auth-box-right .user-logo img {
    height: 80px;
    width: 80px;
}
.auth-box .auth-box-right .login-or {
    position: relative;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.auth-box .auth-box-right .login-or:before {
    content: "";
    background: rgba(0, 0, 0, 0.05);
    height: 1px;
    width: 45%;
    top: 45%;
    left: 0;
    position: absolute;
}
.auth-box .auth-box-right .login-or:after {
    content: "";
    background: rgba(0, 0, 0, 0.05);
    height: 1px;
    width: 45%;
    top: 45%;
    right: 0;
    position: absolute;
}
.auth-box .auth-box-right .forgot-psw {
    text-align: right;
}
.auth-box .auth-box-right .custom-checkbox .custom-control-label:before {
    margin-top: -2px;
}
.auth-box .auth-box-right .custom-checkbox .custom-control-label:after {
    margin-top: -2px;
}
.auth-box.login-box .auth-box-right .card {
    padding-bottom: 12px;
}
.auth-box.register-box .auth-box-right .card {
    padding-bottom: 50px;
}
.auth-box.forgot-password-box .auth-box-right .card {
    padding-bottom: 188px;
}
.auth-box.lock-screen-box .auth-box-right .card {
    padding-bottom: 104px;
}
.auth-box.error-box .error-logo {
    width: 150px;
}
.auth-box.error-box .error-image {
    margin: 30px 0;
}
.auth-box.error-box > .row {
    height: 100vh;
}
.auth-box .countdown-block {
    display: inline-block;
    padding: 30px;
}
@media (max-width: 767px) {
    .auth-box > .row {
        height: auto;
    }
    .auth-box .auth-box-left .card {
        border-radius: 3px 3px 0 0;
    }
    .auth-box .auth-box-left .auth-box-icon {
        margin: 30px 0;
    }
    .auth-box .auth-box-left .auth-box-icon img {
        width: 150px;
    }
    .auth-box .auth-box-left .auth-box-logo {
        width: 100px;
    }
    .auth-box .auth-box-left h4 {
        font-size: 22px;
    }
    .auth-box .auth-box-right .card {
        border-radius: 0 0 3px 3px;
    }
    .auth-box .auth-box-right h4 {
        font-size: 22px;
    }
    .auth-box.login-box .auth-box-right .card {
        padding-bottom: 0;
    }
    .auth-box.register-box .auth-box-right .card {
        padding-bottom: 0;
    }
    .auth-box.forgot-password-box .auth-box-right .card {
        padding-bottom: 0;
    }
    .auth-box.lock-screen-box .auth-box-right .card {
        padding-bottom: 0;
    }
}
@media (max-width: 575px) {
    .auth-box .auth-box-right .forgot-psw {
        text-align: left;
    }
}
.note-btn-group .dropdown-menu > li > a {
    display: block;
    padding: 5px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #282828;
    white-space: nowrap;
}
.note-btn-group .dropdown-menu > li > a:hover {
    background-color: #e1e2e6;
}
.note-image-popover {
    display: none;
}
.note-image-popover .dropdown-toggle::after {
    margin-left: 0;
}
.note-air-popover {
    display: none;
}
.note-air-popover .dropdown-toggle::after {
    margin-left: 0;
}
.note-link-popover {
    display: none;
}
.note-link-popover .dropdown-toggle::after {
    margin-left: 0;
}
.note-icon-caret {
    display: none;
}
.note-editor {
    position: relative;
}
.note-editor .btn-default {
    background-color: transparent;
    border-color: transparent;
}
.note-editor .btn-group-sm > .btn,
.note-editor .btn-sm {
    padding: 8px 12px;
}
.note-editor .note-toolbar {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: 0;
}
.note-editor .note-toolbar .btn-light {
    color: #8a98ac;
    background-color: #dcdde1;
    border-color: transparent;
    box-shadow: none;
}
.note-editor .note-statusbar {
    background-color: transparent !important;
}
.note-editor .note-statusbar .note-resizebar {
    border-top: none;
    height: 15px;
    padding-top: 3px;
}
.note-editor.note-frame {
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.note-editor.note-frame .note-editing-area .note-editable {
    background-color: transparent;
    color: #8a98ac;
}
.note-popover .popover .popover-content {
    padding: 5px 0 10px 5px;
}
.note-popover .btn-default {
    background-color: transparent;
    border-color: transparent;
}
.note-popover .btn-group-sm > .btn,
.note-popover .btn-sm {
    padding: 8px 12px;
}
.note-toolbar {
    padding: 5px 0 10px 5px;
}
.swal2-modal {
    font-family: "Nunito Sans", sans-serif;
    border-radius: 3px;
    border: none;
}
.swal2-modal .swal2-title {
    font-weight: 400;
    font-size: 20px;
    color: #282828;
}
.swal2-modal .swal2-content {
    font-size: 16px;
    font-weight: 400;
    color: #8a98ac;
}
.swal2-modal .swal2-spacer {
    margin: 10px 0;
}
.swal2-modal .swal2-file,
.swal2-modal .swal2-input,
.swal2-modal .swal2-textarea {
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 16px;
    box-shadow: none !important;
    background-color: transparent;
}
.swal2-modal .swal2-file:focus,
.swal2-modal .swal2-input:focus,
.swal2-modal .swal2-textarea:focus {
    border: 1px solid var(--main-color-primary);
}
.swal2-modal .swal2-styled {
    border: inherit;
    font-size: 14px;
    font-weight: 400;
    margin: 0 5px;
    padding: 6px 12px;
}
.swal2-modal .swal2-confirm {
    background-color: var(--main-color-primary) !important;
    border: none;
}
.swal2-modal .swal2-confirm.btn-success {
    background-color: #5fc27e !important;
    box-shadow: none;
    border: none;
    border-radius: 3px;
}
.swal2-modal .swal2-cancel {
    background-color: #f44455 !important;
    border: none;
    border-radius: 3px;
}
.swal2-modal .swal2-icon.swal2-error {
    color: #f44455;
    border-color: #faa4ad;
}
.swal2-modal .swal2-icon.swal2-question {
    color: var(--main-color-primary);
    border-color: #c0c8f0;
}
.swal2-modal .swal2-icon.swal2-warning {
    color: #fcc100;
    border-color: #ffda63;
}
.swal2-modal .swal2-icon.swal2-info {
    color: #72d0fb;
    border-color: #d5f1fe;
}
.swal2-modal .swal2-icon.swal2-success {
    color: #5fc27e;
    border-color: #5fc27e;
}
.swal2-modal .swal2-icon.swal2-success .line {
    background-color: #5fc27e;
}
.swal2-modal .swal2-icon.swal2-success .placeholder {
    border: 4px solid #ceecd7;
}
.swal2-modal .swal2-icon.swal2-success:before {
    background: transparent;
}
.swal2-modal .swal2-icon.swal2-success:after {
    background: transparent;
}
.swal2-modal .swal2-icon.swal2-success .fix {
    background: transparent;
}
.swal2-modal .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
    background: var(--main-color-primary);
}
.swal2-modal .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
    background: #c0c8f0;
}
.swal2-modal .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
    background: #c0c8f0;
}
.swal2-modal .swal2-progresssteps .swal2-progresscircle {
    background: var(--main-color-primary);
}
.swal2-modal .swal2-progresssteps .swal2-progressline {
    background: var(--main-color-primary);
}
.swal2-modal .swal2-validationerror {
    background-color: #dcdde1;
    color: #8a98ac;
    font-weight: 400;
}
.swal2-modal .swal2-validationerror:before {
    background-color: #f44455;
}
.switchery {
    background-color: #ffffff;
    border: 1px solid tranparent;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    position: relative;
    vertical-align: middle;
    width: 50px;
    margin-bottom: 5px;
}
.switchery-small {
    border-radius: 20px;
    height: 20px;
    width: 33px;
}
.switchery-small > small {
    height: 20px;
    width: 20px;
}
.switchery-large {
    border-radius: 40px;
    height: 40px;
    width: 66px;
}
.switchery-large > small {
    height: 40px;
    width: 40px;
}
.table {
    margin-bottom: 10px;
    color: #282828;
}
.table th {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0.7rem;
}
.table td {
    color: #8a98ac;
    vertical-align: middle;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0.6rem;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.table .thead-light th {
    color: #282828;
    background-color: #dcdde1;
    border-color: rgba(0, 0, 0, 0.05);
}
.table .thead-dark th {
    color: #ffffff;
    background-color: #2d3646;
    border-color: rgba(0, 0, 0, 0.05);
}
.table-bordered {
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.table-bordered th {
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.table-bordered td {
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
    color: #282828;
    background-color: #dcdde1;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #dcdde1;
}
.thead-default th {
    background-color: #dcdde1;
}
.table-dark {
    color: #ffffff;
    background-color: #2d3646;
}
.table-dark td {
    border-color: rgba(255, 255, 255, 0.05);
    color: #8a98ac;
}
.table-dark th {
    border-color: rgba(255, 255, 255, 0.05);
}
.table-dark thead th {
    border-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.05);
}
.table-borderless tbody + tbody {
    border: 0 !important;
}
.table-borderless td {
    border: 0 !important;
}
.table-borderless th {
    border: 0 !important;
}
.table-borderless thead th {
    border: 0 !important;
}
.table-primary {
    background-color: rgba(110, 129, 220, 0.1);
}
.table-primary > td {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
}
.table-primary > th {
    background-color: rgba(110, 129, 220, 0.1);
    color: var(--main-color-primary);
}
.table-secondary {
    background-color: rgba(113, 128, 147, 0.1);
}
.table-secondary > td {
    background-color: rgba(113, 128, 147, 0.1);
    color: #718093;
}
.table-secondary > th {
    background-color: rgba(113, 128, 147, 0.1);
    color: #718093;
}
.table-success {
    background-color: rgba(95, 194, 126, 0.1);
}
.table-success > td {
    background-color: rgba(95, 194, 126, 0.1);
    color: #5fc27e;
}
.table-success > th {
    background-color: rgba(95, 194, 126, 0.1);
    color: #5fc27e;
}
.table-danger {
    background-color: rgba(244, 68, 85, 0.1);
}
.table-danger > td {
    background-color: rgba(244, 68, 85, 0.1);
    color: #f44455;
}
.table-danger > th {
    background-color: rgba(244, 68, 85, 0.1);
    color: #f44455;
}
.table-warning {
    background-color: rgba(252, 193, 0, 0.1);
}
.table-warning > td {
    background-color: rgba(252, 193, 0, 0.1);
    color: #fcc100;
}
.table-warning > th {
    background-color: rgba(252, 193, 0, 0.1);
    color: #fcc100;
}
.table-info {
    background-color: rgba(114, 208, 251, 0.1);
}
.table-info > td {
    background-color: rgba(114, 208, 251, 0.1);
    color: #72d0fb;
}
.table-info > th {
    background-color: rgba(114, 208, 251, 0.1);
    color: #72d0fb;
}
.table-light {
    background-color: rgba(220, 221, 225, 0.1);
}
.table-light > td {
    background-color: rgba(220, 221, 225, 0.1);
    color: #8a98ac;
}
.table-light > th {
    background-color: rgba(220, 221, 225, 0.1);
    color: #8a98ac;
}
.table.dataTable {
    border-spacing: 0;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before {
    background-color: var(--main-color-primary);
    border: none;
    box-shadow: none;
    height: 18px;
    width: 18px;
    line-height: 18px;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    background-color: var(--main-color-primary);
    border: none;
    box-shadow: none;
    height: 18px;
    width: 18px;
    line-height: 18px;
}
div.dt-button-info {
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border: none !important;
    border-radius: 3px !important;
}
div.dt-button-info h2 {
    color: #282828;
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 0.05);
    font-size: 20px;
    border-radius: 3px 3px 0 0 !important;
}
div.dt-button-info div {
    color: #8a98ac;
}
button.tabledit-edit-button {
    background-color: var(--main-color-primary);
    border: none;
    border-radius: 3px !important;
    width: 35px;
    height: 35px;
}
button.tabledit-edit-button:active {
    background-color: var(--main-color-primary-hover) !important;
    box-shadow: none !important;
}
button.tabledit-edit-button:hover {
    background-color: var(--main-color-primary-hover) !important;
    box-shadow: none !important;
}
button.tabledit-edit-button:focus {
    background-color: var(--main-color-primary-hover) !important;
    box-shadow: none !important;
}
button.tabledit-edit-button:visited {
    background-color: var(--main-color-primary-hover) !important;
    box-shadow: none !important;
}
button.tabledit-edit-button:not(:disabled):not(.disabled).active {
    background-color: var(--main-color-primary-hover) !important;
    box-shadow: none !important;
}
button.tabledit-delete-button {
    background-color: #f44455;
    border: none !important;
    border-radius: 3px !important;
    width: 35px;
    height: 35px;
}
button.tabledit-delete-button:active {
    background-color: #f32c3f !important;
    box-shadow: none !important;
}
button.tabledit-delete-button:hover {
    background-color: #f32c3f !important;
    box-shadow: none !important;
}
button.tabledit-delete-button:focus {
    background-color: #f32c3f !important;
    box-shadow: none !important;
}
button.tabledit-delete-button:visited {
    background-color: #f32c3f !important;
    box-shadow: none !important;
}
button.tabledit-delete-button:not(:disabled):not(.disabled).active {
    background-color: #f32c3f !important;
    box-shadow: none !important;
}
.tabledit-save-button {
    border-radius: 3px;
}
.tabledit-confirm-button {
    border-radius: 3px;
}
table.focus-on tbody tr.focused th {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
table.focus-on tbody tr.focused td {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
table.focus-on tfoot tr.focused th {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
table.focus-on tfoot tr.focused td {
    background-color: var(--main-color-primary);
    color: #ffffff;
}
.table-wrapper .btn-toolbar {
    display: block;
    margin-bottom: 15px;
}
.table-responsive[data-pattern="priority-columns"] {
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.footable-paging .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}
.footable-paging .pagination > li {
    display: inline;
}
.footable-paging .pagination > li a.footable-page-link {
    position: relative;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--main-color-primary);
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.footable-paging .pagination > li:first-child a.footable-page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.footable-paging .pagination > li:last-child a.footable-page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.footable-paging .pagination > li.active a.footable-page-link {
    color: #ffffff;
    background-color: var(--main-color-primary);
    border: 1px solid var(--main-color-primary);
}
.footable-paging .label {
    background-color: #dcdde1;
    color: #8a98ac;
    padding: 2px 10px;
    border-radius: 3px;
}
.footable-filtering .form-inline {
    display: block;
    float: right;
}
.footable-filtering .open > .dropdown-menu {
    display: block;
    padding: 15px;
    color: #8a98ac;
}
.footable-filtering .open > .dropdown-menu li a.checkbox input[type="checkbox"] {
    margin-top: 5px;
}
.footable-filtering .input-group-btn .btn {
    margin-left: 5px;
    padding: 7px 12px;
}
.footable-filtering .input-group-btn .fooicon-search:before {
    content: "";
    font-family: "feather" !important;
}
.nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.nav-tabs .nav-link {
    color: #2d3646;
}
.nav-tabs .nav-link:hover {
    border-color: rgba(0, 0, 0, 0.05) rgba(0, 0, 0, 0.05) #ffffff;
}
.nav-tabs .nav-link:focus {
    border-color: rgba(0, 0, 0, 0.05) rgba(0, 0, 0, 0.05) #ffffff;
}
.nav-tabs .nav-link.active {
    color: var(--main-color-primary);
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 0.05) rgba(0, 0, 0, 0.05) #ffffff;
}
.nav-tabs .nav-link.disabled {
    color: #718093;
}
.nav-tabs .nav-item.show .nav-link {
    color: var(--main-color-primary);
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 0.05) rgba(0, 0, 0, 0.05) #ffffff;
}
.nav-pills .nav-link {
    color: #2d3646;
}
.nav-pills .nav-link.disabled {
    color: #718093;
}
.nav-pills .nav-link.active {
    color: #ffffff;
    background-color: var(--main-color-primary);
}
.nav-pills .show > .nav-link {
    color: #ffffff;
    background-color: var(--main-color-primary);
}
.nav-tabs.custom-tab-line .nav-item {
    margin-right: 20px;
}
.nav-tabs.custom-tab-line .nav-item.show .nav-link {
    color: var(--main-color-primary);
    background-color: #ffffff;
    border-color: #ffffff #ffffff var(--main-color-primary);
}
.nav-tabs.custom-tab-line .nav-link {
    border-bottom-width: 2px;
    padding-left: 0;
    padding-right: 0;
}
.nav-tabs.custom-tab-line .nav-link.active {
    color: var(--main-color-primary);
    background-color: #ffffff;
    border-color: #ffffff #ffffff var(--main-color-primary);
}
.nav-tabs.custom-tab-line .nav-link:focus {
    color: var(--main-color-primary);
    background-color: #ffffff;
    border-color: #ffffff #ffffff var(--main-color-primary);
}
.nav-tabs.custom-tab-line .nav-link:hover {
    color: var(--main-color-primary);
    background-color: #ffffff;
    border-color: #ffffff #ffffff var(--main-color-primary);
}
.nav-pills.custom-tab-button {
    text-align: center;
}
.nav-pills.custom-tab-button .nav-item {
    margin-right: 20px;
}
.nav-pills.custom-tab-button .nav-link .tab-btn-icon {
    display: block;
}
.timeline-container {
    padding: 2.5em 0;
}
.btn-toolbar {
    margin: 0 auto;
}
.tool-bottom .tool-item:first-child,
.tool-top .tool-item:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.tool-bottom .tool-item:last-child,
.tool-top .tool-item:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.btn-toolbar > i {
    color: #ffffff;
    font-size: 20px;
}
.toolbar-primary .tool-item {
    background: var(--main-color-primary);
}
.toolbar-primary .tool-item > .feather {
    color: #ffffff;
}
.toolbar-primary.tool-top .arrow {
    border-color: var(--main-color-primary) transparent transparent;
}
.toolbar-primary .tool-item.selected {
    background: var(--main-color-primary-hover);
}
.toolbar-primary .tool-item:hover {
    background: var(--main-color-primary-hover);
}
.btn-toolbar-primary.pressed {
    background: var(--main-color-primary-hover);
}
.toolbar-secondary .tool-item {
    background: #718093;
}
.toolbar-secondary .tool-item > .feather {
    color: #ffffff;
}
.toolbar-secondary.tool-top .arrow {
    border-color: #718093 transparent transparent;
}
.toolbar-secondary .tool-item.selected {
    background: #657385;
}
.toolbar-secondary .tool-item:hover {
    background: #657385;
}
.btn-toolbar-secondary.pressed {
    background: #657385;
}
.toolbar-success .tool-item {
    background: #5fc27e;
}
.toolbar-success .tool-item > .feather {
    color: #ffffff;
}
.toolbar-success.tool-top .arrow {
    border-color: #5fc27e transparent transparent;
}
.toolbar-success.tool-right .arrow {
    border-color: transparent #5fc27e transparent transparent;
}
.toolbar-success .tool-item.selected {
    background: #4dbb6f;
}
.toolbar-success .tool-item:hover {
    background: #4dbb6f;
}
.btn-toolbar-success.pressed {
    background: #4dbb6f;
}
.toolbar-danger .tool-item {
    background: #f44455;
}
.toolbar-danger .tool-item > .feather {
    color: #ffffff;
}
.toolbar-danger.tool-top .arrow {
    border-color: #f44455 transparent transparent;
}
.toolbar-danger.tool-bottom .arrow {
    border-color: transparent transparent #f44455;
}
.toolbar-danger .tool-item.selected {
    background: #f32c3f;
}
.toolbar-danger .tool-item:hover {
    background: #f32c3f;
}
.btn-toolbar-danger.pressed {
    background: #f32c3f;
}
.toolbar-warning .tool-item {
    background: #fcc100;
}
.toolbar-warning .tool-item > .feather {
    color: #ffffff;
}
.toolbar-warning.tool-top .arrow {
    border-color: #fcc100 transparent transparent;
}
.toolbar-warning.tool-left .arrow {
    border-color: transparent transparent transparent #fcc100;
}
.toolbar-warning .tool-item.selected {
    background: #e3ad00;
}
.toolbar-warning .tool-item:hover {
    background: #e3ad00;
}
.btn-toolbar-warning.pressed {
    background: #e3ad00;
}
.toolbar-info .tool-item {
    background: #72d0fb;
}
.toolbar-info .tool-item > .feather {
    color: #ffffff;
}
.toolbar-info.tool-top .arrow {
    border-color: #72d0fb transparent transparent;
}
.toolbar-info .tool-item.selected {
    background: #59c8fa;
}
.toolbar-info .tool-item:hover {
    background: #59c8fa;
}
.btn-toolbar-info.pressed {
    background: #59c8fa;
}
.toolbar-light .tool-item {
    background: #dcdde1;
}
.toolbar-light .tool-item > .feather {
    color: #ffffff;
}
.toolbar-light.tool-top .arrow {
    border-color: #dcdde1 transparent transparent;
}
.toolbar-light .tool-item.selected {
    background: #ced0d5;
}
.toolbar-light .tool-item:hover {
    background: #ced0d5;
}
.btn-toolbar-light.pressed {
    background: #ced0d5;
}
.toolbar-dark .tool-item {
    background: #2d3646;
}
.toolbar-dark .tool-item > .feather {
    color: #ffffff;
}
.toolbar-dark.tool-top .arrow {
    border-color: #2d3646 transparent transparent;
}
.toolbar-dark .tool-item.selected {
    background: #232a36;
}
.toolbar-dark .tool-item:hover {
    background: #232a36;
}
.btn-toolbar-dark.pressed {
    background: #232a36;
}
.tourbar .card-body {
    padding: 20px;
}
.cd-single-step .cd-more-info {
    font-family: "Nunito Sans", sans-serif !important;
    border-radius: 3px;
    border: none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
}
.cd-single-step .cd-more-info:before {
    color: #ffffff;
}
.cd-single-step .cd-more-info h2 {
    font-size: 18px;
    color: #282828;
    line-height: 1.2;
    margin-bottom: 0.4em;
}
.cd-single-step .cd-more-info p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 1.4em;
    font-family: "Nunito Sans", sans-serif !important;
    color: #8a98ac;
}
.cd-single-step .cd-more-info img {
    margin-bottom: 1.4em;
    max-width: 100%;
    width: 150px;
}
.cd-single-step .cd-more-info span {
    float: left;
    padding-top: 0.1em;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif !important;
}
.cd-single-step .cd-more-info .cd-close:before {
    background-color: #8a98ac;
}
.cd-single-step .cd-more-info .cd-close:after {
    background-color: #8a98ac;
}
.cd-single-step .cd-more-info .cd-tour-nav li:first-of-type {
    margin-right: 15px;
}
.cd-single-step .cd-more-info .cd-tour-nav a {
    font-size: 14px;
    color: var(--main-color-primary);
    font-weight: 400;
    background-color: rgba(110, 129, 220, 0.1);
    padding: 6px 12px;
    border-radius: 3px;
}
.cd-single-step .cd-more-info .cd-tour-nav a.cd-prev.inactive {
    background-color: rgba(113, 128, 147, 0.1);
    border: none;
    color: #718093;
}
.cd-single-step .cd-more-info .cd-tour-nav a.cd-next.inactive {
    background-color: rgba(113, 128, 147, 0.1);
    border: none;
    color: #718093;
}
@media only screen and (min-width: 768px) {
    .cd-single-step .cd-more-info {
        font-family: "Nunito Sans", sans-serif !important;
        border-radius: 3px;
        border: none;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        padding: 20px;
    }
    .cd-single-step .cd-more-info:before {
        color: #ffffff;
    }
    .cd-single-step .cd-more-info h2 {
        font-size: 18px;
        color: #282828;
        line-height: 1.2;
        margin-bottom: 0.4em;
    }
    .cd-single-step .cd-more-info p {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 1.4em;
        font-family: "Nunito Sans", sans-serif !important;
        color: #8a98ac;
    }
    .cd-single-step .cd-more-info span {
        float: left;
        padding-top: 0.1em;
        font-size: 14px;
        font-family: "Nunito Sans", sans-serif !important;
    }
    .cd-single-step .cd-more-info .cd-close:before {
        background-color: #8a98ac;
    }
    .cd-single-step .cd-more-info .cd-close:after {
        background-color: #8a98ac;
    }
    .cd-single-step .cd-more-info .cd-tour-nav li:first-of-type {
        margin-right: 15px;
    }
    .cd-single-step .cd-more-info .cd-tour-nav a {
        font-size: 14px;
        color: var(--main-color-primary);
        font-weight: 400;
        background-color: rgba(110, 129, 220, 0.1);
        padding: 6px 12px;
        border-radius: 3px;
    }
    .cd-single-step .cd-more-info .cd-tour-nav a.cd-prev.inactive {
        background-color: rgba(113, 128, 147, 0.1);
        border: none;
        color: #718093;
    }
    .cd-single-step .cd-more-info .cd-tour-nav a.cd-next.inactive {
        background-color: rgba(113, 128, 147, 0.1);
        border: none;
        color: #718093;
    }
}
.widget-icon-bg {
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    margin: 0 auto;
}
.to-do-list .list-group-item {
    border: none;
}
.to-do-list .custom-control-label {
    color: #282828;
}
.to-do-list-remove {
    float: right;
    vertical-align: middle;
    cursor: pointer;
}
.to-do-list-remove:hover {
    color: #f44455 !important;
}
.activities-history-list {
    position: relative;
}
.activities-history-list i {
    position: absolute;
    color: var(--main-color-primary);
    top: 9px;
    left: 9px;
    font-size: 18px;
}
.activities-history-list:before {
    content: "";
    position: absolute;
    top: 0;
    width: 36px;
    height: 36px;
    border-radius: 3px;
    background-color: rgba(110, 129, 220, 0.1);
}
.activities-history-list:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 3px;
    top: 36px;
    left: 16px;
    background: #dcdde1;
}
.activities-history-list .activities-history-item {
    margin-left: 55px;
    margin-bottom: 36px;
}
.activities-history-list.activities-success i {
    color: #5fc27e;
}
.activities-history-list.activities-success:before {
    background-color: rgba(95, 194, 126, 0.1);
}
.activities-history-list.activities-danger i {
    color: #f44455;
}
.activities-history-list.activities-danger:before {
    background-color: rgba(244, 68, 85, 0.1);
}
.activities-history-list.activities-info i {
    color: #72d0fb;
}
.activities-history-list.activities-info:before {
    background-color: rgba(114, 208, 251, 0.1);
}
.icon-timer i {
    width: 30px;
    height: 30px;
    padding: 15px;
    font-size: 20px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.2);
}
.widget-newsletter .form-control {
    background-color: #ffffff;
    border-radius: 50px;
}
.widget-newsletter .form-control:focus {
    background-color: #ffffff;
}
.widget-newsletter .input-group-append {
    padding: 3px;
    background: #ffffff;
    border-radius: 0 50px 50px 0;
}
.widget-newsletter .input-group-append .btn {
    border-radius: 50px;
    background-color: #f44455;
    color: #ffffff;
    padding: 4px 10px;
    font-weight: 700;
}
.chartist-widget-color {
    height: 194px !important;
}
.chartist-widget-color .ct-series-a .ct-bar {
    stroke: #ffffff;
}
.chartist-widget-color .ct-label {
    fill: #ffffff;
    color: #ffffff;
    font-size: 14px;
    margin-top: 5px;
}
.blog-meta {
    text-align: right;
    font-size: 14px;
}
@media (max-width: 767px) {
    .blog-link {
        text-align: center;
    }
    .blog-meta {
        text-align: center;
        margin-top: 15px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    body {
        overflow-x: hidden;
    }
}
@media (max-width: 991px) {
    h1 {
        font-size: 38px;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 26px;
    }
    h4 {
        font-size: 22px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }
}
@media (min-width: 768px) {
    .vertical-layout.toggle-menu .leftbar {
        position: fixed;
        width: 80px;
        margin-left: 0;
        transition: all 0.3s ease;
    }
    .vertical-layout.toggle-menu .leftbar:hover {
        width: 250px;
    }
    .vertical-layout.toggle-menu .leftbar:hover ~ .rightbar {
        margin-left: 250px;
    }
    .vertical-layout.toggle-menu .leftbar:hover .vertical-menu > li > a > span {
        display: inline-block;
    }
    .vertical-layout.toggle-menu .leftbar:hover .vertical-menu > li > a > i {
        font-size: 16px;
    }
    .vertical-layout.toggle-menu .leftbar:hover .vertical-menu li > a > .icon-chevron-right {
        display: inline-block;
    }
    .vertical-layout.toggle-menu .leftbar:hover .vertical-menu li.active > .vertical-submenu {
        display: block !important;
    }
    .vertical-layout.toggle-menu .leftbar:hover .profilename {
        display: block;
    }
    .vertical-layout.toggle-menu .leftbar:hover .userbox {
        display: block;
    }
    .vertical-layout.toggle-menu .leftbar:hover .vertical-header {
        display: block;
    }
    .vertical-layout.toggle-menu .leftbar:hover .profilebar {
        padding: 15px 30px 15px 30px;
    }
    .vertical-layout.toggle-menu .leftbar:hover .logobarHo .logo.logo-small {
        display: none;
    }
    .vertical-layout.toggle-menu .leftbar:hover .logobarHo .logo.logo-large {
        display: block;
    }
    .vertical-layout.toggle-menu .leftbar:hover .logobarHo .logo img {
        width: 120px;
    }
    .vertical-layout.toggle-menu .leftbar:hover ~ .rightbar .topbar {
        left: 250px;
    }
    .vertical-layout.toggle-menu .vertical-menu > li > a > i {
        font-size: 18px;
    }
    .vertical-layout.toggle-menu .vertical-menu > li > a > span {
        display: none;
    }
    .vertical-layout.toggle-menu .vertical-menu li > a > .icon-chevron-right {
        display: none;
    }
    .vertical-layout.toggle-menu .vertical-menu li.active > .vertical-submenu {
        display: none !important;
    }
    .vertical-layout.toggle-menu .profilename {
        display: none;
    }
    .vertical-layout.toggle-menu .userbox {
        display: none;
    }
    .vertical-layout.toggle-menu .vertical-header {
        display: none;
    }
    .vertical-layout.toggle-menu .profilebar {
        padding: 15px 15px 15px 15px;
    }
    .vertical-layout.toggle-menu .logobarHo .logo.logo-small {
        display: block;
    }
    .vertical-layout.toggle-menu .logobarHo .logo.logo-large {
        display: none;
    }
    .vertical-layout.toggle-menu .logobarHo .logo img {
        width: 30px;
    }
}
@media (max-width: 767px) {
    body {
        overflow-x: hidden;
    }
    h1 {
        font-size: 36px;
    }
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 15px;
    }
    .leftbar {
        position: fixed;
        margin-left: -250px;
    }
    .rightbar {
        margin-left: 0 !important;
    }
    .topbar-mobile {
        display: block;
        position: fixed;
        width: 100%;
        z-index: 1;
    }
    .topbar {
        display: none;
    }
    .topbar .togglebar {
        display: none;
    }
    .topbar .infobar {
        margin-right: 0;
    }
    .topbar-toggle-menu .topbar {
        display: block;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1;
        margin-top: 71px;
    }
    .menubar {
        text-align: right;
    }
    .footerbar {
        left: 0;
    }
    .email-rightbar .email-open-box .open-email-head ul {
        text-align: left;
        margin-top: 15px;
    }
    .breadcrumbbar {
        padding: 30px 0 0 0;
    }
    .breadcrumbbar .page-title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .breadcrumbbar .breadcrumb-list {
        text-align: center;
    }
    .breadcrumbbar .widgetbar {
        text-align: center;
        margin-top: 10px;
    }
    .analytic-chart-label .analytic-label-perform {
        text-align: left;
        margin-bottom: 30px;
    }
    .analytic-chart-label .list-inline {
        text-align: left;
    }
    .analytic-chart-label .analytic-chart-piety {
        text-align: left;
    }
}
@media (max-width: 420px) {
    .infobar-notifications-sidebar.sidebarshow {
        width: 100%;
    }
    .infobar-settings-sidebar.sidebarshow {
        width: 100%;
    }
}
