.footer1-ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.footer1-ul>li {
    padding: 4px;
    color:#C5CAE9;
}

.footer1-ul>li:hover {
    color: #957bda;
    cursor: pointer
}

.footer1-hr {
    border-width: 3px
}

.footer1-card {
    padding: 2% 2%
}

.footer1-social>i {
    padding: 1%;
    font-size: 15px
}

.footer1-social>i:hover {
    color: #957bda;
    cursor: pointer
}

.footer1-policy>div {
    padding: 4px
}

.footer1-heading {
    font-family: 'Titillium Web', sans-serif;
    color: black
}

.footer1-divider {
    border-top: 2px solid
}