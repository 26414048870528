.team-our-team {
    padding: 30px 0 40px;
    margin-bottom: 30px;
    background-color: #f7f5ec;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  
  .team-our-team .team-picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
  }
  
  .team-our-team .team-picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: var(--mainPrimaryCol);
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
  }
  
  .team-our-team:hover .team-picture::before {
    height: 100%;
  }
  
  .team-our-team .team-picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--mainPrimaryCol);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .team-our-team .team-picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
  }
  
  .team-our-team:hover .team-picture img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.7);
  }
  
  .team-our-team .team-title {
    display: block;
    font-size: 15px;
    color: #4e5052;
    text-transform: capitalize;
  }
  
  .team-our-team .team-social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--mainPrimaryCol);
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.5s ease 0s;
  }
  
  .team-our-team:hover .team-social {
    bottom: 0;
  }
  
  .team-our-team .team-social li {
    display: inline-block;
  }
  
  .team-our-team .team-social li a {
    display: block;
    padding: 10px;
    font-size: 17px;
    color: white;
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  
  .team-our-team .team-social li a:hover {
    color: var(--mainPrimaryCol);
    background-color: #f7f5ec;
  }
  